/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
//   BookmarkAltIcon,
//   CalendarIcon,
//   ChartBarIcon,
//   CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
//   ShieldCheckIcon,
//   SupportIcon,
//   ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ArrowCircleRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { Link, useNavigate } from 'react-router-dom'
import { images } from '../constants'
import RequestCallbackDialog from '../components/RequestCallbackDialog'
import HeaderBanner from '../components/HeaderBanner'
import { useSelector } from 'react-redux'
import BreadCrumb from '../pages/BreadCrumb'
import { setThemeColor } from '../utils/Utils'
import { useEffect } from 'react'
import MobileAppPresentationDialog from '../components/MobileAppPresentationDialog'

const menuItems = [
    { name: 'Careers', navigation: '/career' },
    { name: 'Contact Us', navigation: '/contactus' },
    // { name: 'Coming Soon', navigation: '/comingsoon' },
]
const mobileMenuItems = [
    { name: 'Careers', navigation: '/career' },
    { name: 'Contact Us', navigation: '/contactus' },
    // { name: 'Coming Soon', navigation: '/comingsoon' },
]

const serviceTitles = [
  { name: 'FSSAI Registration', navigation: '/fssai-food-license', icon: PlayIcon },
  { name: 'FSSAI State License', navigation: '/fssai-food-license', icon: PlayIcon },
  { name: 'FSSAI Central License', navigation: '/fssai-food-license', icon: PhoneIcon },
//   { name: 'Not Sure?', navigation: '/services/fssai-recommend', icon: PhoneIcon },
]

const servicesCol1 = [
    { name: 'New License Application', navigation: '/fssai-food-license-new?type=registration-certificate', icon: images.new_agreement },
    { name: 'License Renewal', navigation: '/fssai-food-license-renewal?type=state-license', icon: images.renew },
    { name: 'License Modification', navigation: '/fssai-food-license-modification?type=central-license', icon: images.modification },
    // { name: 'FLRS to FoSCoS Migration', navigation: '/fssai-food-license-migration', icon: images.migration },
]

const servicesCol2 = [
    { name: 'New License Application', navigation: '/fssai-food-license-new?type=registration-certificate', icon: images.new_agreement },
    { name: 'License Renewal', navigation: '/fssai-food-license-renewal?type=state-license', icon: images.renew },
    { name: 'License Modification', navigation: '/fssai-food-license-modification?type=central-license', icon: images.modification },
    // { name: 'FLRS to FoSCoS Migration', navigation: '/fssai-food-license-migration', icon: images.migration },
    // { name: 'File Annual Returns', navigation: '/fssai-food-license-file-itr', icon: images.file_itr },
]

const servicesCol3 = [
    { name: 'New License Application', navigation: '/fssai-food-license-new?type=registration-certificate', icon: images.new_agreement },
    { name: 'License Renewal', navigation: '/fssai-food-license-renewal?type=state-license', icon: images.renew },
    { name: 'License Modification', navigation: '/fssai-food-license-modification?type=central-license', icon: images.modification },
    // { name: 'FLRS to FoSCoS Migration', navigation: '/fssai-food-license-migration', icon: images.migration },
    // { name: 'File Annual Returns', navigation: '/fssai-food-license-file-itr', icon: images.file_itr },
]

const services = [
    // {
    //     name: 'End-to-End Package',
    //     description: 'One-stop solution for all your queries',
    //     imageSrc: images.compliance_dark,
    //     imageAlt: '',
    //     navigation: '',
    //     items: [],
    //     background: {backgroundColor: '#b2f5ea'}
    // },
    {
        name: 'FSSAI License',
        description: 'New food license, license modification, renewal etc.',
        imageSrc: images.license_dark,
        imageAlt: '',
        navigation: '/fssai-food-license',
        items: [],
        background: { backgroundColor: '#e2d4ff' }
    },
    {
        name: 'FSSAI Regulatory',
        description: 'Annual returns, lable/product review, export advisory etc.',
        imageSrc: images.regulatory_dark,
        imageAlt: '',
        navigation: '',
        items: [],
        background: { backgroundColor: '#e2d4ff' }
    },
    {
        name: 'Food Testing',
        description: 'Microbial testing, nutritional analysis, shelf life testing etc.',
        imageSrc: images.food_testing_dark,
        imageAlt: '',
        navigation: '/testing-food',
        items: [],
        background: { backgroundColor: '#e7ebef' }
    },
    {
        name: 'Water Testing',
        description: 'Potable, mineral, packaged drinking water testing.',
        imageSrc: images.water_testing_dark,
        imageAlt: '',
        navigation: '/testing-water',
        items: [],
        background: { backgroundColor: '#e2d4ff' }
    },
    {
        name: 'FoSTaC Training',
        description: 'Basic & advance food safety supervisor training',
        imageSrc: images.certificate_dark,
        imageAlt: '',
        navigation: '/training',
        items: [],
        background: { backgroundColor: '#feebc8' }
    },
    {
        name: 'Health Checkup',
        description: 'Medical fitness test for food handlers.',
        imageSrc: images.health_dark,
        imageAlt: '',
        navigation: '/medical-fitness',
        items: [],
        background: { backgroundColor: '#f4ced4' }
    },
    {
        name: 'Pest Control',
        description: 'Pest control as per the regulation.',
        imageSrc: images.pest_control_dark,
        imageAlt: '',
        navigation: '/pest-control',
        items: [],
        background: { backgroundColor: '#f4ced4' }
    },
    {
        name: 'Instrument Calibration',
        description: 'Instrument calibration as per the regulation.',
        imageSrc: images.calibration_dark,
        imageAlt: '',
        navigation: '/process',
        items: [],
        background: { backgroundColor: '#f4ced4' }
    },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = ({ path }) => {
    
    console.log('Path in Navbar : ', path)

    let navBarColor = 'bg-purple-600';
    let textColor = 'text-white';
    let hoverColor = 'text-darkblue-200';
    const [show, showServices] = useState(false);
    const [showRCDialog, setShowRCDialog] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [showBanner, setShowBanner] = useState(true);
    const [showMAppDialog, setShowMAppDialog] = useState(false);

    const navigate = useNavigate();

    const { navTitle, navDescription, primaryColor } = useSelector(state => state.app);
    
    if (primaryColor === 'red') {
        navBarColor = 'bg-red-500'
    } else if (primaryColor === 'white') {
        navBarColor = `bg-darkblue-200`
    } else {
        navBarColor = `bg-${primaryColor}-600`
    }

    if (primaryColor === 'white') {
        textColor = 'text-gray-900';
        hoverColor = 'text-black';
    } else {
        textColor = 'text-white';
        hoverColor = 'text-darkblue-200';
    }

    setThemeColor(navBarColor);

    useEffect(() => {
        setTimeout(() => {
            setShowMAppDialog(true);
        }, 1500);
    }, [path])
    

    return (
        <>
            <MobileAppPresentationDialog show={showMAppDialog} bgColor={primaryColor} onDialogClosed={() => setShowMAppDialog(false)} />
            <RequestCallbackDialog show={showRCDialog} onDialogClosed={() => { setShowRCDialog(false); setOpenMenu(false) }} />
            {/* <nav className="sticky top-0 bg-teal-600 p-5 drop-shadow shadow-teal-600">
                <h1 className="text-white text-4xl text-center">Sticky Nav Bar</h1>
            </nav> */}
            <Popover className={`bg-9 fixed w-full z-50 text-${primaryColor}-100 ${navBarColor}`}>
                {/* {showBanner && <HeaderBanner onClose={() => setShowBanner(false)} />} */}
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center border-gray-100 py-3 md:py-5 md:justify-start md:space-x-10">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <Link to="/" className='hover:text-gray-200'>
                                <h1 className={`text-2xl lg:text-3xl text-base font-medium ${textColor}`}>{navTitle}</h1>
                                <h1 className={`text-sm ${textColor}`}>{navDescription}</h1>
                            </Link>
                            <Popover.Group as="nav" className="hidden md:flex ml-5 mt-1 items-center"
                                onMouseEnter={() => showServices(true)}
                                onMouseLeave={() => showServices(false)}  
                            >
                                <Popover className="relative">
                                {({ open }) => (
                                    <>
                                    <Popover.Button
                                        
                                        className={classNames(
                                        open ? `text-gray-100` : `${textColor}`,
                                        `group ${navBarColor} rounded-md inline-flex items-center text-base font-medium hover:${hoverColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${primaryColor}-500`
                                        )}
                                    >
                                        <span>Services</span>
                                        {show ? <ChevronUpIcon
                                            className={classNames(
                                                open ? `text-gray-100` : `${textColor}`,
                                                `ml-2 h-5 w-5 group-hover:text-gray-500`
                                            )}
                                            aria-hidden="true"
                                        /> : <ChevronDownIcon
                                            className={classNames(
                                                open ? `text-gray-100` : `${textColor}`,
                                                `ml-2 h-5 w-5 group-hover:text-gray-500`
                                            )}
                                            aria-hidden="true"
                                        />}
                                    </Popover.Button>

                                    <Transition
                                        as={Fragment}
                                        show={show}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel
                                            onMouseLeave={() => showServices(false)}              
                                            className="scroll scroll-auto absolute z-50 -ml-4 mt-3 transform px-2 w-screen md:max-w-2xl lg:max-w-5xl sm:px-0 lg:ml-0 md:left-20 md:-translate-x-40 lg:left-1/2 lg:-translate-x-56">
                                            <div className="mt-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-scroll" style={{height: '44rem'}}>
                                                <div className="bg-white px-12 pt-8">
                                                            <div onClick={() => { navigate('/completeCompliance'); showServices(false) }} className="group bg-purple-200 hover:bg-purple-300 cursor-pointer px-5 pt-6 pb-5 text-left" style={{ borderRadius: '1rem', }}>
                                                        <div className="flex flex-col md:flex-row justify-around md:justify-center items-center md:w-8/12 md:mx-auto lg:w-full">
                                                            <div className='md:w-36' style={{ flexShrink: 0, width: '7.5rem'}}>
                                                                <img className="w-20 object-center object-cover mx-auto" src={images.compliance_dark} alt="illustration" loading="lazy" />
                                                            </div>
                                                            <div className="mt-5 md:mt-0 flex flex-col items-start">
                                                                <h1 className="text-xl font-extrabold text-gray-900">Complete Compliance Package</h1>
                                                                <h3 className="mt-2 text-md text-gray-900 tracking-wide">
                                                                    All in one solution to manage end-to-end compliance needs.
                                                                </h3>
                                                            </div>
                                                            {/* <Link to='/completeCompliance' onClick={() => showServices(false)} className='mt-5 md:mt-2 self-end' style={{flexShrink: 0}}>
                                                                <ArrowCircleRightIcon
                                                                    className="text-purple-500 h-10 w-10" aria-hidden="true">
                                                                </ArrowCircleRightIcon> 
                                                            </Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative pt-5 pb-10 bg-white">  
                                                    <div className="relative container m-auto px-6 text-gray-500 md:px-12">
                                                        <div className="grid lg:grid-cols-3 gap-6 md:w-8/12 md:mx-auto lg:w-full">
                                                            {services.map((e, idx) =>
                                                                <Link
                                                                    to={e.navigation}
                                                                    onClick={() => showServices(false)}
                                                                    className={
                                                                        classNames(idx === 0 ? 'bg-darkblue-200 hover:bg-darkblue-300'
                                                                            : idx === 1 ? 'bg-darkblue-200 hover:bg-darkblue-300'
                                                                            : idx === 2 ? 'bg-green-200 hover:bg-green-300'
                                                                            : idx === 3 ? 'bg-teal-200 hover:bg-teal-300' 
                                                                            : idx === 4 ? 'bg-orange-200 hover:bg-orange-300'
                                                                            : idx === 5 ? 'bg-red-200 hover:bg-red-300'
                                                                            : idx === 6 ? 'bg-silver-200 hover:bg-silver-300'
                                                                            : idx === 7 ? 'bg-pink-200 hover:bg-pink-300' : '', "group bg-white rounded-lg shadow-lg px-5 pt-6 pb-5 text-left")} style={{ borderRadius: '1rem' }}>
                                                                    <img className="w-10 object-center object-contain" src={e.imageSrc} alt="illustration" loading="lazy" />
                                                                    <h1 className="mt-3 text-lg font-extrabold text-gray-900">{e.name}</h1>
                                                                    <h3 className="text-md text-gray-900 tracking-wide">{e.description}</h3>
                                                                    {/* <div className='mt-5 md:mt-2 group flex justify-start md:justify-end items-end'>
                                                                        {idx === 0 ?
                                                                            <div className='flex justify-center items-center w-28 block px-4 p-2 border border-transparent text-base rounded-lg text-white bg-${primaryColor}-500 hover:bg-${primaryColor}-700 focus:outline-none'>
                                                                                <Link
                                                                                    to="/fssai-food-license"
                                                                                    onClick={(e) => showServices(false)}
                                                                                    className="text-sm"
                                                                                >
                                                                                    Get Started
                                                                                </Link>
                                                                            </div>
                                                                            : 
                                                                            <div
                                                                                className={
                                                                                    classNames(idx === 0 ? 'bg-${primaryColor}-500 hover:bg-${primaryColor}-700'
                                                                                        : idx === 1 ? 'bg-green-500 hover:bg-green-700'
                                                                                        : idx === 2 ? 'bg-teal-500 hover:bg-teal-700' 
                                                                                        : idx === 3 ? 'bg-${primaryColor}-500 hover:bg-${primaryColor}-700'
                                                                                        : idx === 4 ? 'bg-red-500 hover:bg-red-700'
                                                                                        : idx === 5 ? 'bg-gray-500 hover:bg-gray-700' : '','flex justify-center items-center w-28 block px-4 p-2 border border-transparent text-base rounded-lg text-white focus:outline-none')}>
                                                                                <Link
                                                                                    to="/"
                                                                                    onClick={(e) => showServices(false)}
                                                                                    className="text-sm"
                                                                                >
                                                                                    Coming Soon
                                                                                </Link>
                                                                            </div>
                                                                        }
                                                                    </div> */}
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                    </>
                                )}
                                </Popover>
                            </Popover.Group>  
                        </div>
                        <div className="-my-2 md:hidden">
                            <Popover.Button onClick={() => setOpenMenu(!openMenu)} className={`transition ease-linear ${navBarColor} rounded-md inline-flex items-center justify-center text-gray-400 focus:outline-none`}>
                                <span className="sr-only">Open menu</span>
                                {openMenu ? <XIcon className="h-6 w-6" aria-hidden="true" /> : <MenuIcon className="h-6 w-6" aria-hidden="true" />}
                            </Popover.Button>
                        </div>
                        
                        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 space-x-10">
                            {menuItems.map((e) => (
                                <Link to={e.navigation} key={e.name} className={`text-base font-medium ${textColor} hover:${hoverColor}`}>
                                    {e.name}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>

                {/* BreadCrumb Component */}
                {
                    path !== '/' &&  <BreadCrumb />
                }

                <Transition
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    
                >
                    <Popover.Panel className="bg-opacity-70 backdrop-blur absolute top-10 inset-x-0 transition transform origin-top-right md:hidden">
                        {({ close }) => (
                            <div className={`bg-${ primaryColor }-500 divide-y-2 divide-gray-50`}>
                                <div className="pb-6 px-5">
                                    {/* <div className="flex items-center justify-between">
                                        <div>
                                            <p className="text-2xl text-gray-900 lg:text-4xl">aahar.compliance</p>
                                            <img
                                                className="h-8 w-auto"
                                                src="https://tailwindui.com/img/logos/workflow-mark-${primaryColor}-600.svg"
                                                alt="Workflow"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${primaryColor}-500">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div> */}
                                    
                                    {/* <hr className='my-2' /> */}
                                        
                                    <div className="">
                                        <nav className="grid gap-y-8">
                                            {mobileMenuItems.map((item) => (
                                                <Link
                                                    onClick={() => { close();  setOpenMenu(false)}}
                                                    key={item.name}
                                                    to={item.navigation}
                                                    className={classNames(primaryColor !== 'white' ? 'bg-transparent' : 'bg-white',"mt-2 -m-3 p-3 flex items-center rounded-md")}
                                                >
                                                        <span className={`ml-3 text-base font-medium ${textColor}`}>{item.name}</span>
                                                </Link>
                                            ))}
                                            {/* <button
                                                onClick={() => setShowRCDialog(true)}
                                                className="block text-white bg-${primaryColor}-500 hover:text-white hover:bg-${primaryColor}-600 mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">
                                                Request Callback
                                            </button> */}
                                        </nav>
                                    </div>
                                </div>
                                {/* <div className="py-6 px-5 space-y-6">
                                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                    Pricing
                                    </a>

                                    <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                    Docs
                                    </a>
                                    {resources.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        {item.name}
                                    </a>
                                    ))}
                                </div>
                                <div>
                                    <a
                                    href="#"
                                    className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-${primaryColor}-600 hover:bg-${primaryColor}-700"
                                    >
                                    Sign up
                                    </a>
                                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                                    Existing customer?{' '}
                                    <a href="#" className="text-${primaryColor}-600 hover:text-${primaryColor}-500">
                                        Sign in
                                    </a>
                                    </p>
                                </div> 
                                </div> */}
                            </div>
                        )}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </>
  )
}

export default Navbar;