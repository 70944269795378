import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import MobileAppPresentation from './MobileAppPresentation';
import confetti from 'canvas-confetti';
import { useEffect } from 'react';

const MobileAppPresentationDialog = ({ show, onDialogClosed, bgColor }) => {
    
    let pColor = 'darkblue';
    if (bgColor === 'white' || !bgColor ) {
        pColor = 'darkblue'
    } else {
        pColor = bgColor;
    };

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onDialogClosed}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-stretch md:items-center justify-center min-h-full text-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex text-base text-left transform transition md:max-w-5xl md:px-4 md:my-2">
                                <div className={`bg-${pColor}-500 rounded-lg mx-5 mt-16`}>
                                    <div className="flex justify-center items-center">
                                        {/* <h1 className="mt-5 text-2xl text-center font-extrabold text-gray-900">Our Services</h1> */}
                                        <div style={{right: 0}} className="absolute mt-12 md:mt-20 text-right pr-8 md:pr-12 px-4 border border-transparent">
                                            <button
                                                className='focus:outline-none'
                                                onClick={() => onDialogClosed()}
                                            >
                                                <XIcon className='h-5 md:h-8 w-5 md:w-8 text-white' aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                    
                                    {/* <h1 className="mt-3 text-lg text-center font-extrabold text-gray-900">Individual Services</h1> */}
                                    <div className="relative mt-16 pb-10 px-4 md:px-8">  
                                        <MobileAppPresentation isDialog={true} />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default MobileAppPresentationDialog;