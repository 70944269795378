import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Faq = ({cols, titleAlignment}) => {
    
    const [expandedRow, setExpandedRow] = useState({ r1: false, r2: false, r3: false, r4: false, r5: false, r6: false, r7: false, r8: false, r9: false, r10: false, r11: false, r12: false});
    const [selectedTopic, setSelectedTopic] = useState("Introduction");
    const [selectedFaqs, setSelectedFaqs] = useState([]);

    const faqTopics = [
        "Introduction",
        "Procedure",
        "Non-Complaince",
        "Do & Dont's",
        "Documents"
    ];

    const faqs = [
        {
            isExpanded: false,
            q: `Are different Licenses required to conduct different kind of food business activities at the same premise?`,
            a: `FSSAI license is based on premise. All kinds of food business activities which are being conducted at the 
                same premise can be applied for in a single FSSAI license`
        },
        {
            isExpanded: false,
            q: `How to convert manually issued licenses/ registration to online during renewal of license?`,
            a: `FBO can approach the Designated Officer [Licensing Authority] or Registering Authority of that area.
                Designated Officer/ Registering Authority will enter the details of the offline issued license / registration in
                the FoSCoS portal. Thereafter, the license/registration can be renewed through online process, whenever
                required.`
        },
        {
            isExpanded: false,
            q: `What are the advantages of converting your manually issued license to the online licensing and
                registration system [FoSCoS]? `,
            a: `FoSCoS portal sends regular alerts to FBOs regarding various compliances through email and sms.
                FBOs will be able to track the compliances attached with their licenses / registration from time to time and
                can avoid penalties, if any.`
        },
        {
            isExpanded: false,
            q: `Who are petty food business operators? What is the eligibility for FSSAI registration certificate? `,
            a: `Petty Food Business Operators (FBO) who himself manufactures or sells any article of food or a petty
                retailer, hawker, itinerant vendor or a temporary stall holder or small scale or cottage or such other industries
                relating to food business or tiny food business operator having annual turnover up to Rs. 12 Lacs and/or
                whose
                i. production capacity of food products does not exceed 100 kg or litres per day, or
                ii. procurement or handling and collection of milk is up to 500 litres of milk per day, or
                iii. slaughtering capacity is 2 large animals or 10 small animals or 50 poultry birds per day or
                less
                These FBOs are required to obtain FSSAI Registration Certificate by applying on Food Safety Compliance
                System or Food Safety Connect app. This Registration Certificate is embedded with QR code and has the
                image of FBO with 14-digit Registration number starting with 2 (2xxxxxxxxxxxxx).`
        },
        {
            isExpanded: false,
            q: `My License/Registration is suspended or cancelled. Do I need to stop food business activities?`,
            a: `On the suspension or cancellation of his/her License or registration, a food business operator needs to
                stop food business activities immediately. Carrying out any food business activity on suspended or cancelled
                License/Registration is an unlawful activity and shall attract penal actions under FSS Act, 2006.`
        },
        {
            isExpanded: false,
            q: `Do I need to obtain FSSAI License for utensils/packaging material meant for serving or packaging
                of food?`,
            a: `No. FSSAI License/Registration is not required for utensils or packaging material as these are not
                covered under the definition of food as per Section 3(n) of FSS Act, 2006.`
        },
    ];

    const faqData = [
        {
            topic: "Introduction",
            faqs: faqs
        },
        {
            topic: "Procedure",
            faqs: faqs
        },
    ];

    useEffect(() => {
        console.log('Selected Topic : ', selectedTopic);
        const filteredFaqs = faqData.filter(e => e.topic === selectedTopic);
        if (filteredFaqs && filteredFaqs.length > 0) {
            setSelectedFaqs(filteredFaqs[0].faqs)
        }
    }, [selectedTopic]);

    const onFaqClicked = (idx, isExpanded) => {
        const filteredFaqs = faqData.filter(e => e.topic === selectedTopic);
        if (filteredFaqs && filteredFaqs.length > 0) {
            const temp = filteredFaqs[0].faqs;
            temp[idx].isExpanded = !isExpanded;
            setSelectedFaqs(temp);
        }
    };

    const { primaryColor } = useSelector(state => state.app);
    
    let bgColor = 'bg-gray-200';
    if (primaryColor === 'white') {
        bgColor = `darkblue`;
    } else {
        bgColor = `${primaryColor}`;
    }

    
    return (
        <div className="py-12">
            <div className="max-w-6xl mx-auto px-4">
                <div className={classNames(titleAlignment === 'left' ? 'text-left' : 'text-center')}>
                    {/* <h2 className={`text-base text-${bgColor}-500 font-semibold tracking-wide uppercase`}>Transactions</h2> */}
                    <span class={`inline-block py-1 px-2 bg-${bgColor}-50 text-${bgColor}-500 text-xs font-medium tracking-widest`}>FAQ'S</span>
                    <h1 className="mt-5 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 md:text-4xl lg:text-5xl">
                        Frequently asked questions
                    </h1>
                    {/* <p className="mt-4 max-w-2xl text-md text-gray-500 lg:mx-auto">
                        Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                        accusamus quisquam.
                    </p> */}
                </div>

                <nav className="mt-10 py-4 bg-opacity-75 backdrop-blur-xl border-t border-b border-opacity-10">
                    <div className="w-full m-auto md:px-12">
                        <div className="w-max m-auto select-none flex gap-4 flex-wrap md:justify-center text-sm font-semibold h-auto">
                            <h1 className='text-lg px-0 md:px-4'>Topics: </h1>
                            {faqTopics.map((topic) => (
                                <button
                                    key={topic}
                                    onClick={() => setSelectedTopic(topic)}
                                    title={topic}
                                    aria-label={topic}
                                    className={classNames( topic === selectedTopic ? `bg-${bgColor}-500 text-white` : `bg-white text-gray-900 hover:text-${bgColor}-500` ,`px-4 py-1 rounded-full hover:border-${bgColor}-500 border border-opacity-20 cursor-pointer focus:outline-none`)}
                                >
                                    {topic}
                                </button>
                            ))}
                        </div>
                    </div>
                </nav>

                <div className='mt-10 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                    <div className={classNames(cols === '1' ? 'md:grid-cols-1 lg:grid-cols-1' : 'md:grid-cols-2 lg:grid-cols-2', 'w-full grid gap-4')}>
                        {selectedFaqs && selectedFaqs.map((e, idx) =>
                            <div key={idx} className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => onFaqClicked(idx, e.isExpanded)}>
                                <div className='px-3 flex justify-between items-start'>
                                    <h3 className={classNames(e.isExpanded ? 'text-gray-900 font-bold' : 'text-gray-700', 'text-left tracking-wide text-sm leading-6 group-hover:text-gray-900')}>{e.q}</h3>
                                    {(e.isExpanded)
                                        ? <ChevronUpIcon style={{ width: '2rem' }} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{ width: '2rem' }} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {e.isExpanded && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{selectedFaqs[idx].a}</p>
                                </div>}
                            </div>
                        )}
                    </div>
                </div>
                

                {/* <div className='mt-10 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                    <div className={classNames(cols === '1' ? 'md:grid-cols-1 lg:grid-cols-1' : 'md:grid-cols-2 lg:grid-cols-2','w-full grid gap-4')}>
                        <div className="w-full flex flex-col items-stretch">
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r1: !expandedRow.r1})}>
                                <div className='px-3 flex justify-between items-start'>
                                    <h3 className={classNames(expandedRow.r1 ? 'text-gray-900 font-bold' : 'text-gray-700', 'text-left tracking-wide text-sm leading-6 group-hover:text-gray-900')}>{faqs[0].q}</h3>
                                    {(expandedRow.r1)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r1 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[0].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r2: !expandedRow.r2})}>
                                <div className='px-3 flex justify-between items-start'>
                                    <h3 className={classNames(expandedRow.r2 ? 'text-gray-900 font-bold' : 'text-gray-700', 'text-left tracking-wide text-sm leading-6 group-hover:text-gray-900')}>{faqs[1].q}</h3>
                                    {(expandedRow.r2)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r2 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[1].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r3: !expandedRow.r3})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r3 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[2].q}</h3>
                                    {(expandedRow.r3)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r3 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[2].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r4: !expandedRow.r4})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r4 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[3].q}</h3>
                                    {(expandedRow.r4)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r4 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[3].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r5: !expandedRow.r5})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r5 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[4].q}</h3>
                                    {(expandedRow.r5)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r5 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[4].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r6: !expandedRow.r6})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r6 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[5].q}</h3>
                                    {(expandedRow.r6)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r6 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[5].a}</p>
                                </div>}
                            </div>
                        </div>
                        <div className="w-full flex flex-col items-stretch">
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r7: !expandedRow.r7})}>
                                <div className='px-3 flex justify-between items-start'>
                                    <h3 className={classNames(expandedRow.r7 ? 'text-gray-900 font-bold' : 'text-gray-700', 'text-left tracking-wide text-sm leading-6 group-hover:text-gray-900')}>{faqs[0].q}</h3>
                                    {(expandedRow.r7)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r7 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[0].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r8: !expandedRow.r2})}>
                                <div className='px-3 flex justify-between items-start'>
                                    <h3 className={classNames(expandedRow.r8 ? 'text-gray-900 font-bold' : 'text-gray-700', 'text-left tracking-wide text-sm leading-6 group-hover:text-gray-900')}>{faqs[1].q}</h3>
                                    {(expandedRow.r8)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r8 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[1].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r9: !expandedRow.r3})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r9 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[2].q}</h3>
                                    {(expandedRow.r9)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r9 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[2].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r10: !expandedRow.r4})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r10 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[3].q}</h3>
                                    {(expandedRow.r10)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r10 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[3].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r11: !expandedRow.r5})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r11 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[4].q}</h3>
                                    {(expandedRow.r11)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r11 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[4].a}</p>
                                </div>}
                            </div>
                            <div className="group cursor-pointer card-w-border hover:border-gray-400 pb-5 mb-5" onClick={() => setExpandedRow({r12: !expandedRow.r6})}>
                                <div className='px-3 flex justify-between'>
                                    <h3 className={classNames(expandedRow.r12 ? 'text-gray-900 font-bold' : 'text-gray-700' ,'text-left tracking-wide text-sm group-hover:text-gray-700')}>{faqs[5].q}</h3>
                                    {(expandedRow.r12)
                                        ? <ChevronUpIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                        : <ChevronDownIcon style={{width: '2rem'}} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                </div>
                                {expandedRow.r12 && <div className='pt-3 px-3 flex justify-between'>
                                    <p className='text-left tracking-wide text-sm text-gray-700'>{faqs[5].a}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
        
        
    )
}

export default Faq