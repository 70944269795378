const initialState = {
    primaryColor: 'purple'
};

const UtilsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'PRIMARY_COLOR':
            return {
                ...state,
                primaryColor: payload
            }
        default:
            return {...state};
    }
}

export default UtilsReducer;