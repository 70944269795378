import React from 'react';
// import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
import Testimonials from '../components/Testimonials';
import RequestCallback from '../components/RequestCallback';
import Faq from '../components/Faq';
import PricingPlans from '../components/PricingPlans';
import Newsletter from '../components/Newsletter';
import { images } from '../constants';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CourseCatalog from './CourseCatalog';
import { classNames } from '../utils/Utils';
import { ArrowCircleDownIcon, ChevronDownIcon } from '@heroicons/react/outline';
import E2EComplaince from '../components/E2EComplaince';

const Training = () => {

    const features = [
        {
            name: 'Expert Team',
            description:
                'A team of food technologists, FSSAI regulatory & food safety consultants assisting you in your compliance journey right from the first day of your business.',
            icon: images.experts_png,
        },
        {
            name: 'On Time',
            description:
                'We understand the importance of deadlines with FSSAI regulations and the underlying penalty, we will work with you to finish your licensing work on time.',
            icon: images.on_time_png,
        },
        {
            name: 'Affordable Price',
            description:
                'Unlike other consultants who do incomplete work by charging less and leave you in the middle, we conduct a thorough review of your business & food product to provide a one-time reasonable estimate and stay till the closure.',
            icon: images.price,
        },
        {
            name: '100% Satisfaction',
            description:
                "Offload your compliance responsibilities to us, and we'll take care of the rest, allowing you to focus on your core business. We are confident that you will be satisfied with our service.",
            icon: images.lol,
        },
    ];

    const benefits = [
        {
            title: "Certificate",
            description: "Participants will get a FoSTaC certificate from FSSAI which adds upto their resume. The certificate is valid for lifetime in the specific food category and is valid across India.",
            imgSrc: images.benefits_certificate,
            imgAlt: 'Certificate'
        },
        {
            title: "Knowledge",
            description: "Enhance your knowledge on the concepts of food safety & standards, good manufacturing & good hygiene practices and become a food safety supervisor in your company.",
            imgSrc: images.benefits_knowledge,
            imgAlt: 'Knowledge'
        },
        {
            title: "Compliance",
            description: "One of the important steps of complying with FSSAI regulations is to have at-least one food safety supervisor for every 25 food handlers. This training will ensure that your business is in compliance with the regulations.",
            imgSrc: images.benefits_complaince,
            imgAlt: 'Compliance'
        }
    ];

    const process = [
        {
            title: "Talk to our team to understand which training course is necessary for your business/ career and proceed with the booking online",
            description: "",
            imgSrc: images.training_support,
            imgAlt: 'Support'
        },
        {
            title: "Post payment, an account will be created in our learning module system to manage your courses and FoSTaC portal to download your certificate",
            description: "",
            imgSrc: images.training_payment,
            imgAlt: 'Payment'
        },
        {
            title: "The training will be conducted online on zoom/ meet. The participant will be required to complete an online evaluation after the course",
            description: "",
            imgSrc: images.training_video_call,
            imgAlt: 'Video-Conference'
        },
        {
            title: "After the successful completion of the assessment, within 7 days, the results will be declared and the certificate is made available for download",
            description: "",
            imgSrc: images.training_certificate,
            imgAlt: 'Certificate'
        }
    ];
    
    return (
        <>
            <Helmet>
                <title>FSSAI Food License Registration, Renewal, Modification | Aahar Compliance</title>
                <meta name='title' content='FSSAI Food License Registration, Renewal, Modification | Aahar Compliance' />
                <meta name='description' content='Get your FSSAI food license registration, renewal, modification done by a team of food technologists & food safety consultants at an affordable price!' />
                <meta name='keywords' content='FSSAI Food License, FSSAI Food Registration Certificate, FSSAI Food State License, FSSAI Food Central License, FSSAI Food License Modification, FSSAI Food License Renewal' />
            </Helmet>
            <div className="max-w-6xl mx-auto">
                <div className="relative bg-white">
                    <div className="mt-10 mx-auto max-w-6xl px-4 mt-20 sm:px-6 md:mt-20 lg:mt-32 xl:mt-32">
                        <div className="text-center">
                            <div className='h-screen-min'>
                                <div className='w-full flex flex-col-reverse md:flex md:flex-row-reverse items-center'>
                                    <div className='w-full mt-5 px-3 md:px-0 md:ml-5 md:w-2/3'>
                                        <div className="text-3xl tracking-tight text-left font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                            <h1 className="text-3xl font-extrabold tracking-tight text-orange-500 md:text-3xl lg:text-4xl">
                                                FoSTaC - Food Safety Training & Certification Food Safety Supervisor Training
                                            </h1>
                                        </div>
                                        <p className="mt-3 text-left tracking-wide text-gray-700">
                                            FoSTaC is a food safety supervisor training program implemented and made mandatory by FSSAI in India. As per FSSAI, every food business must have at-least one certified food safety supervisor for every 25 food handlers.
                                            The training will provide the participant with a basic knowledge of food safety & standards, good manufacturing and good hygiene practices as per Schedule 4 of Food Safety and Standards Licensing and Registration Regulations, 2011.
                                        </p>
                                    </div>
                                    <div className='w-full mt-10 mb-5 md:w-1/3'>
                                        <img
                                            className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                            src={images.training}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <p className='mt-10 text-gray-900 tracking-normal text-3xl font-bold'>
                                    Learn from <span className="text-orange-500">India's</span> best food safety veterans & professionals with <span> <br /> rich <span className="text-orange-500">real-world</span> industry experience!</span>
                                </p>
                                <div className='md:flex md:justify-center'>
                                    <RequestCallback topic='FoSTaC Training' layoutStyle={'md'} />
                                </div>
                            </div>
                            {/* <hr className='my-10' /> */}
                            
                            <div className="flex justify-center mt-5">
                                {/* <p className='mt-10 text-gray-900 tracking-normal text-3xl font-bold'>
                                    Search courses from our <span className="text-orange-500">catalog</span>
                                </p> */}
                                <a href="#search">
                                    <ArrowCircleDownIcon className='w-10 h-10 text-orange-500' />
                                </a>
                            </div>

                            <div className='h-screen-min'>
                                <a className="anchor" id="search"></a>
                                <div className="mt-8 text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                        Search by Course
                                    </h1>
                                </div>

                                <div className="pt-5 flex flex-col items-stretch md:flex-row lg:flex-row md:justify-center lg:justify-center">
                                    <div className="pb-5">
                                        <input
                                            type="search"
                                            name="search"
                                            id="search"
                                            autoComplete="search"
                                            placeholder="Search by Course."
                                            className='form-input py-3 lg:w-64 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                        />
                                    </div>
                                    <div className="md:pl-5 lg:pl-5">
                                        <Link to=""
                                            className='lg:px-6 py-3 p-3 block text-white bg-orange-500 hover:text-white hover:bg-orange-600 text-sm font-semibold text-center rounded-lg shadow-md'>Search</Link>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="font-medium mt-5 lg:mt-2">
                                        <div className="text-center text-teal-900 bg-teal-200 p-4 shadow rounded-lg">
                                            <p>
                                                FoSTaC Basic Course - Applicable for Petty Food Businesses with FSSAI Registration Certificate</p>
                                            <p className='mt-3'>
                                                FoSTaC Advance Course - Applicable for medium-large businesses with FSSAI State/ Central Food License
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                                <CourseCatalog />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='bg bg-gray-200' />

            {/* Our Process */}
            <div className="bg-orange-100">
                <div className="min-h-full max-w-6xl mx-auto py-6 lg:py-20">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base text-orange-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                            <h1 className="text-4xl tracking-tight font-extrabold text-orange-500 sm:text-5xl md:text-5xl">
                                Our Process
                            </h1>
                            {/* <h2 className="mt-4 max-w-2xl text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                4 easy steps to help you get your fssai licence work done.
                            </h2> */}
                        </div>
                    </div>
                    <div className="mt-10 grid gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-4">
                        {process.map((e, idx) =>  <div className="mx-4 sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-32 object-contain"
                                        src={e.imgSrc} alt={e.imgAlt} />
                                    <div className="mt-10 flex flex-col justify-start lg:justify-between">
                                        <h3 className={`text-2xl sm:text-2xl font-bold text-orange-600`} style={styles.stepText}>
                                            {`Step 0${idx+1}`}
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-5 text-semibold tracking-wide text-gray-900">
                                            {e.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> )}
                    </div>
                </div>
            </div>
            <hr />

            {/* Why are we better */}
            <div className="bg-white">
                <div className="min-h-full max-w-6xl mx-auto py-6 lg:py-20 bg-transparent">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base text-orange-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                            <h1 className="text-4xl tracking-tight font-extrabold text-orange-500 sm:text-5xl md:text-5xl">
                                Benefits of attending training
                            </h1>
                            {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                accusamus quisquam.
                            </p> */}
                        </div>
                    </div>
                    <div className="mt-10 grid gap-16 md:grid-cols-2 lg:grid-cols-2">
                        {benefits.map((e, idx) =>
                            <div className="mx-4 bg-transparent sm:rounded-lg pt-3 pb-3 sm:pb-4 lg:w-full lg:mt-3 lg:m-5">
                                <div className="sm:flex sm:justify-center sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 lg:flex">
                                        <div className={classNames(idx===0 ?"w-2/3 pr-8 mx-auto": "w-full")}>
                                            <img
                                                className="mx-auto h-48 lg:h-32 object-contain"
                                                src={e.imgSrc} alt={e.imgAlt} />
                                        </div>
                                        <div className="mt-5 lg:mt-0 lg:ml-5 lg:text-left flex flex-col justify-start lg:justify-between h-36">
                                            <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                                {e.title}
                                            </h3>
                                            <p className="text-md mt-3 lg:mt-0 text-semibold tracking-wide text-gray-700">
                                                {e.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <hr className='bg bg-gray-200' />
            <E2EComplaince />

            <hr className='bg bg-gray-200' />
            <Newsletter />
        </>
    )
}

const styles = {
    stepText: {
        // color: "#314259",
        // opacity: 0.5,
        fontSize: '2rem',
        fontWeight: 1000,
        margin: 0,
        letterSpacing: '-1px',
    }
}

export default Training