import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { notifyLaunches } from '../actions/marketing';
import { images } from '../constants';

const ComingSoon = () => {

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNo: '',
        topic: 'Coming Soon - FSSAI Regulatory'
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Notify Me!');
        console.log('Form Data : ', formData);
        dispatch(notifyLaunches(formData));
        setShowMessage(true);
    };
  
    return (
        <>
            <Helmet>
                <title>Coming Soon</title>
                <meta name="description" content="Coming Soon" />
            </Helmet>
            <div className="max-w-6xl mx-auto">
                <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                    <div className="mt-10 mx-auto max-w-6xl px-4 mt-20 sm:px-6 md:mt-16 lg:mt-32 lg:px-8">
                        <div className="text-center">
                            <div className="mt-10 flex justify-center">
                                <img className='h-56' src={images.coming_soon} alt='not-found' />
                            </div>  
                            <div className="mt-10 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                                <h1 className="block xl:inline">Work in progress</h1>{' '}
                                {/* <p className="block text-purple-600 xl:inline">Introducing aahar.compliance!</p> */}
                            </div>
                            <h2 className="mt-3 text-base tracking-wider text-gray-700 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                We're about to launch something exciting. Drop us your details, and we'll let you know as soon as we go live.
                            </h2>
                            {/* <Link to='/' className='text-purple-500 font-bold'> home page </Link> */}
                            {!showMessage && <form onSubmit={onSubmit}>
                                <div className="mt-10 flex flex-col md:flex-row justify-center">
                                    <div className="pb-5 md:pl-5 lg:pl-5">
                                        <input
                                            type="name"
                                            name="name"
                                            id="name"
                                            onChange={onChange}
                                            autoComplete="name"
                                            placeholder="Enter your name"
                                            className='form-input py-3 lg:w-64 border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                            required
                                        />
                                    </div>
                                    <div className="pb-5 md:pl-5 lg:pl-5">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            onChange={onChange}
                                            autoComplete="email"
                                            placeholder="Enter your email"
                                            className='form-input py-3 lg:w-64 border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                            required
                                        />
                                    </div>
                                    <div className="pb-5 md:pl-5 lg:pl-5">
                                        <input
                                            type="tel"
                                            name="phoneNo"
                                            id="phoneNo"
                                            onChange={onChange}
                                            maxLength="10"
                                            autoComplete="tel"
                                            placeholder="Enter your phone no"
                                            className='form-input py-3 lg:w-60 border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                            required
                                        />
                                    </div>
                                    <div className="md:pl-5 lg:pl-5">
                                        <button type='submit'
                                            className='lg:px-6 py-3 p-3 block text-white bg-purple-500 hover:text-white hover:bg-purple-600 text-sm font-semibold text-center rounded-lg shadow-md'
                                        >Notify me</button>
                                    </div>
                                </div>
                            </form>}
                            {showMessage && <div className="mt-5 flex justify-center">
                                <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                    <span className='flex items-center'>
                                        <CheckCircleIcon className={`w-5 h-5 text-purple-500`} />
                                        <span className='ml-2'> {state?.notifyLaunches?.msg} </span>
                                    </span>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComingSoon;