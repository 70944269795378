import { CheckCircleIcon } from '@heroicons/react/solid';
import confetti from 'canvas-confetti';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { notifyLaunches } from '../actions/marketing';
import { images } from '../constants';
import { classNames } from '../utils/Utils'

const MobileAppPresentation = ({ isDialog }) => {

    const mobileImages = [
        images.mobile_device,
        images.mobile_pink,
        images.mobile_silver,
        images.mobile_red,
        images.mobile_orange,
        images.mobile_teal,
        images.mobile_green,
        images.mobile_purple,
        images.mobile_darkblue
    ];
    
    const { primaryColor } = useSelector(state => state.app);

    let pColor = 'darkblue';
    if (primaryColor === 'white') {
        pColor = 'darkblue';
    } else {
        pColor = primaryColor;
    };

    const mobileImage = mobileImages.filter((e) => e.includes(pColor))[0];

    const features = [
        {
            name: 'Manage all your documents in one place',
            icon: images.mobile_feature_1
        },
        {
            name: 'Reminders for document renewals',
            icon: images.mobile_feature_2
        },
        {
            name: 'Self-diagnose to check your compliance score',
            icon: images.mobile_feature_3
        },
        {
            name: 'Notifications on latest updates from FSSAI',
            icon: images.mobile_feature_4
        },
    ];

    const throwConfetti = () => {
        // let duration = 10 * 1000;
        // let animationEnd = Date.now() + duration;
        // let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 99999 };
    
        // function randomInRange(min, max) {
        //     return Math.random() * (max - min) + min;
        // }
    
        // let interval = setInterval(function() {
        //     let timeLeft = animationEnd - Date.now();
        
        //     if (timeLeft <= 0) {
        //         return clearInterval(interval);
        //     }
    
        //     let particleCount = 50 * (timeLeft / duration);
        //     // since particles fall down, start a bit higher than random
        //     confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
        //     confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
        // }, 250);

        let count = 200;
        let defaults = {
            origin: { y: 0.7 }
        };

        function fire(particleRatio, opts) {
            confetti(Object.assign({}, defaults, opts, {
                particleCount: Math.floor(count * particleRatio)
            }));
        }

        fire(2, {
            spread: 80,
            startVelocity: 55,
        });
        fire(1, {
            spread: 90,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8
        });
        fire(0.1, {
            spread: 130,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    };

    useEffect(() => {
        setTimeout(() => {
            throwConfetti();
        }, 1000);
    }, []);

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNo: '',
        topic: 'Mobile App Launch'
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Notify Me!');
        console.log('Form Data : ', formData);
        dispatch(notifyLaunches(formData));
        setShowMessage(true);
    };

    return (
        <section className={`bg-${pColor}-500`}>
            <div className={classNames(isDialog ? 'py-0': 'py-8 md:py-24', 'md:max-w-6xl px-4 mx-auto')}>
                <div class="flex md:px-5 md:flex-row flex-col-reverse items-center">
                    <div class="flex flex-col items-center md:items-start md:text-left md:w-2/3 md:pr-5 text-center">
                        <div class={`ml-2 text-center inline-block py-1 px-2 rounded-lg bg-${pColor}-50 text-${pColor}-500 text-xs font-medium tracking-widest`}>COMING SOON</div>
                        {/* <h1 className="text-3xl tracking-tight font-bold text-white">
                            <span className="text-4xl block md:text-5xl">Launching Soon...</span>
                        </h1> */}
                        <p class="mt-3 pl-2 text-white font-semibold text-md md:text-xl">India's 1 <sup>st</sup> FSSAI Compliance App!</p>
                        <h1 className="text-center md:text-left text-3xl tracking-tight font-bold text-white">
                            <span className="text-3xl text-white md:text-5xl">Aahar Compliance</span>
                        </h1>
                        <dl className="mt-5 grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pr-5 md:pr-1 py-2 md:py-5">
                                    <div className='flex items-start'>
                                        <div className={`absolute flex items-center justify-center h-12 w-12 rounded-md text-${pColor}-500 text-white`}>
                                            {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                                            <img src={feature.icon} className="h-8 w-8" aria-hidden="true" alt={feature.name} />
                                        </div>
                                        <p className="ml-16 text-left text-md text-white">{feature.name}</p>
                                    </div>
                                    {/* <dd className="mt-2 text-left ml-16 text-base tracking-wide text-white">{feature.description}</dd> */}
                                </div>
                            ))}
                        </dl>
                        {/* <div class="mt-5 flex w-full md:justify-start justify-center items-end">
                            <input
                                type="text"
                                name="email-address"
                                id="email-address"
                                autoComplete="email"
                                placeholder="Enter your email address"
                                className={`form-input border hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block lg:w-5/6 shadow-sm sm:text-sm md:text-lg lg:text-lg border-black-300 rounded-md`}
                            />
                            <button className='bg-${pColor}-500 p-3 -ml-3 rounded-r-md rounded-br-md hover:bg-${pColor}-400'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                                </svg>
                            </button>
                        </div>
                        <div className="mt-5 flex items-start md:px-40 lg:px-0">
                            <input
                                id="send-communications"
                                name="send-communications"
                                type="checkbox"
                                defaultChecked={true}
                                className={`mt-1 h-3 w-3 bg-${pColor}-600 text-${pColor}-500 rounded`}
                                style={{flexShrink: 0}}
                            />
                            <label htmlFor="send-communications" className="ml-2 text-xs md:text-sm text-left text-white md:pr-20">
                                I provide my consent to receive communications from Aahar. Check our
                                <Link to="/terms" className={`text-${pColor}-500 hover:text-${pColor}-700`}> terms & conditions </Link> .
                            </label>
                        </div> */}
                        
                        {/* <p class="text-sm mt-2 text-gray-500 mb-8 w-full">Neutra shabby chic ramps, viral fixie.</p> */}
                        {/* <div class="flex lg:flex-row md:flex-col">
                            <button class="bg-black-900 inline-flex py-3 px-5 rounded-lg items-center hover:bg-black-900 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 text-white" viewBox="0 0 512 512">
                                    <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                                </svg>
                                <span class="ml-4 flex text-white items-start flex-col leading-none">
                                    <span class="text-xs mb-1">GET IT ON</span>
                                    <span class="">Google Play</span>
                                </span>
                            </button>
                            <button class="bg-black-900 inline-flex py-3 px-5 rounded-lg items-center lg:ml-4 md:ml-0 ml-4 md:mt-4 mt-0 lg:mt-0 hover:bg-black-900 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="w-6 h-6 text-white" viewBox="0 0 305 305">
                                    <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                                    <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                                </svg>
                                <span class="ml-4 flex text-white items-start flex-col leading-none">
                                    <span class="text-xs mb-1">Download on the</span>
                                    <span class="font-medium">App Store</span>
                                </span>
                            </button>
                        </div> */}
                    </div>
                    <div class="mb-10 md:mb-0 md:w-1/3 w-5/6">
                        <img class="h-52 object-cover object-center rounded" alt="mobile device" src={mobileImage} />
                    </div>
                </div>
                <h2 className="md:px-5 mt-12 text-center text-white text-md md:mx-auto">
                    We're about to launch something exciting. Drop us your details, and we'll let you know as soon as we go live.
                </h2>
                {!showMessage && <form onSubmit={onSubmit} action='null'>
                    <div className="mt-5 flex flex-col md:flex-row justify-center">
                        <div className="pb-5">
                            <input
                                type="name"
                                name="name"
                                id="name"
                                onChange={onChange}
                                autoComplete="name"
                                placeholder="Enter your name"
                                className={`form-input py-3 md:w-48 lg:w-60 border border-gray-300 hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`}
                                required
                            />
                        </div>
                        <div className="pb-5 md:pl-5 lg:pl-5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                onChange={onChange}
                                autoComplete="email"
                                placeholder="Enter your email"
                                className={`form-input py-3 md:w-48 lg:w-60 border border-gray-300 hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`}
                                required
                            />
                        </div>
                        <div className="pb-5 md:pl-5 lg:pl-5">
                            <input
                                type="tel"
                                name="phoneNo"
                                id="phoneNo"
                                onChange={onChange}
                                maxLength="10"
                                autoComplete="tel"
                                placeholder="Enter your phone no"
                                className={`form-input py-3 md:w-48 lg:w-60 border border-gray-300 hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`}
                            />
                        </div>
                        <div className="md:pl-5 lg:pl-5">
                            <button type='submit'
                                className={`w-full md:px-6 py-3 block text-${pColor}-500 bg-white hover:text-${pColor}-600 hover:bg-white text-sm font-semibold text-center rounded-lg shadow-md`}
                            >Notify me</button>
                        </div>
                    </div>
                </form>}
                {!showMessage && <div className="mx-auto flex justify-center items-center md:px-5">
                    <input
                        id="send-communications"
                        name="send-communications"
                        type="checkbox"
                        defaultChecked={true}
                        className={`h-3 w-3 rounded`}
                        style={{flexShrink: 0, accentColor: 'white'}}
                    />
                    <label htmlFor="send-communications" className="ml-2 mt-3 md:mt-0 text-xs md:text-sm text-left text-white">
                        I provide my consent to receive communications from Aahar. Check our
                        <Link to="/terms" className={`text-white hover:text-white`}> terms & conditions </Link> .
                    </label>
                </div>}
                {/* {!showMessage && 
                    <div>
                        <div className={`mt-5 flex justify-center items-center md:px-5 ml-2 text-center inline-block py-1 px-2 rounded-lg bg-${pColor}-50 text-${pColor}-500 text-xs font-medium tracking-wide`}>
                            <CheckCircleIcon className='w-5 h-5 text-green-500' />
                            <p className={`ml-2 mt-3 md:mt-0 text-lg md:text-sm text-left text-${pColor}-500`}>
                                {state?.notifyLaunches?.msg}
                            </p>
                        </div>
                    </div>
                } */}
                {showMessage && <div className="mt-5 flex justify-center">
                    <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                        <span className='flex items-center'>
                            <CheckCircleIcon className={`w-5 h-5 text-${pColor}-500`} />
                            <span className='ml-2'> {state?.notifyLaunches?.msg} </span>
                        </span>
                    </div>
                </div>}

            </div>
        </section>
    )
}

export default MobileAppPresentation