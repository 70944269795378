import React, {useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BreadCrumb = () => {

    const { primaryColor } = useSelector(state => state.app);
    const [breadCrumbs, setBreadCrumbs] = useState([])

    const location = useLocation();
    const currentLocation = location.pathname;

    let bgColor = 'bg-darkblue-100';
    if (primaryColor === 'white') {
        bgColor = 'bg-darkblue-100';
    } else {
        bgColor = `bg-${primaryColor}-100`
    }

    const breadCrumbPaths = {
        '': {
            label: 'Home',
            navigation: '/'
        },
        'completeCompliance': {
            label: 'Complete Compliance',
            navigation: '/completeCompliance'
        },
        'fssai-food-license': {
            label: 'Regulatory',
            navigation: '/fssai-food-license'
        },
        'fssai-food-license-new': {
            label: 'New License',
            navigation: '/fssai-food-license-new'
        },
        'fssai-food-license-renewal': {
            label: 'License Renewal',
            navigation: '/fssai-food-license-renewal'
        },
        'fssai-food-license-modification': {
            label: 'License Modification',
            navigation: '/fssai-food-license-modification'
        },
        'testing-food': {
            label: 'Food Testing',
            navigation: '/testing-food'
        },
        'testing-water': {
            label: 'Water Testing',
            navigation: '/testing-water'
        },
        'training': {
            label: 'Training',
            navigation: '/training'
        },
        'trainingDetails': {
            label: 'Details',
            navigation: '/trainingDetails'
        },
        'medical-fitness': {
            label: 'Medical Fitness',
            navigation: '/medical-fitness'
        },
        'pest-control': {
            label: 'Pest Control',
            navigation: '/pest-control'
        },
        'process': {
            label: 'Calibration',
            navigation: '/process'
        },
        'career': {
            label: 'Career',
            navigation: '/career'
        },
        'contactus': {
            label: 'Contact Us',
            navigation: '/contactus'
        },
        'terms': {
            label: 'Terms & Conditions',
            navigation: '/terms'
        },
        'privacy': {
            label: 'Privacy Notice',
            navigation: '/privacy'
        },
        'disclaimer': {
            label: 'Disclaimer',
            navigation: '/disclaimer'
        }
    };
    
    useEffect(() => {
        const paths = currentLocation.split('/');
        console.log('Paths : ', paths);
        setBreadCrumbs(paths);
    }, [currentLocation])
    

    return (
        <div className={`${bgColor}`}>
            <div className='max-w-6xl mx-auto px-4 sm:px-6 py-1'>
                <nav aria-label="Breadcrumb">
                    <ul className="flex max-w-2xl items-center space-x-2">
                        {breadCrumbs.map((e, idx) => 
                            (idx + 1) !== breadCrumbs.length ?
                                <li>
                                    <div className="flex items-center">
                                        <Link to={breadCrumbPaths[e].navigation ?? ''} className="mr-2 text-sm font-medium text-gray-900 hover:underline">{breadCrumbPaths[e]?.label}</Link>
                                        <div aria-hidden="true" class="text-gray-900">/</div>
                                    </div>
                                </li>
                                : <li className="text-sm">
                                <p aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">{breadCrumbPaths[e]?.label}</p>
                            </li>
                        )}
                        {/* <li>
                            <div className="flex items-center">
                                <a href="#" className="mr-2 text-sm font-medium text-gray-900">Clothing</a>
                                <div aria-hidden="true" class="text-gray-900">/</div>
                            </div>
                        </li>
                        <li className="text-sm">
                            <a href="#" aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">Basic Tee 6-Pack</a>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default BreadCrumb;