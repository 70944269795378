import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Faq from '../components/Faq';
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon, StarIcon } from '@heroicons/react/outline';
import StarIconFilled from '@heroicons/react/solid/StarIcon';
import { CheckCircleIcon } from '@heroicons/react/solid';
import Timeline from '../components/Timeline';
import { images } from '../constants';
import Newsletter from '../components/Newsletter';
import E2EComplaince from '../components/E2EComplaince';
import SuggestedBlogs from '../components/SuggestedBlogs';
import GovtNoteComponent from '../components/GovtNoteComponent';
import { Helmet } from 'react-helmet';
import { toHaveClass } from '@testing-library/jest-dom';
import { classNames, seo } from '../utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { requestCallback } from '../actions/marketing';


const ServicePlanDetails = () => {

    // seo({}) is in useEffect() method

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [path, setPath] = useState();
    const [data, setData] = useState();
    const [process, setProcess] = useState();
    const [licenseType, setLicenseType] = useState();

    const { pathname, search } = useLocation();

    // console.log({ pathname, search });

    const processMetaData = [
        {
            type: 'new',
            ourProcess: {
                title: 'The entire procedure will be done online and updates will be shared at every phase.',
                values: [
                    'Request for callback or call us directly on our toll free number',
                    'Within 24 hours of receiving your inquiry, our team will call you to collect detailed requirements and share the quotation',
                    'Make your payment via online',
                    'After making the payment our team will share a list of applicable documents within 3 hours',
                    'Once we receive all the documents in the specified format, our team will do document verification and file the application within 2 working days.'
                ],
                estimate: '3 days'
            },
            fssaiProcess: {
                title: 'License application is now made completely online by FSSAI, without having to physically visit the food department office.',
                values: [
                    'After submission the application will go to the respective FSSAI designated officer in your district for processing',
                    'FSSAI team will review the application and revert back with queries (if any) (only for state and central licenses)',
                    'FSO might schedule an in-person inspection if required (only for state and central licenses)',
                    'After the verification, the application will either be rejected/ approved'
                ],
                estimate: '60 days'
            }
        },
        {
            type: 'renewal',
            ourProcess: {
                title: 'The entire procedure will be done online and updates will be shared at every phase.',
                values: [
                    'Request for callback or call us directly on our toll free number',
                    'Within 24 hours of receiving your inquiry, our team will call you to collect detailed requirements and share the quotation',
                    'After receiving the payment, we will send a list of required documents',
                    'Our team will verify the documents and file the request for food license renewal',
                    'Make the fees payment on FoSCoS Portal',
                    'FSSAI food license renewal will be complete'
                ],
                estimate: '3 days'
            },
            fssaiProcess: {
                title: 'FSSAI license renewal process is now made completely online, without having to physically visit the food department office.',
                values: [
                    'License renewal process should be started 60 days before expiry date',
                    'Pending past dues (if any) must be cleared before renewal',
                    'FSSAI team will review the application and process the renewal request'
                ],
                estimate: '60 days'
            },
            renewal: {
                title: 'FSSAI license renewal process is now made completely online, without having to physically visit the food department office.',
                values: [
                    'License renewal process should be started 60 days before expiry date',
                    'Pending past dues (if any) must be cleared before renewal',
                    'FSSAI team will review the application and process the renewal request'
                ],
                estimate: '60 days'
            }
        },
        {
            type: 'modification',
            ourProcess: {
                title: 'The entire procedure will be done online and updates will be shared at every phase.',
                values: [
                    'Request for callback or call us directly on our toll free number',
                    'Within 24 hours of receiving your inquiry, our team will call you to collect detailed requirements and share the quotation',
                    'After receiving the payment, we will send a list of required documents',
                    'Our team will verify the documents and file the request for food license renewal',
                    'Make the fees payment on FoSCoS Portal',
                    'FSSAI food license renewal will be complete'
                ],
                estimate: '3 days'
            },
            fssaiProcess: {
                title: 'FSSAI license renewal process is now made completely online, without having to physically visit the food department office.',
                values: [
                    'License renewal process should be started 60 days before expiry date',
                    'Pending past dues (if any) must be cleared before renewal',
                    'FSSAI team will review the application and process the renewal request'
                ],
                estimate: '60 days'
            },
            renewal: {
                title: 'FSSAI license renewal process is now made completely online, without having to physically visit the food department office.',
                values: [
                    'License renewal process should be started 60 days before expiry date',
                    'Pending past dues (if any) must be cleared before renewal',
                    'FSSAI team will review the application and process the renewal request'
                ],
                estimate: '60 days'
            }
        }
    ];

    const metaData = [
        {
            type: 'new',
            values: [
                {
                    name: 'registration',
                    title: 'FSSAI Food Registration Certificate - New Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you apply for a new FSSAI (registration certificate/state food license/central food license) online.',
                    applicableFor: [
                        'Petty or small food businesses, having annual turnover of less than Rs. 12 lakhs per year'
                    ],
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'The FSSAI Food Registration Certificate is given to those who operate a petty/ small scale food business or have an annual turnover of less than Rs. 12 lakhs per year.',
                        servicesCovered: [
                            'Document Preparation & Verification',
                            'Application Drafting & Filing',
                            'SMS & Email Alerts'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for petty or small food businesses',
                            'Having annual turnover of less than Rs. 12 lakhs per year'
                        ],
                        services: [
                            'Petty Retailer',
                            'Thela Wala',
                            'Sweet Shop',
                            'Juice Stall',
                            'Vegetable/ Fruit Store',
                            'Tea Stall',
                            'Kirana Store',
                            'Hawker',
                            'Milk Vendor/ Petty Milkman',
                            'Slaughtering House',
                            'Retail',
                            'Wholesale/ Distribution',
                            'Hotel/ Restaurant',
                            'Canteen/ Catering'
                        ]
                    },
                    seo: 
                    `   <meta name="title" content="Aahar Regulatory | FSSAI Food Registration Certificate - New Application" />
                        <meta name="description" content="Our team of food technologists & regulatory consultants will help you get a New FSSAI Registration Certificate at an affordable cost, completely online." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license, food license, fssai registration certificate, fssai online application, fssai regulations" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI Food Registration Certificate - New Application',
                        metaDescription: "Our team of food technologists & regulatory consultants will help you get a New FSSAI Registration Certificate at an affordable cost, completely online.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license, food license, fssai registration certificate, fssai online application, fssai regulations",
                    }
                },
                {
                    name: 'state',
                    title: 'FSSAI State Food License - New Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you apply for a new FSSAI (registration certificate/state food license/central food license) online.',
                    applicableFor: [
                        'Small or medium food businesses, having annual turnover between Rs. 12 lakhs to Rs. 20 crores per year',
                        'Can vary based on food product category or kind of business or production capacity.'
                    ],
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'The FSSAI State Food License is given to those who operate a small/ medium scale food business or have an annual turnover between Rs. 12 lakhs to Rs. 20 crores per year.',
                        servicesCovered: [
                            "Drafting & Filing Application",
                            "KOB and Food Product Category Selection",
                            "Answer Reverted Queries from FSSAI",
                            "End-to-end Support till Closure",
                            "Dedicated Account Manager",
                            "SMS & Email Alerts",
                            "Weekly Status Updates",
                            "Licence Renewal Reminders"
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for small or medium food businesses',
                            'Having annual turnover between Rs. 12 lakhs to Rs. 20 crores per year',
                            '*sometimes varies based on food product category or kind of business or production capacity.'
                        ],
                        services: [
                            'Dairy Unit',
                            'Milk Chilling Center',
                            'Milk Products',
                            'Vegetable Oil Processing',
                            'Slaughtering House',
                            'Meat Processing Units',
                            'Manufacturing',
                            'Repacking',
                            'Relabelling',
                            'Food Processing',
                            'Food Additives/ Enzyme/ Flavouring Agents etc',
                            'Storage',
                            'Transportation',
                            'Wholesale',
                            'Distribution',
                            'Retail',
                            'Hotel',
                            'Restaurant',
                            'Club/ Canteen',
                            'Catering',
                            'Food Vending Agency'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI State Food License New - Online Application" />
                        <meta name="description" content="Apply Online for a New FSSAI State Food License at a reasonable price. Assisted by a team of food technologists and food safety regulatory experts. " />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license, food license, fssai state food license, fssai online application, fssai regulations, state license, fssai state license" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI State Food License New - Online Application',
                        metaDescription: "Apply Online for a New FSSAI State Food License at a reasonable price. Assisted by a team of food technologists and food safety regulatory experts.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license, food license, fssai state food license, fssai online application, fssai regulations, state license, fssai state license",
                    }
                },
                {
                    name: 'central',
                    title: 'FSSAI Central Food Licence - New Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you apply for a new FSSAI (registration certificate/state food license/central food license) online.',
                    applicableFor: [
                        'Medium or large food businesses, having annual turnover of more than Rs. 20 crores per year',
                        'Can vary based on food product category or kind of business or production capacity.'
                    ],
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'The FSSAI Central Food License is given to those who operate a medium/ large scale food business or have an annual turnover of more than Rs. 20 crores per year.',
                        servicesCovered: [
                            "Drafting & Filing Application",
                            "KOB and Food Product Category Selection",
                            "Answer Reverted Queries from FSSAI",
                            "End-to-end Support till Closure",
                            "Dedicated Account Manager",
                            "SMS & Email Alerts",
                            "Weekly Status Updates",
                            "Licence Renewal Reminders"
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for medium or large food businesses',
                            'Having annual turnover of more than Rs. 20 crores per year',
                            '*sometimes varies based on food product category or kind of business or production capacity.'
                        ],
                        services: [
                            'Dairy Unit',
                            'Milk Chilling Center',
                            'Milk Products',
                            'Vegetable Oil Processing',
                            'Slaughtering House',
                            'Meat Processing Units',
                            'Manufacturing',
                            'Repacking',
                            'Relabelling',
                            'Food Processing',
                            'Food Additives/ Enzyme/ Flavouring Agents etc',
                            'Storage',
                            'Transportation',
                            'Wholesale',
                            'Distribution',
                            'Retail',
                            'Hotel',
                            'Restaurant',
                            'Catering',
                            'Food Vending Agency',
                            'Proprietary Food',
                            'Food/ Health Supplements & Nutraceuticals',
                            'Radiation Processing of Foods',
                            'Import/ Export',
                            'E-Commerce'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI Central Food License New - Online Application" />
                        <meta name="description" content="Planning to register online for FSSAI Central Food License? Our team of food technologists & regulatory consultants are here to help you at a reasonable cost. " />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license, food license, fssai central food license, fssai online application, fssai regulations, central license, fssai central license" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI Central Food License New - Online Application',
                        metaDescription: "Planning to register online for FSSAI Central Food License? Our team of food technologists & regulatory consultants are here to help you at a reasonable cost.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license, food license, fssai central food license, fssai online application, fssai regulations, central license, fssai central license",
                    }
                },
            ]
        },
        {
            type: 'renewal',
            values: [
                {
                    name: 'registration',
                    title: 'FSSAI Food Registration Certificate Renewal - Online Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you renew your FSSAI food registration certificate.',
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'FSSAI Food Registration Certificate will be issued for a period of 1-5 years and has to be renewed post expiry. The renewal process is now made completely online without having to visit physically.',
                        servicesCovered: [
                            'Document Preparation & Verification',
                            'Application Drafting & Filing',
                            'SMS & Email Alerts'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for petty or small food businesses',
                            'Having annual turnover of less than Rs. 12 lakhs per year'
                        ],
                        services: [
                            'Petty Retailer',
                            'Thela Wala',
                            'Sweet Shop',
                            'Juice Stall',
                            'Vegetable/ Fruit Store',
                            'Tea Stall',
                            'Kirana Store',
                            'Hawker',
                            'Milk Vendor/ Petty Milkman',
                            'Slaughtering House',
                            'Retail',
                            'Wholesale/ Distribution',
                            'Hotel/ Restaurant',
                            'Canteen/ Catering'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI Food Registration Certificate - Online Renewal" />
                        <meta name="description" content="Our team of food technologists & regulatory consultants will help you renew your FSSAI food registration certificate online at an affordable cost." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license renewal, food license renewal, fssai registration certificate renewal, fssai online application, fssai regulations, fssai registration certificate" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI Food Registration Certificate - Online Renewal',
                        metaDescription: "Our team of food technologists & regulatory consultants will help you renew your FSSAI food registration certificate online at an affordable cost",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license renewal, food license renewal, fssai registration certificate renewal, fssai online application, fssai regulations, fssai registration certificate"
                    }
                },
                {
                    name: 'state',
                    title: 'FSSAI State Food License Renewal - Online Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you renew your FSSAI state food license.',
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'FSSAI State Food License will be issued for a period of 1-5 years and has to be renewed post expiry. The renewal process is now made completely online without having to visit physically.',
                        servicesCovered: [
                            'Drafting & Filing Application',
                            'Answer Reverted Queries from FSSAI',
                            'End-to-end Support till Closure',
                            'Comprehensive License Checkup',
                            'Dedicated Account Manager',
                            'SMS & Email Alerts',
                            'Weekly Status Updates'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for small or medium food businesses',
                            'Having annual turnover between Rs. 12 lakhs to Rs. 20 crores per year',
                            '*sometime varies based on food product category or kind of business or production capacity.'
                        ],
                        services: [
                            'Dairy Unit',
                            'Milk Chilling Center',
                            'Milk Products',
                            'Vegetable Oil Processing',
                            'Slaughtering House',
                            'Meat Processing Units',
                            'Manufacturing',
                            'Repacking',
                            'Relabelling',
                            'Food Processing',
                            'Food Additives/ Enzyme/ Flavouring Agents etc',
                            'Storage',
                            'Transportation',
                            'Wholesale',
                            'Distribution',
                            'Retail',
                            'Hotel',
                            'Restaurant',
                            'Club/ Canteen',
                            'Catering',
                            'Food Vending Agency'
                        ]
                    },
                    seo: 
                    `   <meta name="title" content="Aahar Regulatory | FSSAI State Food License Renewal - Online Application" />
                        <meta name="description" content="Apply online for FSSAI State Food License Renewal at a reasonable price. Assisted by a team of food technologists and food safety regulatory experts." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license renewal, food license renewal, fssai state food license renewal, fssai online application, fssai regulations, fssai state food license" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI State Food License Renewal - Online Application',
                        metaDescription: "Apply online for FSSAI State Food License Renewal at a reasonable price. Assisted by a team of food technologists and food safety regulatory experts.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license renewal, food license renewal, fssai state food license renewal, fssai online application, fssai regulations, fssai state food license"
                    }
                },
                {
                    name: 'central',
                    title: 'FSSAI Central Food License Renewal - Online Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you renew your FSSAI central food license.',
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'FSSAI Central Food License will be issued for a period of 1-5 years and has to be renewed post expiry. The renewal process is now made completely online without having to visit physically.',
                        servicesCovered: [
                            'Drafting & Filing Application',
                            'Answer Reverted Queries from FSSAI',
                            'End-to-end Support till Closure',
                            'Comprehensive License Checkup',
                            'Dedicated Account Manager',
                            'SMS & Email Alerts',
                            'Weekly Status Updates'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for medium or large food businesses',
                            'Having annual turnover of more than Rs. 20 crores per year',
                            '*sometime varies based on food product category or kind of business or production capacity.'
                        ],
                        services: [
                            'Dairy Unit',
                            'Milk Chilling Center',
                            'Milk Products',
                            'Vegetable Oil Processing',
                            'Slaughtering House',
                            'Meat Processing Units',
                            'Manufacturing',
                            'Repacking',
                            'Relabelling',
                            'Food Processing',
                            'Food Additives/ Enzyme/ Flavouring Agents etc',
                            'Storage',
                            'Transportation',
                            'Wholesale',
                            'Distribution',
                            'Retail',
                            'Hotel',
                            'Restaurant',
                            'Catering',
                            'Food Vending Agency',
                            'Proprietary Food',
                            'Food/ Health Supplements & Nutraceuticals',
                            'Radiation Processing of Foods',
                            'Import/ Export',
                            'E-Commerce'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI Central Food License Renewal - Apply Online" />
                        <meta name="description" content="Planning to renew your FSSAI Central Food License Online? Our team of food technologists & regulatory consultants are here to help you at a reasonable cost." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license renewal, food license renewal, fssai central food license renewal, fssai online application, fssai regulations, fssai central food license" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `
                },
            ]
        },
        {
            type: 'modification',
            values: [
                {
                    name: 'registration',
                    title: 'FSSAI Food Registration Certificate Modification - Online Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you modify your FSSAI food registration certificate.',
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'Changing the basic details on your registration certificate (Form C Modification) or addition of a new food product category or a new business type (Non-Form C Modification) requires you to go for food license modification with FSSAI.',
                        servicesCovered: [
                            'Document Preparation & Verification',
                            'SMS & Email Alerts'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for petty or small food businesses',
                            'Having annual turnover of less than Rs. 12 lakhs per year'
                        ],
                        services: [
                            'Petty Retailer',
                            'Thela Wala',
                            'Sweet Shop',
                            'Juice Stall',
                            'Vegetable/ Fruit Store',
                            'Tea Stall',
                            'Kirana Store',
                            'Hawker',
                            'Milk Vendor/ Petty Milkman',
                            'Slaughtering House',
                            'Retail',
                            'Wholesale/ Distribution',
                            'Hotel/ Restaurant',
                            'Canteen/ Catering'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI Food Registration Certificate Modification" />
                        <meta name="description" content="Our team of food technologists & regulatory consultants will help you modify your FSSAI food registration certificate online at an affordable cost." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license modification, food license modification, fssai registration certificate modification, fssai online application, fssai regulations, fssai registration certificate" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI Food Registration Certificate Modification',
                        metaDescription: "Our team of food technologists & regulatory consultants will help you modify your FSSAI food registration certificate online at an affordable cost.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license modification, food license modification, fssai registration certificate modification, fssai online application, fssai regulations, fssai registration certificate"
                    }
                },
                {
                    name: 'state',
                    title: 'FSSAI State Food License Modification - Online Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you modify your FSSAI state food license.',
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'Changing the basic details on your state food license (Form C Modification) or addition of a new food product category or a new business type (Non-Form C Modification) requires you to go for food license modification with FSSAI.',
                        servicesCovered: [
                            'Drafting & Filing Application',
                            'Answer Reverted Queries from FSSAI',
                            'End-to-end Support till Closure',
                            'Comprehensive License Checkup',
                            'Dedicated Account Manager',
                            'SMS & Email Alerts',
                            'Weekly Status Updates'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for small or medium food businesses',
                            'Having annual turnover between Rs. 12 lakhs to Rs. 20 crores per year',
                            '*sometime varies based on food product category or kind of business or production capacity.'
                        ],
                        services: [
                            'Dairy Unit',
                            'Milk Chilling Center',
                            'Milk Products',
                            'Vegetable Oil Processing',
                            'Slaughtering House',
                            'Meat Processing Units',
                            'Manufacturing',
                            'Repacking',
                            'Relabelling',
                            'Food Processing',
                            'Food Additives/ Enzyme/ Flavouring Agents etc',
                            'Storage',
                            'Transportation',
                            'Wholesale',
                            'Distribution',
                            'Retail',
                            'Hotel',
                            'Restaurant',
                            'Club/ Canteen',
                            'Catering',
                            'Food Vending Agency'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI State Food License Modification - Apply Online" />
                        <meta name="description" content="Apply online for FSSAI State Food License Modification at a reasonable price. Assisted by a team of food technologists and food safety regulatory experts." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license modification, food license modification, fssai state food license modification, fssai online application, fssai regulations, fssai state food license" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI State Food License Modification - Apply Online',
                        metaDescription: "Apply online for FSSAI State Food License Modification at a reasonable price. Assisted by a team of food technologists and food safety regulatory experts.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license modification, food license modification, fssai state food license modification, fssai online application, fssai regulations, fssai state food license"
                    }
                },
                {
                    name: 'central',
                    title: 'FSSAI Central Food License Modification - Online Application',
                    description: 'Our expert team of food technologists, food safety consultants & FSSAI regulatory experts will help you modify your FSSAI central food license.',
                    about: {
                        desc1: 'The Food Safety and Standards Authority of India (FSSAI) is the regulatory organisation which provides registration certificate & licenses to food businesses in India. FSSAI is in charge of food security in the country and their approval is mandatory to start & manage any food business.',
                        desc2: 'Changing the basic details on your central food license (Form C Modification) or addition of a new food product category or a new business type (Non-Form C Modification) requires you to go for food license modification with FSSAI.',
                        servicesCovered: [
                            'Drafting & Filing Application',
                            'Answer Reverted Queries from FSSAI',
                            'End-to-end Support till Closure',
                            'Comprehensive License Checkup',
                            'Dedicated Account Manager',
                            'SMS & Email Alerts',
                            'Weekly Status Updates'
                        ],
                    },
                    eligibilityCriteria: {
                        criteria: [
                            'Applicable for medium or large food businesses',
                            'Having annual turnover of more than Rs. 20 crores per year',
                            '*sometime varies based on food product category or kind of business or production capacity.'
                        ],
                        services: [
                            'Dairy Unit',
                            'Milk Chilling Center',
                            'Milk Products',
                            'Vegetable Oil Processing',
                            'Slaughtering House',
                            'Meat Processing Units',
                            'Manufacturing',
                            'Repacking',
                            'Relabelling',
                            'Food Processing',
                            'Food Additives/ Enzyme/ Flavouring Agents etc',
                            'Storage',
                            'Transportation',
                            'Wholesale',
                            'Distribution',
                            'Retail',
                            'Hotel',
                            'Restaurant',
                            'Catering',
                            'Food Vending Agency',
                            'Proprietary Food',
                            'Food/ Health Supplements & Nutraceuticals',
                            'Radiation Processing of Foods',
                            'Import/ Export',
                            'E-Commerce'
                        ]
                    },
                    seo:
                    `   <meta name="title" content="Aahar Regulatory | FSSAI Central Food License Modification - Apply Online" />
                        <meta name="description" content="Planning to modify your FSSAI Central Food License Online? Our team of food technologists & regulatory consultants are here to help you at a reasonable cost." />
                        <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license modification, food license modification, fssai central food license modification, fssai online application, fssai regulations, fssai central food license" />
                        <meta name="robots" content="index, follow" />
                        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                        <meta name="language" content="English" />
                        <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                    `,
                    seoObj: {
                        title: 'Aahar Regulatory | FSSAI Central Food License Modification - Apply Online',
                        metaDescription: "Planning to modify your FSSAI Central Food License Online? Our team of food technologists & regulatory consultants are here to help you at a reasonable cost.",
                        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai license modification, food license modification, fssai central food license modification, fssai online application, fssai regulations, fssai central food license"
                    }
                },
            ]
        },
    ];

    useEffect(() => {
        setPath(pathname);
        if (pathname && search) {
            const param = pathname.split('-');
            const searchParam = search.split('=');
            setLicenseType(param[param.length - 1])
            metaData.map((data) => {
                if (data.type === param[param.length - 1]) {
                    const d = data.values.filter(e => searchParam[1].includes(e.name));
                    setData(d);
                    seo({ ...d[0].seoObj });
                }
            });
            processMetaData.map((data) => {
                if (data.type === param[param.length - 1]) { 
                    setProcess(data);
                }
            })
        }
    }, [pathname, search])

    const titleKeyWords = {
        fssai: 'FSSAI ',
        new: 'New ',
        renewal: 'Renewal ',
        modification: 'Modification ',
        registration: 'Registration ',
        state: 'State ',
        central: 'Central ',
        license: 'License ',
        certificate: 'Certificate '
    };

    const features = [
        {
            name: 'Directors/Partners/Proprietor Details',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_1
                // icon: GlobeAltIcon,
        },
        {
            name: 'Photo ID & Address Proof',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_2
                // icon: ScaleIcon,
        },
        {
            name: 'Rental Agreement / Sale Deed',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_3
                // icon: LightningBoltIcon,
        },
        {
            name: 'Partnership Deed / MoA & AoA',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_4
                // icon: AnnotationIcon,
        },
        {
            name: 'Form IX',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_5
                // icon: GlobeAltIcon,
        },
        {
            name: 'Recall Plan',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_6
                // icon: ScaleIcon,
        },
        {
            name: 'Layout Plan',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_7
                // icon: LightningBoltIcon,
        },
        {
            name: 'Water Analysis Report',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_8
                // icon: AnnotationIcon,
        },
        {
            name: 'Photos of Production Unit',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_9
                // icon: AnnotationIcon,
        },
        {
            name: 'Details of Machinery & Equipments',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.doc_10
                // icon: AnnotationIcon,
        },
    ];

    const servicesCovered = [
        'Documentation Help',
        'Dedicated Account Manager',
        'Drafting & filing application',
        'Status Update - Every Week',
        'Followups with FSSAI',
        'End to end support till closure',
        'Answer queries if arises',
        'Licence renewal reminders',
        'KOB and Food Product Category Selection',
    ];

    const stateLicenceServices = [
        "Hotel",
        " Restaurants",
        " Caterer",
        " Club/Canteen",
        " Wholesaler",
        " Food Vending Agencies",
        " Marketer",
        " Storage",
        " Transportation",
        " Distributor",
        " Supplier",
        " Mid-Day meal - Caterer",
        " Vegetable oil",
        " processing units",
        " Slaughtering units",
        " Dairy units",
        " Meat processing units"
    ];

    // const centralLicenceServices = [
    //     ...stateLicenceServices,
    //     "Proprietary Food Manufacturer",
    //     "Health Supplements / Nutraceuticals Manufacturer",
    //     "Importer",
    //     "e-Commerce",
    //     "Exporter"
    // ];

    const ourTimeLine = [
        "Request for callback or call us directly on our toll free number",
        "Within 24 hours of receiving your inquiry, our team will call you to collect detailed requirements and share the quotation",
        "Make your payment via online",
        "After making the payment our team will share a list of applicable documents within 3 hours",
        "Once we receive all the documents in the specified format, our team will do document verification and file the application within 2 working days."
    ];

    const fssaiTimeLine = [
        "After submission the application will go to the respective FSSAI designated officer in your district for processing",
        "FSSAI team will review the application and revert back with queries (if any) (only for state and central licenses)",
        "FSO might schedule an in-person inspection if required (only for state and central licenses)",
        "After the verification, the application will either be rejected/ approved"
    ];

    const getBackLinks = {
        'new': {
            'keyword': 'apply for',
            'registration': {
                name: 'State',
                link: '/fssai-food-license-new?type=state-license'
            },
            'state': {
                name: 'Central',
                link: '/fssai-food-license-new?type=central-license'
            },
            'central': {
                name: 'State',
                link: '/fssai-food-license-new?type=state-license'
            },
        },
        'renewal': {
            'keyword': 'renew',
            'registration': {
                name: 'State',
                link: '/fssai-food-license-renewal?type=state-license'
            },
            'state': {
                name: 'Central',
                link: '/fssai-food-license-renewal?type=central-license'
            },
            'central': {
                name: 'State',
                link: '/fssai-food-license-renewal?type=state-license'
            },
        },
        'modification': {
            'keyword': 'modify',
            'registration': {
                name: 'State',
                link: '/fssai-food-license-modification?type=state-license'
            },
            'state': {
                name: 'Central',
                link: '/fssai-food-license-modification?type=central-license'
            },
            'central': {
                name: 'State',
                link: '/fssai-food-license-modification?type=state-license'
            },
        },
    };

    console.log('Selected Data : ', data);

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  
        name: '',
        email: '',
        phoneNo: '',
        topic: data && data[0].title || ''
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
                setFormData({name: '', email: '', phoneNo: ''})
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Request Callback!');
        console.log('Form Data : ', formData);
        formData.topic = data[0].title || '';
        dispatch(requestCallback(formData));
        setShowMessage(true);
    };

    return (
        <>
            <Helmet>
                {data && data.seo}
            </Helmet>
            <div className="max-w-6xl mx-auto">
                <div className="relative pb-8 bg-white">
                    <div className="mt-10 mx-auto max-w-6xl px-8 sm:px-6 md:mt-20 lg:mt-32 xl:mt-32">

                        {/* Left Section */}
                        <div className="flex flex-col justify-between items-center md:flex-row lg:flex-row">
                            <div className='w-full mt-10 mb-10 pt-10 md:hidden'>
                                <img
                                    className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                    src={images.regulatory_details_mob}
                                    alt=""
                                />
                            </div>
                            {data && data.map((e) => <div key={e.title} className="text-left w-full md:w-2/3 lg:w-2/3">
                                <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-5xl">
                                    {licenseType === 'new' && <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">NEW FSSAI FOOD LICENSE</span>}
                                    {licenseType === 'renewal' && <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">FSSAI FOOD LICENSE RENEWAL</span>}
                                    {licenseType === 'modification' && <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">FSSAI FOOD LICENSE MODIFICATION</span>}
                                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                        {/* {path && path.split('-').map((e) => titleKeyWords[e])}  */}
                                        {e.title}
                                    </h1>
                                </div>
                                <h2 className="mt-3 text-base text-left text-lg md:text-xl tracking-wide text-gray-900">
                                    {e.description}
                                </h2>
                                {/* <div className='mt-3 flex flex-row items-center'>
                                    <p className="text-base text-left text-md lg:text-2xl text-gray-700">
                                        4.2
                                    </p>
                                    <div className='ml-2 flex flex-row'>
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIcon
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <Link to="" className='ml-2 text-sm lg:text-md text-darkblue-500 hover:text-darkblue-700'>(All Reviews)</Link>
                                </div> */}

                                {e.applicableFor && <div className="mt-5 md:mr-10">
                                    <div className="text-left text-teal-900 bg-teal-200 p-5 shadow rounded-lg">
                                        <p className="text-xl font-semibold">Applicable For: </p>
                                        {e.applicableFor.map((f, i) => 
                                            <div className='flex items-start font-medium md:ml-2 mt-2'>
                                                {!f.includes('Can vary') && <span className='hidden md:flex md:mr-2'>
                                                    <CheckCircleIcon className='h-5 w-5' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                                                </span>}
                                                <span className="">{f}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>}

                                {/* <div className="mt-5 md:mr-10">
                                    <div className={classNames(licenseType === 'new' ? 'bg-darkblue-200' : 'bg-teal-200' ,'text-left text-gray-900 p-5 shadow rounded-lg')}>
                                        <p className='font-bold'>
                                            Want to {getBackLinks[licenseType]?.keyword} {getBackLinks[licenseType][e.name]?.name} License instead?
                                            <Link to={getBackLinks[licenseType][e.name]?.link} className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> Click here</Link> 
                                        </p>
                                        {licenseType === 'new' &&
                                            <>
                                                <p className='mt-3 font-medium'>
                                                    <span>Not sure which license is applicable for your business? </span>
                                                </p>
                                                <p className='mt-2 text-gray-900 font-medium'>
                                                    <div className=''>
                                                        Talk to us on <a className='text-darkblue-500 hover:text-darkblue-700 font-semibold' href="tel:9901177902"> 18001020127 </a>
                                                        or <Link to="/comingsoon" className="font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> click here </Link>
                                                        to use our tool to find out yourself
                                                    </div>
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div> */}
                                
                            </div>)}
                            <div className='w-full mt-10 lg:mt-2 md:w-1/3 lg:w-1/3'>
                                <div className="w-max flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-darkblue-500 group-hover:text-white">
                                        <div>
                                            <p className="text-center text-gray-700 font-semibold">Starts from</p>
                                            <p className="mt-2 text-center mx-auto sm:text-md lg:text-lg">
                                                <span className="ml-2 text-4xl font-bold">₹ 7500</span>
                                                <span className="ml-2 text-gray-700 font-semibold">per licence</span>
                                            </p>
                                            <p className='mt-2 text-center mx-auto text-sm'>*conditions apply</p>
                                            <Link to="/checkout" className="mt-3 block text-white bg-darkblue-500 hover:text-white hover:bg-darkblue-600 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                            <div className="flex items-center py-2">
                                                <div className="flex-grow h-px bg bg-gray-300 h-0.5"></div>
                                                <div className="flex-grow-0 mx-5 text text-gray-700">or</div>
                                                <div className="flex-grow h-px bg bg-gray-300 h-0.5"></div>
                                            </div>
                                            <form onSubmit={onSubmit}>
                                                <div className="flex-1 items-stretch space-y-4">
                                                    <p className='text-left text-base text-gray-700 font-semibold'>Got any questions? Talk to our expert.</p>
                                                    <div>
                                                        <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                            Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            autoComplete="name"
                                                            placeholder="Enter your name"
                                                            value={formData.name}
                                                            onChange={(e) => { setName(e.target.value); onChange(e)}}
                                                            className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                            required
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                            Phone No
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            name="phoneNo"
                                                            id="phoneNo"
                                                            autoComplete="tel"
                                                            placeholder="+91 ##### #####"
                                                            maxLength='10'
                                                            value={formData.phoneNo}
                                                            onChange={(e) => {setPhone(e.target.value); onChange(e)}}
                                                            className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                            required
                                                        />
                                                    </div>
                                                    {name && name.length > 3 && <div>
                                                        <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            autoComplete="email"
                                                            value={formData.email}
                                                            onChange={(e) => {setEmail(e.target.value); onChange(e)}}
                                                            placeholder="Enter your email"
                                                            className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                            required
                                                        />
                                                    </div>}
                                                </div>
                                                <div className="flex mt-5 items-start">
                                                    <input
                                                        id="send-communications"
                                                        name="send-communications"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                        className="h-3 w-3  mt-1 bg-darkblue-600 text-darkblue-500 rounded"
                                                        style={{flexShrink: 0}}
                                                    />
                                                    <label htmlFor="send-communications" className="ml-2 text-sm text-gray-900">
                                                        By clicking on request callback, I provide my consent to receive communications from Aahar. Check our 
                                                        <Link to="/terms" className={`text-darkblue-500 hover:text-darkblue-700`}> terms & conditions </Link> .
                                                    </label>
                                                </div>
                                                {!showMessage && <button type='submit' className="w-full block text-white bg-darkblue-500 hover:text-white hover:bg-darkblue-600 group-hover:text-darkblue-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Request Callback</button>}
                                                {showMessage && <div className="mt-5 flex justify-center">
                                                    <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                                        <span className='flex items-center'>
                                                            <CheckCircleIcon className={`w-5 h-5 text-darkblue-500`} />
                                                            <span className='ml-2'> {state?.requestCallback?.msg} </span>
                                                        </span>
                                                    </div>
                                                </div>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Right Section */}
                        <div className="flex flex-col justify-between items-start md:flex-row lg:flex-row">
                            <div className='w-full md:w-2/3 lg:w-2/3 mt-5 lg:mt-20 pt-5 lg:pt-10 lg:pr-10'>
                                
                                {/* About this plan */}
                                {data && data.map((e) => <div key={e} className="py-10 text-left">
                                    <div className="mt-3 text-3xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            About
                                        </h1>
                                    </div>
                                    <h2 className="mt-4 max-w-2xl text-lg md:text-xl tracking-wide text-gray-900">
                                        {e.about.desc1}
                                    </h2>
                                    <h2 className="mt-4 max-w-2xl text-lg md:text-xl tracking-wide text-gray-900">
                                        {e.about.desc2}
                                    </h2>
                                    {/* <Link to="" className="mt-5 py-5 font-semibold text-darkblue-500 text-md hover:text-darkblue-700">Know More &nbsp;&nbsp; →</Link> */}
                                    <div className='mt-3 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch">
                                            <div className="card-w-border-wo-shadow mb-5">
                                                <div className='p-2 lg:p-5 flex flex-col lg:flex-row justify-between items-center'>
                                                    <div className='mb-3 lg:pr-5'>
                                                        <h2 className="text-xl font-extrabold tracking-tight text-gray-900 md:text-2xl">
                                                            Services Covered
                                                        </h2>
                                                    </div>
                                                    <div className='flex flex-col items-stretch points grid md:grid-cols-2 lg:grid-cols-2'>
                                                        {e.about.servicesCovered.map((service) =>
                                                            <div key={service} className="mb-2 flex flex-row justify-start items-start">
                                                                <div className=''>
                                                                    <CheckCircleIcon className='h-5 w-5' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                                                                </div>
                                                                <p className="ml-2 text-md text-gray-900">{service}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-3 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch">
                                            <div className="card-w-border-wo-shadow mb-5">
                                                <div className='p-2 lg:p-5 flex flex-col lg:flex-row justify-start items-center'>
                                                    <div className='mb-3 lg:pr-5'>
                                                        <h2 className="text-xl font-extrabold tracking-tight text-gray-900 md:text-2xl">
                                                            {'Eligibility \n Criteria'}
                                                        </h2>
                                                    </div>
                                                    <div className='lg:ml-10 flex flex-col items-stretch points'>
                                                        {e.eligibilityCriteria.criteria.map((c) =>
                                                            <div key={c} className="mb-2 flex flex-row justify-start items-start">
                                                                <div className='mt-1'>
                                                                    <CheckCircleIcon className='h-5 w-5' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                                                                </div>
                                                                <p className="ml-2 text-md text-gray-900">{c}</p>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                                <hr className='mx-5' />
                                                <div className='mt-5 lg:mx-5 grid gap-x-8 gap-y-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
                                                    {/* <div className='p-3 flex flex-col items-start lg:items-center points'> */}
                                                        {/* <h3 className="text-2xl text-center sm:text-2xl font-bold text-gray-900">
                                                            Restaurant
                                                        </h3> */}
                                                    {e.eligibilityCriteria.services.map((service) => 
                                                        <div key={service} className="mb-2 flex flex-row justify-start items-center">
                                                            <div className=''>
                                                                <CheckCircleIcon className='h-5 w-5' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                                                            </div>
                                                            <p className="ml-2 text-md text-gray-900">{service}</p>
                                                        </div>
                                                    )}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mt-5 font-medium">
                                        <div className="text-left text-teal-900 bg-teal-200 p-5 shadow rounded-lg">
                                            <p>
                                                <Link to={path ? "/service-info/" + path : "/service-info"} className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white">Click here</Link> 
                                                <span> to see the complete list of businesses which comes under state licence </span>
                                            </p>
                                        </div>
                                    </div> */}
                                </div>)}

                                {/* Our Process */}
                                {process && process.ourProcess && <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Our Process
                                        </h1>
                                    </div>
                                    <h2 className="mt-4 max-w-2xl text-lg md:text-xl tracking-wide text-gray-900">
                                        {process.ourProcess.title}
                                    </h2>
                                    <h2 className="mt-16 text-md font-bold text-gray-900 md:text-2xl">
                                        Timeline - {process.ourProcess.estimate} estimate
                                    </h2>
                                    <Timeline type='our' data={process.ourProcess.values} />
                                </div>}

                                {/* FSSAI Process */}
                                {process && process.fssaiProcess && <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            FSSAI Process
                                        </h1>
                                    </div>
                                    <h2 className="mt-4 max-w-2xl text-lg md:text-xl tracking-wide text-gray-900">
                                        {process.fssaiProcess.title}
                                    </h2>
                                    <h2 className="mt-16 text-md font-bold text-gray-900 md:text-2xl">
                                        Timeline - {process.fssaiProcess.estimate} estimate
                                    </h2>
                                    <Timeline type='fssai' data={process.fssaiProcess.values} />
                                </div>}

                                {/* Renewal Process */}
                                {process && process.renewalProcess && <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            FSSAI Process
                                        </h1>
                                    </div>
                                    <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        {process.renewalProcess.title}
                                    </h2>
                                    <h2 className="mt-16 text-md font-bold text-gray-900 md:text-2xl">
                                        Timeline - {process.renewalProcess.estimate} estimate
                                    </h2>
                                    <Timeline type='fssai' data={process.renewalProcess.values} />
                                </div>}

                                {/* Documents */}
                                <div className="py-5 text-left">
                                    <div className="py-4">
                                        <div className="max-w-3xl">
                                            <div className="">
                                                {/* <h2 className="text-base text-darkblue-500 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                                <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                                    Documents To Be Submitted
                                                </h1>
                                                {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                                    Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                                    accusamus quisquam.
                                                </p> */}
                                            </div>

                                            <div className="mt-10">
                                                <dl className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2">
                                                    {features.map((feature) => (
                                                        <div key={feature.name} className="relative pr-5 md:pr-1 py-5">
                                                            <div className='flex items-center'>
                                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-darkblue-500 text-white">
                                                                    {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                                                                    <img src={feature.icon} className="h-6 w-6" aria-hidden="true" alt={feature.name} />
                                                                </div>
                                                                <p className="ml-16 text-left text-lg text-gray-900">{feature.name}</p>
                                                            </div>
                                                            {/* <dd className="mt-2 text-left ml-16 text-base tracking-wide text-gray-900">{feature.description}</dd> */}
                                                        </div>
                                                    ))}
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 font-medium">
                                        <div className="text-left text-teal-900 bg-teal-200 p-5 shadow rounded-lg">
                                            <p> Final list of documents required for filing new FSSAI food license application will be shared after understanding the food product category and kind of business.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <Faq cols={'1'} titleAlignment={'left'} /> */}
                            </div>
                            {/* <div className='w-full md:w-1/3 lg:w-1/3 sticky top-0 pt-0 lg:pt-20'>
                                <div className="w-max mt-0 lg:mt-20 flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-darkblue-500 group-hover:text-white">
                                        <div className='flex flex-col items-center'>
                                            <p className="text-base text-left text-2xl font-semibold text-gray-700">
                                                Service Title
                                            </p>
                                            <p className="mt-2 text-center mx-auto text-lg">
                                                <span>₹</span>
                                                <span className="ml-2 text-5xl font-bold">25</span>
                                                <span className="ml-2">/one time</span>
                                            </p>
                                            <Link to="/checkout" className="block text-white lg:w-64 bg-darkblue-500 hover:text-white hover:bg-darkblue-600 group-hover:text-darkblue-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                            <Link to="" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700">Request Callback &nbsp;&nbsp; →</Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='w-full md:w-1/3 lg:w-1/3 sticky top-0 pt-0 lg:pt-20'>
                                <div className="w-max mt-0 lg:mt-1 flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-darkblue-500 group-hover:text-white">
                                        <div>
                                            <p className="text-center text-gray-700 font-semibold">Starts from</p>
                                            <p className="mt-2 text-center mx-auto sm:text-md lg:text-lg">
                                                <span className="ml-2 text-4xl font-bold">₹ 7500</span>
                                                <span className="ml-2 text-gray-700 font-semibold">per licence</span>
                                            </p>
                                            <p className='mt-2 text-center mx-auto text-sm'>*conditions apply</p>
                                            <Link to="/checkout" className="mt-3 block text-white bg-darkblue-500 hover:text-white hover:bg-darkblue-600 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                            <div className="flex items-center py-2">
                                                <div className="flex-grow h-px bg bg-gray-300 h-0.5"></div>
                                                <div className="flex-grow-0 mx-5 text text-gray-700">or</div>
                                                <div className="flex-grow h-px bg bg-gray-300 h-0.5"></div>
                                            </div>
                                            <div className="flex-1 items-stretch space-y-4">
                                                <p className='text-left text-base text-gray-700 font-semibold'>Got any questions? Talk to our expert.</p>
                                                <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="name"
                                                        name="name"
                                                        id="name"
                                                        autoComplete="name"
                                                        placeholder="Enter your name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Phone No
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        name="phone-no"
                                                        id="phone-no"
                                                        autoComplete="tel"
                                                        placeholder="+91 ##### #####"
                                                        maxLength='10'
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>
                                                {name && name.length > 3 && <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email-address"
                                                        id="email-address"
                                                        autoComplete="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="Enter your email"
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>}
                                            </div>
                                            <div className="flex mt-5 items-start">
                                                <input
                                                    id="send-communications"
                                                    name="send-communications"
                                                    type="checkbox"
                                                    defaultChecked={true}
                                                    className="h-3 w-3  mt-1 bg-darkblue-600 text-darkblue-500 rounded"
                                                    style={{flexShrink: 0}}
                                                />
                                                <label htmlFor="send-communications" className="ml-2 text-sm text-gray-900">
                                                    By clicking on request callback, I provide my consent to receive communications from Aahar. Check our 
                                                    <Link to="/terms" className={`text-darkblue-500 hover:text-darkblue-700`}> terms & conditions </Link> .
                                                </label>
                                            </div>
                                            <Link to="" className="block text-white bg-darkblue-500 hover:text-white hover:bg-darkblue-600 group-hover:text-darkblue-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Request Callback</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium mt-5 lg:mt-2">
                                    <div className="text-left text-teal-900 bg-teal-200 p-4 shadow rounded-lg">
                                        <GovtNoteComponent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* {data && data.map((e) => 
                        <div className="mt-5 md:mr-10">
                            <div className={classNames(licenseType === 'new' ? 'bg-darkblue-200' : 'bg-teal-200' ,'text-left text-gray-900 p-5 shadow rounded-lg')}>
                                <p className='font-bold'>
                                    Want to {getBackLinks[licenseType]?.keyword} {getBackLinks[licenseType][e.name]?.name} License instead?
                                    <Link to={getBackLinks[licenseType][e.name]?.link} className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> Click here</Link> 
                                </p>
                                {licenseType === 'new' &&
                                    <>
                                        <p className='mt-3 font-medium'>
                                            <span>Not sure which license is applicable for your business? </span>
                                        </p>
                                        <p className='mt-2 text-gray-900 font-medium'>
                                            <div className=''>
                                                Talk to us on <a className='text-darkblue-500 hover:text-darkblue-700 font-semibold' href="tel:9901177902"> 18001020127 </a>
                                                or <Link to="/comingsoon" className="font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> click here </Link>
                                                to use our tool to find out yourself
                                            </div>
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    )} */}

                    {data && data.map((e) => 
                        <div className="px-8 md:px-0 py-8 mx-auto md:px-24 lg:px-8 lg:py-10 flex flex-col md:flex-row">
                            <div className="p-8 md:mx-4 rounded-lg shadow-lg card-shadow sm:p-16 lg:w-full">
                                <div className="">
                                    <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">NEW FSSAI FOOD LICENSE</span>
                                </div>
                                <div className="my-5 flex flex-col">
                                    <div className="lg:pr-5">
                                        <h2 className="text-2xl font-bold text-gray-900 md:text-4xl">
                                            Want to {getBackLinks[licenseType]?.keyword} <span className='text-darkblue-500'>{getBackLinks[licenseType][e.name]?.name} License </span> instead?
                                            {/* <Link to={getBackLinks[licenseType][e.name]?.link} className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> Click here</Link>  */}
                                        </h2>
                                        {/* Used a and href instead of Link to reload the page */}
                                        <div className="flex">
                                            <a href={getBackLinks[licenseType][e.name]?.link}>
                                                <div className="mt-5 transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                                    <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                                        <p className="font-semibold text-md md:text-xl text-gray-900 w-48">
                                                            Get Started
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 space-y-3">
                                    {licenseType === 'new' &&
                                        <>
                                            <p className='mt-3 font-medium'>
                                                <span className='text-gray-900'>Not sure which license is applicable for your business? </span>
                                            </p>
                                            <p className='mt-2 text-gray-900 font-medium'>
                                                {/* <Link to="/service-info" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> Request Callback </Link> or  */}
                                                <div className=''>
                                                    Talk to us on <a className='text-darkblue-500 hover:text-darkblue-700 font-semibold' href="tel:9901177902"> 18001020127 </a>
                                                    or <Link to="/comingsoon" className="font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> click here </Link>
                                                    to use our tool to find out yourself
                                                </div>
                                            </p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
    
                    <div className="px-8 md:px-0 mx-auto md:px-24 lg:px-8 lg:py-3 flex flex-col md:flex-row">
                        {!(licenseType === 'new') && <div className="p-8 mb-4 md:mb-0 md:mx-4 rounded-lg shadow-lg card-shadow sm:p-16 lg:w-1/2">
                            <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">NEW FSSAI FOOD LICENSE</span>
                            <div className="my-5 flex flex-col">
                                <div className="lg:pr-5">
                                    <h2 className="text-2xl font-bold text-gray-900 md:text-4xl">
                                        Want to apply for a <span className="text-darkblue-500">new</span> food license?
                                    </h2>
                                </div>
                            </div>
                            <div className="mt-5 grid gap-5 md:grid-cols-1">
                                <Link to="/fssai-food-license-new?type=registration-certificate">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                New Registration Certificate
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/fssai-food-license-new?type=state-license">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                New State License
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/fssai-food-license-new?type=state-license">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                New Central License
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>}
                        {!(licenseType === 'renewal') && <div className="p-8 mb-4 md:mb-0 md:mx-4 rounded-lg shadow-lg card-shadow sm:p-16 lg:w-1/2">
                            <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">FSSAI FOOD LICENSE RENEWAL</span>
                            <div className="my-5 flex flex-col">
                                <div className="lg:pr-5">
                                    <h2 className="text-2xl font-bold text-gray-900 md:text-4xl">
                                        Want to <span className="text-darkblue-500">renew</span> your existing food license?
                                    </h2>
                                </div>
                            </div>
                            <div className="mt-6 grid gap-5 md:grid-cols-1">
                                <Link to="/fssai-food-license-renewal?type=registration-certificate">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                Renew Registration Certificate
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/fssai-food-license-renewal?type=state-license">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                Renew State License
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/fssai-food-license-renewal?type=central-license">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                Renew Central License
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>}
                        {!(licenseType === 'modification') && <div className="p-8 mb-4 md:mb-0 md:mx-4 rounded-lg shadow-lg card-shadow sm:p-16 lg:w-1/2">
                            <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">FSSAI FOOD LICENSE MODIFICATION</span>
                            <div className="my-5 flex flex-col">
                                <div className="lg:pr-5">
                                    <h2 className="text-2xl font-bold text-gray-900 md:text-4xl">
                                        Want to <span className="text-darkblue-500">modify</span> your existing food license?
                                    </h2>
                                </div>
                            </div>
                            <div className="mt-5 grid gap-5 md:grid-cols-1">
                                <Link to="/fssai-food-license-modification?type=registration-certificate">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                Modify Registration Certificate
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/fssai-food-license-modification?type=state-license">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                Modify State License
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                                <Link to="/fssai-food-license-modification?type=central-license">
                                    <div className="transform duration-500 hover:scale-105 bg-white border-l-4 shadow-sm border-darkblue-500 hover:border-darkblue-600">
                                        <div className="h-full p-2 md:p-3 border border-l-0 rounded-r-lg">
                                            <p className="font-semibold text-md md:text-xl text-gray-900">
                                                Modify Central License
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            <hr className='bg bg-gray-200' />
            <E2EComplaince />

            <hr className='bg bg-gray-200' />
            <SuggestedBlogs />

            <hr className='bg bg-gray-200' />
            <Newsletter />
        </>
    )
}

export default ServicePlanDetails