import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { images } from '../constants';
// import { Menu } from '@headlessui/react';
// import Dropdown from '../components/Dropdown';
import CategoryDropdown from '../components/CategoryDropdown';
import TurnOverDropdown from '../components/TurnOverDropdown';
import KobData from '../assets/kob.json';
import BusinessTypeDropdown from '../components/BusinessTypeDropdown';

const ExploreTool = () => {
    const [plan, setPlan] = useState('');
    // const [plan, setPlan] = useState('');
    const [licenceType, setLicenceType] = useState();
    const [showExport, setShowExport] = useState(false);
    const [bizzExport, setBizzExport] = useState();
    const [showMultipleBizz, setShowMultipleBizz] = useState(false);
    const [multipleBizz, setMultipleBizz] = useState();
    const [showRadiation, setShowRadiation] = useState(false);
    const [radiation, setRadiation] = useState();
    const [showPropBizz, setShowPropBizz] = useState(false);
    const [propBizz, setPropBizz] = useState();
    const [showHealthSupp, setShowHealthSupp] = useState(false);
    const [healthSupp, setHealthSupp] = useState();

    const [businessData, setBusinessData] = useState([]);
    const [kobData, setKobData] = useState([]);
    const [criteriaData, setCriteriaData] = useState([]);
    const [turnOverData, setTurnOverData] = useState([]);

    const [businessType, setBusinessType] = useState();
    const [category, setCategory] = useState();
    const [criteria, setCriteria] = useState();
    const [turnOver, setTurnOver] = useState();

    const [showCriteria, setShowCriteria] = useState(false);

    console.log('Kob Data : ', KobData);

    useEffect(() => {

        setBusinessData(KobData.map((e) => (e.name)));

        // if (businessType !== null && businessType !== undefined) {
        //     setKobData(KobData.map((e) => (e.name === businessType ? e.kob : null)));
        //     setCriteriaData(KobData.map((e) => (e.name === businessType ? e.criteria : null)));
        // }
        
      
    }, [businessType])
    

    console.log({ businessData, kobData, criteriaData, turnOverData });

    const onChange = (type, value) => {
        if (type === 'licenceType') {
            if (value === 'yes') {
                setLicenceType('Central');
                setShowExport(false)
            } else {
                setLicenceType();
                setShowExport(true)
            }
        } else if (type === 'export') {
            if (value === 'yes') {
                setLicenceType('Central');
                setBizzExport(value);
                setShowMultipleBizz(false);
            } else {
                setLicenceType();
                setBizzExport(value);
                setShowMultipleBizz(true);
            }
        } else if (type === 'multipleBizz') {
            if (value === 'yes') {
                setLicenceType('Central');
                setMultipleBizz(value);
                setShowRadiation(false);
            } else {
                setLicenceType();
                setMultipleBizz(value);
                setShowRadiation(true);
            }
        } else if (type === 'radiation') {
            if (value === 'yes') {
                setLicenceType('Central');
                setRadiation(value);
                setShowPropBizz(false);
            } else {
                setLicenceType();
                setRadiation(value);
                setShowPropBizz(true);
            }
        } else if (type === 'propBizz') {
            if (value === 'yes') {
                setLicenceType('Central');
                setPropBizz(value);
                setShowHealthSupp(false);
            } else {
                setLicenceType();
                setPropBizz(value);
                setShowHealthSupp(true);
            }
        } else if (type === 'healthSupplements') {
            if (value === 'yes') {
                setLicenceType('Central');
                setHealthSupp(value);
            } else {
                setLicenceType();
                setHealthSupp(value);
            }
        }
    }

    const onBusinessTypeSelected = (e) => {
        setBusinessType(e);
        if (e) {
            const busniess = KobData.filter(data => data.name === e)[0];
            setKobData(busniess.kob);
        }
    }
    
    const onKobSelected = (e) => {
        setCategory(e);
        if (businessType) {
            const business = KobData.filter(data => data.name === businessType)[0];
            console.log('Busniess Type : ', business);

            if (business.kobType === 'multi') {
                setShowCriteria(true); 
                setCriteriaData(business.criteria.map(e => (e.name)));
            } else {
                setTurnOverData(business.criteria[0].values);
            }
            
        }
    }

    const onCriteriaSelected = (e) => {
        if (businessType) {
            const business = KobData.filter(data => data.name === businessType)[0];
            const criteria = business.criteria.filter(data => data.name === e)[0];
            setCriteria(e)
            setTurnOverData(criteria.values);
        }
    }

    const onTurnOverSelected = (e) => {
        setTurnOver(e.licenceType);
    }

    return (
        <>
            <div className="max-w-6xl mx-auto">
                <div className="relative bg-white">
                    <div className="mt-10 mx-auto max-w-6xl px-4 mt-20 sm:px-6 md:mt-20 lg:mt-32 xl:mt-32">
                        <div className="text-center">
                            <div className='w-full flex flex-col-reverse md:flex md:flex-row-reverse items-center'>
                                <div className='w-full mt-5 px-3 md:px-0 md:ml-5 md:w-2/3'>
                                    <div className="text-3xl tracking-tight text-left font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                                            Le us help you <span className='text-purple-500'>Plan</span> your <span className='text-purple-500'>Licence</span>
                                        </h2>
                                    </div>
                                    <p className="mt-3 text-base text-left tracking-wider text-gray-700">
                                        Our team of experts in food safety and standards will help you understand FSSAI regulations which includes how to apply for a new licence application, how to renew licence, how to modify licence, how to file annual return etc. 
                                    </p>
                                </div>
                                <div className='w-full mt-10 mb-5 md:w-1/3'>
                                    <img
                                        className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                        src={images.team}
                                        alt=""
                                    />
                                </div>
                            </div>
                            
                            <hr className='mt-10' />
                            
                            <div className="py-12">
                                
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="lg:text-center">
                                        {/* <h2 className="text-base text-purple-500 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                        {/* <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl lg:text-5xl">
                                            Food technologists & FSSAI regulatory experts assisting you with <span className='text-purple-500 font-extrabold'>100% compliance!</span>
                                        </p> */}
                                        {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-700 lg:mx-auto">
                                            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                            accusamus quisquam.
                                        </p> */}
                                    </div>
                                    
                                    <div className="text-teal-900 bg-teal-200 py-3 px-6 rounded">
                                        <p>If your business is in Railways or Airport, Sea Port or Central Govt. Agencies, Please Ignore this tool!</p>
                                    </div>
                                    
                                    <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h1>E-commerce</h1>
                                            <div className='mt-5' onChange={(e) => onChange('licenceType', e.target.value)}>
                                                <input type="radio" value="yes" name="licenceType" /> Yes
                                                <input type="radio" value="no" name="licenceType" /> No
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {showExport && <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h1>Do you Export/Import?</h1>
                                            <div className='mt-5' onChange={(e) => onChange('export', e.target.value)}>
                                                <input type="radio" value="yes" name="export" /> Yes
                                                <input type="radio" value="no" name="export" /> No
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {showMultipleBizz && <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h1>Are you conducting business in more than 2 states?</h1>
                                            <div className='mt-5' onChange={(e) => onChange('multipleBizz', e.target.value)}>
                                                <input type="radio" value="yes" name="multipleBizz" /> Yes
                                                <input type="radio" value="no" name="multipleBizz" /> No
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {showRadiation && <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h1>Radiation?</h1>
                                            <div className='mt-5' onChange={(e) => onChange('radiation', e.target.value)}>
                                                <input type="radio" value="yes" name="radiation" /> Yes
                                                <input type="radio" value="no" name="radiation" /> No
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {showPropBizz && <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h1>Prop. Business?</h1>
                                            <div className='mt-5' onChange={(e) => onChange('propBizz', e.target.value)}>
                                                <input type="radio" value="yes" name="propBizz" /> Yes
                                                <input type="radio" value="no" name="propBizz" /> No
                                            </div>
                                        </div>
                                    </div>}
                                    
                                    {showHealthSupp && <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h1>Do you use Health Supplements?</h1>
                                            <div className='mt-5' onChange={(e) => onChange('healthSupplements', e.target.value)}>
                                                <input type="radio" value="yes" name="healthSupplements" /> Yes
                                                <input type="radio" value="no" name="healthSupplements" /> No
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="mt-5 flex flex-row border justify-around p-5">
                                        <div className="">
                                            <h2>Result</h2>
                                            <p>Licence Type - {licenceType}</p>
                                            <p>Fee - {licenceType === 'Central' ? 7500 : ''}</p>
                                        </div>
                                    </div>


                                </div>

                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="mt-5 flex flex-col border justify-around p-5">
                                        <div className="mt-5">
                                            {/* <Dropdown type='categories' data={categories} /> */}
                                            <p className='mb-3'>Business Type - {businessType}</p>
                                            <BusinessTypeDropdown data={businessData ?? []} onSelected={onBusinessTypeSelected} />
                                        </div>
                                        <div className="mt-5">
                                            {/* <Dropdown type='categories' data={categories} /> */}
                                            <p className='mb-3'>Category - {category}</p>
                                            <CategoryDropdown data={kobData ?? []} onSelected={onKobSelected} />
                                        </div>
                                        {
                                            showCriteria && 
                                        <div className="mt-5">
                                            {/* <Dropdown type='categories' data={categories} /> */}
                                            <p className='mb-3'>Criteria - {criteria}</p>
                                            <CategoryDropdown data={criteriaData ?? []} onSelected={onCriteriaSelected} />
                                        </div>
                                        }
                                        <div className="mt-5">
                                            {/* <Dropdown type='categories' data={categories} /> */}
                                            <p className='mb-3'>Turn Over - {turnOver}</p>
                                            <TurnOverDropdown data={turnOverData ?? []} onSelected={onTurnOverSelected} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <hr className='bg bg-gray-200' />
            <Newsletter /> */}
        </>
    )
}

export default ExploreTool