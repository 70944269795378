import React from 'react';
import { images } from '../constants';
import BlogPost from './BlogPost';

const BlogHome = ({pinned}) => {

    

    return (
        <div className="max-w-7xl mx-auto mt-32">
            <div className="relative bg-white">
                <div className="flex text-center">
                    <div className='w-full mt-5 px-3 md:px-0 md:ml-5 md:w-2/3'>
                        <div className="text-3xl tracking-tight text-left font-bold text-gray-900 sm:text-3xl md:text-4xl">
                            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                                Let our <span className='text-purple-500'>food technologists</span> and <span className='text-purple-500'>regulatory experts</span> manage your FSSAI Compliance!
                            </h1>
                        </div>
                        <h2 className="mt-3 text-base text-left tracking-wider text-gray-700">
                            Our team of experts in food safety and standards will help you understand FSSAI regulations which includes how to apply for a new licence application, how to renew licence, how to modify licence, how to file annual return etc. 
                        </h2>
                    </div>
                    <div className='w-full mt-10 mb-5 md:w-1/3'>
                        <img
                            className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                            src={images.team}
                            alt=""
                        />
                    </div>
                </div>
                <BlogPost pinned={pinned} />
            </div>
        </div>
    )
}

export default BlogHome