import React from 'react';
// import { Link } from 'react-router-dom'

const Privacy = () => {
  return (
    <div className="max-w-6xl mx-auto pt-20">
      <div className="relative pb-8 bg-white">
          <div className="mt-24 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="">
                  <div className="text-center text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                      <h1 className="block xl:inline">Privacy Notice</h1>{' '}
                      {/* <p className="block text-purple-600 xl:inline">Introducing aahar.compliance!</p> */}
                  </div>
                  <p className="text-left mt-3 tracking-wide text-gray-700 sm:mt-5 sm:text-md sm:mx-auto md:mt-6 md:text-lg lg:mx-0" style={{whiteSpace: 'pre-wrap'}}>
                    {`Last updated: 30-Sep-2022

                      This privacy notice for TU Aahar Consulting Private Limited ("Company," "we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you: 

                      Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at team@aahar.in

                      SUMMARY OF KEY POINTS

                      This summary provides key points from our privacy notice, but you can find out more details about any of these topics by using our table of contents below to find the section you are looking for.

                      What personal information do we process? 
                      When you visit, use, or navigate our Services, we may process personal information depending on how you interact with Aahar and the Services, the choices you make, and the products and features you use. 

                      Do we process any sensitive personal information? 
                      We do not process sensitive personal information.

                      Do we receive any information from third parties? 
                      We may receive information from public databases, marketing partners, social media platforms, and other outside sources.

                      How do we process your information? 
                      We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.

                      In what situations and with which parties do we share personal information?
                      We may share information in specific situations and with specific third parties.

                      What are your rights? 
                      Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.

                      How do you exercise your rights?
                      The easiest way to exercise your rights is by filling out our data subject request form by contacting us at team@aahar.in and we will consider and act upon any request in accordance with applicable data protection laws.`}
                  </p>
              </div>
          </div>
        </div>
        {/* <div className="max-w-5xl mx-auto px-4 sm:px-6 py-8">
          <div className="font-medium leading-7 text-center space-y-2 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-4 sm:space-y-0">
              <div className="text-teal-900 bg-teal-200 py-3 px-6 rounded">
              <p>Hello, you! 👋</p>
              </div>
              <div className="text-purple-900 bg-purple-200 py-3 px-6 rounded">
              <p>Here is a 🎁</p>
              </div>
              <div className="text-green-900 bg-green-200 py-3 px-6 rounded">
              <p>Tailwind starter 🎉</p>
              </div>
          </div>
          </div> */}
    </div>
  )
}

export default Privacy