const initialState = {
    id: 0,
    navTitle: 'aahar.consulting',
    navDescription: '',
    meta: 'For Complaince & FSSAI',
    primaryColor: 'white'
};

export const apps = [
    {
        id: 0,
        navTitle: 'aahar.consulting',
        navDescription: '',
        meta: 'For Complaince & FSSAI',
        primaryColor: 'white'
    },
    {
        id: 1,
        navTitle: 'aahar.regulatory',
        navDescription: '',
        meta: 'For Complaince & FSSAI',
        primaryColor: 'darkblue'
    },
    {
        id: 2,
        navTitle: 'aahar.testing',
        navDescription: '',
        meta: 'Food Testing',
        primaryColor: 'green'
    },
    {
        id: 3,
        navTitle: 'aahar.testing',
        navDescription: '',
        meta: 'Water Testing',
        primaryColor: 'teal'
    },
    {
        id: 4,
        navTitle: 'aahar.learning',
        navDescription: '',
        meta: 'Training & Academy',
        primaryColor: 'orange'
    },
    {
        id: 5,
        navTitle: 'aahar.people',
        navDescription: '',
        meta: 'Health',
        primaryColor: 'red'
    },
    {
        id: 6,
        navTitle: 'aahar.control',
        navDescription: '',
        meta: 'Pest Control',
        primaryColor: 'silver'
    },
    {
        id: 7,
        navTitle: 'aahar.process',
        navDescription: '',
        meta: 'Process',
        primaryColor: 'pink'
    },
    {
        id: 8,
        navTitle: 'aahar.compliance',
        navDescription: '',
        meta: 'Complete Compliance',
        primaryColor: 'purple'
    },
];

const AppReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'APP':
            return {
                ...state,
                ...payload
            }
        default:
            return {...state};
    }
}

export default AppReducer;