import api from "../utils/api";
import { CONTACT_US, CONTACT_US_ERROR, NEWSLETTER_SUBS, NEWSLETTER_SUBS_ERROR, NOTIFY_LAUNCH, NOTIFY_LAUNCH_ERROR, REQUEST_CALLBACK, REQUEST_CALLBACK_ERROR } from "./types";

// Request Callback 
export const requestCallback = (formData) => async (dispatch) => {
    try {
        const res = await api.post('/requestCallback', formData);
    
        dispatch({
            type: REQUEST_CALLBACK,
            payload: res.data
        });
        
        // remove the data in the store
        setTimeout(() => {
            dispatch({
                type: REQUEST_CALLBACK,
                payload: {}
                });
        }, 7500);
  
    //   dispatch(setAlert('Post Created', 'success'));
    } catch (err) {
      dispatch({
        type: REQUEST_CALLBACK_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
};

// News Letter Subscriptions 
export const newsLetterSubs = (formData) => async (dispatch) => {
    try {
      const res = await api.post('/newsletterSubs', formData);
  
      dispatch({
        type: NEWSLETTER_SUBS,
        payload: res.data
      });

        // remove the data in the store
        setTimeout(() => {
            dispatch({
                type: NEWSLETTER_SUBS,
                payload: {}
                });
        }, 7500);
  
    //   dispatch(setAlert('Post Created', 'success'));
    } catch (err) {
      dispatch({
        type: NEWSLETTER_SUBS_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
};

// Notify Launches 
export const notifyLaunches = (formData) => async (dispatch) => {
    try {
      const res = await api.post('/notifyLaunches', formData);
  
      dispatch({
        type: NOTIFY_LAUNCH,
        payload: res.data
      });
        
        // remove the data in the store
        setTimeout(() => {
            dispatch({
                type: NOTIFY_LAUNCH,
                payload: {}
                });
        }, 7500);
  
    //   dispatch(setAlert('Post Created', 'success'));
    } catch (err) {
      dispatch({
        type: NOTIFY_LAUNCH_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
};

// Contact Us 
export const contactUs = (formData) => async (dispatch) => {
    try {
      const res = await api.post('/contactUs', formData);
  
      dispatch({
        type: CONTACT_US,
        payload: res.data
      });
        
        // remove the data in the store
        setTimeout(() => {
            dispatch({
                type: CONTACT_US,
                payload: {}
                });
        }, 7500);
  
    //   dispatch(setAlert('Post Created', 'success'));
    } catch (err) {
      dispatch({
        type: CONTACT_US_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
};