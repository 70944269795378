export const foodProducts = [
    {
        name: "Meat & Meat Products",
        products: [
            "Fresh and frozen meat and eggs",
            "Processed and preserved meat"
        ]
    },
    {
        name: "Dairy Products & Analogues",
        products: [
            "Milk",
            "Cream",
            "Malai",
            "Curd",
            "Paneer",
            "Cheese",
            "Dairy based desserts / confections",
            "Evaporated / condensed milk & milk products ",
            "Foods for infant nutrition",
            "Butter, ghee & milk fats",
            "Chakka & shirkhand",
            "Fermented milk products",
            "Whey products",
            "Edible casein products"
        ]
    },
    {
        name: "Fats, Oils & Fat Emulsions",
        products: [
            "Fats, oils & fat emulsions",
            "Edible fats",
            "Margarine & fat spreads",
            "Hydrogenated vegatable oils",
            "Interesterified vegetable fat",
            "Partialy hydrogenated soyabean oil"
        ]
    },
    {
        name: "Fruits & Vegetable Products",
        products: [
            "Fruits and vegetable products",
            "Vinegar",
            "Nuts & raisins",
            "Bean"
        ]
    },
    {
        name: "Cereals & Cereals Products",
        products: [
            "Atta",
            "Maida",
            "Semolina",
            "Besan",
            "Pearl barkey",
            "Food grains",
            "Corn flour",
            "Corn flakes",
            "Macaroni products",
            "Rolled oats",
            "Solvent extracted flours",
            "Starchy foods",
            "Bakery products"
        ]
    },
    {
        name: "Fish & Fish Products",
        products: [
            "Fresh fish",
            "Processed and preserved fish"
        ]
    },
    {
        name: "Sweets & Confectionery Products",
        products: [
            "Sugar boiled confectionery",
            "Lozenges",
            "Chewing gum and bubble gum",
            "Chocolate",
            "Ice lollies or edible ice"
        ]
    },
    {
        name: "Sweetening agents including honey products",
        products: [
            "Sugar",
            "Misri",
            "Honey",
            "Jaggery",
            "Dextrose",
            "Golden syrup",
            "Dreid glucose syrup",
            "Aspartame",
            "Acesufame potassium",
            "Sucralose"
        ]
    },
    {
        name: "Salt, Spices, Condiments & Related Products",
        products: [
            "Caraway",
            "Cardamom",
            "Chillies and Capsicum",
            "Cinnamon",
            "Cassia",
            "Cloves",
            "Coriander",
            "Cumin",
            "Fennel",
            "Fenugreek",
            "Ginger",
            "Mace",
            "Mustard",
            "Nutmeg",
            "Pepper black & white",
            "Saffron",
            "Turmeric",
            "Curry Powder",
            "Mixed Masala",
            "Aniseed",
            "Ajwain",
            "Dried mango slices & powder",
            "Garlic",
            "Edible common salt"
        ]
    },
    {
        name: "Beverages (Other than dairy, fruit & vegetable)",
        products: [
            "Tea",
            "Coffee",
            "Chicory",
            "Alcoholic beverages",
            "Non-alcoholic carbonated beverages",
            "Packaged drinking water"
        ]
    },
    {
        name: "Proprietary Foods",
        products: []
    },
    {
        name: "Nutraceuticals",
        products: [
            "Health supplements",
            "Nutraceuticals",
            "Food for special dietary use",
            "Food for special medical purpose",
            "Functional food",
            "Novel food"
        ]
    },
    {
        name: "Organic Food",
        products: []
    },
    {
        name: "Other foods",
        products: []
    }
];

export const foodTestCategory = [
    {
        name: "Microbial Testing",
        params: [
            "Total Plate Count",
            "Yeast & Mold",
            "Coliforms",
            "Escherichia coli and O157 :H7",
            "Salmonella species",
            "Enterobacteriaceae",
            "Lactic Acid Bacteria",
            "Staphylococcus aureus",
            "Bacillus cereus",
            "Shigella species",
            "Campylobacter",
            "Spore-forming Mesophilic bacteria",
            "Spore-forming Thermophilic bacteria",
            "Pseudomonas aeruginosa",
            "Clostridium species",
            "Cronobacter sakazakki",
            "Listeria monocytogenes & spp",
            "Thermophilic Flat sour spore formers",
            "Vibrio cholerae",
            "Vibrio parahaemolyticus",
            "Fecal streptococci",
            "MS2 Phage",
            "GMO & cotton GMO by RT PCR",
            "Salmonella by RT PCR",
            "Sesame allergen by RT PCR",
            "Vegan test by RT PCR",
            "C.botulinum",
            "Alicyclobacillus (TAB)",
            "Heat resistance mold",
            "Helminth eggs in wastewater",
            "EN 1276 - Evaluation of the bactericidal activity of chemical disinfectants",
            "EN 13697 - Chemical disinfectants and antiseptics - Quantitative non-porous surface test for the evaluation of bactericidal and/or fungicidal activity",
            "AATCC 100 - Antimicrobial fabric test",
            "AATCC 147 - Antimicrobial Activity of Textile Materials"
        ]
    },
    {
        name: "Shelf Life Testing",
        params: [
            "Organoleptic properties",
            "Rancidity",
            "Texture changes",
            "Moisture loss",
            "Moisture gain",
            "Staling",
            "Flavor loss",
            "Light induced changes",
            "Microbial spoilage"
        ]
    },
    {
        name: "Nutritional Analysis",
        params: [
            "Calorific value",
            "Energy",
            "Protein",
            "Vitamin",
            "Mineral",
            "Carbohydrate",
            "Sugar",
            "Fibre",
            "Fats",
            "Saturated Fatty Acids",
            "Monounsaturated Fatty Acids",
            "Dietary fibre",
            "Polyunsaturated Fatty Acids",
            "Trans fats",
            "Cholesterol",
            "Amino acids",
            "Nucleotides"
        ]
    },
    {
        name: "Food Packaging Testing",
        params: [
            "Overall migration testing",
            "Color migration",
            "Toxic metals",
            "Polyaromatic amine",
            "Sensory analysis",
            "Overall product safety analysis",
            "Restricted substance testing - Bisphenol A",
            "Brimful capacity",
            "Qualititative test for material",
            "Physical testing",
            "Drop test",
            "Leakage test",
            "Stock load test",
            "Ink adhesion test",
            "Product resistance test",
            "Vibration leakage test",
            "Migration test"
        ]
    },
    {
        name: "Sensory Analysis",
        params: []
    },
    {
        name: "Food Additives",
        params: [
            "Aminoacids",
            "Preservatives",
            "Antioxidants",
            "Sweeteners",
            "Dyes",
            "Acidulants",
            "Other additives (phosphates, gultamates, propylene glycol, vanillin, alcohols, pimaricin, natamycin, nisin, nucelotides)"
        ]
    },
    {
        name: "Pesticide & Residual Analysis",
        params: [
            "Organochlorine",
            "Organophosphorous",
            "Synthetic Pyrethroids",
            "Triazines",
            "Carbamates",
            "Conazoles",
            "Pyrimidines",
            "Benzimidazole",
            "Nicotinoids",
            "Others",
            "Dioxins",
            "Furans",
            "PCBs"
        ]
    },
    {
        name: "Food Adulterants",
        params: []
    },
    {
        name: "Food Allergens",
        params: [
            "Cereals containing gluten",
            "Oat, Barley, Rye, Spelt, Kamut, Wheat",
            "Gluten-gliadin",
            "Crustaceans",
            "Eggs",
            "Lysozyme (Sausage, cheese & wine)",
            "Fish",
            "Peanuts",
            "Soybeans",
            "Milk",
            "Milk sugar / Lactose",
            "Casein and/or Beta-lactoglobulin",
            "Nuts",
            "Walnut, Cashew Nut, Pistachio, Pinenut",
            "Almond, Hazelnut",
            "Celery",
            "Mustard",
            "Sesame",
            "Sulphur dioxide (SO₂) and sulphites",
            "Lupin",
            "Molluscs"
        ]
    },
    {
        name: "Heavy Metals",
        params: [
            "Lead",
            "Arsenic",
            "Mercury",
            "Cadmium",
            "Copper",
            "Nickel",
            "Tin",
            "Zinc",
            "Sodium",
            "Iron",
            "Magnesium",
            "Manganese",
            "Methyl Mercury"
        ]
    },
];

export const waterTestCategory = [
    {
        name: "Potable Drinking Water (IS 10500:2012)"
    },
    {
        name: "Packaged Drinking Water (IS 14543:2016)"
    },
    {
        name: "Natural Mineral Water (IS 13428:2005)"
    }
];

export const waterTestParams = [
    {
        name: "Physio-chemical Tests",
        params: [
            "Phenolic Compounds",
            "Cyanide",
            "Odour",
            "Total Hardness",
            "Taste",
            "Sulphide as H2S",
            "pH",
            "Calcium",
            "Mineral Oil",
            "Residual Free Chlorine",
            "Total Alkalinity",
            "Total Dissolved Solids",
            "Ammonia",
            "Chloramines",
            "Chloride",
            "Color",
            "Fluoride",
            "Magnesium",
            "Sulphate",
            "Anionic Detergents",
            "Turbidity",
            "Nitrate"
        ]
    },
    {
        name: "Microbiology Tests",
        params: [
            "Coliform Count",
            "E.Coli"
        ]
    },
    {
        name: "Heavy Metals & Toxic Substances",
        params: [
            "PAH",
            "PCB",
            "Uranium",
            "Molybdenum",
            "Aluminium",
            "Copper",
            "Lead",
            "Nickel",
            "Silver",
            "Selenium",
            "Barium",
            "Mercury",
            "Arsenic",
            "Iron",
            "Manganese",
            "Zinc",
            "Boron",
            "Cadmium",
            "Chromium",
            "Bromoform",
            "Dibromocholro-methane",
            "Bromodichloro-methane",
            "Chloroform"
        ]
    },
    {
        name: "Pesticide Residues",
        params: [
            "2,4-D",
            "Atrazine",
            "alpha-HCH",
            "beta-HCH",
            "delta-HCH",
            "gamma-HCH",
            "Methyl Parathion",
            "Phorate",
            "DDT",
            "Isoproturon",
            "Malathion",
            "Butachlor",
            "Chlorpyrifos",
            "Aldrin & Dieldrin",
            "Monocrotophos",
            "Endosulphan",
            "Alachlor",
            "Ethion"
        ]
    },
];

export const medicalFitnessTestCategory = [
    {
        name: "Physical Examination"
    },
    {
        name: "Eye Test"
    },
    {
        name: "Skin Examination"
    },
    {   
        name: 'Vaccination - Typhoid, Hepatitis B'
    },
    {   
        name: 'FBS, PPBS, RBS'
    },
    {   
        name: 'CBC'
    },
    {   
        name: 'Blood Group & Rh Type'
    },
    {   
        name: 'Blood Urea, Creatinine'
    },
    {   
        name: 'SGOT/ SGPT'
    },
    {   
        name: 'Urine Routine'
    },
    {   
        name: 'Chest X-Ray'
    },
    {   
        name: 'ECG'
    },
    {   
        name: 'HbsAg, HIV 1&2 '
    },
    {   
        name: 'Widal Test'
    },
    {   
        name: 'Stool Routine Test'
    },
    {   
        name: 'Sputum Examination'
    },
];

export const pestControlTestCategory = [
    {   name : 'Rodent' },
    {   name : 'Cockroach' },
    {   name : 'Flies' }
]
export const calibrationCategory = [
    { name : 'Pressure' },
    { name : 'Temperature' },
    { name : 'Volume' },
    { name : 'TPM' },
    { name : 'pH' },
    { name : 'Humidity' }
]
export const instrumentCategory = [
    {
        name: "Chromatography",
        instruments: [
            "LC-MS / MS Triple Quadrupole",
            "GC-MS MS Triple Quadrupole",
            "UPLC/HPLC",
            "GC MS FID",
            "GC MS Headspace",
            "Purge & Trap GC-MS/MS",
            "GC-FID and ECD",
            "Protein analyser",
            "Ion Chromatography (1)"
        ]
    },
    {
        name: "Trace Elements and Heavy Metals Analysis",
        instruments: [
            "ICP-MS-LC",
            "ICP-OES",
            "Flame Photometer"
        ]
    },
    {
        name: "Others",
        instruments: [
            "RT-PCR",
            "FTIR",
            "VIDAS & TEMPO",
            "Elisa reader",
            "Flame photometer",
            "UV-Vis-Spectrophotometer",
            "Microscopy",
            "Stability chambers",
            "Water activity meter",
            "Microwave digestor"
        ]
    }
];
