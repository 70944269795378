import React from 'react';
import { Link } from 'react-router-dom';

const GovtNoteComponent = () => {
  return (
    <div>
        <p>
            <span>* Govt fees & GST are not included in the above price. </span>
            <Link to="/service-info" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white">Click here</Link> 
            <span> to check the exact govt fees.</span>
        </p>
        <p className='mt-3'>
            ** Final price may vary depending on the complexity of the license (Total number of products, kind of business, food product category etc)
        </p>
        <p className='mt-3'>
            Talk to our team on 1800 1020 127 to get an estimate. 
        </p>
    </div>
  )
}

export default GovtNoteComponent