import { CheckIcon } from '@heroicons/react/solid';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tab } from '@headlessui/react'
import { borderRadius } from 'tailwindcss/defaultTheme';

const PricingPlans = () => {

    let location = useLocation();

    const [path, setPath] = useState();
    const [freeze, setFreeze] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {

        if (location.pathname.split('/').length === 3) {
            setPath(location.pathname.split('/')[2]);
        } else {
            setPath('')
        }
      
    }, [location.pathname]);

    useEffect(() => {
        window.addEventListener('scroll', scrollPosition);
        return () => window.removeEventListener('scroll', scrollPosition)
    }, []);

    const scrollPosition = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        // if (scrolled > 0.127136021872864) {
        //     setFreeze(true);
        // } else {
        //     setFreeze(false);
        // }

        // show sticky tab bar only when it is scrolled for so much height
        if (scrolled > 0.13408521303258145) {
            setShow(true);
        } else {
            setShow(false);
        }
        // console.log(scrolled);
    };

    const pricingPlans = [
        {
            planName: 'New License',
            shortPlanName: 'New License',
            plans: [{
                name: 'New FSSAI Registration',
                price: 1000,
                features: [
                    'Application Filing',
                    'Documents Verification',
                    'SMS & Email Alerts'
                ],
                navigation: "/fssai-food-license-new?type=registration-certificate",
            },
            {
                name: 'New FSSAI State Licence',
                price: 10000,
                features: [
                    'Dedicated Account Manager & Status Update - Every Week',
                    'Document Preparation & Application Filing',
                    'KOB & Food Category Selection',
                    'Addressing reverted queries from FSSAI (If any)',
                    'End-to-end support till closure',
                    'Free Consultation - Any doubts related to FSSAI compliance'
                ],
                navigation: "/fssai-food-license-new?type=state-license",
            },
            {
                name: 'New FSSAI Central License',
                price: 15000,
                features: [
                    'Dedicated Account Manager & Status Update - Every Week',
                    'Document Preparation & Application Filing',
                    'KOB & Food Category Selection',
                    'Addressing reverted queries from FSSAI (If any)',
                    'End-to-end support till closure',
                    'Free Consultation - Any doubts related to FSSAI compliance'
                ],
                navigation: "/fssai-food-license-new?type=central-license",
            }],
            alertMessage: "Don’t know which license to apply for? "
        },
        {
            planName: 'License Renewal',
            shortPlanName: 'Renewal',
            plans: [{
                name: 'FSSAI Registration Certificate Renewal',
                price: 1000,
                features: [
                    'Document verification',
                    'Submission of license renewal request',
                    'SMS & Email alerts'
                ],
                navigation: "/fssai-food-license-renewal?type=registration-certificate",
            },
            {
                name: 'FSSAI State Licence Renewal',
                price: 7000,
                features: [
                    'Dedicated Account Manager & Status Update - Every Week',
                    'Submission of license renewal request',
                    'Addressing reverted queries from FSSAI',
                    'End to end support till closure',
                    'Comprehensive license checkup (For other compliance issues)',
                    'FSSAI license renewal reminder (3 months before expiry)',
                    'Free Consultation - Any doubts related to FSSAI compliance'
                ],
                navigation: "/fssai-food-license-renewal?type=state-license",
            },
            {
                name: 'FSSAI Central License Renewal',
                price: 10000,
                features: [
                    'Dedicated Account Manager & Status Update - Every Week',
                    'Submission of license renewal request',
                    'Addressing reverted queries from FSSAI',
                    'End to end support till closure',
                    'Comprehensive license checkup (For other compliance issues)',
                    'FSSAI license renewal reminder (3 months before expiry)',
                    'Free Consultation - Any doubts related to FSSAI compliance'
                ],
                navigation: "/fssai-food-license-renewal?type=central-license",
            }]
        },
        {
            planName: 'License Modification',
            shortPlanName: 'Modification',
            plans: [{
                name: 'FSSAI Registration Certificate Modification',
                price: 1000,
                features: [
                    'Submitting modification request',
                    'Supporting document verification',
                    'SMS & Email alerts'
                ],
                navigation: "/fssai-food-license-modification?type=registration-certificate",
            },
            {
                name: 'FSSAI State Licence Modification',
                price: 10000,
                features: [
                    'Dedicated Account Manager & Status Update - Every Week',
                    'Submitting modification request',
                    'Addressing reverted queries from FSSAI',
                    'End to end support till closure',
                    'Comprehensive license checkup (For other compliance issues)',
                    'FSSAI license renewal reminder (3 months before expiry)',
                    'Free Consultation - Any doubts related to FSSAI compliance'
                ],
                navigation: "/fssai-food-license-modification?type=state-license",
            },
            {
                name: 'FSSAI Central License Modification',
                price: 15000,
                features: [
                    'Dedicated Account Manager & Status Update - Every Week',
                    'Submitting modification request',
                    'Addressing reverted queries from FSSAI',
                    'End to end support till closure',
                    'Comprehensive license checkup (For other compliance issues)',
                    'FSSAI license renewal reminder (3 months before expiry)',
                    'Free Consultation - Any doubts related to FSSAI compliance'
                ],
                navigation: "/fssai-food-license-modification?type=central-license",
            }]
        },
    ];

    const getAdditionalInfo = (index) => {
        if (index === 0) {
            return (
                <div className="mt-5 px-0 lg:px-8 font-medium">
                    <div className="text-left bg-teal-200 p-3 sm:p-5 shadow rounded-lg">
                        <p className='text-center font-bold text-lg'>
                            <span>Don’t know which license to apply for? </span>
                        </p>
                        <p className='mt-2 text-center text-gray-900'>
                            <Link to="/comingsoon" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> Click here </Link>
                            to use our tool & find out which license is applicable to your business under 30 secs or
                            {/* <Link to="/service-info" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> Request Callback </Link> or  */}
                            <span>
                                &nbsp;talk to us on
                                <a className='text-darkblue-500 hover-link' href="tel:9901177902"> 9901177902</a>
                            </span>
                        </p>
                    </div>
                </div>
            )
        } else if (index === 1) {
            return (
                <div className="px-0 lg:px-8 font-medium">
                    <div className="text-left bg-teal-200 p-5 shadow rounded-lg">
                        {/* <p className='text-center font-bold text-lg'>
                            <span>If you dont know whether you have Registration Certificate/ State License/ Central License?</span>
                        </p> */}
                        <p className='text-center text-gray-900'>
                            Please check your FSSAI certificate copy to know whether you have registration certificate / state license / central license.
                            <Link to="/service-info" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> See sample. </Link>
                        </p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="px-0 lg:px-8 font-medium">
                    <div className="text-left bg-teal-200 p-5 shadow rounded-lg">
                        {/* <p className='text-center font-bold text-lg'>
                            <span>If you dont know whether you have Registration Certificate/ State License/ Central License?</span>
                        </p> */}
                        <p className='text-center text-gray-900'>
                            Please check your FSSAI certificate copy to know whether you have registration certificate / state license / central license.
                            <Link to="/service-info" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white"> See sample. </Link>
                        </p>
                    </div>
                </div>
            )
        }
    };

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    };
    
    return (
        <div>
            <div className="mt-8 text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    Pick your service
                </h1>
            </div>
            {/* <h2 className="mt-2 text-base text-center tracking-wide text-gray-700">
                Description about state licence 
            </h2> */}

            <div className="w-full px-2 pt-8 pb-16 sm:px-0">
                <Tab.Group>
                    {!show && <div>
                        <Tab.List className="mb-3 md:mx-10 flex border border-darkblue-500 bg-white">
                            {pricingPlans.map((e, i) => (
                                <Tab
                                    key={e.planName}
                                    style={{border: '1px solid #1578fa'}}
                                    // style={{ borderRadius: '10px 0px 0px 10px'}}
                                    className={({ selected }) =>
                                        classNames(
                                        'w-full py-3 sm:py-5 text-base',
                                        'focus:outline-none',
                                        selected
                                            ? 'bg-darkblue-500 text-white'
                                            : 'hover:bg-white/[0.12]'
                                        )
                                    }
                                    onClick={(e) => { if (window.innerWidth < 600) window.scrollTo(0, 1082) }}
                                >
                                <h1 className="sm:hidden text-md font-medium tracking-loose">
                                    {e.shortPlanName}
                                </h1>
                                <h1 className="hidden sm:block text-xl font-medium tracking-loose">
                                    {e.planName}
                                </h1>
                                </Tab>
                            ))}
                        </Tab.List>
                    </div>}
                    {show && <div style={{paddingTop: '4.75rem'}} className='sticky top-0 z-50 md:relative md:pt-0 md:z-0 transition duration-500 hover:scale-105 md:hover:scale-110 ease-out'>
                        <Tab.List className="mb-3 mt-4 -mx-6 md:mx-10 flex border border-darkblue-500 bg-white">
                            {pricingPlans.map((e, i) => (
                                <Tab
                                    key={e.planName}
                                    style={{border: '1px solid #1578fa'}}
                                    // style={{ borderRadius: '10px 0px 0px 10px'}}
                                    className={({ selected }) =>
                                        classNames(
                                        'w-full py-3 sm:py-5 text-base',
                                        'focus:outline-none',
                                        selected
                                            ? 'bg-darkblue-500 text-white'
                                            : 'hover:bg-white/[0.12]'
                                        )
                                    }
                                    onClick={(e) => window.scrollTo(0, 1082)}
                                >
                                <h1 className="sm:hidden text-md font-medium tracking-loose">
                                    {e.shortPlanName}
                                </h1>
                                <h1 className="hidden sm:block text-xl font-medium tracking-loose">
                                    {e.planName}
                                </h1>
                                </Tab>
                            ))}
                        </Tab.List>
                    </div>}
                    <Tab.Panels>
                        {pricingPlans.map((e, idx) => (
                            <Tab.Panel
                                key={idx}
                                className={classNames(
                                'rounded-xl bg-white md:p-3',
                                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                            >
                                {getAdditionalInfo(idx)}
                                <div className="relative">
                                    <div className="grid lg:grid-cols-3 gap-12 lg:gap-8 max-w-6xl mx-auto py-6 sm:px-6 lg:px-8">
                                        {e.plans.map((plan) => <div key={plan.name} className="group flex flex-col card-shadow">
                                            <div className="transition duration-500 group-hover:scale-105 lg:group-hover:scale-110 relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg p-8 bg-white rounded-lg group-hover:bg-darkblue-500 group-hover:text-white">
                                                <div>
                                                    <h3 className="text-xl font-semibold">{plan.name}</h3>
                                                    <p className="mt-4 text-slate-700 text-md">Starts from</p>
                                                    {/* <div className="mt-4 p-6 rounded-lg -mx-6 mx-auto"> */}
                                                    <p className="mt-1 text-center mx-auto">
                                                        <span className="ml-2 text-4xl font-bold">₹ {plan.price}</span>
                                                        <span className="ml-2 text-slate-700 text-md">/per licence*</span>
                                                    </p>
                                                    <p className='mt-2 text-center mx-auto text-sm'>* Govt fees - not included</p>
                                                    <p className='text-center mx-auto text-sm'>* conditions apply</p>
                                                    <hr className="mt-3 -mx-8" />
                                                    {e.shortPlanName.includes('Modification') &&
                                                        <>
                                                            <p className='mt-3 text-center mx-auto text-sm'>Address Modification, Name Modification, KOB Modification, Addition of New Product, Business</p>
                                                            <hr className="mt-3 -mx-8" />
                                                        </>
                                                    }
                                                    {/* </div>       */}
                                                    <ul className="text-left flex-1 mt-6 space-y-3">
                                                        {plan.features.map((feature) =>
                                                        <li key={feature} className="flex text-sm text-slate-700 leading-6">
                                                                <CheckIcon className='h-5 w-5 text-cyan-500' style={{ flexShrink: 0 }} />
                                                            <span className="ml-3 tracking-wide">{feature}</span>
                                                        </li>)}
                                                    </ul>
                                                </div>
                                                <div className='flex flex-col justify-around'>
                                                    <Link to={plan.navigation} state={'hello'} className="transition duration-500 group-hover:scale-105 lg:group-hover:scale-110 block text-white bg-darkblue-500 hover:text-white hover:bg-darkblue-600 group-hover:text-darkblue-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Get Started</Link>
                                                    {/* <Link to="/service-info" className="mt-2 py-3 font-semibold text-darkblue-500 text-md hover:text-darkblue-700 group-hover:text-white">More Details &nbsp;&nbsp; →</Link> */}
                                                </div>
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
            
        </div>
    )
}

export default PricingPlans