import React, { useState } from 'react';
import { loadScript } from '../utils/Utils';

const Checkout = () => {

    const [totalAmount, ] = useState(1000);

    const loadRazorPay = async () => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // creating a new order
        // const result = await axios.post("http://localhost:5000/payment/orders");

        // if (!result) {
        //     alert("Server error. Are you online?");
        //     return;
        // }

        // Getting the order details back
        // const { amount, id: order_id, currency } = result.data;

        const options = {
            key: "rzp_test_b0hu4yQZcWS9zJ", // Enter the Key ID generated from the Dashboard
            amount: totalAmount * 100, // to convert to rupees
            currency: 'INR',
            name: "iot Corp.",
            description: "Test Transaction",
            // image: { logo },
            // order_id: '12345',
            handler: async function (response) {
                // const data = {
                //     orderCreationId: 'order_id',
                //     razorpayPaymentId: response.razorpay_payment_id,
                //     razorpayOrderId: response.razorpay_order_id,
                //     razorpaySignature: response.razorpay_signature,
                // };

                // const result = await axios.post("http://localhost:5000/payment/success", data);

                // alert(result.data.msg);

                console.log('Payment Success : ', response);
            },
            prefill: {
                name: "John Doe",
                email: "JohnDoe@gmail.com",
                contact: "9009990099",
            },
            notes: {
                address: "KR Puram, Bangalore",
            },
            theme: {
                color: "#4c51bf",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <div className="max-w-6xl mx-auto">
            <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                <div className="mt-10 mx-auto max-w-6xl px-4 mt-20 sm:mt-12 sm:px-6 md:mt-20 lg:mt-24 xl:mt-24">
                    <div className="text-center">
                        <div className="">
                            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl md:text-5xl lg:text-5xl">
                                Your order summary
                            </h1>
                        </div>
                        <h2 className="mt-3 text-bold text-xl text-center tracking-wide text-gray-700">
                            Salaried Individual Tax Filing (Plan ID:ITR-SAL-011)
                        </h2>
                    </div>
                </div>
                <div className="mt-10 grid gap-10 md:grid-cols-2 lg:grid-cols-2"> 
                    <div className="p-8 bg-gray-100 text-gray-900 rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold tracking-tight text-gray-700">Contact Information</h2>
                        <div className="mt-3">
                            <span className="text-sm text-gray-600 font-bold">Full Name</span>
                            <input
                                type="text"
                                name="full-name"
                                id="full-name"
                                autoComplete="name"
                                placeholder="Enter your name"
                                className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                        </div>
                        <div className="mt-8">
                            <span className="text-sm text-gray-600 font-bold">Email</span>
                            <input
                                type="email"
                                name="email-address"
                                id="email-address"
                                autoComplete="email"
                                placeholder="Enter your email"
                                className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                        </div>
                        <div className="mt-8">
                            <span className="text-sm text-gray-600 font-bold">Phone</span>
                            <input
                                type="tel"
                                name="tel"
                                id="tel"
                                autoComplete="+91 ##### #####"
                                maxLength='10'
                                placeholder="Enter your phone"
                                className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                        </div>
                        {/* <div className="mt-8">
                            <span className="text-sm text-gray-600 font-bold">Message</span>
                            <textarea
                                style={{height: '7rem'}}
                                placeholder='Enter Message'
                                className="form-input-lg w-full h-40 bg-gray-300 text-gray-900 mt-3 p-3 border border-gray-300 hover:border-gray-400 rounded-md sm:text-sm md:text-md lg:text-md focus:ring-purple-500 focus:border-purple-500"></textarea>
                        </div> */}
                        <div className="mt-8">
                            <button className="text-sm font-bold tracking-wide bg-purple-500 text-gray-100 p-3 rounded-lg w-full hover:bg-purple-700 focus:ring-purple-500 focus:border-purple-500">
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className="p-8 flex flex-col justify-between text-gray-900 rounded-lg shadow-lg">
                        <div>
                            <h2 className="text-xl font-bold tracking-tight text-gray-700">Payment Information</h2>
                            <div className="mt-5 flex justify-between">
                                <h2 className="text-xl font-bold tracking-tight text-gray-900">Selected Plan Price</h2>
                                <h2 className="text-xl font-bold tracking-tight text-gray-900">₹ 999/-</h2>
                                {/* <p className="mt-3 text-base text-lg text-center tracking-wide text-gray-700">
                                    Get in touch with us and let us know how we can help!
                                </p> */}
                            </div>
                            <div className="mt-3 flex justify-between">
                                <h2 className="text-xl font-bold tracking-tight text-gray-900">GST @ 18%</h2>
                                <h2 className="text-xl font-bold tracking-tight text-gray-900">+ ₹ 179.82/-</h2>
                                {/* <p className="mt-3 text-base text-lg text-center tracking-wide text-gray-700">
                                    Get in touch with us and let us know how we can help!
                                </p> */}
                            </div>
                            <hr className='my-5 px-3' />
                            <h2 className="text-xl font-bold tracking-tight text-purple-500">Have a coupon code?</h2>
                            <div className="mt-3">
                                <input
                                    type="text"
                                    name="full-name"
                                    id="full-name"
                                    autoComplete="name"
                                    placeholder="Enter Coupon COde"
                                    className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                            </div>
                            <hr className='my-5 px-3' />
                            <div className="mt-5 flex justify-between">
                                <h2 className="text-xl font-bold tracking-tight text-gray-900">Total Price</h2>
                                <h2 className="text-xl font-bold tracking-tight text-gray-900">₹ 1099/-</h2>
                                {/* <p className="mt-3 text-base text-lg text-center tracking-wide text-gray-700">
                                    Get in touch with us and let us know how we can help!
                                </p> */}
                            </div>
                        </div>
                        {/* <div className="mt-8">
                            <span className="text-sm text-gray-600 font-bold">Email</span>
                            <input
                                type="email"
                                name="email-address"
                                id="email-address"
                                autoComplete="email"
                                placeholder="Enter your email"
                                className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                        </div>
                        <div className="mt-8">
                            <span className="text-sm text-gray-600 font-bold">Message</span>
                            <textarea
                                style={{height: '7rem'}}
                                placeholder='Enter Message'
                                className="form-input-lg w-full h-40 bg-gray-300 text-gray-900 mt-3 p-3 border border-gray-300 hover:border-gray-400 rounded-md sm:text-sm md:text-md lg:text-md focus:ring-purple-500 focus:border-purple-500"></textarea>
                        </div> */}
                        <div className="mt-8">
                            <button
                                onClick={loadRazorPay}
                                className="text-sm font-bold tracking-wide bg-purple-500 text-gray-100 p-3 rounded-lg w-full hover:bg-purple-700 focus:ring-purple-500 focus:border-purple-500">
                                Proceed to Pay
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout;