import { configureStore } from '@reduxjs/toolkit';
import AppReducer from './reducer/appReducer';
import MarketingReducer from './reducer/marketingReducer';
import UtilsReducer from './reducer/utilsReducer';

export default configureStore({
  reducer: {
    app: AppReducer,
    utils: UtilsReducer,
    marketing: MarketingReducer,
  },
})