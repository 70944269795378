import React from 'react'
import ImageGrid from '../components/ImageGrid';
import { images } from '../constants';

const Careers = () => {
    
    const values = [
        {
            img: images.value_1,
            value: 'Customer satisfaction is our obsession.'
        },
        {
            img: images.value_2,
            value: 'We value ideas over hierarchies.'
        },
        {
            img: images.value_3,
            value: 'We take big and bold bets.'
        },
        // {
        //     img: images.value_4,
        //     value: 'We respect each other at work.'
        // },
        {
            img: images.value_4,
            value: 'By fostering trust, we operate transparently.'
        },
    ]
    return (
        <>
            <div className="">
                <div className="min-h-screen max-w-6xl mx-auto mt-20 flex flex-col-reverse lg:flex-row justify-center align-center">
                    <div className="mt-5 md:mt-24 px-4">
                        <div className="relative pb-8 bg-transparent sm:pb-16 md:pb-18 lg:pb-20 xl:pb-20">
                            <div className="mx-auto max-w-6xl px-4 sm:px-6 md:mt-16 lg:mt-28 lg:px-4">
                                <div className="sm:text-center lg:text-left">
                                    <div className="text-4xl tracking-tight font-bold text-gray-900 sm:text-4xl md:text-5xl">
                                        <h1 className="block text-darkblue-500">Careers @ Aahar</h1>
                                    </div>
                                    <h2 className="mt-3 tracking-wide text-gray-700 sm:mt-5 sm:text-md sm:mx-auto md:mt-8 md:text-xl lg:mx-0">
                                        Join us in building an ecosystem which is empowering Indian food businesses to provide safe, hygenic and healthy food.
                                    </h2>
                                    <div className="mt-6 hidden md:flex md:justify-center lg:justify-start lg:mt-24">
                                        <div className="rounded-md shadow">
                                            <button
                                                className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-darkblue-500 hover:bg-darkblue-700 md:py-4 md:text-lg md:px-10 focus:outline-none"
                                            >
                                                Open Positions
                                            </button>
                                        </div>
                                        {/* <div className="mt-3 sm:mt-0 sm:ml-3">
                                            <button
                                                className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-darkblue-500 text-base font-medium rounded-md text-darkblue-700 bg-darkblue-100 hover:bg-darkblue-200 md:py-4 md:text-lg md:px-10 focus:outline-none"
                                            >
                                                Request Callback
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sm:m-0 mb-10 lg:-mt-20 px-8 w-35 h-50 md:w-4/6 lg:-mr-44" style={{alignSelf: 'center'}}>
                        <img
                            className="h-52 object-contain sm:h-60 md:h-90 lg:w-full lg:h-full"
                            src={images.interview}
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <hr />

            {/* <div className="py-16 bg-grey-200">  
                <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
                    <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                        <div className="md:5/12 lg:w-5/12">
                            <img className="h-48 object-contain" src={images.career} alt="" />
                        </div>
                        <div className="md:7/12 lg:w-6/12">
                        <h2 className="text-2xl text-gray-900 font-bold md:text-4xl">Customer satisfaction is our obsession.</h2>
                        </div>
                    </div>
                </div>
            </div> */}

            
            {/* <div class="py-16 bg-darkblue-200">  
                <div class="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
                    <div class="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
                        <div class="bg-white rounded-lg shadow-xl px-8 py-12 sm:px-12 lg:px-8">
                            <div class="mb-12 space-y-4">
                                <h3 class="text-2xl font-semibold text-darkblue-900">Graphic Design</h3>
                                <p class="mb-6">Obcaecati, quam? Eligendi, nulla numquam natus laborum porro at cum, consectetur ullam tempora ipsa iste officia sed officiis! Incidunt ea animi officiis.</p>
                                <a href="#" class="block font-medium text-darkblue-600">Know more</a>
                            </div>
                            <img src={images.value_1} class="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600" />
                        </div>
                        <div class="bg-white rounded-lg shadow-xl px-8 py-12 sm:px-12 lg:px-8">
                            <div class="mb-12 space-y-4">
                                <h3 class="text-2xl font-semibold text-darkblue-900">UI Design</h3>
                                <p class="mb-6">Obcaecati, quam? Eligendi, nulla numquam natus laborum porro at cum, consectetur ullam tempora ipsa iste officia sed officiis! Incidunt ea animi officiis.</p>
                                <a href="#" class="block font-medium text-darkblue-600">Know more</a>
                            </div>
                            <img src={images.value_1} class="w-2/3 ml-auto" alt="illustration" loading="lazy" width="900" height="600" />
                        </div>
                        <div class="bg-white rounded-lg shadow-xl px-8 py-12 sm:px-12 lg:px-8">
                            <div class="mb-12 space-y-4">
                                <h3 class="text-2xl font-semibold text-darkblue-900">UX Design</h3>
                                <p class="mb-6">Obcaecati, quam? Eligendi, nulla numquam natus laborum porro at cum, consectetur ullam tempora ipsa iste officia sed officiis! Incidunt ea animi officiis.</p>
                                <a href="#" class="block font-medium text-darkblue-600">Know more</a>
                            </div>
                            <img src={images.value_1} class="w-2/3 ml-auto " alt="illustration" loading="lazy" width="900" height="600" />
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="py-16 bg-gray-50 overflow-hidden">
                <div className="container m-auto px-6 space-y-8 text-gray-500 md:px-12">
                    <h1 className="text-center text-3xl tracking-tight font-extrabold text-darkblue-500 sm:text-5xl md:text-5xl md:pb-8">
                        Our Values
                    </h1>
                    <div className="mt-16 grid border divide-x divide-y rounded-lg overflow-hidden sm:grid-cols-2 lg:divide-y-0 lg:grid-cols-3 xl:grid-cols-4">
                        {values.map((e, i) => 
                            <div className="relative bg-white transition hover:z-[1] hover:shadow-2xl flex">
                                <div className="relative p-8 space-y-8 text-center flex flex-col justify-between h-60">
                                    {i === 0 && 
                                        <img src={e.img} className="mx-auto mr-5 mt-10 h-40 object-contain" alt={e.img} />
                                    }
                                    {i === 1 && 
                                        <img src={e.img} className="mx-auto h-60 mt-3 object-contain" alt={e.img} style={{marginTop: '5rem'}} />
                                    }
                                    {i === 2 && 
                                        <img src={e.img} className="mx-auto h-40 mt-12 object-contain" alt={e.img} />
                                    }
                                    {i === 3 && 
                                        <img src={e.img} className="mx-auto object-contain" style={{height: '10rem', marginTop: '2.5rem'}} alt={e.img} />
                                    }
                                    <div className="">
                                        <h3 className="text-xl md:text-2xl text-gray-800 font-medium transition">{e.value}</h3>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
            {/* <div className="md:pb-10 bg-transparent">
                <img className="mx-auto object-cover" src={images.life_at_aahar} alt="" />
            </div> */}
                                
            
            <div class="py-20 bg-white">
                <div class="container m-auto px-6 space-y-8 md:px-12 lg:px-20">
                    <p className="text-center text-3xl tracking-tight font-extrabold text-darkblue-500 sm:text-5xl md:text-5xl">
                        Come Join Us...
                    </p>
                    <div class="mt-16">
                        <img className="mx-auto object-cover" src={images.life_at_aahar} alt="" />
                    </div>
                </div>
            </div>   
                                

            {/* Why are we better */}
            {/* <div className="bg-grey-100">
                <div className="min-h-full max-w-3xl mx-auto py-6 bg-darkblue-100 rounded-lg shadow-lg mx-10">
                    <div className="px-4 mt-5 bg-transparent sm:px-6 lg:px-8">
                        <div className="text-center">
                            <p className="text-4xl tracking-tight font-extrabold text-darkblue-500 sm:text-5xl md:text-5xl">
                                Our Values
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col items-center">
                        {values.map((e) => 
                            <div className="mx-4 bg-transparent py-10">
                                <div className="mt-3 flex flex-row justify-start">
                                    <img
                                        className="h-48 lg:h-20 object-contain"
                                        src={e.img} alt="" />
                                    <div className="mt-10 md:ml-5 lg:text-left flex flex-col justify-start">
                                        <h3 className="text-xl text-gray-900">
                                            {e.value}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}
            {/* <ImageGrid /> */}
            <hr />

            <div className="mx-auto flex flex-col-reverse mt-5 py-12 lg:flex-row justify-center align-center">
                <div className="px-4 py-10">
                    <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                        <div className="mx-auto max-w-5xl sm:mt-12 sm:px-6 md:mt-8 lg:mt-10 lg:px-8 xl:mt-28">
                            <div className="text-center lg:text-left">
                                <div className="">
                                    <h1 className="block text-3xl tracking-tight font-extrabold text-gray-900 md:text-3xl lg:text-4xl">
                                        Unable to find your desired role?</h1>{' '}
                                    {/* <p className="mt-3 block text-xl tracking-tight font-extrabold md:text-3xl lg:text-4xl text-darkblue-500">Subscribe to our newsletter now!</p> */}
                                </div>
                                <h2 className="mt-3 text-base tracking-wide text-gray-700 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                        Let us find one for you. Drop in your resumes at
                                        <br /><a href='mailto:team@aahar.in' className="text-darkblue-500 hover-link">
                                            team@aahar.in    
                                        </a>
                                </h2>
                                {/* <div className="mt-5 grid grid-cols-12 md:px-40 lg:px-0 md:grid-cols-12 lg:grid-cols-12">
                                    <div className="col-span-12 md:col-span-12 lg:col-span-10">
                                        <button
                                            className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-darkblue-500 hover:bg-darkblue-700 md:py-4 md:text-lg md:px-10 hover:bg-darkblue-700 focus:outline-none"
                                        >
                                            See Open Positions
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:m-0 mb-10 lg:mt-5 px-4 w-55 h-55 md:w-1/3 lg:-mt-12" style={{alignSelf: 'center'}}>
                    <img
                        className="h-40 object-contain md:h-96 lg:w-full lg:h-full"
                        src={images.updated_resume}
                        alt=""
                    />
                </div>
            </div>
        </>
    )
}

export default Careers;