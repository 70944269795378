import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AnnotationIcon, GlobeAltIcon, InformationCircleIcon, LightningBoltIcon, ScaleIcon, StarIcon, UserCircleIcon } from '@heroicons/react/outline';
import StarIconFilled from '@heroicons/react/solid/StarIcon';
import { ArrowCircleRightIcon, CheckCircleIcon } from '@heroicons/react/solid';
import Timeline from '../components/Timeline';
import { images } from '../constants';
import Newsletter from '../components/Newsletter';
import GridCarousel from '../components/GridCarousel';
import { courses } from './CourseCatalog';
import { classNames, seo } from '../utils/Utils';
import { instrumentCategory, waterTestCategory } from '../data/TestingData';
import Faq from '../components/Faq';
import E2EComplaince from '../components/E2EComplaince';
import WaterTestingParamsDialog from '../components/WaterTestingParamsDialog';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { requestCallback } from '../actions/marketing';


const WaterTesting = () => {

    seo({
        title: 'Aahar Testing | Water Testing Lab - FSSAI Compliance',
        metaDescription: "Aahar offers wide range of water testing services like potable & packaged drinking water testing, mineral water testing etc as per FSSAI guidelines.",
        keywords: "aahar testing, aahar consulting, aahar compliance, aahar fssai, aahar bangalore, water testing lab, aahar water testing, potable drinking water testing, packaged drinking water testing, mineral water testing"
    });

    const { pathname, search, state } = useLocation();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [foodProductIdx, setFoodProductIdx] = useState();
    const [testCategoryIdx, setTestCategoryIdx] = useState();
    const [show, setShowDialog] = useState(false);

    const trainingDetails = courses[0];

    console.log({ pathname, search, state });

    const features = [
        {
            name: 'Form 16 from your company',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: GlobeAltIcon,
        },
        {
            name: 'Additional Form 16',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: ScaleIcon,
        },
        {
            name: 'Form 26AS Tax Credit Statement',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: LightningBoltIcon,
        },
        {
            name: 'Aadhaar card',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: AnnotationIcon,
        },
        {
            name: 'Bank statement if interest received is above Rs. 10,000/-',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: GlobeAltIcon,
        },
        {
            name: 'Salary Slip of any month during the Financial Year',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: ScaleIcon,
        },
        {
            name: 'Annual Information Statement',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: LightningBoltIcon,
        },
        {
            name: 'Aadhaar card 1',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: AnnotationIcon,
        },
    ];

    const eligibilityCriteria = [
        {
            eligibeFor: 'For foood handler/ supervisor/ other individuals employed in petty food business',
            criteria: [
                'Minimum class 5th std',
                'No experience required'
            ]
        },
        {
            eligibeFor: 'For students',
            criteria: [
                'Education in relevant field with 10+2 passed with science'
            ]
        }
    ];

    const benefits = [
        "Every participant will get a Food Safety Supervisor certificate from FSSAI after completing the course successfully.",
        "The certificate is valid for lifetime, for the specific food category.",
        "Knowledge about the basic concepts of food safety, standards & hygiene."
    ];

    const process = {
        title: 'The entire procedure will be done online and updates will be shared at every phase.',
        values: [
            'Request for callback or call us directly on our toll free number',
            'Within 24 hours of receiving your inquiry, our team will call you to collect detailed requirements and share the quotation',
            'Make your payment via online',
            'After making the payment our team will share a list of applicable documents within 3 hours',
            'Once we receive all the documents in the specified format, our team will do document verification and file the application within 2 working days.'
        ],
        estimate: '3 days'
    };

    const whyChooseUs = [
        {
            name: 'Analyse 100+ parameters',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.water_test_why_1,
        },
        {
            name: 'State-of-the-art testing facility',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.water_test_why_2,
        },
        {
            name: 'Quick turn-around time',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.water_test_why_3,
        },
        {
            name: 'Reasonable Price',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.water_test_why_6,
        },
        {
            name: 'All kind of water sample',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.water_test_why_5,
        },
        {
            name: 'Sample collection network in 75 cities across India',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: images.water_test_why_4,
        },
        // {
        //     name: 'Annual Information Statement',
        //     description:
        //         'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
        //     icon: LightningBoltIcon,
        // },
        // {
        //     name: 'Aadhaar card 1',
        //     description:
        //         'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
        //     icon: AnnotationIcon,
        // },
    ];

    const testStandards = [
        'FSSC 22000',
        'BRC',
        'ISO 22000',
        'ISO 9001',
        'GMP+',
        'GMHP Audit',
        'GCP Audit',
        'ISO 22716'
    ];

    const ara = [
        'USFDA',
        'CODEX',
        'EU',
        'APEDA',
        'USP 561',
        'FSSAI',
        'SFDA',
        'EP',
        'USDA NOP for organic',
        'ISO 17025',
        'EIC / EIA',
        'FSVPS',
        'BIS',
        'NABL',
        'CDSCO',
        'AGMARK',
        'ISM & H'
    ];

    const dispatch = useDispatch();
    const state1 = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  
        name: '',
        email: '',
        phoneNo: '',
        topic: 'Water Testing'
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
                setFormData({name: '', email: '', phoneNo: ''})
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Water testing!');
        console.log('Form Data : ', formData);
        dispatch(requestCallback(formData));
        setShowMessage(true);
    };

    return (
        <>
            <Helmet>
                <meta name="title" content="Aahar Testing | Water Testing Lab - FSSAI Compliance" />
                <meta name="description" content="Aahar offers wide range of water testing services like potable & packaged drinking water testing, mineral water testing etc as per FSSAI guidelines."  />
                <meta name="keywords" content="aahar testing, aahar consulting, aahar compliance, aahar fssai, aahar bangalore, water testing lab, aahar water testing, potable drinking water testing, packaged drinking water testing, mineral water testing" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <meta name="author" content="Aahar Consulting Pvt. Ltd." />
            </Helmet>
            <WaterTestingParamsDialog show={show} testCategoryIdx={testCategoryIdx} onDialogClosed={() => setShowDialog(false)} />
            <div className="max-w-6xl mx-auto">
                <div className="relative pb-8 bg-white">
                    <div className="mt-10 mx-auto max-w-6xl px-6">
                        <div className="flex flex-col justify-between items-start md:flex-row lg:flex-row">
                            <div className='w-full mt-10 mb-5 pt-10 md:hidden'>
                                <img
                                    className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                    src={images.water_testing_mob}
                                    alt=""
                                />
                            </div>
                            <div className='w-full md:w-2/3 lg:w-2/3 lg:pr-10'>
                                <div className="mt-10 md:mt-0 md:h-screen flex flex-col justify-center text-left w-full">
                                    <div className=''>
                                        <span class="mb-2 inline-block py-1 px-2 bg-teal-100 text-teal-500 text-xs font-medium tracking-widest">FSSAI WATER TESTING</span>
                                        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Water Testing
                                        </h1>
                                        <h2 className="mt-5 text-left text-lg md:text-xl tracking-wide text-gray-900">
                                            Water is the primary source of contamination during manufacturing process. Water can be directly used as an ingredient or can be used during other stages of food processing.
                                            Getting water testing done at regular intervals is a very important step to ensure food safety and to comply with FSSAI regulations.
                                        </h2>
                                    </div>

                                    {/* Ratings */}
                                    {/* <div className='mt-3 flex flex-row items-center'>
                                        <p className="text-base text-left text-md lg:text-2xl text-gray-700">
                                            4.2
                                        </p>
                                        <div className='ml-2 flex flex-row'>
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIcon
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <Link to="" className='ml-2 text-sm lg:text-md text-teal-500 hover:text-teal-700'>(All Reviews)</Link>
                                    </div> */}
                                </div>
                                
                                {/* Outline */}
                                <div className="mt-10 md:mt-20 py-10 text-left">
                                    <div className="mt-3 text-3xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Outline
                                        </h1>
                                    </div>
                                    <h2 className="mt-5 text-lg md:text-xl tracking-wide text-gray-900">
                                        We offer wide range of water testing services for all kinds of water used in the process of food manufacturing and preparation.
                                        Our water testing laboratories are equipped with state-of-the-art instruments and are highly sophisticated to perform bacteriological and chemical analysis of water at every stage of the food supply chain.
                                    </h2>
                                </div>
                                
                                {/* Test Category */}
                                <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Test Category
                                        </h1>
                                    </div>
                                    <div className='mt-5 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch">
                                            <div className='flex flex-col justify-center md:flex-row lg:flex-row'>
                                                <div className="w-full grid grid-cols-1 gap-4 items-stretch">
                                                    {waterTestCategory.map((e, idx) =>
                                                        <div key={e.name}
                                                            onClick={() => { setShowDialog(!show); setTestCategoryIdx(idx) }}
                                                            className="group cursor-pointer transition duration-500 hover:scale-105 rounded-lg border-teal-300 hover:border-teal-400 bg-teal-200 hover:bg-teal-500">
                                                            <div className='p-3 md:p-2 lg:p-5 flex flex-row justify-between items-center'>
                                                                <p className="ml-2 text-lg font-semibold text-gray-900 group-hover:text-white">{e.name}</p>
                                                                {/* <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/with_steps_alternate-svg1.svg" alt="icon"/> */}
                                                                <InformationCircleIcon className='h-6 w-6 mr-3 text-gray-900 group-hover:text-white' aria-hidden="true"></InformationCircleIcon>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Food Products */}
                                {/* <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Food Products :
                                        </h1>
                                    </div>
                                    <div className='mt-5 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full grid gap-2 md:grid-cols-1 lg:grid-cols-1">
                                            {foodProducts.map((e, idx) =>
                                                <div key={idx} className="group cursor-pointer card-w-border transition duration-500 hover:scale-105 md:hover:scale-110 hover:border-gray-400" onClick={() => setFoodProductIdx(foodProductIdx === idx ? null : idx)}>
                                                    <div className='px-3 flex justify-between items-start'>
                                                        <h3 className={classNames(e.isExpanded ? 'text-gray-900 font-bold' : 'text-gray-900', 'text-left tracking-wide text-lg font-bold group-hover:text-gray-900')}>
                                                            {e.name}
                                                        </h3>
                                                        {(idx === foodProductIdx)
                                                            ? <ChevronUpIcon style={{ width: '2rem' }} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />
                                                            : <ChevronDownIcon style={{ width: '2rem' }} className='ml-2 h-5 w-5 text-gray-500 group-hover:text-gray-700' aria-hidden="true" />}
                                                    </div>
                                                    {idx === foodProductIdx &&
                                                        <div className='transition duration-500 ml-3 pt-3 px-3 grid grid-cols-1 md:grid-cols-2 justify-between'>
                                                            {e.products.map((product) =>
                                                                <div className='flex items-center'>
                                                                    <CheckCircleIcon className='h-4 w-4 mr-3' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                                                                    <p className='text-left tracking-wide text-sm text-gray-700'>{product}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div> */}

                                {/* Benefits */}
                                <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Equipments
                                        </h1>
                                    </div>
                                    <div className='mt-3 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch">
                                            <div className='flex flex-col justify-center md:flex-row lg:flex-row'>
                                                <div className="w-full flex flex-col items-stretch">
                                                    <div className="card-w-border-wo-shadow mb-5">
                                                        <div className='p-2 lg:p-5 flex flex-col lg:flex-row justify-start items-center'>
                                                            <div className='flex flex-col items-stretch points'>
                                                                {instrumentCategory.map((e) =>
                                                                    <div className='mb-3'>
                                                                        <p className="ml-2 text-lg font-semibold">{e.name}</p>
                                                                        {e.instruments.map((item) => 
                                                                            <div key={item} className="ml-5 my-3 flex flex-row justify-start items-center">
                                                                                <div className=''>
                                                                                    <ArrowCircleRightIcon className='h-5 w-5 text-teal-500' aria-hidden="true"></ArrowCircleRightIcon>
                                                                                </div>
                                                                                <p className="ml-2 text-md text-gray-900">{item}</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="py-5 text-left">
                                    <div className="max-w-3xl">
                                        <div className="">
                                            {/* <h2 className="text-base text-darkblue-500 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                            <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                                Why Choose Us
                                            </h1>
                                            {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                                accusamus quisquam.
                                            </p> */}
                                        </div>

                                        <div className='mt-3 lg:mt-5 flex flex-col justify-center md:flex-row lg:flex-row'>
                                            <div className="w-full flex flex-col items-stretch">
                                                <div className='flex flex-col justify-center md:flex-row lg:flex-row'>
                                                    <div className="w-full flex flex-col items-stretch">
                                                        <div className="card-w-border-wo-shadow mb-5">
                                                            <div className="p-5">
                                                                <dl className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2">
                                                                    {whyChooseUs.map((feature, idx) => (
                                                                        <div key={feature.name} className="relative pr-5 md:pr-1 py-5">
                                                                            <div className='flex items-center'>
                                                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-darkblue-500 text-white">
                                                                                    {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                                                                                    <img src={feature.icon} className="h-10 w-10" aria-hidden="true" alt={feature.name} />
                                                                                </div>
                                                                                <p className="ml-16 text-left text-md text-gray-900" style={idx === 4 ? { whiteSpace: 'pre' } : {whiteSpace: 'normal'}}>{feature.name}</p>
                                                                            </div>
                                                                            {/* <dd className="mt-2 text-left ml-16 text-base tracking-wide text-gray-900">{feature.description}</dd> */}
                                                                        </div>
                                                                    ))}
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                {/* Our Process */}
                                {process && <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Our Process
                                        </h1>
                                    </div>
                                    <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        {process.title}
                                    </h2>
                                    <h2 className="mt-16 text-md font-bold text-gray-900 md:text-2xl">
                                        Timeline - {process.estimate} estimate
                                    </h2>
                                    <Timeline type='our' data={process.values} color='teal' />
                                </div>}

                                <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Standards
                                        </h1>
                                    </div>
                                    <div className='mt-3 lg:mt-5 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch">
                                            <div className='flex flex-col justify-center md:flex-row lg:flex-row'>
                                                <div className="w-full flex flex-col items-stretch">
                                                    <div className="card-w-border-wo-shadow mb-5">
                                                        <div className='p-2 lg:p-5 flex flex-col lg:flex-row justify-start items-center'>
                                                            <div className='flex flex-col items-stretch points'>
                                                                <div className='mb-3'>
                                                                    <p className="ml-2 text-lg font-semibold">Standards we test against</p>
                                                                    {testStandards.map((e) => 
                                                                        <div key={e} className="ml-5 my-3 flex flex-row justify-start items-center">
                                                                            <div className=''>
                                                                                <ArrowCircleRightIcon className='h-5 w-5 text-teal-500' aria-hidden="true"></ArrowCircleRightIcon>
                                                                            </div>
                                                                            <p className="ml-2 text-md text-gray-900">{e}</p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='mb-3'>
                                                                    <p className="ml-2 text-lg font-semibold">Approval, recognition & accreditiation</p>
                                                                    {ara.map((e) => 
                                                                        <div key={e} className="ml-5 my-3 flex flex-row justify-start items-center">
                                                                            <div className=''>
                                                                                <ArrowCircleRightIcon className='h-5 w-5 text-teal-500' aria-hidden="true"></ArrowCircleRightIcon>
                                                                            </div>
                                                                            <p className="ml-2 text-md text-gray-900">{e}</p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Trainer Details */}
                                <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Lab Partner
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2> */}
                                    <div class="mt-5">
                                        <div class="flex w-full card-w-border-wo-shadow">
                                            <div class="w-full relative p-4">
                                                <div class="flex justify-between">
                                                    <div class="flex">
                                                        <div>
                                                            <div class="h-20 w-20 rounded-full bg-cover" style={{ backgroundImage: `url(https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=256&amp;q=80&quot)` }}></div>
                                                        </div>
                                                        <div class="ml-4">
                                                            <div class="font-bold text-xl">Tricia Matthew</div>
                                                            <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 25+ courses</div>
                                                            <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 4.7 rating</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="my-6 border-b"></div>
                                                <div>
                                                    <p className='tracking-wide'>A Review card written completely with utlities from Tailwind CSS. This is just some filler text to make the card look populated. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Certificate */}
                                <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Sample Report
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2> */}
                                    <div class="mt-5">
                                        <div class="flex w-full card-w-border-wo-shadow">
                                            <div class="w-full relative p-4">
                                                <div class="flex justify-between">
                                                    <div class="flex flex-col">
                                                        <div style={{width: '30%'}}>
                                                            {/* <div class="h-20 w-20 rounded-full bg-cover" style={{ backgroundImage: images.fostac_certificate }}></div> */}
                                                            <img src={images.fostac_certificate} alt="fostac certificate" className="bg-cover" />
                                                        </div>
                                                        <div class="mt-5">
                                                            <div class="font-bold text-xl">Sample Certificate</div>
                                                            {/* <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 25+ courses</div>
                                                            <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 4.7 rating</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="my-6 border-b"></div>
                                                <div>
                                                    <p className='tracking-wide'>A Review card written completely with utlities from Tailwind CSS. This is just some filler text to make the card look populated. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Documents */}
                                {/* <div className="py-5 text-left">
                                    <div className="py-4">
                                        <div className="max-w-3xl">
                                            <div className="">
                                                <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                                    Documents Required
                                                </h1>
                                                <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                                    Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                                    accusamus quisquam.
                                                </p>
                                            </div>

                                            <div className="mt-10">
                                                <dl className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2">
                                                    {features.map((feature) => (
                                                        <div key={feature.name} className="relative pr-5 py-5">
                                                            <div className='flex items-center'>
                                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-teal-500 text-white">
                                                                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                                                                </div>
                                                                <p className="ml-16 text-left text-lg text-gray-900">{feature.name}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 font-medium">
                                        <div className="text-left text-teal-900 bg-teal-200 p-5 shadow rounded-lg">
                                            <p> After receiving the detailed requirement, a complete list of applicable documents will be shared.</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Ratings & Reviews */}
                                {/* <div className="py-5 text-left">
                                    <div className="py-4">
                                        <div className="max-w-3xl">
                                            <div className="">
                                                <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                                    Ratings & Reviews :
                                                </h1>
                                                <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                                    Ratings and Reviews for the course
                                                </p>
                                            </div>

                                            <div className="mt-10 space-y-5">
                                                <Review />
                                                <Review />
                                                <Review />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 font-medium">
                                        <div className="text-left text-teal-900 bg-teal-200 p-5 shadow rounded-lg">
                                            <p> After receiving the detailed requirement, a complete list of applicable documents will be shared.</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <Faq cols={'1'} titleAlignment={'left'} /> */}
                            </div>
                            {/* <div className='w-full md:w-1/3 lg:w-1/3 sticky top-0 pt-0 lg:pt-20'>
                                <div className="w-max mt-0 lg:mt-20 flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-teal-500 group-hover:text-white">
                                        <div className='flex flex-col items-center'>
                                            <p className="text-base text-left text-2xl font-semibold text-gray-700">
                                                Service Title
                                            </p>
                                            <p className="mt-2 text-center mx-auto text-lg">
                                                <span>₹</span>
                                                <span className="ml-2 text-5xl font-bold">25</span>
                                                <span className="ml-2">/one time</span>
                                            </p>
                                            <Link to="/checkout" className="block text-white lg:w-64 bg-teal-500 hover:text-white hover:bg-teal-600 group-hover:text-teal-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                            <Link to="" className="mt-2 py-3 font-semibold text-teal-500 text-md hover:text-teal-700">Request Callback &nbsp;&nbsp; →</Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='w-full md:w-1/3 lg:w-1/3 sticky top-0 pt-0 lg:pt-20'>
                                <div className="w-max mt-0 pt-2 lg:mt-1 flex flex-col">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-teal-500 group-hover:text-white">
                                        <div>
                                            <p className="text-center font-bold">Starts from</p>
                                            <p className="mt-2 text-center mx-auto sm:text-md lg:text-lg">
                                                <span className="ml-2 text-4xl font-bold">₹ 7500</span>
                                                <span className="ml-2 font-bold">per test</span>
                                            </p>
                                            <p className='mt-2 text-center mx-auto text-sm'>*conditions apply</p>
                                            <p className='text-center mx-auto text-sm'>*talk to us to get exact pricing</p>
                                            <Link to="/checkout" className="mt-3 block text-white bg-teal-500 hover:text-white hover:bg-teal-600 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                            <div className="flex items-center py-2">
                                                <div className="flex-grow h-px bg bg-gray-300 h-0.5"></div>
                                                <div className="flex-grow-0 mx-5 text text-gray-700">or</div>
                                                <div className="flex-grow h-px bg bg-gray-300 h-0.5"></div>
                                            </div>
                                            <form onSubmit={onSubmit}>
                                                <div className="flex-1 items-stretch space-y-4">
                                                    <p className='text-left text-base text-gray-700 font-semibold'>Got any questions? Talk to our expert.</p>
                                                    <div>
                                                        <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                            Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="name"
                                                            autoComplete="name"
                                                            placeholder="Enter your name"
                                                            value={formData.name}
                                                            onChange={(e) => { setName(e.target.value); onChange(e)}}
                                                            className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                        />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                            Phone No
                                                        </label>
                                                        <input
                                                            type="tel"
                                                            name="phoneNo"
                                                            id="phoneNo"
                                                            autoComplete="tel"
                                                            placeholder="+91 ##### #####"
                                                            maxLength='10'
                                                            value={formData.phoneNo}
                                                            onChange={(e) => {setPhone(e.target.value); onChange(e)}}
                                                            className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                        />
                                                    </div>
                                                    {name && name.length > 3 && <div>
                                                        <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            autoComplete="email"
                                                            value={formData.email}
                                                            onChange={(e) => {setEmail(e.target.value); onChange(e)}}
                                                            placeholder="Enter your email"
                                                            className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-teal-500 focus:border-teal-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                        />
                                                    </div>}
                                                </div>
                                                <div className="flex mt-5 items-start">
                                                    <input
                                                        id="send-communications"
                                                        name="send-communications"
                                                        type="checkbox"
                                                        defaultChecked={true}
                                                        className="h-4 w-4 mt-1 bg-teal-600 text-teal-500 rounded"
                                                        style={{flexShrink: 0}}
                                                    />
                                                    <label htmlFor="send-communications" className="ml-2 text-sm text-gray-900">
                                                        By clicking on request callback, I provide my consent to receive communications from Aahar. Check our
                                                        <Link to="/terms" className={`text-teal-500 hover:text-teal-700`}> terms & conditions </Link> .
                                                    </label>
                                                </div>
                                                {!showMessage && <button type='submit' className="w-full block text-white bg-teal-500 hover:text-white hover:bg-teal-600 group-hover:text-teal-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Request Callback</button>}
                                                {showMessage && <div className="mt-5 flex justify-center">
                                                        <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                                            <span className='flex items-center'>
                                                                <CheckCircleIcon className={`w-5 h-5 text-teal-500`} />
                                                                <span className='ml-2'> {state1?.requestCallback?.msg} </span>
                                                            </span>
                                                        </div>
                                                </div>}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="font-medium mt-5 lg:mt-2">
                                    <div className="text-left text-teal-900 bg-teal-200 p-4 shadow rounded-lg">
                                        <p>
                                            <span>* Govt fees - not included, want to check the exact govt fees? </span>
                                            <Link to="/service-info" className="mt-2 py-3 font-semibold text-teal-500 text-md hover:text-teal-700 group-hover:text-white">Click here</Link> 
                                            <span> to find out</span>
                                        </p>
                                        <p className='mt-3'>
                                            ** Final price may vary depending on the complexity (Total number of products, kind of business, food product category etc)
                                        </p>
                                        <p className='mt-3'>
                                            Talk to our team on 1800 1020 127 to get an estimate. 
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className='bg bg-gray-200' />
            <Faq />

            <hr className='bg bg-gray-200' />
            <E2EComplaince />
            
            <hr className='bg bg-gray-200' />
            <Newsletter />
        </>
    )
}

export default WaterTesting