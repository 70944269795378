import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { images } from '../constants';
import { classNames } from '../utils/Utils';
import $ from "jquery";

export const courses = [
    {
        courseName: 'General Catering - Basic',
        courseTitle: 'General Catering',
        level: 'Basic',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design of Food Establishment Premises',
                    'Equipment & Containers',
                    'Facilities'
                ]
            },
            {
                name: 'Food Production',
                items: [
                    'Receiving',
                    'Storage of Raw Materials & Food',
                    'Cross Contamination',
                    'Cooking',
                    'Reheating of Food',
                    'Hot & Colf Holding of Food',
                    'Food Packing',
                    'Food Distribution/ Service',
                    'Waste Disposal'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status',
                    'Behavioural & Personal Cleanliness'
                ]
            },
            {
                name: 'Pest Control',
                items: [
                    'Pest Control'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            },
        ],
        courseType: 'Catering',
        duration: '60 mins',
        fees: 2999,
        trainer: 'Chandan',
        eligibilityCriteria: [
            {
                eligibeFor: 'For foood handler/ supervisor/ other individuals employed in petty food business',
                criteria: [
                    'Minimum class 5th std',
                    'No experience required'
                ]
            },
            {
                eligibeFor: 'For students',
                criteria: [
                    'Education in relevant field with 10+2 passed with science'
                ]
            }
        ],
        description: 'FoSTaC - Food safety supervisor training and certification',
        about: `To mark the World Food Safety Day, we will be talking about ensuring safer food for better health in the context of street food vending setups and how to ensure we reduce risks and hazards.
        In recent times, Shawrama as been in the news for wrong reasons where 1 person lost her life and multiple others were hospitalized. This brings food safety back in the forefront and it is a great time to raise te awareness about the topic.`
    },
    // {
    //     courseName: "Integrated Child Development Services Catering",
    //     courseContent: '',
    //     courseType: 'Catering',
    //     duration: '60 mins',
    //     fees: 2999,
    //     trainer: 'Chandan',
    //     about: 'Training',
    //     eligibilityCriteria: '',
    //     description: ''
    // },
    {
        courseName: "Mid Day Meal Catering",
        courseTitle: "Mid Day Meal Catering",
        level: "Basic",
        courseContent: [],
        courseType: 'Catering',
        duration: '60 mins',
        fees: 2999,
        trainer: 'Chandan',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "Bakery Level 1 Catering",
        courseTitle: "Bakery Level 1 Catering",
        level: "Basic",
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Contamination',
                    'Food Allergens',
                    'Food Poisoning',
                    'Food Safety Programs'
                ]
            },
            {
                name: 'Food Safety in Bakery Practices',
                items: [
                    'Introduction',
                    'Bakery Premises - Location',
                    'Design & Layout',
                    'Facilities',
                    'Equipments',
                    'Bakery Process Flow',
                    'Storage of Raw Materials & Food',
                    'Mixing, Dividing, Baking, Cooling, Packaging',
                    'Transportation'
                ]
            },
            {
                name: 'Good Hygiene Practices (GHP) & Good Manufacturing Practices (GMP) in Bakery',
                items: [
                    'Personal Hygiene',
                    'Food Temperature & Storage',
                    'Pest Prevention & Control',
                    'Cleaning & Sanitation',
                    'Stop Cross Contamination'
                ]
            },
            {
                name: 'Regulation & Technical Skills in Bakery',
                items: [
                    'Food Laws - FSSAI',
                    'Food Safety',
                    'Process Control Points'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: [
                    ''
                ]
            }
        ],
        courseType: 'Catering',
        duration: '60 mins',
        fees: 2999,
        trainer: 'Chandan',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "General Manufacturing - Basic",
        courseTitle: "General Manufacturing",
        level: 'Basic',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status',
                    'Behavioural & Personal Cleanliness',
                    'Visitors'
                ]
            },
            {
                name: 'Food Operation & Control',
                items: [
                    'Location & Surroundings',
                    'Raw Materials Procurement',
                    'Storage of Raw Materials & Food',
                    'Stock Management',
                    'Food Safety Guidelines for Production Process',
                    'Pest Control'
                ]
            },
            {
                name: 'Packaging & Distribution',
                items: [
                    'Product Packaging',
                    'Transportation',
                    'Documentation & Records',
                    'Labelling',
                    'Training'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: [
                    ''
                ]
            },
        ],
        courseType: 'Manufacturing',
        duration: '60 mins',
        fees: 2999,
        trainer: 'Chandan',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "General Catering - Advance",
        courseName: "General Catering",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Allergens',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design of Food Establishment Premises',
                    'Equipment & Containers',
                    'Facilities'
                ]
            },
            {
                name: 'Material Handling',
                items: [
                    'Receiving',
                    'Storage',
                    // 'Equipment & Containers',
                    // 'Facilities'
                ]
            },
            {
                name: 'Pre Production Processing',
                items: [
                    'Preperation of Fruits & Vegetables',
                    'Preperation of Non-Vegetarian Products',
                    'Thawing',
                    'Cross-contamination'
                ]
            },
            {
                name: 'Production',
                items: [
                    'Cooking',
                    'Reheating of Food',
                    'Chilling',
                    'Special Requirement of High Risk Food'
                ]
            },
            {
                name: 'Holding, Serving & Transportation',
                items: [
                    'Hot & Cold Holding',
                    'Food Packaging',
                    'Food Distribution/ Storage',
                    'Transportation'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status',
                    'Behavioural & Personal Cleanliness',
                    'Visitors'
                ]
            },
            {
                name: 'Support Services',
                items: [
                    'Management & Supervision',
                    'Food Testing Facilities',
                    'Pest Control',
                    'Sanitation & Maintenance of Establishment Premises',
                    'Waste Handling',
                    'Training & Record Keeping'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            },
        ],
        courseType: 'Catering',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "General Manufacturing - Advance",
        courseTitle: "General Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Allergens',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design of Food Establishment Premises',
                    'Equipment & Containers',
                    'Facilities'
                ]
            },
            {
                name: 'Cleaning & Sanitation, Maintenance of Establishment',
                items: [
                    'Cleaning & Sanitation of Equipment & Premises',
                    'Preventive & Corrective Maintenance'
                ]
            },
            {
                name: 'Pest Control',
                items: [
                    'Pest Control',
                    'Monitoring & Detection',
                    'Major Pests'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status',
                    'Behavioural & Personal Cleanliness',
                    'Visitors'
                ]
            },
            {
                name: 'Food Operation & Controls',
                items: [
                    'Procurement of Raw Materials',
                    'Storage of Raw Materials',
                    'Production: Pre-processing, Production',
                    'Produc Packaging',
                    'Approved Additives'
                ]
            },
            {
                name: 'Food Transportation, Storage & Distribution',
                items: [
                    'Food Transportation, Storage & Distribution'
                ]
            },
            {
                name: 'Management & Supervision',
                items: [
                    'Management & Supervision'
                ]
            },
            {
                name: 'Food Testing',
                items: [
                    'Sampling',
                    'Quality Testing Parameters',
                    'Safety Testing Parameters'
                ]
            },
            {
                name: 'Training',
                items: [
                    'Training'
                ]
            },
            {
                name: 'Audit, Document & Records',
                items: [
                    'Audit, Document & Records'
                ]
            },
            {
                name: 'Product Information & Consumer Awareness',
                items: [
                    'Product Labelling'
                ]
            },
            {
                name: 'Food Safety Management System Plan',
                items: [
                    'Introduction to FSMS',
                    'FSMS Plan'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            },
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "Milk and milk products",
        courseTitle: "Milk and milk products - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Milk Safety Hazard',
                    'Allergen',
                    'Food Quality'
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design of Food Establishment Premises',
                    'Equipment & Containers',
                    'Facilities'
                ]
            },
            {
                name: 'Milk Processing & Packaging',
                items: [
                    'Clean Milk Production at Farm & DCS Level',
                    'Raw Milk Reception at Dairy Plant',
                    'Processing',
                    'Packaging',
                    'Different types of Milk & Milk Products',
                    'Packaging Requirements of Milk & Milk Products'
                ]
            },
            {
                name: 'Cleaning, Sanitation & Equipment Maintenance',
                items: [
                    'Cleaning & Sanitation',
                    'Cleaning-In-Place (CIP)',
                    'Sanitation/ Disinfection',
                    'Hygiene Station',
                    'Equipment Maintenance',
                    'Instrumentation in Dairy Sector'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status',
                    'Behavioural & Personal Cleanliness',
                    'Visitors'
                ]
            },
            {
                name: 'Transportation, Storage & Distribution',
                items: [
                    'Storage & Transportation',
                    'Global Positioning System',
                    'Distribution'
                ]
            },
            {
                name: 'Management & Supervision',
                items: [
                    'Standard Operating Procedure (SOP)',
                    'Cold Chain Management',
                    'Supply Chain of Indian Dairy Industry'
                ]
            },
            {
                name: 'Milk & Milk Products Testing',
                items: [
                    'Sampling',
                    'Quality Testing Parameters',
                    'Microbiological Standards for Milk & Milk Products',
                    'Safety Testing Parameters'
                ]
            },
            {
                name: 'Training',
                items: [
                    'Training'
                ]
            },
            {
                name: 'Audit, Documentation & Records',
                items: [
                    'Audit, Documentation & Records'
                ]
            },
            {
                name: 'Product Information & Consumer Awareness',
                items: [
                    'Labelling',
                    'Manner of Declaration',
                    'Specific Requirements/ Restrictions on Manner of Labelling',
                    'Exemptions from Labelling Requirements',
                    'Notice of Addition, Admixture or Deficiency in Food'
                ]
            },
            {
                name: 'Food Safety Management System Plan',
                items: [
                    'Structure of the FSMS Plan',
                    'Flow Chart',
                    'FSMS Plan'
                ]
            },
            {
                name: 'HACCP',
                items: [
                    'HACCP Principles',
                    'Purpose & Importance of Hazard Analysis',
                    'Hazard Analysis, Process, Hazard Identification & Evaluation, Control Measures',
                    'Flow Diagram of Liquid Milk & UHT Milk Processing'
                ]
            },
            {
                name: 'Pest Control',
                items: []
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "Animal meat and meat products",
        courseTitle: "Animal meat and meat products - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Key Definitions',
                    'Food Safety & Food Safety Hazards',
                    'Food Spoilage'
                ]
            },
            {
                name: 'Establishment, Design & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design: Premises & Rooms',
                    'Equipment & Containers',
                    'Facilities & Utilities'
                ]
            },
            {
                name: 'Receipt & Storage',
                items: [
                    'Receipt & Transportation - Live Animals',
                    'Receipt & Storage of Raw and Packaging Material'
                ]
            },
            {
                name: 'Slaughtering, Deboning/ Portioning and Processing & Transportation',
                items: [
                    'Slaughter, Deboning/ Portioning',
                    'Allergen Management',
                    'Packaging & Transportation',
                    'Rework & Control of Non-Conforming Products',
                    'Transportation'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status, Illness & Injury',
                    'Personal Cleanliness',
                    'Personla Behaviour',
                    'Work Wear & Gowning',
                    'Visitor Control'
                ]
            },
            {
                name: 'Support Services',
                items: [
                    'Quality Control & Testing Facilities',
                    'Pest Control System',
                    'Cleaning & Maintenance',
                    'Waste Handling',
                    'Training & Management',
                    'Audit, Documentation & Record Keeping',
                    'Product Information & Consumer Awareness',
                    'Traceability & Recall'
                ]
            },
            {
                name: 'Implementation of HACCP',
                items: [
                    'Introduction to FSMS',
                    'HACCP Manufacturing & HACCP Implementation of important Control Measures',
                    'FSMS & HACCP Plan',
                    'Hazards',
                    'Risk Assessment'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "Poultry meat and meat products",
        courseTitle: "Poultry meat and meat products - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Key Definitions',
                    'Food Safety & Food Safety Hazards',
                    'Food Spoilage'
                ]
            },
            {
                name: 'Establishment, Design & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design: Premises & Rooms',
                    'Equipment & Containers',
                    'Facilities & Utilities'
                ]
            },
            {
                name: 'Receipt & Storage',
                items: [
                    'Receipt & Transportation - Live Animals',
                    'Receipt & Storage of Raw and Packaging Material'
                ]
            },
            {
                name: 'Slaughtering, Deboning/ Portioning and Processing & Transportation',
                items: [
                    'Slaughter, Deboning/ Portioning',
                    'Allergen Management',
                    'Packaging & Transportation',
                    'Rework & Control of Non-Conforming Products',
                    'Transportation'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status, Illness & Injury',
                    'Personal Cleanliness',
                    'Personla Behaviour',
                    'Work Wear & Gowning',
                    'Visitor Control'
                ]
            },
            {
                name: 'Support Services',
                items: [
                    'Quality Control & Testing Facilities',
                    'Pest Control System',
                    'Cleaning & Maintenance',
                    'Waste Handling',
                    'Training & Management',
                    'Audit, Documentation & Record Keeping',
                    'Product Information & Consumer Awareness',
                    'Traceability & Recall'
                ]
            },
            {
                name: 'Implementation of HACCP',
                items: [
                    'Introduction to FSMS',
                    'HACCP Manufacturing & HACCP Implementation of important Control Measures',
                    'FSMS & HACCP Plan',
                    'Hazards',
                    'Risk Assessment'
                ]
            },
            {
                name: 'Hygiene and Safety of Eggs',
                items: [
                    'Hygiene and Safety of Eggs'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "Fish and fish products",
        courseTitle: "Fish and fish products - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Key Definitions',
                    'Food Safety & Food Safety Hazards',
                    'Food Spoilage'
                ]
            },
            {
                name: 'Establishment, Design & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design: Premises & Rooms',
                    'Equipment & Containers',
                    'Facilities & Utilities'
                ]
            },
            {
                name: '',
                items: [
                    'Receiving of Incoming Material',
                    'Storage of Raw & Packaging Material',
                    'Allergen Management',
                    'Fish & Fish Products - Processing Including Pre-processing',
                    'Packaging of Fish & Fish Products and Warehousing',
                    'Rework & Control of Non-Comforming Products',
                    'Transportation & Distribution of Fish & Fish Products'
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    'Health Status, Illness & Injury',
                    'Personal Cleanliness',
                    'Personla Behaviour',
                    'Work Wear & Gowning',
                    'Visitor Control'
                ]
            },
            {
                name: 'Support Services',
                items: [
                    'Quality Control & Testing Facilities',
                    'Pest Control System',
                    'Cleaning & Maintenance',
                    'Waste Handling',
                    'Training & Management',
                    'Audit, Documentation & Record Keeping',
                    'Product Information & Consumer Awareness',
                    'Traceability & Recall'
                ]
            },
            {
                name: 'Implementation of HACCP',
                items: [
                    'Introduction to FSMS',
                    'HACCP Manufacturing & HACCP Implementation of important Control Measures',
                    'HACCP Plan'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "Bakery Level 2",
        courseTitle: "Bakery Level 2 - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Allergens',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design of Food Establishment Premises',
                    'Equipment & Containers',
                    'Facilities'
                ]
            },
            {
                name: 'Material Handling',
                items: [
                    "Procurement of Raw Material",
                    "Handling of Raw Material"
                ]
            },
            {
                name: 'Pre Production Processing',
                items: [
                    "Pre Production Processing"
                ]
            },
            {
                name: 'Production',
                items: [
                    "Baking",
                    "Cooling",
                    "Sandwiching/ Mallow/ Center Filling/ Enrobing",
                    "Role of process control in food safety",
                    "Few good practices in bakery industry"
                ]
            },
            {
                name: 'Packaging & Transportation',
                items: [
                    "Slicing & packing of bakery products",
                    "Storage & transportation of finished product",
                    "Dispatch & loading",
                    "Retail & display",
                    "Control of storage & transportation conditions"
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    "Health status",
                    "Personal & Behavioural Cleanliness",
                    "Visitors"
                ]
            },
            {
                name: 'Support Services',
                items: [
                    "Management & Supervision",
                    "Food Testing Facilities",
                    "Pest Control",
                    "Cleaning & Maintenance",
                    "Water Handling",
                    "Training",
                    "Record Keeping",
                    "Consumer Awareness"
                ]
            },
            {
                name: 'Food Safety Management System Plan',
                items: [
                    "Food Safety Management System Plan"
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "Edible oils and fats",
        courseTitle: "Edible oils and fats - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Allergens',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    'Location & Surroundings',
                    'Layout & Design of Food Establishment Premises',
                    'Equipment & Containers',
                    'Facilities'
                ]
            },
            {
                name: 'Cleaning & Sanitation',
                items: [
                    'Cleaning',
                    'Maintenance'
                ]
            },
            // {
            //     name: 'Pest Control',
            //     items: [
            //         'Pest Control',
            //         'Monitoring & Detection',
            //         'Major Pests'
            //     ]
            // },
            {
                name: 'Pest Control',
                items: [
                    'Health Status',
                    'Behavioural & Personal Cleanliness',
                    'Visitors'
                ]
            },
            {
                name: 'Food Operation & Controls',
                items: [
                    'Procurement of Raw Materials',
                    'Storage of Raw Materials',
                    'Production: Pre-processing, Production',
                    'Produc Packaging',
                    'Approved Additives'
                ]
            },
            {
                name: 'Food Transportation, Storage & Distribution',
                items: [
                    'Food Transportation, Storage & Distribution'
                ]
            },
            {
                name: 'Management & Supervision',
                items: [
                    'Management & Supervision'
                ]
            },
            {
                name: 'Food Testing',
                items: [
                    'Sampling',
                    'Quality Testing Parameters',
                    'Safety Testing Parameters'
                ]
            },
            {
                name: 'Training',
                items: [
                    'Training'
                ]
            },
            {
                name: 'Audit, Document & Records',
                items: [
                    'Audit, Document & Records'
                ]
            },
            {
                name: 'Product Information & Consumer Awareness',
                items: [
                    'Product Labelling'
                ]
            },
            {
                name: 'Food Safety Management System Plan',
                items: [
                    'Introduction to FSMS',
                    'FSMS Plan'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            },
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "Alcoholic beverages",
        courseTitle: "Alcoholic beverages - Manufacturing",
        level: 'Advanced',
        courseContent: [],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "Organic food products",
        courseTitle: "Organic food products - Manufacturing",
        level: 'Advanced',
        courseContent: [],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "Packaged water and water based beverages",
        courseTitle: "Packaged water and water based beverages - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Hygiene Prescriptions for Collection of Drinking Water',
                items: [
                    "Extraction or Collection",
                    "Materials"
                ]
            },
            {
                name: 'Protective Measures',
                items: [
                    "Genral Measures",
                    "Protection of area of origin"
                ]
            },
            {
                name: 'Transport of Drinking Water',
                items: [
                    "Means of Transport, Piping & Reservoirs",
                    "Maintenance of Vehicles & Reservoirs"
                ]
            },
            {
                name: 'Establishment for Processing of Drinking Water',
                items: [
                    "Location",
                    "Roadways & areas used by wheeled traffic",
                    "Building & Facilities",
                    "Hygienic Facilities",
                    "Equipment & Utensils"
                ]
            },
            {
                name: 'Establishment',
                items: [
                    "Maintenance",
                    "Cleaning & Disinfection"
                ]
            },
            {
                name: 'Personnel Hygiene & Health Requirements',
                items: [
                    "Hygiene Training",
                    "Medical Examination",
                    "Communicable Diseases",
                    "Injuries",
                    "Washing of Hands",
                    "Personal Cleanliness",
                    "Personla Behaviour"
                ]
            },
            {
                name: 'Establishment: Hygienic Processing Requirements',
                items: [
                    "Raw Material Requirements",
                    "Treatment",
                    "Packaging material & containers",
                    "Filling and sealing of containers",
                    "Packaging of containers",
                    "Lot/ Batch Identification",
                    "Processing & Production Records",
                    "Storage & Transport of the end-product"
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            },
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "Health supplements and nutraceuticals",
        courseTitle: "Health supplements and nutraceuticals - Manufacturing",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Objective & Scope',
                items: [
                    "Objective & Scope"
                ]
            },
            {
                name: 'Location, Layout & Facilities',
                items: [
                    "Location & Surroundings",
                    "Layout & Building Design",
                    "Equipment Design & Installation",
                    "Facilities",
                    "Sector Specific Food Manufacturing Practices"
                ]
            },
            {
                name: 'Material Handling',
                items: [
                    "Receiving, Storing",
                    "Pre-production processing",
                    "General requirements",
                    "Botanical Extract Preperation"
                ]
            },
            {
                name: 'Production of Health Supplements & Nutraceuticals',
                items: [
                    "Manufacturing Requirements for Tabets & Capsules",
                    "Manufacturing requirements for Liquids",
                    "Manufacturing of Powder",
                    "Allergen Management",
                    "Packaging & Warehousing",
                    "Rework & Control of Non-Confirming Products"
                ]
            },
            {
                name: 'Transport, Handling & Distribution',
                items: [
                    "Transport, Handling & Distribution"
                ]
            },
            {
                name: 'Personal Hygiene',
                items: [
                    "Health Status, Illness & Injury",
                    "Personal Cleanliness",
                    "Personal Behaviour",
                    "Work Wear & Grooming",
                    "Visitors Control"
                ]
            },
            {
                name: 'Support Services',
                items: [
                    "Sub-contracting of operations",
                    "Quality Control & Testing Facilities",
                    "Pest Control Systems",
                    "Cleaning & Maintenance",
                    "Waste Handling",
                    "Training",
                    "Record Keeping",
                    "Product Information & Consumer Awareness",
                    "Traceability & Recall",
                    "Stability Program"
                ]
            },
            {
                name: 'Implementation of HACCP',
                items: [
                    "Introduction to HACCP",
                    "Application of HACCP Systems"
                ]
            }
        ],
        courseType: 'Manufacturing',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training',
    },
    {
        courseName: "General Retail and Distribution",
        courseTitle: "General Retail and Distribution - Basic",
        level: 'Basic',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Food Sopilage'
                ]
            },
            {
                name: 'GMP-GHP for Wholesalers',
                items: [
                    'Drainage & Waste Disposal',
                    'Storage of Food',
                    'Food Storage/ Food Service',
                    'Cleaning & Manitenance',
                    'Pest Control Systems',
                    'Health Status',
                    'Personal Cleanliness',
                    'Visitors',
                    'Training'
                ]
            },
            {
                name: 'GMP-GHP for Distributors',
                items: [
                    'Drainage & Waste Disposal',
                    'Personal Facilities & Toilets',
                    'Storage of Food',
                    'Food Distribution/ Food Service',
                    'Cleaning & Manitenance',
                    'Pest Control Systems',
                    'Health Status',
                    'Personal Cleanliness',
                    'Visitors'
                ]
            },
            {
                name: 'GMP-GHP for Transporters',
                items: [
                    'Operations & Control',
                    'Cleaning & Manitenance',
                    'Pest Control Systems'
                ]
            },
            {
                name: 'GMP-GHP for Retailers - Registration',
                items: [
                    'Sanitary & Hygienic Requirements'
                ]
            },
            {
                name: 'GMP-GHP for Retailer - License',
                items: [
                    'Drainage & Waste Disposal',
                    'Personal Facilities & Toilets',
                    'Raw Material Procurement',
                    'Storage of Food & Raw Materials',
                    'Food Processing, Preservation, Packaging, Service',
                    'Sanitation & Maintenance'
                ]
            },
            {
                name: 'Food Safety Management Systems',
                items: [
                    ''
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: [
                    ''
                ]
            }
        ],
        courseType: 'Retail & Distribution',
        duration: '60 mins',
        fees: 2999,
        trainer: 'Chandan',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "General Retail and Distribution",
        courseTitle: "General Retail and Distribution - Advance",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    "Food Safety Introduction",
                    "Food Safety Hazards & GMP Requirements for Food Storage & Transportation",
                    "Common Hazards in Storage & Transportation"
                ]
            },
            {
                name: 'Ensure Food Safety',
                items: [
                    "Control of Food Safety Hazards",
                    "Basics of Preventing Health Risks"
                ]
            },
            {
                name: 'General Principles of Food Safety',
                items: [
                    "Food Safety Checklist",
                    "Facility GMP Programme"
                ]
            },
            {
                name: 'Specific GMP Measures for Food Storage',
                items: [
                    "Key Design Aspects",
                    "Food Storage - Specific GMP Conditions"
                ]
            },
            {
                name: 'Specific GMP Measures for Transportation',
                items: [
                    "General Requirements",
                    "Specific GMP requirements for transportation of food",
                    "Responsibilities of the establishment that own food transportation units",
                    "Usage and maintenance"
                ]
            },
            {
                name: 'Documentation & Records',
                items: [
                    "Documentation",
                    "Lot Identification",
                    "Product Information",
                    "Labelling",
                    "Customer Experience",
                    "Training",
                    "Inspection Checklist - Food Storage/ Warehouse",
                    "Inspection Checklist - Distribution & Transportation"
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Retail & Distribution',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "Point of sale of Health supplement",
        courseTitle: "Point of sale of Health supplement - Retail and Distribution",
        level: 'Advanced',
        courseContent: [],
        courseType: 'Retail & Distribution',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
    {
        courseName: "General Storage and Transport",
        courseTitle: "General Storage and Transport - Basic",
        level: 'Basic',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    'Food Safety & Food Safety Hazards',
                    'Food Sopilage'
                ]
            },
            {
                name: 'Ensure Food Safety',
                items: [
                    'Control of Food Safety Hazards'
                ]
            },
            {
                name: 'Specific GMP Measures for Food Storage',
                items: [
                    'Infrastructure',
                    'Good Warehousing Practices',
                    'Safe Food Storage',
                    'Personal Cleanliness & Hygiene',
                    'Pest Management',
                    'Returned Goods',
                    'Finished Good Traceability'
                ]
            },
            {
                name: 'Specific GMP Measures for Transportation',
                items: [
                    'Introduction'
                ]
            },
            {
                name: 'Documentation & Records',
                items: [
                    'Documentation',
                    'Batch Identification',
                    'Product Information',
                    'Labelling',
                    'Training',
                    'Inspection Checklist'
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: [
                    ''
                ]
            }
        ],
        courseType: 'Storage & Transport',
        duration: '60 mins',
        fees: 2999,
        trainer: 'Chandan',
        about: 'Training',
        eligibilityCriteria: '',
        description: ''
    },
    {
        courseName: "General Storage and Transport",
        courseTitle: "General Storage and Transport - Advance",
        level: 'Advanced',
        courseContent: [
            {
                name: 'Introduction to Food Safety',
                items: [
                    "Food Safety Introduction",
                    "Food Safety Hazards & GMP Requirements for Food Storage & Transportation",
                    "Common Hazards in Storage & Transportation"
                ]
            },
            {
                name: 'Ensure Food Safety',
                items: [
                    "Control of Food Safety Hazards",
                    "Basics of Preventing Health Risks"
                ]
            },
            {
                name: 'General Principles of Food Safety',
                items: [
                    "Food Safety Checklist",
                    "Facility GMP Programme"
                ]
            },
            {
                name: 'Specific GMP Measures for Food Storage',
                items: [
                    "Key Design Aspects",
                    "Food Storage - Specific GMP Conditions"
                ]
            },
            {
                name: 'Specific GMP Measures for Transportation',
                items: [
                    "General Requirements",
                    "Specific GMP requirements for transportation of food",
                    "Responsibilities of the establishment that own food transportation units",
                    "Usage and maintenance"
                ]
            },
            {
                name: 'Documentation & Records',
                items: [
                    "Documentation",
                    "Lot Identification",
                    "Product Information",
                    "Labelling",
                    "Customer Experience",
                    "Training",
                    "Inspection Checklist - Food Storage/ Warehouse",
                    "Inspection Checklist - Distribution & Transportation"
                ]
            },
            {
                name: 'Covid-19 Precautions',
                items: []
            }
        ],
        courseType: 'Storage & Transport',
        duration: '160 mins',
        fees: 4999,
        trainer: 'Shiva',
        eligibilityCriteria: [],
        description: '',
        about: 'Training'
    },
];

const CourseCatalog = () => {

    const navigate = useNavigate();

    const categories = [
        {
            name: 'Catering',
            link: '#Catering'
        },
        {
            name: 'Manufacturing',
            link: '#Manufacturing'
        },
        {
            name: 'Retail & Distribution',
            link: '#Retail & Distribution'
        },
        {
            name: 'Storage & Transport',
            link: '#Storage & Transport'
        },
    ];

    const [courseCategory, setCourseCategory] = useState(categories[0].name);

    const onSelectCourse = (course) => {
        // console.log('Selected Course : ', course);
        navigate("/trainingDetails", { state: course });
    }

    // Scrolling Hook for Categories and Content
    $(function () {
        var currentHash = "#Catering"
        $(document).scroll(function () {
            $('.hash').each(function () {
                var top = window.pageYOffset;
                var distance = top - $(this).offset().top;
                var hash = $(this).attr('href');
    
                if (distance < 30 && distance > -30 && currentHash !== hash) {
                    // alert(hash);
                    // console.log(hash.substring(1, hash.length));
                    setCourseCategory(hash.substring(1, hash.length));
                    currentHash = hash;
                }
            });
        });
    });

  return (
    <div className="w-full px-2 pb-16 sm:px-0 flex justify-start">
        <div className='hidden md:flex md:w-1/5 md:flex-col'>
            <div className='sticky top-0 pt-32'>
                {categories.map((e) => 
                    <div key={e.name} className="text-left pb-3">
                        <a key={e.name} href={e.link}
                            onClick={() => setCourseCategory(e.name)}
                            className={classNames(courseCategory === e.name ? 'text-orange-500' : 'text-gray-700', "hover:text-orange-500 hover:pointer text-left mb-2 max-w-2xl text-md lg:text-lg tracking-wide md:mx-auto lg:mx-auto")}>
                            {e.name}
                        </a>
                    </div>
                )}
              </div>
        </div>
        <div className='md:w-4/5 text-left pt-32'>
            {categories.map((cat) => 
                <div key={cat.name} className='grid gap-x-8 gap-y-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-3 items-end'>
                    {courses.filter((e) => cat.name === e.courseType).map((course, idx) =>
                        <div key={course.name} className='mb-8'>
                            <a className="anchor hash" href={cat.link} id={course.courseType}></a>
                            {idx === 0 && <p key={cat.name} className="text-left mb-5 pl-3 max-w-2xl text-md lg:text-lg tracking-wide font-bold text-gray-700 md:mx-auto lg:mx-auto">
                                {cat.name}
                            </p>}
                            <div key={course.name} className="flex m-2 font-medium cursor-pointer" onClick={() => onSelectCourse(course)}>
                                <div className="w-full text-left p-5 h-40 card-shadow rounded-lg hover:cursor-pointer flex flex-col justify-between">
                                    <img className="w-10 object-center object-contain" src={images.certificate} alt="illustration" loading="lazy" />
                                    <p className="mt-5 max-w-2xl text-sm font-medium tracking-loose text-gray-900 whitespace-pre-line">
                                        {course.courseName}
                                    </p>
                                    {/* <div className="flex mt-5">
                                        <p className="mb-2 max-w-2xl text-sm font-medium tracking-loose text-gray-900">
                                            {e.courseType}
                                        </p>
                                        <p className="ml-3 mb-2 max-w-2xl text-sm font-medium tracking-loose text-gray-900">
                                            {e.duration}
                                        </p>
                                        <p className="ml-3 mb-2 max-w-2xl text-sm font-medium tracking-loose text-gray-900">
                                            {e.trainer}
                                        </p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )}  
                </div>
            )}
        </div>
    </div>
  )
}

export default CourseCatalog