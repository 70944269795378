import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { images } from '../constants';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import parse from 'html-react-parser';
import { PaperAirplaneIcon, ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/solid';
import { classNames } from '../utils/Utils';

const BlogPost = ({pinned}) => {

    const [showSmallHeader, setShowSmallHeader] = useState(false);
    const [liked, setLiked] = useState(false);

    const article = {
        name: 'Cleaning of Eggs and its effects. Is cleaning of eggs necessary?',
        content: `
        <p>The food consumption pattern in India is gradually getting diversified. Livestock products are of utmost importance in this diversified menu. There are numerous recipes that require eggs to be added but how safe is it to consume eggs without cleaning them? Should the eggs be washed or sanitized before consumption

        FSSAI defines Egg as “eggs-in-shell other than broken, incubated or cooked eggs, laid by poultry species or birds meant for direct human consumption or the preparation of egg products.” 
        
        Eggs can taste and smell fine, but they may still have harmful microorganisms. Certainly, the dirt on the egg is generally organic material including fecal matter which may have harmful bacteria. 
        
        It is important to note that the quality and appearance of eggs depend mostly on storage and the length of the storage period.
        
        Shelf life of an egg:  Eggs keep their freshness for up to 10 to 12 days after they have been laid at room temperature of 280 C ±20 C, but the shelf life shortens with higher temperatures. Eggs can lose as much quality in one day at room temperature as compared to the days in the refrigerator. </p>
        
        <p id="risks">The risks associated with eggs:</p>
        
        <p>Eggs are among the nutritious foods and can be part of a healthy diet. But there are a lot of risks associated with it and to be safe, eggs must be safely handled as unbroken, clean, fresh. 
        
        Figure: Estimated percentage of foodborne salmonella illnesses (with 90% credibility intervals) for 2019, in descending order, attributed to each of 17 food categories, based on multi-year outbreak data, * United States.
        
        
        Shell eggs may have Salmonella enteritidis (SE) bacteria that can cause foodborne illness. 
        
        A report was released by Interagency Food Safety Analytics Collaboration (IFSAC) about foodborne illness outbreak sources in the United States in 2019 for Salmonella, Listeria monocytogenes and two other microorganisms. Among the annual reports of IFSAC, this was the first time in which Listeria illnesses were also attributed to either Other Produce or Eggs. 
        
        More than 75% of Salmonella illnesses were attributed to seven food categories: Eggs, Chicken, Fruits, Pork, Seeded Vegetables (such as tomatoes), Other Produce (such as nuts), and Turkey.
        
        Is washing or cleaning of eggs necessary? 
        
        Before taking a call on whether the eggs must be cleaned or not, we should first have to understand the basics of the egg’s structure. 
        
        
        The egg has a cuticle as a topmost layer where it is made of a protein layer that covers the surface of the egg and fills the pores that allow the air inside for the growing chick. The purpose of the cuticle is to prevent the bacteria from entering the egg and to form the first line of defense against infection.
        
        Bacteria can be present on the outside of an Eggshell because the egg exits the hen’s body through the same passageway as feces is excreted. 
        
        Considering cuticles, the washing of eggs may damage the cuticle and eventually leave the eggshells porous. This process may affect the eggshell cuticle layer and may allow the harmful bacteria to enter the eggs increasing the risk of contamination as the wash water can seep into the egg through the pores in the shell. 
        
        Various rules that are followed by the different countries regarding egg washing or cleaning: 
        
        India– The Food Safety Standard Authority of India released a guideline note on the handling of Eggs where they mentioned that they must not be washed because they become porous when washed. The FSSAI’s take on this is to avoid washing dirty eggs as it becomes more porous when wet and pave the way for bacteria to enter the egg. In the guidance document, It is also recommended to discard the dirty eggs.
        
        European Union: EU regulations emphasize not to wash eggs because such practices can cause damage to the eggshell, which is an effective barrier to bacterial ingress with an array of antimicrobial properties. However, some practices, such as the treatment of eggs with ultra-violet rays, should not be interpreted as constituting a cleaning process.  
        
        USDA- United States Government regulations require that USDA-graded eggs be carefully washed and sanitized using only compounds meeting FDA regulations for processing foods. All USDA-graded eggs and most large volume processors follow the washing step with a sanitizing rinse at the processing plant. 
        
        Timeline
        
        Description automatically generated
        
        Eggs are sometimes washed to remove extraneous material, which may include faeces, from the egg surface. In the commercial setting, this usually involves passing the egg through a series of sprays having detergents and/or sanitizing agents, followed by rinsing, drying, and oiling. 
        
        In a publication released by public health and safety of eggs and egg products in Australia, it was mentioned that, if the washing process is performed correctly, commercial egg washing results in a reduction in the level of microorganisms on the egg surface. Factors that are critical to the effectiveness of egg washing include the correct use of detergents and sanitizing agents and the use of proper wash water temperatures. Alternatively, if performed incorrectly, washing can increase the potential for transmission of Salmonella from the shell surface into the egg contents.
        
        Egg-associated illness is a serious public health problem. Infected individuals may suffer mild to severe gastrointestinal illness, short-term, or chronic arthritis, or even death. 
        
        Proper handling of Eggs- 
        
        DOs √ 
        
        Retailers and traders must source eggs from credible sources and store them in refrigerators.
        Store eggs in refrigerators on designated shelves or in egg crates.
        Appropriate temperature and relative humidity (RH) need to be kept for storing eggs in the cold store, the lack of which can result in mixing up of the yolk and albumin.
        Consumers must buy eggs from stores that store eggs at cooler temperatures.
        Always wash your hands with soap and water after handling eggs.
        Consumers can make use of the guidance document to check the freshness and quality of eggs easily at home with the help of simple tests
        DONTs ×
        
        Eggs must not be left in vehicles or in places where it can be affected by the hot atmosphere. 
        Do not use cracked or dirty eggs and dispose of cracked and dirty eggs immediately.
        Avoid washing dirty eggs until necessary as they can become more porous when wet and this can pave the way for bacteria to enter the egg.
        Implementing preventive measures like ensuring a clean and hygienic processing environment along with personal hygiene would reduce the number of infections from eggs.</p>
        `,
        contents: ''
    };

    const recentArticles = [
        { name: 'Cleaning of Eggs and its effects', blogUrl: ''},
        { name: 'Bottle Caps – What are they made from?', blogUrl: ''},
        { name: 'A quick bite: Tree nuts, Peanuts, and Coconuts Allergies', blogUrl: ''},
    ];
    const popularArticles = [
        { name: 'Cleaning of Eggs and its effects', blogUrl: ''},
        { name: 'Bottle Caps – What are they made from?', blogUrl: ''},
        { name: 'A quick bite: Tree nuts, Peanuts, and Coconuts Allergies', blogUrl: ''},
    ];
    const interestedArticles = [
        { name: 'Cleaning of Eggs and its effects', blogUrl: ''},
        { name: 'Bottle Caps – What are they made from?', blogUrl: ''},
        { name: 'A quick bite: Tree nuts, Peanuts, and Coconuts Allergies', blogUrl: ''},
    ];
    const breadcrumbs = [
        { id: 1, name: 'Blogs', href: '/' },
        { id: 2, name: 'Compliance', href: '/bloghome' },
    ];
    const socialHandles = [
        { name: 'Whatsapp', navigation: 'www.facebook.com', icon: images.whatsapp_share },
        { name: 'Facebook', navigation: 'www.facebook.com', icon: images.facebook_share },
        { name: 'Twitter', navigation: 'www.twitter.com', icon: images.twitter_share },
        { name: 'Linkedin', navigation: 'www.linkedin.com', icon: images.linkedin_share },
        { name: 'Share Link', navigation: '', icon: images.share },
    ];

    const shareLink = (props) => {
        if (navigator.share) {
            navigator.share({
                title: props?.title,
                text: props?.text,
                url: props?.url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('Share not supported on this browser.');
        }
    };

    const openShareDialogue = () => {

    };

    return (
        <div className='px-3 flex flex-col justify-between items-start md:flex-row lg:flex-row'>
            {/* Left Section */}
            <div className={classNames(pinned ? 'lg:pt-24' : 'lg:pt-2' , 'w-full md:w-1/5 lg:w-1/5 relative sm:sticky sm:top-0 pt-0')}>
                <div className="flex flex-col mt-5 sm:mt-0 pr-3">
                    <h1 className='text-md font-extrabold tracking-tight text-gray-900 md:text-xl lg:text-xl'>Table of Contents:</h1>
                    <hr className='mt-2 w-40 h-4' />
                    {/* <p>{article.name}</p> */}
                    <ul className="ml-3 mt-3 flex flex-col">
                        <li className='pb-2'>
                            <span className='text-gray-900'>{'>  '}</span><a href={'#risks'} className="text-gray-900 tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900'>{'>  '}</span><a href={'#risks'} className="text-gray-900 tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900'>{'>  '}</span><a href={'#risks'} className="text-gray-900 tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900'>{'>  '}</span><a href={'#risks'} className="text-gray-900 tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900'>{'>  '}</span><a href={'#risks'} className="text-gray-900 tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Center Section  */}
            <div className='w-full md:w-2/3 lg:w-2/3 pt-0'>
                <div className={classNames(pinned ? 'pt-20 lg:pt-24' : 'pt-2 lg:pt-2' , 'sticky top-0 z-30 bg-transparent')}>
                    
                    {/* Shown in Laptops */}
                    {!showSmallHeader && <div className="card-shadow shadow-lg rounded-lg bg-purple-200 p-3 px-5 pb-5">
                        <div className="flex justify-between items-center">
                            <nav aria-label="Breadcrumb mr-5">
                                <ol role="list" className="flex items-center space-x-0 sm:space-x-2">
                                    {breadcrumbs.map((breadcrumb) => (
                                        <li key={breadcrumb.id}>
                                            <div className="flex items-center">
                                                <Link to={breadcrumb.href} className="sm:mr-2 text-sm hover-link">
                                                    {breadcrumb.name}
                                                </Link>
                                                <svg
                                                    width={16}
                                                    height={20}
                                                    viewBox="0 0 16 20"
                                                    color='#ffffff'
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-hidden="true"
                                                    className="w-4 h-5 text-gray-300">
                                                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                                                </svg>
                                            </div>
                                        </li>
                                    ))}
                                    <li className="text-sm">
                                        <p aria-current="page" className="text-gray-700 hover:text-gray-600 w-40 sm:w-full"
                                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            {article.name}
                                        </p>
                                    </li>
                                </ol>
                            </nav>
                            {!showSmallHeader
                                ? <ChevronUpIcon
                                    style={{flexShrink: 0}}
                                    onClick={() => setShowSmallHeader(!showSmallHeader)}
                                    className='h-8 w-8 group-hover:text-gray-500'
                                    aria-hidden="true"
                                />
                                : <ChevronDownIcon
                                    style={{flexShrink: 0}}
                                    onClick={() => setShowSmallHeader(!showSmallHeader)}
                                    className='h-8 w-8 group-hover:text-gray-500'
                                    aria-hidden="true"
                                />
                            }
                        </div>
                        <div className="text-3xl text-left sm:text-3xl md:text-4xl">
                            <h1 className="mt-3 text-xl font-semibold tracking-tight md:text-3xl lg:text-4xl">
                                {article.name}
                            </h1>
                            <div className="mt-2 sm:mt-5">
                                <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                                    <div className='mt-3 sm:mt-0 flex items-center'>
                                        <img
                                            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
                                            alt="avatar"
                                            className="object-cover w-10 h-10 rounded-full shadow-sm"
                                        />
                                        <div className='ml-3'>
                                            <p
                                                aria-label="Author"
                                                title="Author"
                                                className="tracking-wide text-sm sm:text-lg">
                                                Chandan Vivek
                                            </p>
                                            <p className="text-xs tracking-wide">
                                                <span className="mr-1">Author</span>
                                                · <span className="mx-1">April 10, 2021</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='mt-5 sm:mt-0'>
                                        <div className="flex flex-row items-center">
                                            <span className='text-sm tracking-wide'>Share:</span> {' '}
                                            {
                                                socialHandles.map((e, i) => (
                                                    i === socialHandles.length - 1
                                                        ?
                                                        <button key={e.name} className='hover-link focus:outline-none' onClick={() => shareLink()} target="_blank" rel="noopener noreferrer">
                                                            <img src={e.icon} className="mx-2 h-6 w-6" alt={e.name + "-share-icon"} />
                                                        </button>
                                                        :
                                                        <a key={e.name} className='hover-link' href={e.navigation} target="_blank" rel="noopener noreferrer">
                                                            <img src={e.icon} className="mx-1 h-6 w-6" alt={e.name + "-share-icon"} />
                                                        </a>
                                                ))
                                            }
                                        </div>
                                        <p className='text-sm mt-2 tracking-wide'> <span className='font-semibold'>15</span> people found this useful</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {/* Shown in Mobile Phones */}
                    {showSmallHeader && <div className="card-shadow shadow-lg rounded-lg bg-purple-200 p-3 px-5">
                        <div className="text-3xl text-left sm:text-3xl md:text-4xl">
                            <div className="flex justify-between items-baseline">
                                <h1
                                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    className="text-xl font-semibold tracking-tight md:text-xl mr-3">
                                    {article.name}
                                </h1>
                                {!showSmallHeader
                                    ? <ChevronUpIcon
                                        style={{flexShrink: 0}}
                                        onClick={() => setShowSmallHeader(!showSmallHeader)}
                                        className='h-8 w-8 group-hover:text-gray-500'
                                        aria-hidden="true"
                                    />
                                    : <ChevronDownIcon
                                        style={{flexShrink: 0}}
                                        onClick={() => setShowSmallHeader(!showSmallHeader)}
                                        className='h-8 w-8 group-hover:text-gray-500'
                                        aria-hidden="true"
                                    />
                                }
                            </div>
                            <div className="sm:mt-3">
                                <div className='mt-2 sm:mt-0 flex justify-between sm:justify-start items-center'>
                                    {/* <div className="flex flex-row items-center">
                                        <span className='text-lg px-2 tracking-wide'>Share:</span> {' '}
                                        {
                                            socialHandles.map((e, i) => (
                                                i === socialHandles.length - 1
                                                    ?
                                                    <button key={e.name} className='hover-link focus:outline-none' onClick={() => shareLink()} target="_blank" rel="noopener noreferrer">
                                                        <img src={e.icon} className="mx-2 h-6 w-6" alt={e.name + "-share-icon"} />
                                                    </button>
                                                    :
                                                    <a key={e.name} className='hover-link' href={e.navigation} target="_blank" rel="noopener noreferrer">
                                                        <img src={e.icon} className="mx-1 h-6 w-6" alt={e.name + "-share-icon"} />
                                                    </a>
                                            ))
                                        }
                                    </div> */}
                                    <p className='text-sm tracking-wide'> <span className='font-semibold'>15</span> people found this useful</p>
                                    {/* <p className='sm:ml-10 text-sm tracking-wide'> <span className='font-bold'>.</span></p> */}
                                    <div className="sm:ml-10 flex items-center">
                                        <p className='text-sm px-2 tracking-wide'> Share: </p>
                                        <a className='hover-link' href="/" target="_blank" rel="noopener noreferrer">
                                            <img src={images.whatsapp_share} className="mx-1 h-6 w-6" alt="whatsapp-share-icon" />
                                        </a>
                                        <button className='hover-link focus:outline-none' onClick={() => openShareDialogue()} target="_blank" rel="noopener noreferrer">
                                            <img src={images.share} className="mx-2 h-6 w-6" alt={"share-share-icon"} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div
                    style={{whiteSpace: 'pre-wrap'}}
                    className="mt-4 text-left text-lg tracking-wide text-gray-900">
                        {parse(article.content)}
                </div>
                {/* <div id="risks">
                    Risks Div
                </div> */}

                <hr className='mt-2 h-4' />

                <div className='flex items-center py-8'>
                    <h1
                        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        className="text-xl font-semibold tracking-tight md:text-xl mr-3">
                        Did you find this article useful?
                    </h1>
                    <div className="flex items-center">
                            {!liked && <img onClick={() => setLiked(!liked)} src={images.heart} alt="heart" className='w-10 h-10' style={{ cursor: 'pointer' }} />}
                            {liked && <img src={images.heart_animated} alt="heart animated" className='w-10 h-10' />}
                            {liked && <span className='ml-3 text-md text-gray-900 tracking-wide'> We’re so glad  🥰</span>}
                    </div>
                </div>

                <div className="p-5 px-16 my-10 rounded-lg shadow-lg card-shadow flex justify-around items-center">
                    <div className="flex flex-col">
                        <h2 className="text-2.5xl font-bold text-gray-900">
                            Subscribe to our <span className="text-darkblue-500">newsletter</span>
                        </h2>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            placeholder="Enter your email address"
                            className={`form-input border hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block lg:w-64 shadow-sm sm:text-sm md:text-lg lg:text-lg border-black-300 rounded-md`}
                        />
                        <button className='bg-darkblue-500 p-3 -ml-3 rounded-r-md rounded-br-md hover:bg-darkblue-400'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                            </svg>
                        </button>
                    </div>
                    {/* <div className="mt-5">
                        <button
                            className={`transition duration-500 hover:scale-105 md:hover:scale-110 flex items-center justify-center px-8 py-2 border border-transparent text-base font-medium rounded-md text-white bg-darkblue-500 hover:bg-darkblue-700 md:text-lg hover:bg-darkblue-700 focus:outline-none`}
                        >
                            Subscribe
                        </button>
                    </div> */}
                </div>

                <div className="text-teal-900 bg-teal-200 p-5 px-16 my-10 rounded-lg shadow-lg card-shadow flex justify-around items-center">
                    <div className="flex flex-col">
                        <h2 className="text-2xl font-bold">
                            Offers
                        </h2>
                    </div>
                </div>
            </div>

            {/* Right Section */}
            <div className={classNames(pinned ? 'lg:pt-24' : 'lg:pt-2' , 'w-full md:w-1/4 lg:w-1/4 top-0 pt-0 px-5')}>
                <div className="flex flex-col mt-5 sm:mt-0">
                    <h1 className='text-md font-extrabold tracking-tight text-gray-900 md:text-xl lg:text-xl'>Suggested Reads:</h1>
                    <hr className='mt-2 w-40 h-4' />
                    {/* <p>{article.name}</p> */}
                    {/* <ul className="ml-3 mt-3 flex flex-col">
                        <li className='pb-2'>
                            <span className='text-gray-900 font-bold'>{'>  '}</span><a href={'#risks'} className="text-gray-900 font-bold tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900 font-bold'>{'>  '}</span><a href={'#risks'} className="text-gray-900 font-bold tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900 font-bold'>{'>  '}</span><a href={'#risks'} className="text-gray-900 font-bold tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900 font-bold'>{'>  '}</span><a href={'#risks'} className="text-gray-900 font-bold tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                        <li className='pb-2'>
                            <span className='text-gray-900 font-bold'>{'>  '}</span><a href={'#risks'} className="text-gray-900 font-bold tracking-wide hover-link">The risks associated with eggs</a>
                        </li>
                    </ul> */}
                    {/* <div class="flex flex-col items-start col-span-12 overflow-hidden shadow-sm rounded-xl md:col-span-6 lg:col-span-4">
                        <a href="#_" class="block transition duration-200 ease-out transform hover:scale-110">
                            <img class="" src="https://placeimg.com/250/250/any" />
                        </a>
                        <div class="relative flex flex-col items-start px-6 bg-white border border-t-0 border-gray-200 py-7 rounded-b-2xl">
                            <div class="bg-purple-500 absolute top-0 -mt-3 flex items-center px-3 py-1.5 leading-none w-auto inline-block rounded-full text-xs font-medium uppercase text-white inline-block">
                                <span>Resources</span>
                            </div>
                            <h2 class="text-base font-bold sm:text-lg md:text-xl"><a href="#_">A Design Mind-set</a></h2>
                            <p class="mt-2 text-sm text-gray-500">What does it mean to have a design mind-set? Learn how to improve your eye for design.</p>
                        </div>
                    </div> */}
                    <div className="overflow-hidden shadow-lg rounded-lg h-68 cursor-pointer m-auto mb-8">
                        <Link to="/" className="w-full block h-full">
                            <img src="https://placeimg.com/100/100/any" alt="blog_thumbnail" className="h-32 w-full object-cover"/>
                            <div class="absolute -mt-3 ml-3 text-center inline-block py-1 px-2 rounded-sm bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">COMPLAINCE</div>
                            <div className="mt-2 bg-white w-full p-4">
                                {/* <p className="text-indigo-500 text-md font-medium">
                                    Video
                                </p> */}
                                <p className="text-gray-800 text-xl font-medium mb-2">
                                    Cleaning of Eggs and its effects. Is cleaning of eggs necessary?
                                </p>
                                <p className="text-sm text-gray-700">
                                    6 min read · 15 people found this useful 
                                </p>
                                {/* <p className="text-gray-400 font-light text-md">
                                    Work at home, remote, is the new age of the job, every person can work at home....
                                </p> */}
                                {/* <div className="flex items-center mt-4">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src="/images/person/6.jpg" className="mx-auto object-cover rounded-full h-10 w-10 "/>
                                    </a>
                                    <div className="flex flex-col justify-between ml-4 text-sm">
                                        <p className="text-gray-800">
                                            Jean Jacques
                                        </p>
                                        <p className="text-gray-400">
                                            20 mars 2029 - 6 min read
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </Link>
                    </div>
                    <div className="overflow-hidden shadow-lg rounded-lg h-68 cursor-pointer m-auto mb-8">
                        <Link to="/" className="w-full block h-full">
                            <img src="https://placeimg.com/100/100/any" alt="blog_thumbnail" className="h-32 w-full object-cover"/>
                            <div class="absolute -mt-3 ml-3 text-center inline-block py-1 px-2 rounded-sm bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">COMPLAINCE</div>
                            <div className="mt-2 bg-white w-full p-4">
                                {/* <p className="text-indigo-500 text-md font-medium">
                                    Video
                                </p> */}
                                <p className="text-gray-800 text-xl font-medium mb-2">
                                    Cleaning of Eggs and its effects. Is cleaning of eggs necessary?
                                </p>
                                <p className="text-sm text-gray-700">
                                    6 min read · 15 people found this useful 
                                </p>
                                {/* <p className="text-gray-400 font-light text-md">
                                    Work at home, remote, is the new age of the job, every person can work at home....
                                </p> */}
                                {/* <div className="flex items-center mt-4">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src="/images/person/6.jpg" className="mx-auto object-cover rounded-full h-10 w-10 "/>
                                    </a>
                                    <div className="flex flex-col justify-between ml-4 text-sm">
                                        <p className="text-gray-800">
                                            Jean Jacques
                                        </p>
                                        <p className="text-gray-400">
                                            20 mars 2029 - 6 min read
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPost