import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { Link } from 'react-router-dom'

const BlogList = () => {
    return (
        <div className="mt-5 md:mt-0 mx-auto max-w-6xl md:py-10 md:px-6">
            {/* Featured Post */}
            {/* <div className='p-6 space-y-5'>
                <div className="py-5 flex flex-col items-start justify-center">
                    <h1 className="text-4xl font-semibold leading-9 text-center text-gray-900">Featured Post</h1>
                </div>
                <div className="mx-auto">
                    <Link to="" className="my-5 block max-w-sm gap-3 mx-auto sm:max-w-full lg:grid lg:grid-cols-12 card-shadow rounded-lg">
                        <img src="https://source.unsplash.com/random/480x360" alt="" className="object-cover w-full rounded h-96 lg:col-span-7" />
                        <div className="p-6 space-y-2 lg:col-span-5">
                            <h3 className="text-2xl font-semibold md:text-4xl">Noster tincidunt reprimique ad pro</h3>
                            <span className="text-sm m-0 text-gray-500">February 19, 2021</span>
                            <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                            <p className="text-md tracking-wide text-gray-800">Ei delenit sensibus liberavisse pri. Quod suscipit no nam. Est in graece fuisset, eos affert putent doctus id.</p>
                        </div>
                    </Link>
                </div>
            </div> */}

            {/* Popular Posts */}
            <div className="p-6 space-y-5">
                <div className="py-5 flex flex-col items-start justify-center">
                    <h1 className="text-4xl font-semibold leading-9 text-center text-gray-900">Popular Posts</h1>
                    <p className="text-center text-gray-600 mt-4">If you're looking for random paragraphs, you've come to the right place. When a random word or a random sentence isn't quite enough</p>
                    {/* <Link to="" className="px-3 py-2 text-md rounded-md bg-darkblue-500 text-white flex items-center">
                        <span>View all</span>
                        <ChevronRightIcon className='ml-1 w-5 h-5 text-white' />
                    </Link> */}
                </div>
                <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                        <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?1" alt=""/>
                        <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-orange-100 text-orange-500 text-xs font-medium tracking-widest">TRAINING</div>
                        <div className="p-6 space-y-2">
                            <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                            <span className="text-sm m-0 text-gray-500">January 21, 2021</span>
                            <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                            <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                        </div>
                    </Link>
                    <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                        <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?2" alt=""/>
                        <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-green-50 text-green-500 text-xs font-medium tracking-widest">FOOD TESTING</div>
                        <div className="p-6 space-y-2">
                            <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                            <span className="text-sm m-0 text-gray-500">January 22, 2021</span>
                            <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                            <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                        </div>
                    </Link>
                    <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                        <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?3" alt=""/>
                        <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">COMPLAINCE</div>
                        <div className="p-6 space-y-2">
                            <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                            <span className="text-sm m-0 text-gray-500">January 23, 2021</span>
                            <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                            <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default BlogList