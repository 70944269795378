import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { images } from '../constants';
import RequestCallbackDialog from './RequestCallbackDialog';

const E2EComplaince = () => {

    const [showRCDialog, setShowRCDialog] = useState(false);

    const bannerImages = [
        images.e2e_purple,
        images.e2e_darkblue,
        images.e2e_green,
        images.e2e_teal,
        images.e2e_blue,
        images.e2e_orange,
        images.e2e_red,
        images.e2e_silver,
        images.e2e_pink
    ];

    const { primaryColor } = useSelector(state => state.app);

    let bgColor = 'bg-gray-200';
    if (primaryColor === 'white') {
        bgColor = `teal`;
    } else {
        bgColor = `${primaryColor}`;
    }
    
    const bannerImage = bannerImages.filter((e) => e.includes(bgColor))[0];

    return (
        <>
            <RequestCallbackDialog show={showRCDialog} onDialogClosed={() => setShowRCDialog(false)} />
            <section class={`py-10 md:py-20 bg-${bgColor}-500`}>
                <div class="container items-center max-w-6xl px-8 md:px-4 mx-auto">
                    <div class="flex flex-wrap items-center -mx-3">
                        <div class="order-1 w-full px-3 lg:w-2/3 lg:order-0">
                            <div class="w-full">
                                <div className='flex justify-center md:justify-start'>
                                    <span class={`text-center md:text-left inline-block py-1 px-3 rounded-sm bg-${bgColor}-100 text-${bgColor}-500 text-xs font-medium tracking-widest`}>COMPLIANCE PACKAGE</span>
                                </div>
                                <h1 class="hidden md:block text-center md:text-left mt-5 mb-4 font-medium tracking-wide text-white text-lg xl:mb-6">Looking for end-to-end compliance package? We got you covered!</h1>
                                <h1 class="md:hidden text-center md:text-left mt-5 mb-4 font-medium tracking-wide text-white text-lg xl:mb-6">Looking for end-to-end compliance?</h1>
                                <h1 class={`text-center md:text-left mb-4 text-3xl font-extrabold leading-tight tracking-tight md:text-5xl font-heading text-white`}>Introducing Aahar Complete Compliance Package!</h1>
                                <div class="md:hidden mt-10 mx-auto w-55 h-55 mb-12 lg:w-1/3 order-0 lg:order-1 lg:mb-0">
                                    <img class="h-48 md:h-auto mx-auto sm:max-w-sm lg:max-w-full" src={bannerImage} alt="feature" />
                                </div>
                                {/* <p class="mb-4 font-medium tracking-wide text-white xl:mb-6">Introducing Aahar Complete Compliance Package!</p> */}
                                {/* <p class="mb-4 font-medium tracking-wide text-white xl:mb-6">Focus on your core businesses and off-load your compliance burden on us! Our team of expert food technologists and FSSAI regulatory experts will manage your compliance end-to-end. </p> */}
                                
                                {/* Desktop */}
                                <ul className='hidden md:block mt-10'>
                                    <li class="flex items-center py-2 xl:py-3">
                                        <div className='hidden md:flex'>
                                            <img src={images.regulatory} alt="regulatory" class="w-8 h-8 -ml-2" />
                                            <span class="ml-4 font-medium tracking-wide text-white text-md md:text-lg">Regulatory : &nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/fssai-food-license">FSSAI License</Link> ,&nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/">FSSAI Regulations</Link>
                                                {/* <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/comingsoon">Annual Returns</Link> ,&nbsp; */}
                                                {/* <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/comingsoon">Label/ Product Review</Link>&nbsp; */}
                                            </span>
                                        </div>
                                        {/* <div className='flex items-start md:hidden'>
                                            <div className='flex items-center'>
                                                <img src={images.regulatory} alt="regulatory" class="w-8 h-8 -ml-2" />
                                                <span class="ml-2 font-medium tracking-wide text-white text-md md:text-lg">Regulatory : &nbsp;</span>
                                            </div>
                                            <span class="font-medium tracking-wide text-white text-md md:text-lg">
                                                <ul>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/fssai-food-license">FSSAI License</Link>
                                                    </li>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/testing-food">Annual Returns</Link>
                                                    </li>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/testing-food">Label/ Product Review</Link>
                                                    </li>
                                                </ul>
                                            </span>
                                        </div> */}
                                    </li>
                                    <li class="flex items-center py-2 xl:py-3">
                                        <div className='hidden md:flex'>
                                            <img src={images.product} alt="regulatory" class="w-6 h-6"/>
                                            <span class="ml-4 font-medium tracking-wide text-white text-md md:text-lg">Product : &nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/testing-food">Food Testing</Link> ,&nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/testing-water">Water Testing</Link>&nbsp;
                                            </span>
                                        </div>
                                        {/* <div className='flex items-start md:hidden'>
                                            <div className="flex items-center">
                                                <img src={images.product} alt="regulatory" class="w-6 h-6" />
                                                <span class="ml-2 font-medium tracking-wide text-white text-md md:text-lg">Product : &nbsp;</span>
                                            </div>
                                            <span class="font-medium tracking-wide text-white text-md md:text-lg">
                                                <ul>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/testing-food">Food Testing</Link>
                                                    </li>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/testing-water">Water Testing</Link>
                                                    </li>
                                                </ul>
                                            </span>
                                        </div> */}
                                    </li>
                                    <li class="flex items-center py-2 xl:py-3">
                                        <div className='hidden md:flex'>
                                            <img src={images.people} alt="regulatory" class="w-6 h-6" />
                                            <span class="ml-4 font-medium tracking-wide text-white text-md md:text-lg">People : &nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/training">FoSTaC Training</Link> ,&nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/medical-fitness">Medical Fitness Certificate</Link>&nbsp;
                                            </span>
                                        </div>
                                        {/* <div className='flex items-start md:hidden'>
                                            <div className="flex items-center">
                                                <img src={images.people} alt="regulatory" class="w-6 h-6" />
                                                <span class="ml-2 font-medium tracking-wide text-white text-md md:text-lg">People : &nbsp;</span>
                                            </div>
                                            <span class="font-medium tracking-wide text-white text-md md:text-lg">
                                                <ul>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/training">FoSTaC Training</Link>
                                                    </li>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/medical-fitness">Medical Fitness</Link>
                                                    </li>
                                                </ul>
                                            </span>
                                        </div> */}
                                    </li>
                                    <li class="flex items-center py-2 xl:py-3">
                                        <div className='hidden md:flex'>
                                            <img src={images.process} alt="regulatory" class="w-6 h-6"/>
                                            <span class="ml-4 font-medium tracking-wide text-white text-md md:text-lg">Process : &nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/pest-control">Pest Control</Link> ,&nbsp;
                                                <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/process">Instrument Calibration</Link>&nbsp;
                                            </span>
                                        </div>
                                        {/* <div className='flex items-start md:hidden'>
                                            <div className="flex items-center">
                                                <img src={images.process} alt="regulatory" class="w-6 h-6" />
                                                <span class="ml-2 font-medium tracking-wide text-white text-md md:text-lg">Process : &nbsp;</span>
                                            </div>
                                            <span class="font-medium tracking-wide text-white text-md md:text-lg">
                                                <ul>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/pest-control">Pest Control</Link>
                                                    </li>
                                                    <li>
                                                        <Link style={{ textUnderlineOffset: '6px' }} className={`md:underline hover:text-${ bgColor }-900`} to="/process">Equipment Calibration</Link>
                                                    </li>
                                                </ul>
                                            </span>
                                        </div> */}
                                    </li>
                                </ul>

                                {/* Mobile */}
                                <div className='md:hidden'>
                                    <div className='mb-3'>
                                        <div className='flex items-center border-b border-white p-3'>
                                            <img src={images.regulatory} alt="regulatory" class="w-6 h-6"/>
                                            <span class="ml-4 font-medium tracking-wide text-white text-lg">Regulatory</span>
                                        </div>
                                        <div className='flex flex-col mt-3 space-y-2 mx-20'>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/fssai-food-license">FSSAI License</Link>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/">FSSAI Regulations</Link>
                                            {/* <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/testing-food">Annual Returns</Link>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/testing-food">Label/ Product Review</Link> */}
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='flex items-center border-b border-white p-3'>
                                            <img src={images.product} alt="regulatory" class="w-6 h-6"/>
                                            <span class="ml-4 font-medium tracking-wide text-white text-lg">Product</span>
                                        </div>
                                        <div className='flex flex-col mt-3 space-y-2 mx-20'>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/testing-food">Food Testing</Link>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/testing-water">Water Testing</Link>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='flex items-center border-b border-white p-3'>
                                            <img src={images.people} alt="regulatory" class="w-6 h-6"/>
                                            <span class="ml-4 font-medium tracking-wide text-white text-lg">People</span>
                                        </div>
                                        <div className='flex flex-col mt-3 space-y-2 mx-20'>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/training">FoSTaC Training</Link>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/medical-fitness">Medical Fitness</Link>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='flex items-center border-b border-white p-3'>
                                            <img src={images.process} alt="regulatory" class="w-6 h-6"/>
                                            <span class="ml-4 font-medium tracking-wide text-white text-lg">Process</span>
                                        </div>
                                        <div className='flex flex-col mt-3 space-y-2 mx-20'>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/pest-control">Pest Control</Link>
                                            <Link style={{ textUnderlineOffset: '6px' }} className={`font-medium tracking-wide text-white text-md hover:text-${ bgColor }-900`} to="/process">Equipment Calibration</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-10 pb-4 md:w-1/3">
                                    <Link to='/completeCompliance' className={`block text-${bgColor}-700 bg-${bgColor}-100 hover:text-${bgColor} hover:bg-${bgColor}-200 mt-5 px-6 py-4 text-lg font-semibold leading-4 text-center rounded-lg shadow-md`}>Get Started</Link>
                                    {/* <button
                                        onClick={() => setShowRCDialog(!showRCDialog)} className={`block text-white bg-${bgColor}-700 hover:text-white hover:bg-${bgColor}-900 mt-5 px-2 py-4 text-sm font-semibold leading-4 text-center rounded-lg`}>Request Callback</button> */}
                                </div>
                            </div>
                        </div>
                        <div class="hidden md:block mx-auto w-55 h-55 mb-12 lg:w-1/3 order-0 lg:order-1 lg:mb-0">
                            <img class="h-48 md:h-auto mx-auto sm:max-w-sm lg:max-w-full" src={bannerImage} alt="feature" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default E2EComplaince