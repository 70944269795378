import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid';
import { foodTestCategory } from '../data/TestingData'

const FootTestingParamsDialog = ({ show, testCategoryIdx , onDialogClosed }) => {

    console.log({ testCategoryIdx, testCategoryIdx });

    const category = foodTestCategory[testCategoryIdx];

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onDialogClosed}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-stretch md:items-center justify-center min-h-full text-center md:px-2 lg:px-4">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        enterTo="opacity-100 translate-y-0 md:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 md:scale-100"
                        leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex text-base text-left transform transition md:max-w-5xl md:px-4 my-8 md:my-2 lg:max-w-7xl">
                                <div className="bg-white rounded-lg mx-5 mt-16">
                                    <div className="flex justify-center items-center">
                                        {/* <h1 className="mt-5 text-2xl text-center font-extrabold text-gray-900">Our Services</h1> */}
                                        <div style={{right: 0}} className="absolute mt-12 md:mt-16 text-right pr-8 md:pr-12 px-4 border border-transparent">
                                            <button
                                                className='focus:outline-none'
                                                onClick={() => onDialogClosed()}
                                            >
                                                <XIcon className='h-5 md:h-8 w-5 md:w-8 text-gray-900' aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="relative mt-10">  
                                        <div className="relative container m-auto px-6 text-gray-500 md:px-12">
                                            <h1 className="text-2xl font-extrabold text-gray-900">{category?.name}</h1>
                                        </div>
                                    </div>
                                    {/* <h1 className="mt-3 text-lg text-center font-extrabold text-gray-900">Individual Services</h1> */}
                                    <div className="relative pt-5 pb-10">  
                                        <div className="relative container m-auto px-6 text-gray-500 md:px-12">
                                            <div className="grid lg:grid-cols-3 gap-4 md:w-8/12 md:mx-auto lg:w-full">
                                                {category?.params.map((e, idx) =>
                                                    <div key={idx} className='flex items-start'>
                                                        <CheckCircleIcon className='mt-1 h-4 w-4 mr-2' color='#58bf4a' aria-hidden="true" style={{flexShrink: 0}}></CheckCircleIcon>
                                                        <p className='text-left tracking-wide text-md text-gray-700'>{e}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="md:hidden absolute w-full text-center pr-10 px-4" style={{bottom: 10}}>
                                        <button
                                            style={{bottom: 0}}
                                            className="absolute top-4 right-4 text-gray-400 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                            onClick={() => onDialogClosed()}
                                        >
                                            <XCircleIcon className='h-8 w-8 mr-2 text-purple-600' aria-hidden="true" />
                                        </button>
                                    </div> */}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default FootTestingParamsDialog;