import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Newsletter from '../components/Newsletter'
import { images } from '../constants'
import { ArrowCircleRightIcon } from '@heroicons/react/solid';
import Faq from '../components/Faq'
import BlogHome from './BlogHome'
import SuggestedBlogs from '../components/SuggestedBlogs'
import { Helmet } from 'react-helmet'
import RequestCallback from '../components/RequestCallback'
import { seo } from '../utils/Utils'

const CompleteCompliance = () => {

    seo({
        title: 'End-To-End FSSAI Compliance Management Platform | Aahar Compliance',
        metaDescription: "Is your business in compliance with FSSAI Regulations? Check your Compliance Score Now! Created by India's top food technologists & food safety consultants.",
        keywords: "aahar compliance, aahar consulting, fssai guidelines, food license, fssai compliance, compliance score, all in one compliance, fssai regulations, complete fssai compliance, food safety consultants",
    });

    const { primaryColor } = useSelector(state => state.app);

    return (
        <>
            <Helmet>
                <meta name="title" content="End-To-End FSSAI Compliance Management Platform | Aahar Compliance" />
                <meta name="description" content="Is your business in compliance with FSSAI Regulations? Check your Compliance Score Now! Created by India's top food technologists & food safety consultants."  />
                <meta name="keywords" content="aahar compliance, aahar consulting, fssai guidelines, food license, fssai compliance, compliance score, all in one compliance, fssai regulations, complete fssai compliance, food safety consultants" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <meta name="author" content="Aahar Consulting Pvt. Ltd." />
            </Helmet>
            <div className="">
                <div className="bg-bottom bg-bottom bg-no-repeat">
                    <div className="min-h-full max-w-6xl mx-auto mt-16 md:mt-24 flex flex-col-reverse lg:flex-row justify-center align-center">
                        <div className="mt-16 px-4">
                            <div className="relative pb-4">
                                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-4">
                                    <div className="text-center lg:text-left">
                                        <div class="text-center inline-block py-1 px-2 rounded-lg bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">END-TO-END COMPLAINCE PACKAGE</div>
                                        <h1 className="mt-2 text-3xl tracking-tight font-bold text-gray-900 md:text-45xl">
                                            <span className="block">Finding it difficult to understand & manage FSSAI Compliance? </span>{' '}
                                            <span className={`block text-${primaryColor}-500`}>Introducing aahar.compliance!</span>
                                        </h1>
                                        {/* <div className="sm:hidden pt-10 pb-6">
                                            <img
                                                className="h-52 object-contain sm:h-60 md:h-90 lg:w-full lg:h-full"
                                                src={images.questions}
                                                alt=""
                                            />
                                        </div> */}
                                        <h2 className="mt-3 tracking-wide text-gray-700 sm:mt-5 sm:text-md sm:mx-auto md:mt-6 md:text-lg lg:mx-0">
                                            <span className="font-normal">
                                                <span className="font-bold text-xl">One-stop solution for all FSSAI Compliance needs.</span>
                                                <br /><br />
                                                <span>
                                                    Aahar Compliance is an all-in-one package designed to manage end-to-end compliance requirements of your food business.
                                                    It includes all the compliance requiements in food license & product regulations, food testing, water testing, food safety supervisor training, food handler medical fitness test, pest control, equipment calibration etc.
                                                </span>
                                            </span>
                                        </h2>
                                        {/* <h2 className="mt-5 tracking-wide text-gray-700 sm:mt-5 sm:text-md sm:mx-auto md:mt-6 md:text-lg lg:mx-0 lg:mt-16">
                                            <span className="font-normal">
                                                <span>Upload your FSSAI license copy and answer a few questions to know more.</span>
                                            </span>
                                        </h2>
                                        <div className="mt-6 hidden md:flex md:justify-center lg:justify-start">
                                            <div className="rounded-md shadow">
                                                <button
                                                    className={`transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-${primaryColor}-500 hover:bg-${primaryColor}-700 md:py-4 md:text-lg md:px-10 focus:outline-none`}
                                                >
                                                    Upload License Copy
                                                </button>
                                            </div>
                                            <div className="mt-3 sm:mt-0 sm:ml-3">
                                                <button
                                                    className={`transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-${primaryColor}-500 text-base font-medium rounded-md text-${primaryColor}-700 bg-${primaryColor}-100 hover:bg-${primaryColor}-200 md:py-4 md:text-lg md:px-10 focus:outline-none`}
                                                >
                                                    Request Callback
                                                    
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden sm:block sm:m-0 pt-2 px-2 w-35 h-50 md:w-5/6 lg:-mr-32" style={{alignSelf: 'center'}}>
                            <img
                                className="h-52 object-contain sm:h-60 md:h-90 lg:w-full lg:h-full"
                                src={images.cc}
                                alt=""
                            />
                            {/* <div className='p-64 border border-gray-500 rounded-full '></div> */}
                        </div>
                    </div>

                    <div className="px-5 md:-mt-10">
                        <RequestCallback topic='Complete Compliance' layoutStyle={'md'} />
                    </div>

                </div>


                <hr className='mt-5' />

                {/* Our Services */}
                <div className="">
                    <div className="min-h-full max-w-6xl mx-4 mx-auto py-6 lg:py-20 bg-white-100">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                {/* <h2 className="text-base text-purple-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
                                    Our Process
                                </h1>
                                <h2 className="mt-4 max-w-2xl text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                    2 easy steps to help you get your fssai licence work done.
                                </h2>
                            </div>
                        </div>
                        <div className={`mt-8 mx-4 flex flex-col md:flex-row justify-between`}>
                            <div className={`m-2 min-h-full md:w-1/2 rounded-lg px-5 py-5 bg-${primaryColor}-400`}>
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center justify-center px-5 mt-2 text-xl md:text-4xl text-purple-400 bg-white font-extrabold rounded-full sm:mx-auto">
                                        Step 1
                                    </div>
                                    <h1 className="mt-3 text-2xl md:text-4xl text-center font-extrabold text-white">
                                        Compliance Audit
                                    </h1>
                                    <img className='w-64 h-64' src={images.img_2} alt="" />
                                    <div className='px-4 md:px-16'>
                                        <div className="text-left">
                                            <p className="text-md tracking-wide text-white md:mx-auto lg:mx-auto">
                                                Focus on your core businesses and let our team of experts manage the compliance.
                                            </p>
                                        </div>
                                        <div className="mt-3 text-left">
                                            <div className="mb-2 flex flex-row justify-start items-center">
                                                <div className=''>
                                                    <ArrowCircleRightIcon className='h-5 w-5 text-white' aria-hidden="true"></ArrowCircleRightIcon>
                                                </div>
                                                <p className="ml-2 text-md text-white">Off-load your burden!</p>
                                            </div>
                                            <div className="mb-2 flex flex-row justify-start items-center">
                                                <div className=''>
                                                    <ArrowCircleRightIcon className='h-5 w-5 text-white' aria-hidden="true"></ArrowCircleRightIcon>
                                                </div>
                                                <p className="ml-2 text-md text-white">Focus on your core businesses </p>
                                            </div>
                                            <div className="mb-2 flex flex-row justify-start items-center">
                                                <div className=''>
                                                    <ArrowCircleRightIcon className='h-5 w-5 text-white' aria-hidden="true"></ArrowCircleRightIcon>
                                                </div>
                                                <p className="ml-2 text-md text-white">We will work alongsie your team and.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="px-3 pb-2 flex flex-row justify-center">
                                        <Link to="/comingsoon" className={`block text-white bg-${primaryColor}-500 hover:text-white hover:bg-${primaryColor}-600 mt-4 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md`}>Explore Tool</Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className={`m-2 min-h-full md:w-1/2 bg-teal-500 rounded-lg px-5 py-5`}>
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center justify-center px-5 mt-2 text-xl md:text-4xl font-extrabold rounded-full text-teal-500 bg-purple-50 sm:mx-auto">
                                        Step 2
                                    </div>
                                    <h1 className="mt-5 text-2xl md:text-4xl text-center font-extrabold text-white">
                                        Aahar Assistance
                                    </h1>
                                    <img className='w-64 h-64' src={images.img_2} alt="" />
                                    <div className='px-4 md:px-16'>
                                        <div className="text-left">
                                            <p className="text-md tracking-wide text-white md:mx-auto lg:mx-auto">
                                                Focus on your core businesses and let our team of experts manage the compliance.
                                            </p>
                                        </div>
                                        <div className="mt-3 text-left">
                                            <div className="mb-2 flex flex-row justify-start items-center">
                                                <div className=''>
                                                    <ArrowCircleRightIcon className='h-5 w-5 text-white' aria-hidden="true"></ArrowCircleRightIcon>
                                                </div>
                                                <p className="ml-2 text-md text-white">Off-load your burden!</p>
                                            </div>
                                            <div className="mb-2 flex flex-row justify-start items-center">
                                                <div className=''>
                                                    <ArrowCircleRightIcon className='h-5 w-5 text-white' aria-hidden="true"></ArrowCircleRightIcon>
                                                </div>
                                                <p className="ml-2 text-md text-white">Focus on your core businesses </p>
                                            </div>
                                            <div className="mb-2 flex flex-row justify-start items-center">
                                                <div className=''>
                                                    <ArrowCircleRightIcon className='h-5 w-5 text-white' aria-hidden="true"></ArrowCircleRightIcon>
                                                </div>
                                                <p className="ml-2 text-md text-white">We will work alongsie your team and.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="px-3 pb-2 flex flex-row justify-center">
                                        <Link to="/comingsoon" className={`block text-white bg-${primaryColor}-500 hover:text-white hover:bg-${primaryColor}-600 mt-4 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md`}>Explore Tool</Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                {/* Our Process */}
                <div className="min-h-full max-w-6xl mx-auto py-6 lg:py-20 bg-white">
                    {/* <div className="px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h1 className={`text-4xl tracking-tight font-extrabold text-${primaryColor}-500 sm:text-5xl md:text-5xl`}>
                                Pricing
                            </h1>
                            <h2 className="mt-4 max-w-2xl text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                4 easy steps to help you get your fssai licence work done.
                            </h2>
                        </div>
                    </div> */}
                    {/* <div className="mt-10 grid gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-4">
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mt-5 mx-auto h-40 object-contain"
                                        src={images.requirement} alt="" />
                                    <div className="mt-8 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Share Requirements
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-0 text-semibold tracking-wide text-gray-700">
                                            Speak with one of our FSSAI Licence experts and detail your requirement/problem.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-48 object-contain"
                                        src={images.online_payment_1} alt="" />
                                    <div className="mt-5 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Online Payment
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-0 text-semibold tracking-wide text-gray-700">
                                            Before we begin the licence procedure, you must make a payment online.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-48 object-contain"
                                        src={images.submit_docs} alt="" />
                                    <div className="mt-5 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Submit Documents 
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-0 text-semibold tracking-wide text-gray-700">
                                            Provide all the necessary documents required for FSSAI licence application.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-48 object-contain"
                                        src={images.relax_1} alt="" />
                                    <div className="mt-5 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Sit Back & Relax
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-0 text-semibold tracking-wide text-gray-700">
                                            Our Licence specialists will file the application and keep you updated throughout the process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mx-4 px-2">
                        <div className="sm:text-center">
                            <div className='text-center'>
                                <p className="inline-block px-3 py-2 mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase bg-teal-200">
                                    Our Pricing
                                </p>
                            </div>
                            <div className="text-center">
                                <h1 className={`text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl`}>
                                    <span className={`text-${primaryColor}-500`}>Audit</span> pricing. Pay as you grow.
                                </h1>
                                <h2 className="mt-4 max-w-2xl text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                    4 easy steps to help you get your fssai licence work done.
                                </h2>
                            </div>
                        </div>
                        <div className="mt-10 grid gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
                            <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded-lg shadow-md sm:items-center hover:shadow">
                                <div className="text-center">
                                    <div className="text-lg font-semibold">Start</div>
                                    <div className="flex items-center justify-center mt-2">
                                        <div className="mr-1 text-5xl font-bold">Free</div>
                                    </div>
                                    <div className="mt-2 space-y-3">
                                        <div className="text-gray-700">10 deploys per day</div>
                                        <div className="text-gray-700">10 GB of storage</div>
                                        <div className="text-gray-700">20 domains</div>
                                    </div>
                                </div>
                                <div>
                                    <a href="/" className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 text-purple-700 bg-purple-100 hover:bg-purple-200 border border-purple-500 rounded-lg shadow-md focus:shadow-outline focus:outline-none">
                                        Start for free
                                    </a>
                                    <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                                        Sed ut unde omnis iste natus accusantium doloremque.
                                    </p>
                                </div>
                            </div>
                            <div className="relative flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded-lg shadow-md sm:items-center hover:shadow border-purple-500">
                                <div className="absolute inset-x-0 top-0 flex justify-center -mt-3">
                                    <div className="inline-block px-3 py-1 text-xs font-medium tracking-wider text-white uppercase rounded-lg bg-purple-500">
                                        Most Popular
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div className="text-lg font-semibold">Pro</div>
                                    <div className="flex items-center justify-center mt-2">
                                        <div className="mr-1 text-5xl font-bold">$38</div>
                                        <div className="text-gray-700">/ mo</div>
                                    </div>
                                    <div className="mt-2 space-y-3">
                                        <div className="text-gray-700">200 deploys per day</div>
                                        <div className="text-gray-700">80 GB of storage</div>
                                        <div className="text-gray-700">Global CDN</div>
                                    </div>
                                </div>
                                <div>
                                    <a href="/" className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 rounded-lg shadow-md bg-purple-500 hover:bg-purple-700 focus:shadow-outline focus:outline-none">
                                        Buy Pro
                                    </a>
                                    <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                                        Sed ut unde omnis iste natus accusantium doloremque.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded-lg shadow-md sm:items-center hover:shadow">
                                <div className="text-center">
                                    <div className="text-lg font-semibold">Business</div>
                                    <div className="flex items-center justify-center mt-2">
                                        <div className="mr-1 text-5xl font-bold">$78</div>
                                        <div className="text-gray-700">/ mo</div>
                                    </div>
                                    <div className="mt-2 space-y-3">
                                        <div className="text-gray-700">500 GB of storage</div>
                                        <div className="text-gray-700">Unlimited domains</div>
                                        <div className="text-gray-700">24/7 Support</div>
                                    </div>
                                </div>
                                <div>
                                    <a href="/" className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 text-purple-700 bg-purple-100 hover:bg-purple-200 border border-purple-500 rounded-lg shadow-md focus:shadow-outline focus:outline-none">
                                        Buy Business
                                    </a>
                                    <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                                        Sed ut unde omnis iste natus accusantium doloremque.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className="min-h-full max-w-6xl mx-auto py-6 lg:py-20 bg-white">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h1 className={`text-4xl tracking-tight font-extrabold text-${primaryColor}-500 sm:text-5xl md:text-5xl`}>
                                Pricing
                            </h1>
                            <h2 className="mt-4 max-w-2xl text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                4 easy steps to help you get your fssai licence work done.
                            </h2>
                        </div>
                    </div>
                    <div className="mt-10 mx-4">
                        <section className="card-w-border text-gray-100 rounded-lg shadow-lg" style={{backgroundColor: '#8c52FF'}}>
                            <div className="container mx-auto p-3 md:p-5 overflow-x-auto">
                                <table className="w-full">
                                    {/* <caption className="sr-only">Pricing plan comparison</caption> */}
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th scope="col">
                                                <h2 className="px-2 text-2xl font-bold">Starter</h2>
                                                <p className="mb-3">
                                                    {/* <span className="text-2xl font-bold sm:text-4xl dark:text-gray-50">0€</span>
                                                    <span className="font-bold dark:text-gray-400">/mo</span> */}
                                                    &nbsp;
                                                </p>
                                            </th>
                                            <th scope="col">
                                                <h2 className="px-2 text-2xl font-bold">Standard</h2>
                                                <p className="mb-3">
                                                    {/* <span className="text-2xl font-bold sm:text-4xl dark:text-gray-50">19€</span>
                                                    <span className="font-bold dark:text-gray-400">/mo</span> */}
                                                    &nbsp;
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="pt-5 space-y-6 text-center divide-y divide-white">
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">FSSAI License
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per license)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 15000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / license</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 3 licenses</span>
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 10000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / license</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 10 licenses</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">Label Review
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per label)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 3000
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / label</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 10 labels</span>
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 10000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / license</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 10 licenses</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">Food Testing
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per product)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 8000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / product</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 10 products</span>
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 5000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / product</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 25 products</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">Water Testing
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per product)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 8000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / product</span>
                                                </span>
                                                {/* <span className="block text-xs md:text-sm">Upto 10 products</span> */}
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 5000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / product</span>
                                                </span>
                                                {/* <span className="block text-xs md:text-sm">Upto 25 products</span> */}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">Health Checkup
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per employee)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 1000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / employee</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 25 employees</span>
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 750 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / employee</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 100 employees</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">Pest Control
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per sqft)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 50 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / sqft</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 1000 sqft</span>
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 25
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / sqft</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 5000 sqft</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="text-left">
                                                <h3 className="py-3 md:text-lg">Calibration
                                                    <span className="block md:hidden text-xs md:text-sm font-normal"> (per equipment)</span>
                                                </h3>
                                            </th>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 1000 
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / equipment</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 10 equipments</span>
                                            </td>
                                            <td className='py-3'>
                                                <span className="block text-md md:text-lg font-bold">₹ 750
                                                    <span className="hidden md:inline-block text-xs md:text-sm font-normal"> / equipment</span>
                                                </span>
                                                <span className="block text-xs md:text-sm">Upto 25 equipments</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </section>
                    </div>
                </div>

                {/* Why are we better */}
                <div className={`bg-${primaryColor}-100`}>
                    <div className="min-h-full max-w-6xl mx-auto py-6 lg:py-20 bg-transparent">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                {/* <h2 className="text-base text-${primaryColor}-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                <h1 className={`text-4xl tracking-tight font-extrabold text-${primaryColor}-500 sm:text-5xl md:text-5xl`}>
                                    Why are we better?
                                </h1>
                                {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                    Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                    accusamus quisquam.
                                </p> */}
                            </div>
                        </div>
                        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                            <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
                                <div className='md:mr-10'>
                                    <img
                                        className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                                        src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                                        alt=""
                                    />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="max-w-xl mb-6">
                                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                        The quick, brown fox
                                        <br className="hidden md:block" />
                                        jumps over{' '}
                                        <span className="relative px-1">
                                            <div className="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400" />
                                            <span className="relative inline-block text-deep-purple-accent-400">
                                            a lazy dog
                                            </span>
                                        </span>
                                        </h2>
                                        <p className="text-base text-gray-700 md:text-lg">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                        quae. explicabo.
                                        </p>
                                    </div>
                                    <p className="mb-4 text-sm font-bold tracking-widest uppercase">
                                        Features
                                    </p>
                                    <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
                                        <ul className="space-y-3">
                                        <li className="flex">
                                            A slice of heaven
                                        </li>
                                        <li className="flex">
                                            Disrupt inspire
                                        </li>
                                        <li className="flex">
                                            Preliminary thinking
                                        </li>
                                        </ul>
                                        <ul className="space-y-3">
                                        <li className="flex">
                                            Flipboard curmudgeon
                                        </li>
                                        <li className="flex">
                                            Storage shed
                                        </li>
                                        <li className="flex">
                                            Satoshi Nakamoto
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                            <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
                                <div className='md:hidden'>
                                    <img
                                        className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                                        src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                                        alt=""
                                    />
                                </div>
                                <div className="flex flex-col justify-center">
                                    <div className="max-w-xl mb-6">
                                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                        The quick, brown fox
                                        <br className="hidden md:block" />
                                        jumps over{' '}
                                        <span className="relative px-1">
                                            <div className="absolute inset-x-0 bottom-0 h-3 transform -skew-x-12 bg-teal-accent-400" />
                                            <span className="relative inline-block text-deep-purple-accent-400">
                                            a lazy dog
                                            </span>
                                        </span>
                                        </h2>
                                        <p className="text-base text-gray-700 md:text-lg">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                        accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                        quae. explicabo.
                                        </p>
                                    </div>
                                    <p className="mb-4 text-sm font-bold tracking-widest uppercase">
                                        Features
                                    </p>
                                    <div className="grid space-y-3 sm:gap-2 sm:grid-cols-2 sm:space-y-0">
                                        <ul className="space-y-3">
                                        <li className="flex">
                                            A slice of heaven
                                        </li>
                                        <li className="flex">
                                            Disrupt inspire
                                        </li>
                                        <li className="flex">
                                            Preliminary thinking
                                        </li>
                                        </ul>
                                        <ul className="space-y-3">
                                        <li className="flex">
                                            Flipboard curmudgeon
                                        </li>
                                        <li className="flex">
                                            Storage shed
                                        </li>
                                        <li className="flex">
                                            Satoshi Nakamoto
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='hidden md:flex'>
                                    <img
                                        className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
                                        src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <hr className='bg bg-gray-200' />
                <Faq />

                <hr className='bg bg-gray-200' />
                <SuggestedBlogs />
                
                <hr className='bg bg-gray-200' />
                <Newsletter />
                
            </div>
        </>
    )
}

export default CompleteCompliance