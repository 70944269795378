import { XIcon } from '@heroicons/react/solid';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { images } from '../constants';
import { classNames } from '../utils/Utils';

const NotFound = () => {

    const services = [
        // {
        //     name: 'End-to-End Package',
        //     description: 'One-stop solution for all your queries',
        //     imageSrc: images.compliance_dark,
        //     imageAlt: '',
        //     navigation: '',
        //     items: [],
        //     background: {backgroundColor: '#b2f5ea'}
        // },
        {
            name: 'FSSAI License',
            description: 'New food license, license modification, renewal etc.',
            imageSrc: images.license_dark,
            imageAlt: '',
            navigation: '/fssai-food-license',
            items: [],
            background: { backgroundColor: '#e2d4ff' }
        },
        {
            name: 'FSSAI Regulatory',
            description: 'Annual returns, lable/product review, export advisory etc.',
            imageSrc: images.regulatory_dark,
            imageAlt: '',
            navigation: '/comingsoon',
            items: [],
            background: { backgroundColor: '#e2d4ff' }
        },
        {
            name: 'Food Testing',
            description: 'Microbial testing, nutritional analysis, shelf life testing etc.',
            imageSrc: images.food_testing_dark,
            imageAlt: '',
            navigation: '/testing-food',
            items: [],
            background: { backgroundColor: '#e7ebef' }
        },
        {
            name: 'Water Testing',
            description: 'Potable, mineral, packaged drinking water testing.',
            imageSrc: images.water_testing_dark,
            imageAlt: '',
            navigation: '/testing-water',
            items: [],
            background: { backgroundColor: '#e2d4ff' }
        },
        {
            name: 'FoSTaC Training',
            description: 'Basic & advance food safety supervisor training',
            imageSrc: images.certificate_dark,
            imageAlt: '',
            navigation: '/training',
            items: [],
            background: { backgroundColor: '#feebc8' }
        },
        {
            name: 'Health Checkup',
            description: 'Medical fitness test for food handlers.',
            imageSrc: images.health_dark,
            imageAlt: '',
            navigation: '/medical-fitness',
            items: [],
            background: { backgroundColor: '#f4ced4' }
        },
        {
            name: 'Pest Control',
            description: 'Pest control as per the regulation.',
            imageSrc: images.pest_control_dark,
            imageAlt: '',
            navigation: '/pest-control',
            items: [],
            background: { backgroundColor: '#f4ced4' }
        },
        {
            name: 'Instrument Calibration',
            description: 'Instrument calibration as per the regulation.',
            imageSrc: images.calibration_dark,
            imageAlt: '',
            navigation: '/process',
            items: [],
            background: { backgroundColor: '#f4ced4' }
        },
    ];
  
    return (
        <>
            <Helmet>
                <title>404! Page Not Found</title>
                <meta name="description" content="Page Not Found" />
            </Helmet>
            <div className="max-w-6xl mx-auto">
                <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                    <div className="mt-10 mx-auto max-w-6xl px-4 mt-20 sm:px-6 md:mt-16 lg:mt-32 lg:px-8">
                        <div className="text-center">
                            <div className="mt-10 flex justify-center">
                                <img className='h-56' src={images.not_found} alt='not-found' />
                            </div>  
                            <div className="mt-10 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-6xl">
                                <p className="block xl:inline"><span className='text-darkblue-500'>Oops!</span></p>{' '}
                            </div>
                            <p className="font-bold text-gray-700 sm:text-lg sm:mx-auto md:mt-2 md:text-xl">404 - Page Not Found.</p>
                            <p className="mt-2 tracking-wider text-gray-700 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                The page you're looking for no longer exists! Return to the
                                <Link to='/' className='text-darkblue-500 font-bold'> home page </Link>
                            </p>
                        </div>

                        <div className="bg-white rounded-lg mx-5 mt-16">
                                    {/* <div className="flex justify-center items-center">
                                        <div style={{right: 0}} className="absolute mt-12 md:mt-16 text-right pr-8 md:pr-12 px-4 border border-transparent">
                                            <button
                                                className='focus:outline-none'
                                            >
                                                <XIcon className='h-5 md:h-8 w-5 md:w-8 text-gray-900' aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div> */}
                                    <div className="relative mt-10">  
                                        <div className="relative container m-auto px-6 text-gray-500 md:px-12">
                                            <div className="group bg-purple-200 rounded-lg shadow-lg px-5 pt-6 pb-5 text-left" style={{ borderRadius: '1rem' }}>
                                                <div className="flex flex-col md:flex-row justify-around items-center md:w-8/12 md:mx-auto lg:w-full">
                                                    <div className='sm:hidden md:w-36' style={{ flexShrink: 0, width: '7.5rem'}}>
                                                        <img className="w-20 object-center object-cover mx-auto" src={images.compliance_dark} alt="illustration" loading="lazy" />
                                                    </div>
                                                    <div className="mt-5 md:mt-0 flex flex-col items-center">
                                                        <h1 className="text-2xl font-extrabold text-gray-900 text-center">Complete Compliance Package</h1>
                                                        <h3 className="mt-2 text-md text-gray-900 tracking-wide">
                                                            Sit back & relax and let our team manage all your FSSAI compliance requirements end-to-end.
                                                        </h3>
                                                        <div className='mt-5 md:w-40 bg-purple-500 hover:bg-purple-700 flex justify-center items-center px-4 p-2 border border-transparent text-base rounded-lg text-white focus:outline-none'>
                                                            <Link
                                                                to="/completeCompliance"
                                                                className="text-sm"
                                                            >
                                                                Get Started
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    {/* <div className='mt-5 md:mt-2 self-end' style={{flexShrink: 0}}>
                                                        <ArrowCircleRightIcon
                                                            className="text-teal-500 h-16 w-16" aria-hidden="true">
                                                        </ArrowCircleRightIcon> 
                                                    </div> */}
                                                    {/* <div className='mt-5 md:mt-0 md:w-40 bg-teal-500 hover:bg-teal-700 flex justify-center items-center px-4 p-2 border border-transparent text-base rounded-lg text-white focus:outline-none'>
                                                        <Link
                                                            to="/"
                                                            onClick={(e) => onDialogClosed()}
                                                            className="text-sm"
                                                        >
                                                            Get Started
                                                        </Link>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='mt-10 mx-5 bg bg-gray-200' />
                                    <h1 className="my-3 text-2xl text-center font-extrabold text-gray-900">Individual Services</h1>
                                    <div className="relative pt-2 pb-10">  
                                        <div className="relative container m-auto px-6 text-gray-500 md:px-12">
                                            <div className="grid lg:grid-cols-3 gap-6 md:w-8/12 md:mx-auto lg:w-full">
                                                {services.map((e, idx) =>
                                                    <div
                                                        key={e.name}
                                                        className={
                                                            classNames(idx === 0 ? 'bg-darkblue-200'
                                                                : idx === 1 ? 'bg-darkblue-200'
                                                                : idx === 2 ? 'bg-green-200'
                                                                : idx === 3 ? 'bg-teal-200' 
                                                                : idx === 4 ? 'bg-orange-200'
                                                                : idx === 5 ? 'bg-red-200'
                                                                : idx === 6 ? 'bg-silver-200'
                                                                : idx === 7 ? 'bg-pink-200' : '', "group bg-white rounded-lg shadow-lg px-5 pt-6 pb-5 text-left")} style={{ borderRadius: '1rem' }}>
                                                        <img className="w-8 object-center object-contain" src={e.imageSrc} alt="illustration" loading="lazy" />
                                                        <h1 className="mt-2 text-lg font-extrabold text-gray-900">{e.name}</h1>
                                                        <h3 className="text-md text-gray-900 tracking-wide">{e.description}</h3>
                                                        <div className='mt-5 md:mt-2 group flex justify-start md:justify-end items-end'>
                                                            <div
                                                                className={
                                                                    classNames(idx === 0 ? 'bg-darkblue-500 hover:bg-darkblue-700'
                                                                        : idx === 1 ? 'bg-darkblue-500 hover:bg-darkblue-700'
                                                                        : idx === 2 ? 'bg-green-500 hover:bg-green-700'
                                                                        : idx === 3 ? 'bg-teal-500 hover:bg-teal-700' 
                                                                        : idx === 4 ? 'bg-orange-500 hover:bg-orange-700'
                                                                        : idx === 5 ? 'bg-red-500 hover:bg-red-700'
                                                                        : idx === 6 ? 'bg-silver-500 hover:bg-silver-700'
                                                                        : idx === 7 ? 'bg-pink-500 hover:bg-pink-700' : '','flex justify-center items-center w-28 block px-4 p-2 border border-transparent text-base rounded-lg text-white focus:outline-none')}>
                                                                <Link
                                                                    to={e.navigation}
                                                                    className="text-sm"
                                                                >
                                                                    {e.navigation !== "" ? 'Get Started' :'Coming Soon'}
                                                                </Link>
                                                                {/* <ArrowSmRightIcon
                                                                    className="text-purple-500 group-hover:text-white h-6 w-6" aria-hidden="true">
                                                                </ArrowSmRightIcon>  */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="md:hidden absolute w-full text-center pr-10 px-4" style={{bottom: 10}}>
                                        <button
                                            style={{bottom: 0}}
                                            className="absolute top-4 right-4 text-gray-400 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                            onClick={() => onDialogClosed()}
                                        >
                                            <XCircleIcon className='h-8 w-8 mr-2 text-purple-600' aria-hidden="true" />
                                        </button>
                                    </div> */}
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;