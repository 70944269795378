import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { requestCallback } from '../actions/marketing';

const RequestCallback = ({topic, layoutStyle}) => {

    console.log('Request Callback Topic : ', topic);

    const layout = layoutStyle === null ? 'lg' : layoutStyle;

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    };

    const { primaryColor } = useSelector(state => state.app);
    
    let pColor = 'darkblue';
    if (primaryColor === 'white') {
        pColor = 'darkblue'
    } else {
        pColor = primaryColor;
    }

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  
        name: '',
        email: '',
        phoneNo: '',
        topic: topic || ''
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
                setFormData({name: '', email: '', phoneNo: ''})
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Request Callback!');
        console.log('Form Data : ', formData);
        dispatch(requestCallback(formData));
        setShowMessage(true);
    };

    return (
        <div className='mt-5 py-5 flex flex-col justify-start'>
            <form onSubmit={onSubmit}>
                <div className="flex flex-col items-stretch md:flex-row lg:flex-row md:justify-center lg:justify-center">
                    <div className="pb-5">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={formData.name}
                            onChange={onChange}
                            autoComplete="name"
                            placeholder="Enter your name"
                            className={classNames(
                                layout === 'lg' ? 'form-input-lg' : 'form-input',
                                `py-3 lg:w-64 border border-gray-300 hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`
                            )}
                            required
                        />
                    </div>
                    <div className="pb-5 md:pl-5 lg:pl-5">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={onChange}
                            autoComplete="email"
                            placeholder="Enter your email"
                            className={classNames(
                                layout === 'lg' ? 'form-input-lg' : 'form-input',
                                `py-3 lg:w-64 border border-gray-300 hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`
                            )}
                            required
                        />
                    </div>
                    <div className="pb-5 md:pl-5 lg:pl-5">
                        <input
                            type="tel"
                            name="phoneNo"
                            id="phoneNo"
                            value={formData.phoneNo}
                            onChange={onChange}
                            maxLength="10"
                            autoComplete="tel"
                            placeholder="Enter your phone no"
                            className={classNames(
                                layout === 'lg' ? 'form-input-lg' : 'form-input',
                                `py-3 lg:w-60 border border-gray-300 hover:border-gray-400 focus:ring-${pColor}-500 focus:border-${pColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`
                            )}
                            required
                        />
                    </div>
                    <div className="md:hidden mb-2 flex items-start">
                        <input
                            id="send-communications"
                            name="send-communications"
                            type="checkbox"
                            defaultChecked={true}
                            className={`h-3 w-3  bg-${ pColor }-600 mt-1 text-${ pColor }-500 rounded`}
                            style={{flexShrink: 0}}
                        />
                        <label htmlFor="send-communications" className="ml-2 text-sm text-left text-gray-900">
                            By clicking on request callback, I provide my consent to receive communications from Aahar. Check our 
                            <Link to="/terms" className={`text-${pColor}-500 hover:text-${pColor}-700`}> terms & conditions </Link> .
                        </label>
                    </div>
                    <div className="md:pl-5 lg:pl-5">
                        <button type='submit'
                            className={classNames(
                                layoutStyle === 'lg' ? 'md:px-10 lg:px-18 py-4' : 'md:px-6 py-3',
                                `p-3 block text-white bg-${pColor}-500 hover:text-white hover:bg-${pColor}-600 text-sm font-semibold text-center rounded-lg shadow-md`
                            )}>Request Callback</button>
                    </div>
                </div>
            </form>
            {!showMessage && <div className="hidden md:flex justify-center mt-3 items-center">
                <input
                    id="send-communications"
                    name="send-communications"
                    type="checkbox"
                    defaultChecked={true}
                    className={`h-3 w-3 bg-${ pColor }-600 text-${ pColor }-500 rounded`}
                    style={{flexShrink: 0}}
                />
                <label htmlFor="send-communications" className="ml-2 text-sm text-gray-900">
                    By clicking on request callback, I provide my consent to receive communications from Aahar. Check our
                    <Link to="terms" className={`text-${pColor}-500 hover:text-${pColor}-700`}> terms & conditions </Link> .
                </label>
            </div>}
            {showMessage && <div className="mt-5 flex justify-center">
                <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                    <span className='flex items-center'>
                        <CheckCircleIcon className={`w-5 h-5 text-${pColor}-500`} />
                        <span className='ml-2'> {state?.requestCallback?.msg} </span>
                    </span>
                </div>
            </div>}
        </div>
    )
}

export default RequestCallback