import React, { useEffect } from 'react';
import { useState } from 'react';
// import { ThemeContext } from './context/themeContext';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HeaderBanner from './components/HeaderBanner';
import LandingActions from './components/LandingAction';
import Footer from './layouts/Footer';
// import Navbar from './layouts/Navbar1';
// import Landing from './pages/Landing1';
import Navbar from './layouts/Navbar';
import Admin from './pages/admin';
import BlogHome from './pages/BlogHome';
import Blogs from './pages/Blogs';
import BreadCrumb from './pages/BreadCrumb';
import Careers from './pages/Careers';
import Checkout from './pages/Checkout';
import ComingSoon from './pages/ComingSoon';
import CompleteCompliance from './pages/CompleteCompliance';
import ContactUs from './pages/ContactUs';
import ExploreTool from './pages/ExploreTool';
import FoodTesting from './pages/FoodTesting';
import Landing from './pages/Landing';
import MedicalFitness from './pages/MedicalFitness';
import NotFound from './pages/NotFound';
import Page from './pages/Page';
import PestControl from './pages/PestControl';
import Privacy from './pages/Privacy';
import Process from './pages/Process';
import RegulatoryServices from './pages/RegulatoryServices';
import ServicePlanDetails from './pages/ServicePlanDetails';
import ServicePlans from './pages/ServicePlans';
import Splash from './pages/Splash';
import Testing from './pages/Testing';
import Training from './pages/Training';
import TrainingDetails from './pages/TrainingDetails';
import WaterTesting from './pages/WaterTesting';
import ScrollToTop from './utils/ScrollToTop';

import Headroom from 'react-headroom';
import { StickyContainer, Sticky } from 'react-sticky';
import BlogList from './pages/BlogList';
import TermsAndConditions from './pages/TermsAndConditions';
import Disclaimer from './pages/Disclaimer';

function App() {
  // const { theme, toggleTheme } = useContext(ThemeContext);

  // sitemap.toString();
  const [pinned, setPinned] = useState();
  const [path, setPath] = useState();
  const [showHeader, setShowHeader] = useState();
  const [showFooter, setShowFooter] = useState();

  useEffect(() => {
    if (path && path.includes('/admin')) {
      setShowHeader(false);
      setShowFooter(false);
    } else {
      setShowHeader(true);
      setShowFooter(true);
    }
  }, [path]);

  return (
    // <div className="h-screen overflow-y-auto dark:bg-gray-900">
    // <div className="h-screen relative bg-white overflow-hidden overflow-y-auto">
    <div className="h-screen w-full absolute">
      <Router>
        {/* <HeaderBanner /> */}
        
        {showHeader &&
          // <Headroom
          //   onPin={() => setPinned(true)}
          //   onUnfix={() => console.log('onUnfix')}
          //   onUnpin={() => setPinned(false)}
          //   style={{
          //     webkitTransition: 'all .5s ease-in-out',
          //     mozTransition: 'all .5s ease-in-out',
          //     oTransition: 'all .5s ease-in-out',
          //     transition: 'all .5s ease-in-out'
          //   }}
          // >
          //   <Navbar />
          // </Headroom>
          <Navbar path={path} />
        }
        {/* <ScrollPosition /> */}
        <ScrollToTop path={setPath} />
        {/* <BreadCrumb /> */}
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path="/services" element={<ServicePlans />} /> */}
          {/* <Route path="/services/:service" element={<ServicePlans />} /> */}
          {/* <Route path="/service-info" element={<ServicePlanDetails />} /> */}
          {/* <Route path="/service-info/:service" element={<ServicePlanDetails />} /> */}
          <Route path="/fssai-food-license" element={<ServicePlans />} />
          <Route path="/fssai-food-license-new" element={<ServicePlanDetails />} />
          <Route path="/fssai-food-license-renewal" element={<ServicePlanDetails />} />
          <Route path="/fssai-food-license-modification" element={<ServicePlanDetails />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/rs" element={<RegulatoryServices />} />
          <Route path="/page" element={<Page />} />
          <Route path="/career" element={<Careers />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/exploretool" element={<ExploreTool />} />
          <Route path="/splash" element={<Splash />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/bloglist" element={<BlogList />} />
          <Route path="/bloghome" element={<BlogHome />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/completeCompliance" element={<CompleteCompliance />} />
          <Route path="/training" element={<Training />} />
          <Route path="/trainingDetails" element={<TrainingDetails />} />
          <Route path="/testing-food" element={<FoodTesting />} />
          <Route path="/testing-water" element={<WaterTesting />} />
          <Route path="/medical-fitness" element={<MedicalFitness />} />
          <Route path="/pest-control" element={<PestControl />} />
          <Route path="/process" element={<Process />} />
        </Routes>
        {showFooter && <Footer />}
        <div className='md:hidden lg:hidden xl:hidden'>
          <LandingActions path={path} />
        </div>
      </Router>
    </div>
  )
}

export default App
