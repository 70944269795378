import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { classNames } from '../utils/Utils';

const Timeline = ({ type, data, color }) => {
    return (
        <div className="py-6 flex flex-col justify-center sm:py-12">
            <div className="py-3 sm:max-w-xl sm:mx-auto w-full px-2 sm:px-0">
                <div className="relative text-gray-700 antialiased text-sm font-semibold">
                    {/* <!-- Vertical bar running through middle --> */}
                    <div className={classNames(color ? `bg-${color}-300` : 'bg-purple-300', "hidden sm:block w-1 absolute h-full left-1/2")}></div>
                    {data.map((e) => <div key={e} className="mt-6 sm:mt-0 sm:mb-8">
                        <div className="flex flex-col sm:flex-row items-center">
                            <div className="flex justify-start w-full mx-auto items-center">
                                <div className="w-full sm:w-4/5 lg:ml-10 card-w-border-wo-shadow border-gray-400">
                                    <h3 className='px-3 flex justify-between tracking-wide text-md md:text-lg text-gray-900'>
                                        {e}
                                    </h3>
                                </div>
                            </div>
                            <div className="rounded-full bg-white border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                                <CheckCircleIcon className='ml-0.5 h-8 w-8' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default Timeline