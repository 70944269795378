import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation,  } from 'react-router-dom';
import { apps } from '../reducer/appReducer';

const ScrollToTop = ({ path }) => {
  const dispatch = useDispatch();
  
  const location = useLocation();
  const currentLocation = location.pathname;
  console.log('Location Change : ', location.pathname);
  
  const onLocationChange = () => {
    if (currentLocation.includes('fssai')) {
      dispatch({type: 'APP', payload: apps[1]})
    } else if (currentLocation.includes('training')) {
      dispatch({type: 'APP', payload: apps[4]})
    } else if (currentLocation.includes('testing-food')) {
      dispatch({type: 'APP', payload: apps[2]})
    } else if (currentLocation.includes('testing-water')) {
      dispatch({type: 'APP', payload: apps[3]})
    } else if (currentLocation.includes('medical-fitness')) {
      dispatch({type: 'APP', payload: apps[5]})
    } else if (currentLocation.includes('pest-control')) {
      dispatch({type: 'APP', payload: apps[6]})
    } else if (currentLocation.includes('process')) {
      dispatch({type: 'APP', payload: apps[7]})
    } else if (currentLocation.includes('completeCompliance')) {
      dispatch({type: 'APP', payload: apps[8]})
    } else {
      dispatch({type: 'APP', payload: apps[0]})
    }
  }

  useEffect(() => {
      console.log('Scrolling');
      window.scrollTo(0, 0);
      path(currentLocation);
      onLocationChange();
  }, [currentLocation, path]);

  return (null);
}

export default ScrollToTop;