import React, { Suspense} from 'react'
import ReactDOM, { hydrate, render } from 'react-dom'
import './tailwind.output.css'
import App from './App'
import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from './context/themeContext';
import Splash from './pages/Splash';
import { Provider } from 'react-redux';
import store from './store';

// ReactDOM.render(
//   <Suspense fallback={<Splash />}>
//     <React.StrictMode>
//       <ThemeProvider>
//         <App />
//       </ThemeProvider>
//     </React.StrictMode>
//   </Suspense>,
//   document.getElementById('root')
// )

// For React-Snap SEO
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Suspense fallback={<Splash />}>
      <Provider store={store}>
        <React.StrictMode>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </React.StrictMode>
      </Provider>
    </Suspense>,
    rootElement);
} else {
  render(
    <Suspense fallback={<Splash />}>
      <Provider store={store}>
        <React.StrictMode>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </React.StrictMode>
      </Provider>
    </Suspense>,
    rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
