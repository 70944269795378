export const seo = ({title, metaDescription, keywords, robots, language, author}) => {
    const title1 = title || 'Aahar Consulting - FSSAI Compliance Made Easy!';
    const metaDescription1 = metaDescription || "One stop solution for food businesses to manage FSSAI Compliance. Created by India's leading Food Technologists and Food Safety Consultants.";
  
    document.title = title1;
    document.querySelector('meta[name="description"]').setAttribute('content', metaDescription1);
    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
    document.querySelector('meta[name="robots"]').setAttribute('content', robots || 'index, follow');
    document.querySelector('meta[name="language"]').setAttribute('content', language || 'English');
    document.querySelector('meta[name="author"]').setAttribute('content', author || 'Aahar Consulting Pvt. Ltd.');
    document.querySelector('meta[http-equiv="Content-Type"]').setAttribute('content', 'text/html; charset=utf-8');
}

export const setThemeColor = (color) => {
    const themeColors = {
        'bg-darkblue-200': '#c5ddfe',
        'bg-purple-600': '#7e4ae6',
        'bg-orange-600': '#e17424',
        'bg-green-600': '#41a86c',
        'bg-teal-600': '#319795',
        'bg-darkblue-600': '#136ce1',
        'bg-silver-600': '#909dad',
        'bg-pink-600': '#d53f8c',
        'bg-red-500': '#f56565',
    }
    document.querySelector('meta[name="theme-color"]').setAttribute('content', themeColors[color]);
}

export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
        document.cookie = "name=oeschger; SameSite=None; Secure";
    });
}

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}