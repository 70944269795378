import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { images } from '../constants'

const Footer = () => {

    const {navTitle, navDescription, primaryColor} = useSelector(state => state.app);

    let linkColor = 'black';
    if (primaryColor === 'white') {
        linkColor = `darkblue`;
    } else {
        linkColor = `${primaryColor}`;
    }

    const servicesCol1 = [
        { name: 'New License Application', navigation: '/fssai-food-license-new?type=registration-certificate' },
        { name: 'License Renewal', navigation: '/fssai-food-license-renewal?type=registration-certificate' },
        { name: 'License Modification', navigation: '/fssai-food-license-modification?type=registration-certificate' },
        // { name: 'FLRS to FoSCoS Migration', navigation: '/fssai-food-license-migration' },
    ]
    
    const servicesCol2 = [
        { name: 'New License Application', navigation: '/fssai-food-license-new?type=state-license' },
        { name: 'License Renewal', navigation: '/fssai-food-license-renewal?type=state-license' },
        { name: 'License Modification', navigation: '/fssai-food-license-modification?type=state-license' },
        // { name: 'FLRS to FoSCoS Migration', navigation: '/fssai-food-license-migration' },
        // { name: 'File Annual Returns', navigation: '/fssai-food-license-file-itr' },
    ]
    
    const servicesCol3 = [
        { name: 'New License Application', navigation: '/fssai-food-license-new?type=central-license' },
        { name: 'License Renewal', navigation: '/fssai-food-license-renewal?type=central-license' },
        { name: 'License Modification', navigation: '/fssai-food-license-modification?type=central-license' },
        // { name: 'FLRS to FoSCoS Migration', navigation: '/fssai-food-license-migration' },
        // { name: 'File Annual Returns', navigation: '/fssai-food-license-file-itr' },
    ]

    const socialHandles = [
        {name: 'Facebook', navigation: 'www.facebook.com', icon: images.facebook},
        {name: 'Twitter', navigation: 'www.twitter.com', icon: images.twitter},
        {name: 'Youtube', navigation: 'www.youtube.com', icon: images.youtube},
        {name: 'Linkedin', navigation: 'www.linkedin.com', icon: images.linkedin},
    ]

    const ourServices = [
        {
            name: 'Complete Compliance Package',
            services: [
                { name: "Virtual Compliance Checkup", to:"/completeCompliance" },
                { name: "Physical Compliance Checkup", to:"/completeCompliance" },
                // { name: "License Modification", to:"services/fssai-registration-modification" }
            ],
            navigation: '/completeCompliance'
        },
        {
            name: 'FSSAI License',
            services: [
                { name: "New License Application", to:"/fssai-food-license" },
                { name: "License Renewal", to:"/fssai-food-license" },
                { name: "License Modification", to:"/fssai-food-license" },
                { name: "FLRS to FoSCoS Migration", to:"/fssai-food-license" },
            ],
            navigation: '/fssai-food-license'
        },
        {
            name: 'FoSTaC Training',
            services: [
                { name: "Basic FoSTaC", to:"/training" },
                { name: "Advanced FosTaC", to:"/training" },
                // { name: "Special FosTaC", to:"services/fssai-central-renewal" }
            ],
            navigation: '/training'
        },
        {
            name: 'FSSAI Regulatory',
            services: [
                { name: "File Annual Returns", to:"/comingsoon" },
                { name: "Product Evaluation", to:"/comingsoon" },
                { name: "Label Review", to:"/comingsoon" },
                { name: "US FDA Registration", to:"/comingsoon" },
                { name: "Export/ Import Advisory", to:"/comingsoon" },
                { name: "EPR Compliance", to:"/comingsoon" },
            ],
            navigation: '/comingsoon'
        },
        {
            name: 'Food Testing',
            services: [
                { name: "Microbial Testing", to:"/testing-food" },
                { name: "Shelf Life Testing", to:"/testing-food" },
                { name: "Nutritional Analysis", to: "/testing-food" },
                { name: "Food Packaging Testing", to: "/testing-food" },
                { name: "Sensory Analysis", to: "/testing-food" },
                { name: "Food Additives", to: "/testing-food" },
                { name: "Pesticide & Residual Analysis", to: "/testing-food" },
                { name: "Food Adulterants", to: "/testing-food" },
                { name: "Food Allergens", to: "/testing-food" },
                { name: "Heavy Metals", to: "/testing-food" },
            ],
            navigation: '/testing-food'
        },
        {
            name: 'Water Testing',
            services: [
                { name: "Potable Drinking Water (IS 10500:2012)", to:"/testing-water" },
                { name: "Packaged Drinking Water (IS 14543:2016)", to:"/testing-water" },
                { name: "Natural Mineral Water (IS 13428:2005)", to:"/testing-water" },
            ],
            navigation: '/testing-water'
        },
        {
            name: 'Medical Fitness',
            services: [
                { name: "Medical Fitness", to:"/medical-fitness" }
            ],
            navigation: '/medical-fitness'
        },
        {
            name: 'Pest Control',
            services: [
                { name: "Pest Control", to:"/pest-control" }
            ],
            navigation: '/pest-control'
        },
        {
            name: 'Calibration',
            services: [
                { name: "Calibration", to:"/process" }
            ],
            navigation: '/process'
        },
    ];

    return (
        <footer className='bg-gray-200'>
            <div className="min-h-full max-w-6xl mx-auto py-6 lg:py-20 bg-gray-200">
                <div className="px-4 md:px-6 lg:px-8">
                    <div className="mx-4 text-center sm:text-center md:text-center">
                        <Link to='/' className={`text-4xl tracking-tight font-bold tracking-wide text-${linkColor}-500 sm:text-5xl md:text-5xl`}>
                            {navTitle}
                        </Link>
                        <h1 className={`text-xl text-${linkColor}-500`}>{navDescription}</h1>
                        {/* <div className="mt-5 flex sm:flex-row sm:justify-start md:flex-row lg:flex-row lg:justify-center">
                            <div className="w-64 flex sm:flex-row sm:justify-start md:flex-row lg:flex-row lg:justify-around">
                                <Link to="/" title="Facebook">
                                    <img
                                        className="mx-auto h-12 object-cover"
                                        src={images.facebook} alt="" />
                                </Link>
                                <Link to="/">
                                    <img
                                        className="ml-5 mx-auto h-12 object-cover"
                                        src={images.linkedin} alt="" />
                                </Link>
                                <Link to="/">
                                    <img
                                        className="ml-5 mx-auto h-12 object-cover"
                                        src={images.youtube} alt="" />
                                </Link>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="px-4 flex flex-col md:flex-row lg:flex-row justify-around">
                    {/* <div className="mx-4 h-64 bg-gray-200 sm:rounded-lg pt-3 pb-4 sm:pb-4 lg:mt-5 lg:w-1/4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left">
                                <div className="mt-5 flex flex-col justify-between">
                                    <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                        About Us
                                    </h3>
                                    <div className="mt-5 flex flex-col items-start">
                                        <Link to="/" className={`text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500}>
                                            Home  
                                        </Link>
                                        <Link to="/career" className={`mt-1 text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500}>
                                            Careers
                                        </Link>
                                        <Link to="/" className={`mt-1 text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500}>
                                            Terms & Conditions
                                        </Link>
                                        <Link to="/privacy" className={`mt-1 text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500}>
                                            Privacy Policy
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mx-4 h-auto bg-gray-200 sm:rounded-lg pt-3 pb-4 sm:pb-4 lg:mt-5 lg:w-3/4">
                        <div className="">
                            <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left">
                                <h3 className="text-left text-xl sm:text-2xl font-bold text-gray-900">
                                    Services
                                </h3>
                                <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 lg:w-full">
                                    {ourServices.map((e) => 
                                        <div className="mt-5 flex flex-col items-start">
                                            <p className="text-sm md:text-md text-extrabold text-gray-900" style={{fontWeight: 'bolder'}}>
                                                {e.name}
                                            </p>
                                            {e.services.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={e.navigation} className={`mt-1 text-sm md:text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500`}>
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mx-4 h-auto bg-gray-200 sm:rounded-lg pt-3 pb-4 sm:pb-4 lg:mt-5 lg:w-1/4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left">
                                <div className="mt-5 flex flex-col justify-between">
                                    <h3 className="text-left text-2xl sm:text-2xl font-bold text-gray-900">
                                        &nbsp;
                                    </h3>
                                    <div className="mt-5 flex flex-col items-start">
                                        <p className="text-md text-extrabold text-gray-900" style={{fontWeight: 'bolder'}}>
                                            FSSAI State License
                                        </p>
                                        {servicesCol2.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.navigation} className={`mt-1 text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500`}>
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-4 h-auto bg-gray-200 sm:rounded-lg pt-3 pb-4 sm:pb-4 lg:mt-5 lg:w-1/4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left">
                                <div className="mt-5 flex flex-col justify-between">
                                    <h3 className="text-left text-2xl sm:text-2xl font-bold text-gray-900">
                                        &nbsp;
                                    </h3>
                                    <div className="mt-5 flex flex-col items-start">
                                        <p className="text-md text-extrabold text-gray-900" style={{fontWeight: 'bolder'}}>
                                            FSSAI Central License
                                        </p>
                                        {servicesCol3.map((item) => (
                                            <Link
                                                key={item.name}
                                                to={item.navigation} className={`mt-1 text-md text-semibold tracking-wide text-gray-700 hover:text-${linkColor}-300 hover:text-${linkColor}-500`}>
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="mx-4 h-auto bg-gray-200 sm:rounded-lg pt-3 pb-4 sm:pb-4 lg:mt-5 lg:w-1/4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-left sm:mt-0 sm:ml-4">
                                <h3 className="text-left text-xl sm:text-2xl font-bold text-gray-900">
                                    Contact Us
                                </h3>
                                <div className="mt-2 md:mt-5 flex flex-col justify-start">
                                    <div className="flex flex-row items-center">
                                        {/* <img
                                            className="mx-auto h-12 object-cover"
                                            src={images.call} alt="" /> */}
                                        <div className="flex flex-col items-start">
                                            <p className="text-left text-md text-semibold tracking-wide text-gray-700 text-wrap">
                                                Need support?
                                            </p>
                                            <p className="text-left text-md text-semibold tracking-wide text-gray-700 text-wrap">
                                                <Link to="/contactus" className={`text-${linkColor}-500 hover:text-${linkColor}-500`}>Click here </Link>
                                                to raise a ticket or <br/>
                                                <span>
                                                    call us on
                                                    <a className={`text-${linkColor}-500 hover:text-${linkColor}-500`} href="tel:9901177902"> 9901177902</a>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 flex flex-col justify-start">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-col items-start">
                                            <h3 className="text-left text-xl sm:text-2xl font-bold text-gray-900">
                                                Address
                                            </h3>
                                            <p className="mt-5 text-left text-md text-semibold tracking-wide text-gray-700 text-wrap">
                                                1st Floor, Hustle Hub, 522, 24th Main Rd, Parangi Palaya, Sector 2, HSR Layout, Bengaluru, Karnataka 560102
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 md:mt-20 text-left pb-20 px-8 md:text-center md:pb-0">
                    <div className='flex flex-col items-center'>
                        <div className="flex flex-row md:space-x-5">
                            {/* <p className="text-md text-extrabold text-gray-900" style={{fontWeight: 'bolder'}}>
                                Follow | 
                            </p> */}
                            {
                                socialHandles.map((e) => (
                                    <a key={e.name} className={`hover:text-${ linkColor }-500`} href={e.navigation} target="_blank" rel="noopener noreferrer">
                                        <img src={e.icon} className="mx-2 h-8 w-8 md:h-10 md:w-10" alt={e.name+"-footer-icon"} />
                                    </a>
                                ))
                            }
                        </div>
                        <p className="mt-5 text-md text-semibold tracking-wide text-gray-700 text-wrap">
                            <Link to="/" className={`text-${linkColor}-500 hover:text-${linkColor}-500`}>Home </Link> | 
                            <Link to="/terms" className={`text-${linkColor}-500 hover:text-${linkColor}-500`}> Terms & Conditions </Link> | 
                            <Link to="/privacy" className={`text-${linkColor}-500 hover:text-${linkColor}-500`}> Privacy Policy </Link> |
                            <Link to="/disclaimer" className={`text-${linkColor}-500 hover:text-${linkColor}-500`}> Disclaimer </Link>
                        </p>
                        <p className="mt-3 text-md text-semibold tracking-wide text-gray-700 text-wrap">
                            Copyright &#9400; 2022, TU Aahar Consulting Pvt. Ltd.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer