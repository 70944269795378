import { CONTACT_US, CONTACT_US_ERROR, NEWSLETTER_SUBS, NEWSLETTER_SUBS_ERROR, NOTIFY_LAUNCH, NOTIFY_LAUNCH_ERROR, REQUEST_CALLBACK, REQUEST_CALLBACK_ERROR } from "../actions/types";

const initialState = {
    loading: false,
    requestCallback: {},
    newsLetterSubs: {},
    notifyLaunches: {},
    contactUs: {},
};

const MarketingReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_CALLBACK:
            return {
                ...state,
                requestCallback: payload
            }
        case REQUEST_CALLBACK_ERROR:
            return {
                ...state,
                requestCallback: payload
            }
        case NEWSLETTER_SUBS:
            return {
                ...state,
                newsLetterSubs: payload
            }
        case NEWSLETTER_SUBS_ERROR:
            return {
                ...state,
                newsLetterSubs: payload
            }
        case NOTIFY_LAUNCH:
            return {
                ...state,
                notifyLaunches: payload
            }
        case NOTIFY_LAUNCH_ERROR:
            return {
                ...state,
                notifyLaunches: payload
            }
        case CONTACT_US:
            return {
                ...state,
                contactUs: payload
            }
        case CONTACT_US_ERROR:
            return {
                ...state,
                contactUs: payload
            }
        default:
            return {...state};
    }
}

export default MarketingReducer;