import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CRMHome from './CRMHome';


const Admin = () => {
  return (
    <Routes>
        <Route path="/" element={<CRMHome />} />
    </Routes>
  )
}

export default Admin