import React from 'react';
// import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
import Testimonials from '../components/Testimonials';
import RequestCallback from '../components/RequestCallback';
import Faq from '../components/Faq';
import PricingPlans from '../components/PricingPlans';
import Newsletter from '../components/Newsletter';
import { images } from '../constants';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import E2EComplaince from '../components/E2EComplaince';
import SuggestedBlogs from '../components/SuggestedBlogs';
import GovtNoteComponent from '../components/GovtNoteComponent';
import { CheckCircleIcon } from '@heroicons/react/solid';
import KobData from '../assets/kob.json';
import { seo } from '../utils/Utils';

const ServicePlans = () => {

    seo({
        title: 'FSSAI State & Central Food License Registration, Renewal, Modification',
        metaDescription: "Aahar Regulatory - Our team of food technologists will help you apply for FSSAI state/ central food license registration, license renewal & modification online.",
        keywords: "aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai food license, fssai registration, fssai state food license, fssai central food license, food license renewal, food license modification, apply food license online, get food license online, fssai application"
    });

    const features = [
        {
            name: 'Expert Team',
            description:
                'A team of food technologists, FSSAI regulatory & food safety consultants assisting you with compliance requirements through out your business journey starting from the first day of your business.',
            icon: images.experts_png,
        },
        {
            name: 'On Time',
            description:
                'We understand the importance of deadlines with FSSAI regulations and the underlying penalty, we will work with you to finish your licensing work on time.',
            icon: images.on_time_png,
        },
        {
            name: 'Affordable Price',
            description:
                'Unlike other consultants who do incomplete work by charging less and leave you in the middle, we conduct a thorough review of your business & food product to provide a one-time reasonable estimate and stay till the closure.',
            icon: images.price,
        },
        {
            name: '100% Satisfaction',
            description:
                "Offload your compliance responsibilities to us, and we'll take care of the rest, allowing you to focus on your core business. We are confident that you will be satisfied with our service.",
            icon: images.lol,
        },
    ];
    
    return (
        <>
            <Helmet>
                <meta name="title" content="FSSAI State & Central Food License Registration, Renewal, Modification" />
                <meta name="description" content="Aahar Regulatory - Our team of food technologists will help you apply for FSSAI state/ central food license registration, license renewal & modification online." />
                <meta name="keywords" content="aahar regulatory, aahar consulting, aahar fssai, aahar bangalore, fssai, fssai food license, fssai registration, fssai state food license, fssai central food license, food license renewal, food license modification, apply food license online, get food license online, fssai application" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <meta name="author" content="Aahar Consulting Pvt. Ltd." />
            </Helmet>
            <div className="max-w-6xl mx-auto">
                <div className="relative bg-white">
                    <div className="mt-12 mx-auto max-w-6xl px-4 mt-20 sm:px-6 md:mt-20 lg:mt-32 xl:mt-32">
                        <div className="text-center">
                            <div className='w-full flex flex-col-reverse md:flex md:flex-row-reverse items-center'>
                                <div className='w-full mt-5 px-3 md:px-0 md:ml-10 md:w-2/3'>
                                    <div className="text-3xl tracking-tight text-center sm:text-left font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <span class="mb-2 inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">FSSAI FOOD LICENSE</span>
                                        <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                                            Apply Online - FSSAI State/ Central Food License Registration, License Renewal, License Modification
                                        </h1>
                                    </div>
                                    <h2 className="mt-3 text-left tracking-wide text-gray-700">
                                        Our team of food technologists, food safety consultants & FSSAI regulatory experts will help you apply for a fresh food license,
                                        resolve all problems with your existing food license, renew/ modify your food license.
                                        The process is completely online without having to physically visit the food department office.
                                    </h2>
                                </div>
                                <div className='w-full mt-10 mb-5 md:w-1/3'>
                                    <img
                                        className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                        src={images.regulatory_img}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className='md:flex md:justify-center'>
                                <RequestCallback topic='fssai-license' layoutStyle={'md'} />
                            </div>
                            
                            <hr className='mt-10' />
                            
                            <div className='mt-5'>
                                <PricingPlans />
                            </div>
                            
                            <div className="px-4 lg:px-8 font-medium">
                                <div className="text-left text-teal-900 bg-teal-200 p-5 shadow rounded-lg">
                                    <GovtNoteComponent />
                                </div>
                            </div>
                            
                            <div className="mt-20 px-0 lg:px-8 font-medium">
                                <div className="text-left bg-darkblue-200 p-3 sm:p-5 shadow rounded-lg">
                                    <p className='text-center font-bold text-2xl mt-2 mb-5'>
                                        <span>Applicable Businesses</span>
                                    </p>
                                    <div className='mt-5 lg:mx-5 grid gap-x-8 gap-y-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-3'>
                                        {/* <div className='p-3 flex flex-col items-start lg:items-center points'> */}
                                            {/* <h3 className="text-2xl text-center sm:text-2xl font-bold text-gray-900">
                                                Restaurant
                                            </h3> */}
                                        {KobData.map((kob) =>
                                            <div className='mb-5 ml-4 md:mx-0'>
                                                <p className="mb-3 text-lg font-bold text-gray-900">{kob.name}</p>
                                                {kob.kob.map((e) => 
                                                    <div key={e} className="mb-2 flex flex-row justify-start items-center">
                                                        <div className=''>
                                                            <CheckCircleIcon className='h-5 w-5 text-darkblue-500' aria-hidden="true"></CheckCircleIcon>
                                                        </div>
                                                        <p className="ml-2 text-md font-normal text-gray-800">{e}</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="py-12">
                                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                    <div className="lg:text-center">
                                        {/* <h2 className="text-base text-darkblue-500 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                        <span class="inline-block py-1 px-2 bg-darkblue-100 text-darkblue-500 text-xs font-medium tracking-widest">WHY ARE WE BETTER</span>
                                        <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl lg:text-5xl">
                                            Food technologists & food safety consultants assisting you with <span className='text-darkblue-500 font-extrabold'>100% compliance!</span>
                                        </h1>
                                        {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-700 lg:mx-auto">
                                            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                            accusamus quisquam.
                                        </p> */}
                                    </div>

                                    <div className="mt-10">
                                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                                            {features.map((feature) => (
                                            <div key={feature.name} className="relative md:p-5">
                                                <dt>
                                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-darkblue-500 text-white">
                                                        {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                                                        <img src={feature.icon} className="text-white h-8 w-8" alt='' aria-hidden="true" />
                                                    </div>
                                                    <h3 className="ml-16 text-left text-lg leading-6 font-medium text-gray-900">{feature.name}</h3>
                                                </dt>
                                                <dd className="mt-2 text-left ml-16 tracking-wide text-gray-700">{feature.description}</dd>
                                            </div>
                                            ))}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            {/* <Testimonials /> */}
                            <Faq />
                        </div>
                    </div>
                </div>
            </div>

            <hr className='bg bg-gray-200' />
            <E2EComplaince />

            <hr className='bg bg-gray-200' />
            <SuggestedBlogs />

            <hr className='bg bg-gray-200' />
            <Newsletter />
        </>
    )
}

export default ServicePlans