import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { newsLetterSubs } from '../actions/marketing';
import { images } from '../constants';

const Newsletter = () => {

    const newsLetterImages = [
        images.newsletter_purple,
        images.newsletter_darkblue,
        images.newsletter_green,
        images.newsletter_teal,
        images.newsletter_blue,
        images.newsletter_orange,
        images.newsletter_red,
        images.newsletter_silver,
        images.newsletter_pink,
    ];

    const { primaryColor } = useSelector(state => state.app);

    let bgColor = 'bg-gray-200';
    if (primaryColor === 'white') {
        bgColor = `darkblue`;
    } else {
        bgColor = `${ primaryColor }`;
    }
    const newsLetterImage = newsLetterImages.filter((e) => e.includes(bgColor))[0];

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  email: ''  });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('News Subs!');
        console.log('Form Data : ', formData);
        dispatch(newsLetterSubs(formData));
        setShowMessage(true);
    };

    return (
        <div className="max-w-6xl mx-auto flex flex-col-reverse mt-5 py-6 lg:flex-row justify-center align-center">
            <div className="px-4 md:px-0">
                <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                    <div className="mx-auto max-w-7xl sm:mt-12 sm:px-6 md:mt-8 lg:mt-10 lg:px-8 xl:mt-28">
                        <div className="text-center lg:text-left">
                            <span class={`inline-block mb-3 py-1 px-3 rounded-sm bg-${bgColor}-100 text-${bgColor}-500 text-xs font-medium tracking-widest`}>NEWSLETTER</span>
                            <h1 className="">
                                <span className="block text-xl tracking-tight font-extrabold text-gray-900 md:text-3xl lg:text-4xl">Want to stay updated with all the FSSAI regulatory announcements?</span>{' '}
                                <span className={`mt-3 block text-xl tracking-tight font-extrabold md:text-3xl lg:text-4xl text-${bgColor}-500`}>Subscribe to our newsletter now!</span>
                            </h1>
                            {/* <p className="mt-3 text-base tracking-wide text-gray-700 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                Now hire an FSSAI expert to apply/renew/modify your FSSAI Licenses!
                            </p> */}
                            {!showMessage && <form onSubmit={onSubmit}>
                                <div>
                                    <div className="mt-5 grid grid-cols-12 md:px-40 lg:px-0 md:grid-cols-12 lg:grid-cols-12">
                                        <div className="col-span-12 md:col-span-12 lg:col-span-10">
                                            {/* <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label> */}
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                onChange={onChange}
                                                autoComplete="email"
                                                placeholder="Enter your email address"
                                                className={`form-input h-16 md:h-16 lg:h-16 lg:w-96 mt-1 border hover:border-gray-400 focus:ring-${bgColor}-500 focus:border-${bgColor}-500 block w-full shadow-sm sm:text-sm md:text-lg lg:text-lg border-black-300 rounded-md`}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-5 flex items-start md:px-40 lg:px-0">
                                        <input
                                            id="send-communications"
                                            name="send-communications"
                                            type="checkbox"
                                            defaultChecked={true}
                                            className={`mt-1 h-3 w-3 bg-${ bgColor }-600 text-${ bgColor }-500 rounded`}
                                            style={{flexShrink: 0}}
                                        />
                                        <label htmlFor="send-communications" className="ml-2 text-xs md:text-sm text-left text-gray-900 md:pr-20">
                                            By clicking on subscribe, I provide my consent to receive communications from Aahar. Check our
                                            <Link to="/terms" className={`text-${ bgColor }-500 hover:text-${ bgColor }-700`}> terms & conditions </Link> .
                                        </label>
                                    </div>
                                    <div className="mt-5 grid grid-cols-12 md:px-40 lg:px-0 md:grid-cols-12 lg:grid-cols-12">
                                        <div className="col-span-12 md:col-span-12 lg:col-span-10">
                                            {/* <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label> */}
                                            <button type='submit'
                                                className={`transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-${bgColor}-500 hover:bg-${bgColor}-700 md:py-4 md:text-lg md:px-10 hover:bg-${bgColor}-700 focus:outline-none`}
                                            >
                                                Subscribe
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>}

                            {showMessage && <div className="mt-5 flex justify-center">
                                <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                    <span className='flex items-center'>
                                        <CheckCircleIcon className={`w-5 h-5 text-${bgColor}-500`} />
                                        <span className='ml-2'> {state?.newsLetterSubs?.msg} </span>
                                    </span>
                                </div>
                            </div>}
                            {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-left lg:justify-center md:block lg:block lg:mt-5">
                                <div className="rounded-md shadow md:w-full lg:w-96">
                                    <button
                                        className={`transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-${bgColor}-500 hover:bg-${bgColor}-700 md:py-4 md:text-lg md:px-10 hover:bg-${bgColor}-700 focus:outline-none}
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </div> */}
                            <div className="flex justify-center lg:justify-start">
                                <p className="mt-4 max-w-lg text-sm md:text-base tracking-wide text-gray-700">
                                    Our newsletters are simple to read and will be customized according to your food business category. Available in <span className='text-md'>English, हिन्दी, ಕನ್ನಡ, తెలుగు, தமிழ், മലയാളം</span>.
                                </p>
                            </div>
                            {/* <p className="mt-2 max-w-2xl text-sm md:text-md lg:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                Available in English, हिन्दी, ಕನ್ನಡ, తెలుగు, தமிழ், മലയാളം.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="sm:m-0 mb-10 lg:mt-5 px-4 w-55 h-55 md:w-1/3 lg:w-2/3 lg:-mt-12" style={{alignSelf: 'center'}}>
                <img
                    className="h-40 object-contain md:h-96 lg:w-full lg:h-full"
                    src={newsLetterImage}
                    alt=""
                />
            </div>
        </div>
    )
}

export default Newsletter;