import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Faq from '../components/Faq';
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon, StarIcon, UserCircleIcon } from '@heroicons/react/outline';
import StarIconFilled from '@heroicons/react/solid/StarIcon';
import { CheckCircleIcon } from '@heroicons/react/solid';
import Timeline from '../components/Timeline';
import { images } from '../constants';
import Newsletter from '../components/Newsletter';
import Review from '../components/Review';
import Testimonials from '../components/Testimonials';
import Carousel from '../components/Carousel';
import GridCarousel from '../components/GridCarousel';
import E2EComplaince from '../components/E2EComplaince';
import SuggestedBlogs from '../components/SuggestedBlogs';
import { useDispatch, useSelector } from 'react-redux';
import { requestCallback } from '../actions/marketing';


const TrainingDetails = () => {

    const { pathname, search, state } = useLocation();

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    
    const [trainingDetails, setTrainingDetails] = useState(state);

    console.log({ pathname, search, state });

    const features = [
        {
            name: 'Form 16 from your company',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: GlobeAltIcon,
        },
        {
            name: 'Additional Form 16',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: ScaleIcon,
        },
        {
            name: 'Form 26AS Tax Credit Statement',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: LightningBoltIcon,
        },
        {
            name: 'Aadhaar card',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: AnnotationIcon,
        },
        {
            name: 'Bank statement if interest received is above Rs. 10,000/-',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: GlobeAltIcon,
        },
        {
            name: 'Salary Slip of any month during the Financial Year',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: ScaleIcon,
        },
        {
            name: 'Annual Information Statement',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: LightningBoltIcon,
        },
        {
            name: 'Aadhaar card 1',
            description:
                'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
            icon: AnnotationIcon,
        },
    ];

    const eligibilityCriteria = [
        {
            eligibeFor: 'For foood handler/ supervisor/ other individuals employed in petty food business',
            criteria: [
                'Minimum class 5th std',
                'No experience required'
            ]
        },
        {
            eligibeFor: 'For students',
            criteria: [
                'Education in relevant field with 10+2 passed with science'
            ]
        }
    ];

    const benefits = [
        "Every participant will get a Food Safety Supervisor certificate from FSSAI after completing the course successfully.",
        "The certificate is valid for lifetime, for the specific food category.",
        "Knowledge about the basic concepts of food safety, standards & hygiene."
    ];

    const process = [
        "Request callback or call us on our toll free number to get the details of the upcoming training batch.",
        "After completing the payment, we will create an account in our learning module system where you can manage all your courses with Aahar Learning.",
        "Account creation in FoSTaC Portal.",
        "On the day of training, a zoom link will be sent over email and sms. It will also be accessible through our learning module system.",
        "After the completion of the training, every participant will be required to give an assessment.",
        "Within 7 days of the training day, results will be disclosed and the FoSTaC certificate will be issued."
    ];

    const dispatch = useDispatch();
    const state1 = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  
        name: '',
        email: '',
        phoneNo: '',
        topic: 'FoSTaC Training - Enrolling more than 5 people'
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
                setFormData({name: '', email: '', phoneNo: ''})
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('FoSTaC Training!');
        console.log('Form Data : ', formData);
        dispatch(requestCallback(formData));
        setShowMessage(true);
    };


    return (
        <>
            <div className="max-w-6xl mx-auto">
                <div className="relative pb-8 bg-white">
                    <div className="mt-10 mx-auto max-w-6xl px-8 mt-20 sm:px-6 md:mt-10 lg:mt-20 xl:mt-20">

                        {/* First Section */}
                        {/* Hidden this section for this screen */}
                        <div className="hidden flex flex-col justify-between items-center md:flex-row lg:flex-row">
                            <div className='w-full mt-10 mb-5 md:hidden'>
                                <img
                                    className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                    src={images.newsletter}
                                    alt=""
                                />
                            </div>
                            <div className="text-left w-full md:w-2/3 lg:w-2/3">
                                <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-5xl">
                                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                        {trainingDetails.courseName}
                                    </h1>
                                </div>
                                <h2 className="mt-3 text-base text-left text-md lg:text-2xl tracking-wide text-gray-900">
                                    {trainingDetails.description}
                                </h2>
                                <div className='mt-3 flex flex-row items-center'>
                                    <p className="text-base text-left text-md lg:text-2xl text-gray-700">
                                        4.2
                                    </p>
                                    <div className='ml-2 flex flex-row'>
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIconFilled
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                        <StarIcon
                                            className='w-5 h-5 lg:h-6 lg:w-6'
                                            color='#f7bb44'
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <Link to="" className='ml-2 text-sm lg:text-md text-orange-500 hover:text-orange-700'>(All Reviews)</Link>
                                </div>
                            </div>
                            <div className='w-full mt-10 pt-10 lg:mt-2 md:w-1/3 lg:w-1/3'>
                                <div className="w-max flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-orange-500 group-hover:text-white">
                                        <div>
                                            <p className="text-center text-gray-700 font-semibold">Course Price</p>
                                            <p className="mt-2 text-center mx-auto sm:text-md lg:text-lg">
                                                <span className="ml-2 text-4xl font-bold">₹ {trainingDetails.fees}</span>
                                                {/* <span className="ml-2 text-gray-700 font-semibold">per licence</span> */}
                                            </p>
                                            <p className='mt-2 text-center mx-auto text-sm'>*conditions apply</p>
                                            <Link to="/checkout" className="mt-3 block text-white bg-orange-500 hover:text-white hover:bg-orange-600 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy this course</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 w-max flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-orange-500 group-hover:text-white">
                                        <div>
                                            <div className="flex-1 items-stretch space-y-4">
                                                <p className='text-left text-base text-gray-700 font-semibold'>Got any questions? Talk to our expert.</p>
                                                <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="name"
                                                        name="name"
                                                        id="name"
                                                        autoComplete="name"
                                                        placeholder="Enter your name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Phone No
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        name="phone-no"
                                                        id="phone-no"
                                                        autoComplete="tel"
                                                        placeholder="+91 ##### #####"
                                                        maxLength='10'
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>
                                                {name && name.length > 3 && <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email-address"
                                                        id="email-address"
                                                        autoComplete="email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        placeholder="Enter your email"
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>}
                                            </div>
                                            <div className="flex mt-5 items-start">
                                                <input
                                                    id="send-communications"
                                                    name="send-communications"
                                                    type="checkbox"
                                                    defaultChecked={true}
                                                    className="h-4 w-4 mt-1 bg-orange-600 text-orange-500 rounded"
                                                />
                                                <label htmlFor="send-communications" className="ml-2 text-sm text-gray-900">
                                                    By clicking on request callback, I provide my consent to receive communications from Aahar. Check our terms & conditions.
                                                </label>
                                            </div>
                                            <Link to="" className="block text-white bg-orange-500 hover:text-white hover:bg-orange-600 group-hover:text-orange-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Request Callback</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex flex-col justify-between items-start md:flex-row lg:flex-row">
                            <div className='w-full md:w-2/3 lg:w-2/3 lg:pr-10'>

                                <div className='w-full mt-10 mb-5 md:hidden'>
                                    <img
                                        className="h-40 mx-auto object-contain md:h-96 lg:w-full lg:h-full"
                                        src={images.training_details_mob}
                                        alt=""
                                    />
                                </div>

                                <h1 className="pt-10 lg:pt-32 text-base text-left text-md lg:text-xl tracking-wide text-gray-900">
                                    FoSTaC - Food Safety Training & Certification
                                    <span><br />Food Safety Supervisor Training</span>
                                </h1>

                                <div className="h-screen-min flex flex-col mt-5 md:mt-20 text-left w-full">
                                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                        Topic: {trainingDetails.courseTitle}
                                    </h1>
                                    <h2 className="text-base text-left text-md lg:text-xl tracking-wide text-gray-900">
                                        Level: {trainingDetails.level}
                                    </h2>

                                    {/* Ratings */}
                                    {/* <div className='mt-3 flex flex-row items-center'>
                                        <p className="text-base text-left text-md lg:text-2xl text-gray-700">
                                            4.2
                                        </p>
                                        <div className='ml-2 flex flex-row'>
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIconFilled
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                            <StarIcon
                                                className='w-5 h-5 lg:h-6 lg:w-6'
                                                color='#f7bb44'
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <Link to="" className='ml-2 text-sm lg:text-md text-orange-500 hover:text-orange-700'>(All Reviews)</Link>
                                    </div> */}

                                    <div className="mt-5 md:mr-20">
                                        <div className="font-medium mt-5 lg:mt-2">
                                            <div className="text-orange-900 bg-orange-200 p-4 shadow rounded-lg">
                                                <p className='font-bold'>Upcoming Batch Details</p>
                                                <div className='mt-5 ml-3'>
                                                    <li>Training Mode : Online</li>
                                                    <li>Date : 23 Sept 2022</li>
                                                    <li>Time : 9 AM</li>
                                                    <li>Language : English</li>
                                                </div>
                                                <p className='mt-5 font-bold'>
                                                    * To schedule the training on preferred date & language, call us on
                                                    <a className='hover:text-orange-500' href="tel:9901177902"> 1800 1020 127 </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Outline */}
                                <div className="mt-5 md:mt-20 py-10 text-left">
                                    <div className="mt-3 text-3xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Outline :
                                        </h1>
                                    </div>
                                    <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        FoSTaC is a large-scale mandatory training program implemented by FSSAI for food business to ensure food safety & hygiene.
                                        FSSAI has certified training partners across the country who will train food safety supervisors and the supervisors are further expected to train other food handlers in the organisation.
                                    </h2>
                                </div>

                                {/* Applicable for */}
                                <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Applicable for:
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2> */}
                                    <div className='mt-3 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch card-w-border-wo-shadow">
                                            <div className='p-3 flex flex-col lg:flex-row justify-start items-center'>
                                                <div className='flex flex-col items-stretch points'>
                                                    {trainingDetails.eligibilityCriteria.map((e) => 
                                                        <div className='mb-5 flex items-baseline'>
                                                            <div className="mt-1 absolute flex items-baseline justify-center h-12 w-12 rounded-md text-orange-500 text-white">
                                                                <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div>
                                                                <p className="ml-16 text-left text-lg text-gray-900">{e.eligibeFor}</p>
                                                                {e.criteria.map((c) => <li className='ml-20 text-gray-700'>{c}</li>)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Course Content */}
                                <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Course Content:
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2> */}
                                    <div className='mt-3 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch">
                                            <div className='flex flex-col justify-center md:flex-row lg:flex-row'>
                                                <div className="w-full flex flex-col items-stretch">
                                                    <div className="card-w-border-wo-shadow mb-5">
                                                        <div className='p-2 lg:p-5 flex flex-col lg:flex-row justify-start items-center'>
                                                            <div className='flex flex-col items-stretch points'>
                                                                {trainingDetails.courseContent.map((e) =>
                                                                    <div className='mb-3'>
                                                                        <p className="ml-2 text-lg font-semibold">{e.name}</p>
                                                                        {e.items.map((item) => 
                                                                            <div key={item} className="ml-5 my-2 flex flex-row justify-start items-center">
                                                                                <div className=''>
                                                                                    <CheckCircleIcon className='h-4 w-4' color='#58bf4a' aria-hidden="true"></CheckCircleIcon>
                                                                                </div>
                                                                                <p className="ml-2 text-md text-gray-900">{item}</p>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Benefits */}
                                <div className="py-5 lg:py-10">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Benefits :
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        Benefits of attending the course
                                    </h2> */}
                                    <div className='mt-3 lg:mt-10 flex flex-col justify-center md:flex-row lg:flex-row'>
                                        <div className="w-full flex flex-col items-stretch card-w-border-wo-shadow">
                                            <div className='p-3 flex flex-col lg:flex-row justify-start items-center'>
                                                <div className='flex flex-col items-stretch points'>
                                                    {benefits.map((e) => 
                                                        <div className='mb-5 flex items-baseline'>
                                                            <div className="mt-1 absolute flex items-baseline justify-center h-12 w-12 rounded-md text-orange-500 text-white">
                                                                <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                                                            </div>
                                                            <div>
                                                                <p className="ml-16 text-left text-lg text-gray-900">{e}</p>
                                                                {/* {e.criteria.map((c) => <li className='ml-20 text-gray-700'>{c}</li>)} */}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Process */}
                                <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Our Process :
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2>
                                    <h2 className="mt-16 text-md font-bold text-gray-900 md:text-2xl">
                                        Timeline - 3 days estimate 
                                    </h2> */}
                                    <Timeline type='fssai' data={process} color={'orange'} />
                                </div>

                                {/* Trainer Details */}
                                <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Trainer :
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2> */}
                                    <div class="mt-5">
                                        <div class="flex w-full card-w-border-wo-shadow">
                                            <div class="w-full relative p-4">
                                                <div class="flex justify-between">
                                                    <div class="flex">
                                                        <div>
                                                            <div class="h-20 w-20 rounded-full bg-cover" style={{ backgroundImage: `url(https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=256&amp;q=80&quot)` }}></div>
                                                        </div>
                                                        <div class="ml-4">
                                                            <div class="font-bold text-xl">Tricia Matthew</div>
                                                            <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 25+ courses</div>
                                                            <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 4.7 rating</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="my-6 border-b"></div>
                                                <div>
                                                    <p className='tracking-wide'>A Review card written completely with utlities from Tailwind CSS. This is just some filler text to make the card look populated. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Certificate */}
                                <div className="py-5 lg:py-5">
                                    <div className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                        <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                            Certificate :
                                        </h1>
                                    </div>
                                    {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                        The entire procedure will take place online, and status updates will be provided for each phase.
                                    </h2> */}
                                    <div class="mt-5">
                                        <div class="flex w-full card-w-border-wo-shadow">
                                            <div class="w-full relative p-4">
                                                <div class="flex justify-between">
                                                    <div class="flex flex-col">
                                                        <div style={{width: '30%'}}>
                                                            {/* <div class="h-20 w-20 rounded-full bg-cover" style={{ backgroundImage: images.fostac_certificate }}></div> */}
                                                            <img src={images.fostac_certificate} alt="fostac certificate" className="bg-cover" />
                                                        </div>
                                                        <div class="mt-5">
                                                            <div class="font-bold text-xl">Sample Certificate</div>
                                                            {/* <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 25+ courses</div>
                                                            <div class="mt-1 text-md text-gray-900"><span>&bull;</span> 4.7 rating</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="my-6 border-b"></div>
                                                <div>
                                                    <p className='tracking-wide'>A Review card written completely with utlities from Tailwind CSS. This is just some filler text to make the card look populated. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Documents */}
                                {/* <div className="py-5 text-left">
                                    <div className="py-4">
                                        <div className="max-w-3xl">
                                            <div className="">
                                                <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                                    Documents Required
                                                </h1>
                                                <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                                    Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                                    accusamus quisquam.
                                                </p>
                                            </div>

                                            <div className="mt-10">
                                                <dl className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2">
                                                    {features.map((feature) => (
                                                        <div key={feature.name} className="relative pr-5 py-5">
                                                            <div className='flex items-center'>
                                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-orange-500 text-white">
                                                                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                                                                </div>
                                                                <p className="ml-16 text-left text-lg text-gray-900">{feature.name}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 font-medium">
                                        <div className="text-left text-orange-900 bg-orange-200 p-5 shadow rounded-lg">
                                            <p> After receiving the detailed requirement, a complete list of applicable documents will be shared.</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* Ratings & Reviews */}
                                {/* <div className="py-5 text-left">
                                    <div className="py-4">
                                        <div className="max-w-3xl">
                                            <div className="">
                                                <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                                    Ratings & Reviews :
                                                </h1>
                                                <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                                    Ratings and Reviews for the course
                                                </p>
                                            </div>

                                            <div className="mt-10 space-y-5">
                                                <Review />
                                                <Review />
                                                <Review />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 font-medium">
                                        <div className="text-left text-orange-900 bg-orange-200 p-5 shadow rounded-lg">
                                            <p> After receiving the detailed requirement, a complete list of applicable documents will be shared.</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <Faq cols={'1'} titleAlignment={'left'} /> */}
                            </div>
                            {/* <div className='w-full md:w-1/3 lg:w-1/3 sticky top-0 pt-0 lg:pt-20'>
                                <div className="w-max mt-0 lg:mt-20 flex flex-col card-shadow">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-orange-500 group-hover:text-white">
                                        <div className='flex flex-col items-center'>
                                            <p className="text-base text-left text-2xl font-semibold text-gray-700">
                                                Service Title
                                            </p>
                                            <p className="mt-2 text-center mx-auto text-lg">
                                                <span>₹</span>
                                                <span className="ml-2 text-5xl font-bold">25</span>
                                                <span className="ml-2">/one time</span>
                                            </p>
                                            <Link to="/checkout" className="block text-white lg:w-64 bg-orange-500 hover:text-white hover:bg-orange-600 group-hover:text-orange-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                            <Link to="" className="mt-2 py-3 font-semibold text-orange-500 text-md hover:text-orange-700">Request Callback &nbsp;&nbsp; →</Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='w-full md:w-1/3 lg:w-1/3 sticky top-0 pt-0 lg:pt-20'>
                                <div className="w-max mt-0 pt-2 lg:mt-1 flex flex-col">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-orange-500 group-hover:text-white">
                                        <div>
                                            <p className="text-center text-xl font-extrabold tracking-tight text-gray-900">
                                                General Catering
                                                <span className='font-medium text-lg text-gray-700'> <br /> Level : Basic</span>
                                            </p>
                                            <p className="mt-2 text-center mx-auto sm:text-md lg:text-lg">
                                                <span className="ml-2 text-4xl font-bold">₹ {trainingDetails.fees}</span>
                                                {/* <span className="ml-2 text-gray-700 font-semibold">per licence</span> */}
                                            </p>
                                            {/* <p className='mt-2 text-center mx-auto text-sm'>*conditions apply</p> */}
                                            <Link to="/checkout" className="mt-3 block text-white bg-orange-500 hover:text-white hover:bg-orange-600 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy this course</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-max mt-2 flex flex-col">
                                    <div className="relative flex flex-1 flex-col justify-between border border-slate-200 shadow-lg px-8 py-5 bg-white rounded-lg group-hover:bg-orange-500 group-hover:text-white">
                                        <form onSubmit={onSubmit}>
                                            <div className="flex-1 items-stretch space-y-4">
                                                <p className='mx-5 md:-mx-8 text-center text-2xl font-extrabold tracking-tight'>Enrolling more than 5 people?</p>
                                                <p style={{marginTop: '0.5rem'}} className='text-center text-md font-bold tracking-tight text-gray-700'>Connect with us for discounts.</p>
                                                <hr className='-mx-8' />
                                                <div className='mt-3'>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        autoComplete="name"
                                                        placeholder="Enter your name"
                                                        value={formData.name}
                                                        onChange={(e) => { setName(e.target.value); onChange(e)}}
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>
                                                <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Phone No
                                                    </label>
                                                    <input
                                                        type="tel"
                                                        name="phoneNo"
                                                        id="phoneNo"
                                                        autoComplete="tel"
                                                        placeholder="+91 ##### #####"
                                                        maxLength='10'
                                                        value={formData.phoneNo}
                                                        onChange={(e) => {setPhone(e.target.value); onChange(e)}}
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>
                                                {name && name.length > 3 && <div>
                                                    <label htmlFor="first-name" className="block text-md mb-2 font-medium text-gray-700">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        autoComplete="email"
                                                        value={formData.email}
                                                        onChange={(e) => {setEmail(e.target.value); onChange(e)}}
                                                        placeholder="Enter your email"
                                                        className='form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md'
                                                    />
                                                </div>}
                                            </div>
                                            <div className="flex mt-5 items-start">
                                                <input
                                                    id="send-communications"
                                                    name="send-communications"
                                                    type="checkbox"
                                                    defaultChecked={true}
                                                    className="h-4 w-4 mt-1 bg-orange-600 text-orange-500 rounded"
                                                    style={{flexShrink: 0}}
                                                />
                                                <label htmlFor="send-communications" className="ml-2 text-sm text-gray-900">
                                                    By clicking on request callback, I provide my consent to receive communications from Aahar. Check our
                                                    <Link to="/terms" className={`text-orange-500 hover:text-orange-700`}> terms & conditions </Link> .
                                                </label>
                                            </div>
                                            {!showMessage && <button type='submit' className="w-full block text-white bg-orange-500 hover:text-white hover:bg-orange-600 group-hover:text-orange-500 group-hover:bg-white group-hover:border group-hover:border-white mt-8 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Request Callback</button>}
                                                {showMessage && <div className="mt-5 flex justify-center">
                                                        <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                                            <span className='flex items-center'>
                                                                <CheckCircleIcon className={`w-5 h-5 text-orange-500`} />
                                                                <span className='ml-2'> {state1?.requestCallback?.msg} </span>
                                                            </span>
                                                        </div>
                                                </div>}
                                        </form>
                                    </div>
                                </div>
                                {/* <div className="font-medium mt-5 lg:mt-2">
                                    <div className="text-left text-orange-900 bg-orange-200 p-4 shadow rounded-lg">
                                        <p>
                                            <span>* Govt fees - not included, want to check the exact govt fees? </span>
                                            <Link to="/service-info" className="mt-2 py-3 font-semibold text-orange-500 text-md hover:text-orange-700 group-hover:text-white">Click here</Link> 
                                            <span> to find out</span>
                                        </p>
                                        <p className='mt-3'>
                                            ** Final price may vary depending on the complexity (Total number of products, kind of business, food product category etc)
                                        </p>
                                        <p className='mt-3'>
                                            Talk to our team on 1800 1020 127 to get an estimate. 
                                        </p>
                                    </div>
                                </div> */}
                            </div>
                            
                        </div>

                        {/* <hr className='bg bg-gray-200' /> */}
                        {/* Suggested Courses */}
                        <div className="py-10">
                            <div className="max-w-6xl mx-auto text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl md:text-4xl">
                                <h1 className="text-left text-3xl font-extrabold tracking-tight text-gray-900 md:text-5xl">
                                    Suggested Courses :
                                </h1>
                            </div>
                            {/* <h2 className="mt-4 max-w-2xl text-xl tracking-wide text-gray-900">
                                The entire procedure will take place online, and status updates will be provided for each phase.
                            </h2> */}
                            <div class="mt-5 -ml-5 md:-mx-10">
                                {/* <Carousel /> */}
                                <GridCarousel />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <hr className='bg bg-gray-200' />
            <E2EComplaince />

            <hr className='bg bg-gray-200' />
            <SuggestedBlogs />

            <hr className='bg bg-gray-200' />
            <Newsletter />
        </>
    )
}

export default TrainingDetails