import { ArrowCircleRightIcon, ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import E2EComplaince from '../components/E2EComplaince'
import Newsletter from '../components/Newsletter'

const Blogs = () => {
    return (
        <>    
            <div className="mx-auto max-w-6xl md:px-5">
                <div className="mx-auto container lg:py-16 md:py-12 md:px-6 px-6 py-6">
                    <div role="main" className="flex flex-col items-start justify-center">
                        <h1 className="text-4xl font-semibold leading-9 text-gray-900">Aahar Blogs</h1>
                        <p className="text-base leading-normal text-gray-900 mt-2">Select from a range of categories</p>
                    </div>
                    <div className="lg:flex items-stretch mt-8">
                        <div className="lg:w-1/2">
                            <div className="relative">
                                <div>
                                    {/* <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p> */}
                                    <div className="absolute top-0 left-0 p-6">
                                        <h2 className="text-3xl font-bold text-white">Compliance for Food Business</h2>
                                        <p className="font-bold text-sm tracking-wider text-md text-white mt-2 uppercase">15 Posts</p>
                                    </div>
                                    <div className="absolute bottom-0 left-0 p-6">
                                        <Link to="" className='py-3 px-5 p-3 block text-purple-500 bg-white hover:text-purple-700 text-sm font-semibold text-center rounded-md shadow-md'>
                                            View Posts
                                        </Link>
                                    </div>
                                </div>
                                <div className='bg-purple-500 w-full rounded-lg sm:block hidden' style={{height: '15rem'}}></div>
                                <div className='bg-purple-500 w-full rounded-lg sm:hidden' style={{height: '18rem'}}></div>
                                {/* <img src="https://i.ibb.co/6Wfjf2w/img-4.png" alt="sitting place" className="w-full sm:block hidden" /> */}
                                {/* <img className="w-full sm:hidden" src="https://i.ibb.co/dpXStJk/Rectangle-29.png" alt="sitting place" /> */}
                            </div>
                            <div className="sm:flex items-center justify-between gap-6 md:mt-6 mt-4">
                                <div className="relative w-full">
                                    <div>
                                        {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p> */}
                                        <div className="absolute top-0 left-0 p-6">
                                            <h2 className="text-3xl font-bold text-white">Food Safety Training</h2>
                                            <p className="font-bold text-sm tracking-wider text-white mt-2 uppercase">23 Posts</p>
                                        </div>
                                        <div className="absolute bottom-0 left-0 p-6">
                                            <Link to="" className='py-3 px-5 p-3 block text-orange-500 bg-white hover:text-orange-700 text-sm font-semibold text-center rounded-md shadow-md'>
                                                View Posts
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='bg-orange-500 w-full rounded-lg sm:block hidden' style={{height: '23rem'}}></div>
                                    <div className='bg-orange-500 w-full rounded-lg sm:hidden' style={{height: '18rem'}}></div>
                                </div>
                                <div className="relative w-full sm:mt-0 mt-4">
                                    <div>
                                        {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p> */}
                                        <div className="absolute top-0 left-0 p-6">
                                            <h2 className="text-3xl font-bold text-white">Miscellaneous</h2>
                                            <p className="font-bold text-sm tracking-wider text-white mt-2 uppercase">20 Posts</p>
                                        </div>
                                        <div className="absolute bottom-0 left-0 p-6">
                                            <Link to="" className='py-3 px-5 p-3 block text-red-500 bg-white hover:text-red-700 text-sm font-semibold text-center rounded-md shadow-md'>
                                                View Posts
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='bg-red-500 w-full rounded-lg sm:block hidden' style={{height: '23rem'}}></div>
                                    <div className='bg-red-500 w-full rounded-lg sm:hidden' style={{height: '18rem'}}></div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/2 xl:ml-6 lg:ml-4 lg:mt-0 md:mt-6 mt-4 lg:flex flex-col justify-between">
                            <div className="sm:flex items-center justify-between gap-6">
                                <div className="sm:w-1/2 relative">
                                    <div>
                                        {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p> */}
                                        <div className="absolute top-0 left-0 p-6">
                                            <h2 className="text-3xl font-bold text-white">Food Testing</h2>
                                            <p className="font-bold text-sm tracking-wider text-white mt-2 uppercase">10 Posts</p>
                                        </div>
                                        <div className="absolute bottom-0 left-0 p-6">
                                            <Link to="" className='py-3 px-5 p-3 block text-green-500 bg-white hover:text-green-700 text-sm font-semibold text-center rounded-md shadow-md'>
                                                View Posts
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='bg-green-500 w-full rounded-lg sm:block hidden' style={{height: '23rem'}}></div>
                                    <div className='bg-green-500 w-full rounded-lg sm:hidden' style={{height: '18rem'}}></div>
                                </div>
                                <div className="sm:w-1/2 sm:mt-0 mt-4 relative">
                                    <div>
                                        {/* <p className="p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p> */}
                                        <div className="absolute top-0 left-0 p-6">
                                            <h2 className="text-3xl font-bold text-white">Water Testing</h2>
                                            <p className="font-bold text-sm tracking-wider text-white mt-2 uppercase">25 Posts</p>
                                        </div>
                                        <div className="absolute bottom-0 left-0 p-6">
                                            <Link to="" className='py-3 px-5 p-3 block text-teal-500 bg-white hover:text-teal-700 text-sm font-semibold text-center rounded-md shadow-md'>
                                                View Posts
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='bg-teal-500 w-full rounded-lg sm:block hidden' style={{height: '23rem'}}></div>
                                    <div className='bg-teal-500 w-full rounded-lg sm:hidden' style={{height: '18rem'}}></div>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 relative">
                                <div>
                                    {/* <p className="md:p-10 p-6 text-xs font-medium leading-3 text-white absolute top-0 right-0">12 April 2021</p> */}
                                    <div className="absolute top-0 left-0 p-6">
                                        <h2 className="text-3xl font-bold text-white">FSSAI License & Regulations</h2>
                                        <p className="font-bold text-sm tracking-wider text-white mt-2 uppercase">29 Posts</p>
                                    </div>
                                    <div className="absolute bottom-0 left-0 p-6">
                                        <Link to="" className='py-3 px-5 p-3 block text-darkblue-500 bg-white hover:text-darkblue-700 text-sm font-semibold text-center rounded-md shadow-md'>
                                            View Posts
                                        </Link>
                                    </div>
                                </div>
                                <div className='bg-darkblue-500 w-full rounded-lg sm:block hidden' style={{height: '15rem'}}></div>
                                <div className='bg-darkblue-500 w-full rounded-lg sm:hidden' style={{height: '18rem'}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5 md:mt-0 mx-auto max-w-6xl md:py-12 md:px-6">

                {/* Featured Post */}
                <div className='p-6 space-y-5'>
                    <div className="py-5 flex flex-col items-start justify-center">
                        <h1 className="text-4xl font-semibold leading-9 text-center text-gray-900">Featured Post</h1>
                        {/* <p className="text-center text-gray-600 mt-4">If you're looking for random paragraphs, you've come to the right place. When a random word or a random sentence isn't quite enough</p> */}
                    </div>
                    <div className="mx-auto">
                        <Link to="" className="my-5 block max-w-sm gap-3 mx-auto sm:max-w-full lg:grid lg:grid-cols-12 card-shadow rounded-lg">
                            <img src="https://source.unsplash.com/random/480x360" alt="" className="object-cover w-full rounded h-96 lg:col-span-7" />
                            <div className="p-6 space-y-2 lg:col-span-5">
                                <h3 className="text-2xl font-semibold md:text-4xl">Noster tincidunt reprimique ad pro</h3>
                                <span className="text-sm m-0 text-gray-500">February 19, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800">Ei delenit sensibus liberavisse pri. Quod suscipit no nam. Est in graece fuisset, eos affert putent doctus id.</p>
                            </div>
                        </Link>
                    </div>
                </div>

                {/* Popular Posts */}
                <div className="p-6 space-y-5">
                    <div className="py-5 flex flex-row items-start justify-between">
                        <h1 className="text-4xl font-semibold leading-9 text-center text-gray-900">Popular Posts</h1>
                        {/* <p className="text-center text-gray-600 mt-4">If you're looking for random paragraphs, you've come to the right place. When a random word or a random sentence isn't quite enough</p> */}
                        <Link to="" className="px-3 py-2 text-md rounded-md bg-darkblue-500 text-white flex items-center">
                            <span>View all</span>
                            <ChevronRightIcon className='ml-1 w-5 h-5 text-white' />
                        </Link>
                    </div>
                    <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                            <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?4" alt=""/>
                            <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">COMPLAINCE</div>
                            <div className="p-6 space-y-2">
                                <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                                <span className="text-sm m-0 text-gray-500">January 24, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                            </div>
                        </Link>
                        <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                            <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?5" alt=""/>
                            <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-green-50 text-green-500 text-xs font-medium tracking-widest">FOOD TESTING</div>
                            <div className="p-6 space-y-2">
                                <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                                <span className="text-sm m-0 text-gray-500">January 25, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                            </div>
                        </Link>
                        <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                            <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?6" alt=""/>
                            <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-orange-100 text-orange-500 text-xs font-medium tracking-widest">TRAINING</div>
                            <div className="p-6 space-y-2">
                                <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                                <span className="text-sm m-0 text-gray-500">January 26, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="flex justify-center">
                        <button type="button" className="px-6 py-3 text-md rounded-md bg-darkblue-500 text-white">See more posts...</button>
                    </div> */}
                </div>
                
                {/* Recent Posts */}
                <div className="p-6 space-y-5">
                    <div className="py-5 flex flex-row items-start justify-between">
                        <h1 className="text-4xl font-semibold leading-9 text-center text-gray-900">Recent Posts</h1>
                        {/* <p className="text-center text-gray-600 mt-4">If you're looking for random paragraphs, you've come to the right place. When a random word or a random sentence isn't quite enough</p> */}
                        <Link to="" className="px-3 py-2 text-md rounded-md bg-darkblue-500 text-white flex items-center">
                            <span>View all</span>
                            <ChevronRightIcon className='ml-1 w-5 h-5 text-white' />
                        </Link>
                    </div>
                    <div className="grid justify-center grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                            <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?1" alt=""/>
                            <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-orange-100 text-orange-500 text-xs font-medium tracking-widest">TRAINING</div>
                            <div className="p-6 space-y-2">
                                <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                                <span className="text-sm m-0 text-gray-500">January 21, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                            </div>
                        </Link>
                        <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                            <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?2" alt=""/>
                            <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-green-50 text-green-500 text-xs font-medium tracking-widest">FOOD TESTING</div>
                            <div className="p-6 space-y-2">
                                <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                                <span className="text-sm m-0 text-gray-500">January 22, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                            </div>
                        </Link>
                        <Link to="" className="max-w-sm mx-auto card-shadow rounded-lg">
                            <img role="presentation" className="object-cover w-full rounded h-48 bg-gray-500" src="https://source.unsplash.com/random/480x360?3" alt=""/>
                            <div class="absolute -mt-3 ml-6 text-center inline-block py-1 px-2 rounded-sm bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">COMPLAINCE</div>
                            <div className="p-6 space-y-2">
                                <h3 className="text-2xl font-semibold">In usu laoreet repudiare legendos</h3>
                                <span className="text-sm m-0 text-gray-500">January 23, 2021</span>
                                <p className="text-sm m-0 text-gray-500">6 min read · 15 people found this useful</p>
                                <p className="text-md tracking-wide text-gray-800 four-line-ellipsis">Mei ex aliquid eleifend forensibus, quo ad dicta apeirian neglegentur, ex has tantas percipit perfecto. At per tempor albucius perfecto, ei probatus consulatu patrioque mea, ei vocent delicata indoctum pri.</p>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="flex justify-center">
                        <button type="button" className="px-6 py-3 text-md rounded-md bg-darkblue-500 text-white">See more posts...</button>
                    </div> */}
                </div>
            </div>

            <E2EComplaince />

            <Newsletter />
            
        </>
    )
}

export default Blogs