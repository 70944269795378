import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import ServicesDialog from "../components/ServicesDialog";
import { images } from "../constants";
// import { useNavigate } from "react-router-dom";
import { ArrowCircleRightIcon } from "@heroicons/react/solid";
import { Helmet } from "react-helmet";
import Faq from "../components/Faq";
import SuggestedBlogs from "../components/SuggestedBlogs";
import E2EComplaince from "../components/E2EComplaince";
import ConstructionDialog from "../components/ConstructionDialog";
import { seo } from "../utils/Utils";
import MobileAppPresentation from "../components/MobileAppPresentation";
import MobileAppPresentationDialog from "../components/MobileAppPresentationDialog";
// import Newsletter from "../components/Newsletter";
// import RequestCallbackDialog from "../components/RequestCallbackDialog";
// import Footer from "../layouts/Footer";
// import { seo } from "../utils/Utils";

var myWindow;

const Landing = () => {

    seo({
        title: 'Aahar Consulting - FSSAI Compliance Made Easy!',
        metaDescription: "One stop solution for food businesses to manage FSSAI Compliance. Created by India's leading Food Technologists and Food Safety Consultants.",
        keywords: "FSSAI Compliance, FSSAI License, FSSAI Regulations, Food Safety Standards,  FSSAI Consultants, Food Safety Consultants, Aahar Consulting, Aahar, Aahar Compliance, Aahar FSSAI"
    });

    myWindow = window.open('', '_self');

    // const navigate = useNavigate();
    const [showDevDialog, setShowDevDialog] = useState(false);
    const [show, setShowDialog] = useState(false);
    const [showRCDialog, setShowRCDialog] = useState(false);
    const [showMAppDialog, setShowMAppDialog] = useState(false);

    const RequestCallbackDialog = React.lazy(() => import('../components/RequestCallbackDialog'));
    const ServicesDialog = React.lazy(() => import('../components/ServicesDialog'));
    const Newsletter = React.lazy(() => import('../components/Newsletter'));

    const ORG_SCHEMA = JSON.stringify({
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Aahar",
        "description": "",
        "url": "https://aaharventures.com/",
        "logo": "https://aaharventures.com/favicon.ico",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "1st Floor, Hustle Hub, 522, 24th Main Rd, Parangi Palaya, Sector 2, HSR Layout",
            "addressLocality": "Bengaluru",
            "addressRegion": "Karnataka",
            "postalCode": "560102",
            // "Telephone": "+918035003600"
        },
        "sameAs": [
            "https://www.facebook.com/",
            "https://www.linkedin.com/",
            "https://www.instagram.com/"
        ]
      });
    
    const ourServices = [
        {
            name: 'FSSAI License',
            services: [
                { name: "New FSSAI Food License", to:"/fssai-food-license" },
                { name: "FSSAI Food License Renewal", to:"/fssai-food-license" },
                { name: "FSSAI Food License Modification", to:"/fssai-food-license" },
                { name: "FLRS to FoSCoS Migration", to:"/comingsoon" },
            ],
            navigation: '/fssai-food-license',
            color: 'darkblue'
        },
        {
            name: 'FSSAI Regulatory',
            services: [
                { name: "File Annual Returns", to:"/comingsoon" },
                { name: "Product Evaluation & Label Review", to:"/comingsoon" },
                { name: "US FDA Registration", to:"/comingsoon" },
                { name: "Export / Import Advisory", to:"/comingsoon" },
            ],
            navigation: '/comingsoon',
            color: 'darkblue'
        },
        {
            name: 'Food Testing',
            services: [
                { name: "Microbial Testing", to:"/testing-food" },
                { name: "Shelf Life Testing", to:"/testing-food" },
                { name: "Nutritional Analysis", to:"/testing-food" },
                { name: "Food Packaging Testing", to:"/testing-food" }
            ],
            navigation: '/testing-food',
            color: 'green'
        },
        {
            name: 'Food Testing',
            services: [
                { name: "Sensory Analysis", to:"/testing-food" },
                { name: "Food Additives & Heavy Metals", to:"/testing-food" },
                { name: "Pesticide & Residual Analysis", to:"/testing-food" },
                { name: "Food Adulterants & Allergens", to:"/testing-food" },
                // { name: "Food Allergens", to:"/testing-food" },
                // { name: "Heavy Metals", to:"/testing-food" }
            ],
            navigation: '/testing-food',
            color: 'green'
        },
        {
            name: 'Water Testing',
            services: [
                { name: "Potable Drinking Water \n(IS 10500:2012)", to:"/testing-water" },
                { name: "Packaged Drinking Water \n(IS 14543:2016)", to:"/testing-water" },
                { name: "Natural Mineral Water (IS 13428:2005)", to:"/testing-water" }
            ],
            navigation: '/testing-water',
            color: 'teal'
        },
        {
            name: 'FoSTaC Training',
            services: [
                { name: "Catering", to:"/training" },
                { name: "Manufacturing", to:"/training" },
                { name: "Storage & Transport", to:"/training" },
                { name: "Retail & Distribution", to:"/training" }
            ],
            navigation: '/training',
            color: 'orange'
        },
        {
            name: 'Health Checkup',
            services: [
                { name: "Food Handler's Medical Fitness Test", to:"/medical-fitness" }
            ],
            navigation: '/medical-fitness',
            color: 'red'
        },
        {
            name: 'Pest Control',
            services: [
                { name: "Pest Control", to:"/pest-control" }
            ],
            navigation: '/pest-control',
            color: 'silver'
        },
        {
            name: 'Instrument Calibration',
            services: [
                { name: "Instrument Calibration", to:"/process" }
            ],
            navigation: '/process',
            color: 'pink'
        },
    ];

    useEffect(() => {
        setTimeout(() => {
            // setShowDevDialog(true);
        }, 5000);
    }, []);
    

    return (
        <>
            <Helmet>
                <meta name="title" content="Aahar Consulting - FSSAI Compliance Made Easy!" />
                <meta name="description" content="One stop solution for food businesses to manage FSSAI Compliance. Created by India's leading Food Technologists and Food Safety Consultants. " />
                <meta name="keywords" content="FSSAI Compliance, FSSAI License, FSSAI Regulations, Food Safety Standards,  FSSAI Consultants, Food Safety Consultants, Aahar Consulting, Aahar, Aahar Compliance, Aahar FSSAI" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <meta name="author" content="Aahar Consulting Pvt. Ltd." />
                <script type='application/ld+json' dangerouslySetInnerHTML={ { __html: ORG_SCHEMA} } />
            </Helmet>
            <div className="">
                <RequestCallbackDialog show={showRCDialog} onDialogClosed={() => setShowRCDialog(false)} />
                <ConstructionDialog show={showDevDialog} onDialogClosed={() => { setShowDevDialog(false) }} onWindowClosed={() => { setShowDevDialog(false); myWindow.close() }} />
                <ServicesDialog show={show} onDialogClosed={() => setShowDialog(false)} />
                {/* <MobileAppPresentationDialog show={showMAppDialog} onDialogClosed={() => setShowMAppDialog(false)} /> */}
                <div className="bg-9 bg-no-repeat bg-top sm:bg-bottom">
                    <div className="min-h-screen max-w-6xl mx-auto pt-16 flex lg:flex-row justify-center align-center">
                        <div className="pt-12 px-4">
                            <div className="relative sm:pb-16 md:pb-18 lg:pb-20 xl:pb-20">
                                <div className="mx-auto max-w-6xl px-4 sm:px-6 md:mt-16 lg:mt-28 lg:px-4">
                                    <div className="text-center">
                                        <h1 className="text-3xl tracking-tight font-bold text-gray-900">
                                            <span className="text-4xl block md:text-6xl">FSSAI Compliance Made Easy!</span>
                                            {/* <span className="text-5xl text-darkblue-500 md:text-6xl">aahar.consulting</span> */}
                                        </h1>
                                        <h1 className="mt-2 text-3xl tracking-tight font-bold text-gray-900">
                                            {/* <span className="text-5xl block md:text-6xl">FSSAI Compliance Made Easy!</span> */}
                                            <span className="text-4xl text-darkblue-500 md:text-6xl">aahar.consulting</span>
                                        </h1>
                                        {/* <div className="sm:hidden pt-10 pb-6">
                                            <img
                                                className="h-52 object-contain sm:h-60 md:h-90 lg:w-full lg:h-full"
                                                src={images.questions}
                                                alt=""
                                            />
                                        </div> */}
                                        <h2 className="max-w-5xl mt-8 tracking-wide text-gray-700 sm:mt-5 sm:text-md sm:mx-auto md:mt-6 md:text-lg lg:mx-0">
                                            <span className="font-normal">
                                                <span className="text-md md:text-xl">
                                                    Our team of food technologists & food safety consultants will help you understand and manage all compliance requirements from FSSAI for your food business. 
                                                    FSSAI License & Regulations, Food Testing, Water Testing, FoSTaC Training, Food Handlers Health Checkup, Pest Control, Equipment Calibration and more.
                                                </span>
                                            </span>
                                        </h2>
                                        <div className="mt-10 md:hidden flex justify-center">
                                            <button
                                                onClick={() => setShowDialog(!show)}
                                                className="transition duration-500 hover:scale-105 md:hover:scale-110 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-darkblue-500 hover:bg-darkblue-700 md:py-4 md:text-lg md:px-10 focus:outline-none"
                                            >
                                                Get Started
                                            </button>
                                        </div>
                                        <div className="mt-6 hidden md:flex md:justify-center lg:mt-24">
                                            <div className="rounded-md shadow">
                                                <button
                                                    onClick={() => {
                                                        setShowDialog(!show);
                                                        // setShowMAppDialog(!showMAppDialog)
                                                    }}
                                                    className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-darkblue-500 hover:bg-darkblue-700 md:py-4 md:text-lg md:px-10 focus:outline-none"
                                                >
                                                    Get Started
                                                </button>
                                            </div>
                                            <div className="mt-3 sm:mt-0 sm:ml-3">
                                                <button
                                                    onClick={() => setShowRCDialog(!showRCDialog)}
                                                    className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-darkblue-500 text-base font-medium rounded-md text-darkblue-700 bg-darkblue-100 hover:bg-darkblue-200 md:py-4 md:text-lg md:px-10 focus:outline-none"
                                                >
                                                    Request Callback
                                                    
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="hidden sm:block sm:m-0 mb-10 lg:-mt-52 px-2 w-35 h-50 md:w-5/6 lg:-mr-32" style={{alignSelf: 'center'}}>
                            <img
                                className="h-52 object-contain sm:h-60 md:h-90 lg:pb-20 lg:w-full lg:h-full"
                                src={images.questions}
                                alt=""
                            />
                        </div> */}
                    </div>
                </div>
                {/* <hr /> */}

                {/* <MobileAppPresentation />
                <hr /> */}


                {/* Our Services */}
                <div className="">
                    <div className="min-h-full max-w-6xl mx-4 mx-auto pb-10 lg:py-20">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                {/* <h2 className="text-base text-purple-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                <span class="inline-block py-1 px-3 rounded-sm bg-purple-200 text-purple-500 text-xs font-medium tracking-widest">CATEGORY</span>
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
                                    Our Services
                                </h1>
                                <h2 className="mt-1 text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                    All our services are designed to deliver maximum cost savings with accurate compliance and hassle-free management.
                                </h2>
                            </div>
                        </div>
                        <div className="mt-8 mx-6 flex flex-1 flex-col justify-between shadow bg-purple-300 rounded-lg pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="text-center">
                                <p className="text-2xl px-2 lg:text-3xl font-bold text-gray-900">
                                    Complete Compliance Package
                                </p>
                                <p className="mt-4 px-4 text-md md:text-lg tracking-wide text-gray-900 md:mx-auto lg:mx-auto">
                                    All in one solution to manage end-to-end compliance needs of your food business. 
                                </p>
                            </div>
                            <div className="px-3 pb-4 grid grid-rows-1 grid-cols-2 gap-4 mx-auto">
                                <button
                                    onClick={() => setShowRCDialog(!showRCDialog)} className={`block text-white bg-purple-500 hover:text-white hover:bg-purple-600 mt-5 px-4 py-4 text-sm font-semibold leading-4 text-center rounded-lg`}>Request Callback</button>
                                <Link to="/completeCompliance" className={`block text-purple-700 bg-purple-100 hover:text-purple hover:bg-purple-200 mt-5 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md`}>Know More</Link>
                            </div>
                        </div>
                        <h1 className="mt-8 mb-5 text-2xl text-center font-extrabold text-gray-900">Individual Services</h1>
                        <div className="mx-6 md:mb-0 grid lg:grid-cols-3 gap-2 md:gap-6 lg:w-full">
                            {ourServices.map((service) =>
                                <div key={service.name} className={`mt-2 flex flex-1 flex-col justify-between shadow bg-${service.color}-300 rounded-lg pt-5 pb-4 sm:p-6 sm:pb-4 lg:w-full`}>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-lg lg:text-xl font-bold text-gray-900">
                                                {service.name}
                                            </h3>
                                            <div className="mt-2 px-4 lg:px-0 flex flex-col">
                                                {service.services.map((item) => 
                                                    <Link key={item.name} to={item.to} className={`flex py-2 items-center text-md text-semibold tracking-wide text-gray-900 hover:text-${service.color}-500`}>
                                                        <ArrowCircleRightIcon className={`h-5 w-5 mr-2 text-${service.color}-500`} aria-hidden="true"></ArrowCircleRightIcon>
                                                        <span className="text-left">{item.name}</span>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 pb-4 grid grid-rows-1 grid-cols-2 gap-4">
                                        <button
                                            onClick={() => setShowRCDialog(!showRCDialog)} className={`block text-white bg-${service.color}-500 hover:text-white hover:bg-${service.color}-600 mt-5 px-2 py-4 text-sm font-semibold leading-4 text-center rounded-lg`}>Request Callback</button>
                                        <Link to={service.navigation} className={`block text-${service.color}-700 bg-${service.color}-100 hover:text-${service.color} hover:bg-${service.color}-200 mt-5 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md`}>Know More</Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <hr />

                {/* Our Process */}
                <div className="min-h-full max-w-6xl mx-auto py-20 bg-white">
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            {/* <h2 className="text-base text-purple-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                            <span class="inline-block py-1 px-3 rounded-sm bg-purple-200 text-purple-500 text-xs font-medium tracking-widest">OUR PROCESS</span>
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
                                How to get started?
                            </h1>
                            <h2 className="mt-4 max-w-2xl text-md sm:text-lg tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                3 easy steps to comply with FSSAI regulations.
                            </h2>
                        </div>
                    </div>
                    <div className="mt-10 grid gap-x-8 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mt-5 mx-auto h-40 object-contain"
                                        src={images.requirement} alt="" />
                                    <div className="mt-8 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            First Conversation
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-5 text-semibold tracking-wide text-gray-700">
                                            Our team will understand the nature of your food business, food products you deal with, problems you are facing and offer the best package from our portfolio of services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-40 mt-5 object-contain"
                                        src={images.online_payment_1} alt="" />
                                    <div className="mt-8 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Payment & Documentation
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-5 text-semibold tracking-wide text-gray-700">
                                            You can make the payment online, provide all necessary documents and assign one person from your team who can co-ordinate with our compliance experts.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-48 object-contain"
                                        src={images.submit_docs} alt="" />
                                    <div className="mt-5 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Submit Documents 
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-0 text-semibold tracking-wide text-gray-700">
                                            Provide all the necessary documents required for FSSAI licence application.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="mx-4 bg-white sm:rounded-lg pt-5 pb-4 sm:pb-4 lg:mt-5">
                            <div className="sm:flex sm:justify-center sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                                    <img
                                        className="mx-auto h-40 mt-5 object-contain"
                                        src={images.relax_1} alt="" />
                                    <div className="mt-8 flex flex-col justify-start lg:justify-between" style={{height: '8.0rem'}}>
                                        <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                            Sit Back & Relax
                                        </h3>
                                        <p className="text-md mt-3 lg:mt-5 text-semibold tracking-wide text-gray-700">
                                            Hand over your compliance burden to us and we will take care of the rest. You can focus on your core business and let our team manage your business's compliance.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                {/* Why are we better */}
                <div className="">
                    <div className="min-h-full max-w-6xl mx-auto py-20 bg-transparent">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="text-center">
                                {/* <h2 className="text-base text-purple-600 font-semibold tracking-wide uppercase">Transactions</h2> */}
                                <span class="inline-block py-1 px-3 rounded-sm bg-purple-200 text-purple-500 text-xs font-medium tracking-widest">WHY ARE WE BETTER?</span>
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-5xl">
                                    Why choose us to manage your FSSAI Compliance?
                                </h1>
                                {/* <p className="mt-4 max-w-2xl text-xl tracking-wide text-gray-700 md:mx-auto lg:mx-auto">
                                    Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                    accusamus quisquam.
                                </p> */}
                            </div>
                        </div>
                        <div className="mt-10 grid gap-16 md:grid-cols-2 lg:grid-cols-2">
                            <div className="mx-4 bg-transparent sm:rounded-lg pt-3 pb-3 sm:pb-4 lg:w-full lg:mt-3 lg:m-5">
                                <div className="sm:flex sm:justify-center sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 lg:flex">
                                        <img
                                            className="mx-auto h-48 lg:h-32 object-contain"
                                            src={images.experts} alt="" />
                                        <div className="mt-5 lg:mt-0 lg:ml-5 lg:text-left flex flex-col justify-start lg:justify-between h-36">
                                            <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                                FSSAI Regulatory Experts
                                            </h3>
                                            <p className="text-md mt-3 lg:mt-3 text-semibold tracking-wide text-gray-700">
                                                Our team consists of food technologists & food safety consultants with profound domain experience and will be able to provide accurate compliance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-4 bg-transparent sm:rounded-lg pt-3 pb-3 sm:pb-4 lg:w-full lg:mt-3 lg:m-5">
                                <div className="sm:flex sm:justify-center sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 lg:flex">
                                        <img
                                            className="mx-auto h-48 lg:h-32 object-contain"
                                            src={images.on_time} alt="" />
                                        <div className="mt-5 lg:mt-0 lg:ml-5 lg:text-left flex flex-col justify-start lg:justify-between h-36">
                                            <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                                Reliable & Timely Service
                                            </h3>
                                            <p className="text-md mt-3 lg:mt-3 text-semibold tracking-wide text-gray-700 lg:mr-6">
                                                We understand the importance of being accurate & on-time while dealing with FSSAI Compliance, thus avoiding any possible penalties.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-4 bg-transparent sm:rounded-lg pt-3 pb-3 sm:pb-4 lg:w-full lg:mt-3 lg:m-5">
                                <div className="sm:flex sm:justify-center sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 lg:flex">
                                        <img
                                            className="mx-auto h-48 lg:h-32 object-contain"
                                            src={images.savings} alt="" />
                                        <div className="mt-5 lg:mt-0 lg:ml-12 lg:text-left flex flex-col justify-start lg:justify-between h-36">
                                            <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                                Reasonable Fees
                                            </h3>
                                            <p className="text-md mt-3 lg:mt-3 text-semibold tracking-wide text-gray-700">
                                                Our consultation charges are reasonable and offer you maximum cost savings to manage end-to-end FSSAI compliance for your business.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-4 bg-transparent sm:rounded-lg pt-3 pb-3 sm:pb-4 lg:w-full lg:mt-3 lg:m-5">
                                <div className="sm:flex sm:justify-center sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 lg:flex">
                                        <img
                                            className="mx-auto h-48 lg:h-32 object-contain"
                                            src={images.happiness} alt="" />
                                        <div className="mt-5 lg:mt-0 lg:ml-5 lg:text-left flex flex-col justify-start lg:justify-between h-36">
                                            <h3 className="text-2xl sm:text-2xl font-bold text-gray-900">
                                                Customer Satisfaction
                                            </h3>
                                            <p className="text-md mt-3 lg:mt-3 text-semibold tracking-wide text-gray-700 lg:mr-6">
                                                Unlike other consultants who quote less and run away in the mid-way, we ensure that we quote reasonable & stay till the completion making sure you are satisfied.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <Faq />
                <hr />

                <E2EComplaince />
                <hr />

                <SuggestedBlogs />
                <hr />

                {/* Subscribe */}
                <Newsletter />
                <hr />
                
            </div>
        </>
    )
}

export default Landing  