export const add_file = require('../assets/img/add_file.png');
export const compliance = require('../assets/img/compliance.png');
export const discuss = require('../assets/img/discuss.png');
export const qr = require('../assets/img/qr.png');
export const relax = require('../assets/img/relax.png');
export const subscribe = require('../assets/img/subscribe.png');
// export const linkedin = require('../assets/img/linkedin.png');
// export const facebook = require('../assets/img/facebook.png');
// export const youtube = require('../assets/img/youtube.png');
export const documents = require('../assets/img/documents.png');
export const documents_1 = require('../assets/img/documents_1.png');
export const folder = require('../assets/img/folder.png');
export const how_to = require('../assets/img/how_to.jpg');
export const not_sure = require('../assets/img/not_sure.png');
export const new_agreement = require('../assets/img/new_agreement.png');
export const renew = require('../assets/img/renew.png');
export const modification = require('../assets/img/modification.png');
export const file_itr = require('../assets/img/file_itr.png');

export const requirement = require('../assets/img/requirement.svg');
export const submit_docs = require('../assets/img/submit_docs.svg');
export const online_payment = require('../assets/img/online_payment.svg');
export const relax_1 = require('../assets/img/relax_1.svg');
export const experts = require('../assets/img/experts.svg');
export const savings = require('../assets/img/savings.svg');
export const on_time = require('../assets/img/on_time.svg');
export const happiness = require('../assets/img/happiness.svg');
export const location = require('../assets/img/location.svg');
export const call = require('../assets/img/call.svg');
export const online_payment_1 = require('../assets/img/online_payment_1.svg');
export const customer = require('../assets/img/customer.png');
export const experts_png = require('../assets/img/experts.png');
export const on_time_png = require('../assets/img/on-time.png');
export const price = require('../assets/img/price.png');
export const lol = require('../assets/img/lol.png');

export const wave_bg = require('../assets/img/wave_bg.svg');
export const sprinkle_bg = require('../assets/img/sprinkle_bg.svg');
export const shiny_bg = require('../assets/img/shiny-bg.svg');
export const meteor_bg = require('../assets/img/meteor_bg.svg');
export const curve_line_bg = require('../assets/img/curve_line_bg.svg');
export const animated_shape = require('../assets/img/animated_shape.svg');
export const eight = require('../assets/img/8.svg');
// export const linkedin = require('../assets/img/linkedin.svg');
// export const facebook = require('../assets/img/facebook.svg');
// export const twitter = require('../assets/img/twitter.svg');
// export const youtube = require('../assets/img/youtube.svg');
export const linkedin = require('../assets/img/linkedin.png');
export const linkedin_share = require('../assets/img/linkedin_share.png');
export const facebook = require('../assets/img/facebook.png');
export const facebook_share = require('../assets/img/facebook_share.png');
export const twitter = require('../assets/img/twitter.png');
export const twitter_share = require('../assets/img/twitter_share.png');
export const youtube = require('../assets/img/youtube.png');
export const share = require('../assets/img/share.png');
export const whatsapp_share = require('../assets/img/whatsapp_share.png');
export const team = require('../assets/img/team.svg');
export const certification = require('../assets/img/certification.svg');
export const rc_calling = require('../assets/img/rc_calling.svg');
export const coming_soon = require('../assets/img/coming_soon.svg');
export const not_found = require('../assets/img/404.svg');
export const career = require('../assets/img/career.svg');
export const value_1 = require('../assets/img/value_1.svg');
export const value_2 = require('../assets/img/value_2.svg');
export const value_3 = require('../assets/img/value_3.svg');
export const value_4 = require('../assets/img/value_4.svg');
export const value_5 = require('../assets/img/value_5.svg');
export const updated_resume = require('../assets/img/updated_resume.svg');
export const interview = require('../assets/img/interview.svg');
export const life_at_aahar = require('../assets/img/life_at_aahar.png');
export const thought_process = require('../assets/img/thought_process.svg');
export const questions = require('../assets/img/questions.svg');
export const landing_bg = require('../assets/img/landing_bg.png');
export const migration = require('../assets/img/migration.png');
export const claps = require('../assets/img/claps.png');
export const heart_outline = require('../assets/img/heart_outline.png');
export const heart_filled = require('../assets/img/heart_filled.png');
export const logo = require('../assets/img/logo.png');
export const certificate = require('../assets/img/certificate.png');
export const certificate_dark = require('../assets/img/certificate_dark.png');
export const license = require('../assets/img/license.png');
export const home_icon = require('../assets/img/home_icon.png');
export const license_1 = require('../assets/img/license_1.png');
export const license_dark = require('../assets/img/license_dark.png');
export const health = require('../assets/img/health.png');
export const fitness_mob = require('../assets/img/fitness_mob.svg');
export const health_dark = require('../assets/img/health_dark.png');
export const water_testing = require('../assets/img/water_testing.png');
export const water_testing_mob = require('../assets/img/water_testing_mob.svg');
export const water_testing_dark = require('../assets/img/water_testing_dark.png');
export const food_testing = require('../assets/img/food_testing.png');
export const food_testing_mob = require('../assets/img/food_testing_mob.svg');
export const food_testing_dark = require('../assets/img/food_testing_dark.png');
export const food_testing_1 = require('../assets/img/food_testing_1.png');
export const compliance_1 = require('../assets/img/compliance_1.png');
export const compliance_2 = require('../assets/img/compliance_2.png');
export const compliance_dark = require('../assets/img/compliance_dark.png');
export const pest_control_dark = require('../assets/img/pest_control_dark.png');
export const fostac_certificate = require('../assets/img/fostac_certificate.jpeg');


export const training = require('../assets/img/training.svg');
export const training_mob = require('../assets/img/training_mob.svg');
export const training_details_mob = require('../assets/img/training_details_mob.svg');
export const benefits_certificate = require('../assets/img/benefits_certificate.svg');
export const benefits_knowledge = require('../assets/img/benefits_knowledge.svg');
export const benefits_complaince = require('../assets/img/benefits_complaince.svg');
export const training_certificate = require('../assets/img/training_certificate.svg');
export const training_video_call = require('../assets/img/training_video_call.svg');
export const training_payment = require('../assets/img/training_payment.svg');
export const training_support = require('../assets/img/training_support.svg');
export const img_1 = require('../assets/img/img_1.svg');
export const img_2 = require('../assets/img/img_2.svg');

export const regulatory = require('../assets/img/regulatory.png');
export const regulatory_img = require('../assets/img/regulatory.svg');
export const regulatory_details_mob = require('../assets/img/regulatory_details_mob.svg');
export const regulatory_dark = require('../assets/img/regulatory_dark.png');
export const product = require('../assets/img/product.png');
export const people = require('../assets/img/people.png');
export const process = require('../assets/img/process.png');
export const cc = require('../assets/img/cc.png');
export const calibration_dark = require('../assets/img/calibration_dark.png');

// Newsletter Images
export const newsletter_purple = require('../assets/img/newsletter_purple.svg');
export const newsletter_darkblue = require('../assets/img/newsletter_darkblue.svg');
export const newsletter_green = require('../assets/img/newsletter_green.svg');
export const newsletter_teal = require('../assets/img/newsletter_teal.svg');
export const newsletter_blue = require('../assets/img/newsletter_blue.svg');
export const newsletter_orange = require('../assets/img/newsletter_orange.svg');
export const newsletter_red = require('../assets/img/newsletter_red.svg');
export const newsletter_silver = require('../assets/img/newsletter_silver.svg');
export const newsletter_pink = require('../assets/img/newsletter_pink.svg');


// E2E Compliances Images
export const e2e_purple = require('../assets/img/e2e_purple.svg');
export const e2e_darkblue = require('../assets/img/e2e_darkblue.svg');
export const e2e_green = require('../assets/img/e2e_green.svg');
export const e2e_teal = require('../assets/img/e2e_teal.svg');
export const e2e_blue = require('../assets/img/e2e_blue.svg');
export const e2e_orange = require('../assets/img/e2e_orange.svg');
export const e2e_red = require('../assets/img/e2e_red.svg');
export const e2e_silver = require('../assets/img/e2e_silver.svg');
export const e2e_pink = require('../assets/img/e2e_pink.svg');

// Request Callback Images
export const rc_purple = require('../assets/img/rc_purple.svg');
export const rc_darkblue = require('../assets/img/rc_darkblue.svg');
export const rc_green = require('../assets/img/rc_green.svg');
export const rc_teal = require('../assets/img/rc_teal.svg');
export const rc_orange = require('../assets/img/rc_orange.svg');
export const rc_red = require('../assets/img/rc_red.svg');
export const rc_silver = require('../assets/img/rc_silver.svg');
export const rc_pink = require('../assets/img/rc_pink.svg');

export const doc_1 = require('../assets/img/doc_1.png');
export const doc_2 = require('../assets/img/doc_2.png');
export const doc_3 = require('../assets/img/doc_3.png');
export const doc_4 = require('../assets/img/doc_4.png');
export const doc_5 = require('../assets/img/doc_5.png');
export const doc_6 = require('../assets/img/doc_6.png');
export const doc_7 = require('../assets/img/doc_7.png');
export const doc_8 = require('../assets/img/doc_8.png');
export const doc_9 = require('../assets/img/doc_9.png');
export const doc_10 = require('../assets/img/doc_10.png');

export const food_test_why_1 = require('../assets/img/food_test_why_1.png');
export const food_test_why_2 = require('../assets/img/food_test_why_2.png');
export const food_test_why_3 = require('../assets/img/food_test_why_3.png');
export const food_test_why_4 = require('../assets/img/food_test_why_4.png');
export const food_test_why_5 = require('../assets/img/food_test_why_5.png');
export const food_test_why_6 = require('../assets/img/food_test_why_6.png');

export const water_test_why_1 = require('../assets/img/water_test_why_1.png');
export const water_test_why_2 = require('../assets/img/water_test_why_2.png');
export const water_test_why_3 = require('../assets/img/water_test_why_3.png');
export const water_test_why_4 = require('../assets/img/water_test_why_4.png');
export const water_test_why_5 = require('../assets/img/water_test_why_5.png');
export const water_test_why_6 = require('../assets/img/water_test_why_6.png');

export const medical_fitness_why_1 = require('../assets/img/medical_fitness_why_1.png');
export const medical_fitness_why_2 = require('../assets/img/medical_fitness_why_2.png');
export const medical_fitness_why_3 = require('../assets/img/medical_fitness_why_3.png');
export const medical_fitness_why_4 = require('../assets/img/medical_fitness_why_4.png');
export const medical_fitness_why_5 = require('../assets/img/medical_fitness_why_5.png');
export const medical_fitness_why_6 = require('../assets/img/medical_fitness_why_6.png');

export const mobile_feature_1 = require('../assets/img/mobile_feature_1.png');
export const mobile_feature_2 = require('../assets/img/mobile_feature_2.png');
export const mobile_feature_3 = require('../assets/img/mobile_feature_3.png');
export const mobile_feature_4 = require('../assets/img/mobile_feature_4.png');

export const mobile_device = require('../assets/img/mobile_device.svg');
export const mobile_pink = require('../assets/img/mobile_pink.svg');
export const mobile_silver = require('../assets/img/mobile_silver.svg');
export const mobile_red = require('../assets/img/mobile_red.svg');
export const mobile_orange = require('../assets/img/mobile_orange.svg');
export const mobile_teal = require('../assets/img/mobile_teal.svg');
export const mobile_green = require('../assets/img/mobile_green.svg');
export const mobile_purple = require('../assets/img/mobile_purple.svg');
export const mobile_darkblue = require('../assets/img/mobile_darkblue.svg');

export const heart = require('../assets/img/heart.png');
export const circle_chevron_right = require('../assets/img/circle_chevron_right.png');
export const heart_animated = require('../assets/img/heart_animated.gif');


export default {
    add_file,
    compliance,
    discuss,
    qr,
    relax,
    subscribe,
    linkedin,
    linkedin_share,
    facebook,
    facebook_share,
    twitter,
    twitter_share,
    youtube,
    share,
    whatsapp_share,
    documents,
    documents_1,
    folder,
    how_to,
    not_sure,
    new_agreement,
    renew,
    modification,
    file_itr,
    home_icon,
    relax_1,
    submit_docs,
    online_payment,
    online_payment_1,
    requirement,
    experts,
    savings,
    on_time,
    happiness,
    location,
    call,
    customer,
    experts_png,
    on_time_png,
    price,
    lol,

    wave_bg,
    sprinkle_bg,
    shiny_bg,
    meteor_bg,
    curve_line_bg,
    animated_shape,
    eight,
    team,
    certification,
    rc_calling,
    not_found,
    coming_soon,
    career,
    value_1,
    value_2,
    value_3,
    value_4,
    value_5,
    updated_resume,
    interview,
    life_at_aahar,
    thought_process,
    questions,
    landing_bg,
    migration,
    claps,
    heart_outline,
    heart_filled,
    logo,
    certificate,
    certificate_dark,
    license,
    license_1,
    license_dark,
    health,
    health_dark,
    water_testing,
    water_testing_dark,
    food_testing,
    food_testing_dark,
    food_testing_1,
    compliance_1,
    compliance_2,
    compliance_dark,
    pest_control_dark,
    fostac_certificate,
    regulatory_img,
    training_mob,
    training_details_mob,
    food_testing_mob,
    water_testing_mob,
    fitness_mob,

    training,
    benefits_certificate,
    benefits_knowledge,
    benefits_complaince,
    training_certificate,
    training_video_call,
    training_payment,
    training_support,
    img_1,
    img_2,

    regulatory,
    regulatory_details_mob,
    regulatory_dark,
    product,
    people,
    process,
    cc,
    calibration_dark,

    newsletter_purple,
    newsletter_darkblue,
    newsletter_green,
    newsletter_teal,
    newsletter_blue,
    newsletter_orange,
    newsletter_red,
    newsletter_silver,
    newsletter_pink,

    e2e_purple,
    e2e_darkblue,
    e2e_green,
    e2e_teal,
    e2e_blue,
    e2e_orange,
    e2e_red,
    e2e_silver,
    e2e_pink,

    rc_purple,
    rc_darkblue,
    rc_green,
    rc_teal,
    rc_orange,
    rc_red,
    rc_silver,
    rc_pink,

    doc_1,
    doc_2,
    doc_3,
    doc_4,
    doc_5,
    doc_6,
    doc_7,
    doc_8,
    doc_9,
    doc_10,
    
    heart,
    heart_animated,
    circle_chevron_right,

    food_test_why_1,
    food_test_why_2,
    food_test_why_3,
    food_test_why_4,
    food_test_why_5,
    food_test_why_6,

    water_test_why_1,
    water_test_why_2,
    water_test_why_3,
    water_test_why_4,
    water_test_why_5,
    water_test_why_6,

    medical_fitness_why_1,
    medical_fitness_why_2,
    medical_fitness_why_3,
    medical_fitness_why_4,
    medical_fitness_why_5,
    medical_fitness_why_6,

    mobile_feature_1,
    mobile_feature_2,
    mobile_feature_3,
    mobile_feature_4,

    mobile_device,
    mobile_pink,
    mobile_silver,
    mobile_red,
    mobile_orange,
    mobile_teal,
    mobile_green,
    mobile_purple,
    mobile_darkblue

}