import React from 'react';
import Carousel from 'react-grid-carousel';
import { Link } from 'react-router-dom';
import { courses } from '../pages/CourseCatalog';

const GridCarousel = () => {
    const responsiveLayout = [
        {
            breakpoint: 1200,
            cols: 4
        },
        {
            breakpoint: 980,
            cols: 3
        },
        {
            breakpoint: 768,
            cols: 1,
            autoplay: 2000,
            gap: 3
        },
        {
            breakpoint: 480,
            cols: 1,
            autoplay: 2000,
            gap: 3
        }
    ];
    return (
        <Carousel
            rows={1}
            cols={4}
            gap={5} 
            responsiveLayout={responsiveLayout}
            mobileBreakpoint={670}
            loop
            showDots={true}
        >
            {courses.map((course) => (
                <Carousel.Item>
                    <div key={course.courseName} className="group relative p-5 mb-10">
                        <div className="w-full h-32 min-h-40 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-40 lg:aspect-none">
                            <img
                                src={'https://placeimg.com/300/300/tech'}
                                alt={course.imageAlt}
                                className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                            />
                        </div>
                        <div className="mt-4 flex justify-between">
                            <div>
                                <h3 className="text-sm text-gray-700 two-line-ellipsis">
                                    <a href={course.href} className="text-lg text-gray-900 font-semibold">
                                        <span aria-hidden="true" className="absolute inset-0" />
                                        {course.courseName}
                                    </a>
                                </h3>
                                <p className="mt-1 text-md text-gray-500">Trainer: {course.trainer}</p>
                                <p className="mt-2">
                                    <span className="text-xl font-bold">₹ {course.fees}</span>
                                    {/* <span className="ml-2 text-gray-700 font-semibold">per licence</span> */}
                                </p>
                                <div className='mt-8'>
                                    <Link to="/checkout" className="text-white bg-orange-500 hover:text-white hover:bg-orange-600 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md">Buy Now</Link>
                                </div>
                            </div>
                            {/* <p className="text-sm font-medium text-gray-900">{course.fees}</p> */}
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

export default GridCarousel;