import { PhoneIcon, MailIcon, LocationMarkerIcon, CheckCircleIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../actions/marketing';
// import { Link } from 'react-router-dom';
import { images } from '../constants';

const ContactUs = () => {

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  
        name: '',
        email: '',
        phoneNo: '',
        city: '',
        message: '',
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Contact Us!');
        console.log('Form Data : ', formData);
        dispatch(contactUs(formData));
        setShowMessage(true);
    };

    return (
        <div className="max-w-6xl mx-auto">
            <div className="relative pb-8 bg-white pt-5 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                <div className="mt-10 mx-auto max-w-6xl px-8 pt-5 md:pt-0 mt-20 sm:px-6 md:mt-20 lg:mt-32 xl:mt-32">
                    <div className="text-center">
                        <div class="ml-2 text-center inline-block py-1 px-2 rounded-lg bg-darkblue-50 text-darkblue-500 text-xs font-medium tracking-widest">CONTACT US</div>
                        <div className="">
                            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl md:text-5xl lg:text-5xl">
                                We're here for you...
                            </h1>
                        </div>
                        <h2 className="mt-3 tracking-wide text-lg md:text-xl text-center text-gray-700">
                            Get in touch with us and let us know how we can help!
                        </h2>
                    </div>
                </div>
                <div className="sm:max-w-60 md:max-w-3xl mx-auto lg:max-w-6xl mt-10 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-10 md:bg-gray-100 text-gray-900 md:rounded-lg md:shadow-lg">
                    <div className="flex flex-col justify-between">
                        <div>
                            {/* <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl lg:text-4xl">Get In Touch</h2> */}
                            {/* <div className="text-gray-700 mt-8">
                                Hate forms? Send us an <span className="underline">email</span> instead.
                            </div> */}
                            <img className='w-64 md:w-full mx-auto' src={images.location} alt="location" />
                            <div className="mt-8 flex flex-col">
                                <div className='flex flex-row items-start'>
                                    <PhoneIcon className='w-8 h-8 text-darkblue-600' aria-hidden="true" style={{flexShrink: 0}} />
                                    <a href='tel:18001020127' aria-label="Contact us by using the number" className='ml-5 text-base text-md text-left text-gray-700 hover-link'>1800 1020 127</a>
                                </div>
                                <div className='mt-5 flex flex-row items-start'>
                                    <MailIcon className='w-8 h-8 text-darkblue-600' aria-hidden="true" style={{flexShrink: 0}} />
                                    <a href='mailto:team@aahar.in' aria-label="Contact us by mailing team@aahar.in" className='ml-5 text-base text-md text-left text-gray-700 hover-link'>team@aahar.in</a>
                                </div>
                                <div className='mt-5 flex flex-row items-start'>
                                    <LocationMarkerIcon className='w-8 h-8 text-darkblue-600' aria-hidden="true" style={{flexShrink: 0}} />
                                    <p className='ml-5 text-base text-md text-left text-gray-700'>1st Floor, Hustle Hub, 522, 24th Main Rd, Parangi Palaya, Sector 2, HSR Layout, Bengaluru, Karnataka 560102</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="md:ml-5">
                            <h1 className="text-2xl font-bold tracking-tight text-gray-900 md:text-3xl lg:text-4xl">Get In Touch</h1>
                            <div className='mt-5'>
                                <span className="text-sm text-gray-600 font-bold">Full Name</span>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={onChange}
                                    autoComplete="name"
                                    placeholder="Enter name"
                                    required
                                    className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                            </div>
                            <div className="mt-5">
                                <span className="text-sm text-gray-600 font-bold">Email</span>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={onChange}
                                    autoComplete="email"
                                    placeholder="Enter email"
                                    required
                                    className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                            </div>
                            <div className="mt-5 flex flex-row justify-between">
                                <div className='w-full'>
                                    <span className="text-sm text-gray-600 font-bold">Phone No</span>
                                    <input
                                        type="tel"
                                        name="phoneNo"
                                        id="phoneNo"
                                        onChange={onChange}
                                        maxLength="10"
                                        autoComplete="tel"
                                        placeholder="Enter phone no"
                                        required
                                        className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                                </div>
                                <div className='w-full ml-5'>
                                    <span className="text-sm text-gray-600 font-bold">City</span>
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        onChange={onChange}
                                        autoComplete="home city"
                                        placeholder="Enter city"
                                        required
                                        className='form-input-lg mt-3 p-3 lg:w-full border border-gray-300 hover:border-gray-400 focus:ring-darkblue-500 focus:border-darkblue-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md' />
                                </div>
                            </div>
                            <div className="mt-5">
                                <span className="text-sm text-gray-600 font-bold">Message</span>
                                <textarea
                                    type="text"
                                    name="message"
                                    id="message"
                                    onChange={onChange}
                                    style={{height: '7rem', padding: '1rem'}}
                                    placeholder='Enter Message'
                                    className="form-input-lg w-full h-40 bg-gray-300 text-gray-900 mt-3 p-5 border border-gray-300 hover:border-gray-400 rounded-md sm:text-sm md:text-md lg:text-md focus:ring-darkblue-500 focus:border-darkblue-500"></textarea>
                            </div>
                            {!showMessage && <div className="mt-8">
                                <button type='submit' className="text-sm font-bold tracking-wide bg-darkblue-500 text-gray-100 p-3 rounded-lg w-full hover:bg-darkblue-700 focus:ring-darkblue-500 focus:border-darkblue-500">
                                    Send Message
                                </button>
                            </div>}
                            {showMessage && <div className="mt-5 flex justify-center">
                                <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                    <span className='flex items-center'>
                                        <CheckCircleIcon className={`w-5 h-5 text-darkblue-500`} />
                                        <span className='ml-2'> {state?.contactUs?.msg} </span>
                                    </span>
                                </div>
                            </div>}
                        </div>
                    </form>
                </div>
                
            </div>
        </div>
    )
}

export default ContactUs;