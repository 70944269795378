import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
// import RequestCallback from './RequestCallback';
import { images } from '../constants';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import { requestCallback } from '../actions/marketing';

const RequestCallbackDialog = ({ show, onDialogClosed, topic }) => {

    const rcImages = [
        images.rc_purple,
        images.rc_darkblue,
        images.rc_green,
        images.rc_teal,
        images.rc_orange,
        images.rc_red,
        images.rc_silver,
        images.rc_pink,
    ];

    const { primaryColor } = useSelector(state => state.app);

    let bgColor = 'darkblue';
    if (primaryColor === 'white') {
        bgColor = `darkblue`;
    } else {
        bgColor = `${ primaryColor }`;
    }

    const rcImage = rcImages.filter((e) => e.includes(bgColor))[0];

    const dispatch = useDispatch();
    const state = useSelector(state => state?.marketing);

    const [formData, setFormData] = useState({  
        name: '',
        email: '',
        phoneNo: '',
        topic: topic || ''
    });
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                setShowMessage(false);
            }, 7500);
        }
    }, [showMessage]);

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (event) => {
        event.preventDefault();
        console.log('Request Callback!');
        console.log('Form Data : ', formData);
        dispatch(requestCallback(formData));
        setShowMessage(true);
    };

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onDialogClosed}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-x-hidden overflow-y-auto">
                    <div className="flex items-center md:items-center justify-center min-h-full text-center md:px-2 lg:px-4">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        enterTo="opacity-100 translate-y-0 md:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 md:scale-100"
                        leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex text-base text-left transform transition max-w-md md:max-w-5xl md:px-4 my-8 md:my-8 lg:max-w-7xl">
                                <div className="bg-white rounded-lg mx-5 mt-16">
                                <div className="flex justify-center items-center">
                                        {/* <h1 className="mt-5 text-2xl text-center font-extrabold text-gray-900">Our Services</h1> */}
                                        <div style={{right: 0}} className="absolute mt-12 md:mt-16 text-right pr-8 md:pr-12 px-4 border border-transparent">
                                            <button
                                                className='focus:outline-none'
                                                onClick={() => onDialogClosed()}
                                            >
                                                <XIcon className='h-5 md:h-8 w-5 md:w-8 text-gray-900' aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-7xl md:max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                        <div className="mx-auto mx-auto py-10 flex flex-col items-stretch md:flex-row">
                                            <div className='xs:hidden mt-5 md:w-1/2' style={{margin: 'auto'}}>
                                                <img src={rcImage} className="text-red-500" alt='request-callback' />
                                            </div>
                                            <div className='mt-5 md:w-1/2 flex flex-col'>
                                                <h2 className="mx-auto text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                                                    Request <span className={`text-${bgColor}-500`}>Callback</span>
                                                </h2>
                                                <form onSubmit={onSubmit}>
                                                    <div className="px-5 md:px-16 py-5">
                                                        <div className="flex flex-col items-stretch justify-center">
                                                            <div className="pb-5">
                                                                <input
                                                                    type="name"
                                                                    name="name"
                                                                    id="name"
                                                                    onChange={onChange}
                                                                    autoComplete="name"
                                                                    placeholder="Enter your name"
                                                                    className={`form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-${bgColor}-500 focus:border-${bgColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="pb-5 ">
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    id="email"
                                                                    onChange={onChange}
                                                                    autoComplete="email"
                                                                    placeholder="Enter your email"
                                                                    className={`form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-${bgColor}-500 focus:border-${bgColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="pb-5">
                                                                <input
                                                                    type="tel"
                                                                    name="phoneNo"
                                                                    id="phoneNo"
                                                                    onChange={onChange}
                                                                    maxLength="10"
                                                                    autoComplete="tel"
                                                                    placeholder="Enter your phone no"
                                                                    className={`form-input py-3 border border-gray-300 hover:border-gray-400 focus:ring-${bgColor}-500 focus:border-${bgColor}-500 block w-full shadow-sm sm:text-sm md:text-md lg:text-md rounded-md`}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!showMessage && <div className='flex flex-col-reverse md:flex-col'>
                                                        <div className="px-5 md:px-16 flex items-start my-3">
                                                            <input
                                                                id="send-communications"
                                                                name="send-communications"
                                                                type="checkbox"
                                                                defaultChecked={true}
                                                                className={`mt-1 h-3 w-3  bg-${ bgColor }-600 text-${ bgColor }-500 rounded`}
                                                                style={{flexShrink: 0}}
                                                            />
                                                            <label htmlFor="send-communications" className="ml-2 text-xs md:text-sm text-gray-900">
                                                                By clicking on request callback, I provide my consent to receive communications from Aahar. Check our
                                                                <Link to="/terms" onClick={() => onDialogClosed()} className={`text-${ bgColor }-500 hover:text-${ bgColor }-700`}> terms & conditions </Link> .
                                                            </label>
                                                        </div>
                                                        <div className="px-5 md:px-16 my-3">
                                                            <button type='submit'
                                                                className={`w-full lg:px-6 py-3 p-3 block text-white bg-${bgColor}-500 hover:text-white hover:bg-${bgColor}-600 text-sm font-semibold text-center rounded-lg shadow-md`}
                                                                >Request Callback</button>
                                                        </div>
                                                    </div>}
                                                    {showMessage && <div className="mt-5 flex justify-center">
                                                        <div class={`ml-2 text-center inline-block py-1 px-2 rounded-full text-xs font-medium tracking-wide`} style={{backgroundColor: '#333', color: '#fff'}}>
                                                            <span className='flex items-center'>
                                                                <CheckCircleIcon className={`w-5 h-5 text-darkblue-500`} />
                                                                <span className='ml-2'> {state?.requestCallback?.msg} </span>
                                                            </span>
                                                        </div>
                                                    </div>}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="md:hidden absolute w-full text-center pr-10 px-4" style={{bottom: 10}}>
                                        <button
                                            style={{bottom: 0}}
                                            className="absolute top-4 right-4 text-gray-400 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                            onClick={() => onDialogClosed()}
                                        >
                                            <XCircleIcon className={`h-8 w-8 mr-2 text-${bgColor}-600`} aria-hidden="true" />
                                        </button>
                                    </div> */}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default RequestCallbackDialog;