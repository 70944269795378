import React from 'react';
import Carousel from 'react-grid-carousel';
import "react-multi-carousel/lib/styles.css";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SuggestedBlogs = () => {

    const { primaryColor } = useSelector(state => state.app);
    
    let bgColor = 'bg-gray-200';
    if (primaryColor === 'white') {
        bgColor = `darkblue`;
    } else {
        bgColor = `${primaryColor}`;
    }

    // Data
    const data = [
        {
            "title": "Food Teting on Dairy and Meat Products",
            "link": "/bloghome",
            "imageUrl": "https://placeimg.com/300/300/any",

        },
        {
            "title": "Find me on Twitter",
            "link": "/bloghome",
            "imageUrl": "https://placeimg.com/300/300/any"
        },
        {
            "title": "Find me on Twitter",
            "link": "/bloghome",
            "imageUrl": "https://placeimg.com/300/300/any"
        },
        {
            "title": "Find me on Twitter",
            "link": "/bloghome",
            "imageUrl": "https://placeimg.com/300/300/any"
        },
        {
            "title": "Find me on Twitter",
            "link": "/bloghome",
            "imageUrl": "https://placeimg.com/300/300/any"
        },
        
    ];

    const responsiveLayout = [
        {
            breakpoint: 1200,
            cols: 4
        },
        {
            breakpoint: 980,
            cols: 3
        },
        {
            breakpoint: 768,
            cols: 1,
            autoplay: 2000,
            gap: 1
        },
        {
            breakpoint: 480,
            cols: 1,
            autoplay: 2000,
            gap: 1
        }
    ];

    return (
        <div className="py-10 md:py-20">
            <div className="px-4 md:pl-40 md:pr-10 mx-auto flex flex-col md:flex-row justify-between items-center">
                <div className='w-full md:w-1/4'>
                    <div className="text-center md:text-left">
                        <span class={`inline-block py-1 px-3 rounded-sm bg-${bgColor}-100 text-${bgColor}-500 text-xs font-medium tracking-widest`}>BLOGS</span>
                    </div>
                    <h1 className="text-center sm:text-left mt-2 text-3xl font-extrabold tracking-normal text-gray-900 md:text-4xl lg:text-5xl">
                        Learn before you buy
                    </h1>
                    <div className='text-center sm:text-left mt-8 md:mt-10'>
                        <Link to="/blogs" className={`text-white bg-${bgColor}-500 hover:text-white hover:bg-${bgColor}-700 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md`}>Visit Blogs</Link>
                    </div>
                </div>
                <div className='w-full mt-10 md:mt-0 md:w-3/4'>
                    <Carousel
                        rows={1}
                        cols={3}
                        gap={5} 
                        responsiveLayout={responsiveLayout}
                        mobileBreakpoint={670}
                        // loop
                        showDots={false}
                        // autoplay={3000}
                        arrowLeft={<div></div>}
                        arrowRight={<div></div>}
                    >
                        {data.map((d, idx) => (
                            <Carousel.Item>
                                <div key={idx} className="group p-3 relative mb-10">
                                    <div className="w-full h-32 min-h-40 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-40 lg:aspect-none">
                                        <img
                                            src={d.imageUrl}
                                            alt={d.imageAlt}
                                            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                                        />
                                    </div>
                                    <div className="mt-4 flex justify-between">
                                        <div>
                                            <h3 className="text-sm text-gray-700 two-line-ellipsis">
                                                <a href={d.link} className="text-lg text-gray-900 font-semibold h-16 two-line-ellipsis">
                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                    {d.title}
                                                </a>
                                            </h3>
                                            <p className="mt-1 text-sm text-gray-500">Posted On: 23 Jun 2022</p>
                                            <p className="mt-2 tracking-loose text-gray-700 three-line-ellipsis">
                                                This blog is woth respect to the food testing done on dairy and meat products.
                                                The initial analysis says that, both products have limited expiry upon production
                                            </p>
                                            <div className='hidden sm:block mt-8'>
                                                <Link to="/blogs" className={`text-white bg-${ bgColor }-500 hover:text-white hover:bg-${ bgColor }-700 mt-2 px-6 py-4 text-sm font-semibold leading-4 text-center rounded-lg shadow-md`}>Read More</Link>
                                            </div>
                                        </div>
                                        {/* <p className="text-sm font-medium text-gray-900">{course.fees}</p> */}
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
  );
};

export default SuggestedBlogs;
