import React from 'react';

const Splash = () => {
  return (
    <div className="h-screen max-w-6xl mx-auto">
        <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <div className="mt-10 mx-auto max-w-7xl px-8">
                <div className="text-center text-left flex justify-center items-center h-screen">
                    <div className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                        <p className="block text-purple-500 animate-bounce">Loading...</p>
                    </div>
                </div>
            </div>
          </div>
    </div>
  )
}

export default Splash