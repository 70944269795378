import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { images } from '../constants'

const ConstructionDialog = ({ show, onDialogClosed, onWindowClosed }) => {

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onDialogClosed}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-40">
                <div className="bg-white px-10 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex flex-col justify-center items-center">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Deactivate account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All of your data will be permanently
                          removed. This action cannot be undone.
                        </p>
                      </div>
                    </div> */}
                    <div className="mt-10 flex justify-center">
                        <img className='h-64' src={images.coming_soon} alt='not-found' />
                    </div>  
                    <div className="mt-10 text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                        <h1 className="block xl:inline">Work in progress</h1>{' '}
                        {/* <p className="block text-purple-600 xl:inline">Introducing aahar.compliance!</p> */}
                    </div>
                    <h2 className="mt-3 text-center text-base tracking-wider text-gray-700 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                        We're almost in the final stage of completing our website and you might experience some glitches, do you still want to check us out? 
                    </h2>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:flex sm:flex-row-reverse sm:justify-center sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => { onDialogClosed(true); }}
                  >
                    Yes, never mind 
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onWindowClosed(true)}
                  >
                    No, visit later
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ConstructionDialog;