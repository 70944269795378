import { ChatAltIcon, HomeIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { images } from '../constants';
import RequestCallbackDialog from './RequestCallbackDialog';
import ServicesDialog from './ServicesDialog';


const LandingActions = ({path}) => {
    
    // const navigate = useNavigate();
    const location = useLocation();
    const currentLocation = location.pathname;
    const [showDialog, setShowDialog] = useState(false);
    const [showRCDialog, setShowRCDialog] = useState(false);
    const [showLayout, setShowLayout] = useState(false);

    // useEffect(() => {
    //     console.log('Path in Landing Actions : ', path);
    //     path === '/' ? setShowLayout(false) : setShowLayout(true);
    // }, [path])
    
    
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll)
    }, []);

    const onScroll = () => {
        // if (path !== '/') return;

        const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        console.log(scrolled.toFixed(2));
        if (scrolled.toFixed(2) > 0.01) {
            setShowLayout(true);
        } else {
            setShowLayout(false);
        }
    }

    const { primaryColor } = useSelector(state => state.app);
    
    let bgColor = 'bg-white';
    let btnColor = 'bg-blue-500';
    
    if (primaryColor === 'white') {
        bgColor = `bg-darkblue-500`
    } else {
        bgColor = `bg-${primaryColor}-500`
    }

    return (
        <div>
            <RequestCallbackDialog show={showRCDialog} onDialogClosed={() => { setShowRCDialog(false); setShowLayout(true) }} />
            <ServicesDialog show={showDialog} onDialogClosed={() => { setShowDialog(false); setShowLayout(true) }} />
            {showLayout && <div className="transition delay-150 duration-300 ease-in-out fixed inset-x-0 bottom-0 p-2 z-50 bg-white border border-top flex flex-row justify-around">
                <div
                    onClick={() => { setShowDialog(!showDialog); setShowLayout(false)}}
                    className={`shadow ${bgColor} p-3 transition duration-500 hover:scale-105 md:hover:scale-110 transition duration-500 hover:scale-105 md:hover:scale-110 border border-transparent text-base font-medium rounded-md text-white ${bgColor} hover:bg-${ bgColor }-700 hover:bg-${ bgColor }-700 focus:outline-none`} style={{ borderRadius: '50%' }}>
                    <img src={images.home_icon} alt="home-icon" className='w-6 h-6 text-white-500' />
                </div>
                {/* <div className="rounded-md shadow">
                    <button
                        onClick={() => { setShowDialog(!showDialog); setShowLayout(false)}}
                        className={`transition duration-500 hover:scale-105 md:hover:scale-110 transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white ${bgColor} hover:${bgColor} hover:${bgColor} focus:outline-none`}
                    >
                        Our Services
                    </button>
                </div> */}
                <div className="">
                    <button
                        onClick={() => { setShowRCDialog(!showRCDialog); setShowLayout(false)}}
                        className={`transition duration-500 hover:scale-105 md:hover:scale-110 transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white ${bgColor} hover:${bgColor} hover:${bgColor} focus:outline-none`}
                    >
                        Request Callback
                    </button>
                </div>
                <div className="">
                    <button
                        onClick={() => { }}
                        className={`transition duration-500 hover:scale-105 md:hover:scale-110 transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-10 py-3 border border-${bgColor}-500 text-base font-medium rounded-md text-${bgColor}-700 bg-${bgColor}-100 hover:bg-${bgColor}-200 focus:outline-none`}
                    >
                        Chat
                    </button>
                </div>
                {/* <div className={`shadow ${bgColor} p-3 transition duration-500 hover:scale-105 md:hover:scale-110 transition duration-500 hover:scale-105 md:hover:scale-110 border border-transparent text-base font-medium rounded-md text-white ${bgColor} hover:${bgColor} hover:${bgColor} focus:outline-none`} style={{borderRadius: '50%'}}>
                    <ChatAltIcon className='w-6 h-6 text-white-500' />
                </div> */}
            </div>}
        </div>
    )
}

export default LandingActions