import React from 'react';
import { Helmet } from 'react-helmet';
import RequestCallback from '../components/RequestCallback';

const RegulatoryServices = () => {
  
    return (
        <>
            <Helmet>
                <title>Regulatory Services</title>
                <meta name="description" content="Let our FSSAI experts manage your regulatory work." />
            </Helmet>
            <div className="max-w-6xl mx-auto">
                <div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
                    <div className="mt-10 mx-auto max-w-6xl px-4 mt-20 sm:px-6 md:mt-16 lg:mt-32 lg:px-8">
                        <div className="sm:text-center lg:text-left">
                            <div className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                                <p className="block xl:inline">Let our FSSAI experts manage your regulatory work. </p>{' '}
                                {/* <p className="block text-purple-600 xl:inline">Introducing aahar.compliance!</p> */}
                            </div>
                            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                aahar.compliance will help you manage all FSSAI Compliance including New License Application, license modification, license renewal, etc. 
                            </p>
                            <div className="mt-5 md:mt-10 md:col-span-2">
                                {/* <form onSubmit={() => onSubmit}>
                                    <div className="overflow-hidden sm:rounded-md">
                                        <div className="px-2 py-5 bg-white sm:p-6">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                                        First name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        autoComplete="given-name"
                                                        placeholder="Name"
                                                        className="h-12 md:h-16 lg:h-16 mt-1 border focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-lg lg:text-lg border-black-300 rounded-md"
                                                    />
                                                </div>
                                                
                                                <div className="col-span-6 sm:col-span-3">
                                                    <input
                                                        type="text"
                                                        name="last-name"
                                                        id="last-name"
                                                        autoComplete="family-name"
                                                        placeholder="Phone Number"
                                                        className="h-12 md:h-16 lg:h-16 mt-1 border focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-lg lg:text-lg border-black-300 rounded-md"
                                                    />
                                                </div>

                                                <div className="col-span-6 sm:col-span-3">
                                                    <input
                                                        type="text"
                                                        name="email-address"
                                                        id="email-address"
                                                        autoComplete="email"
                                                        placeholder="Email"
                                                        className="h-12 md:h-16 lg:h-16 mt-1 border focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm md:text-lg lg:text-lg border-black-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 px-2 sm:mt-8 sm:flex sm:justify-left lg:justify-center md:block lg:block lg:mt-5">
                                            <div className="rounded-md shadow lg:w-96">
                                                <button
                                                    className="lg:hover-btn-light transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-500 hover:bg-purple-700 md:py-4 md:text-lg md:px-10 hover:bg-purple-700 focus:outline-none"
                                                >
                                                    Request Callback
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                                <RequestCallback topic='Regulatory Service' layoutStyle={'lg'} />
                            </div>  
                            
                            {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                <div className="rounded-md shadow">
                                <a
                                    href="#"
                                    className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 md:py-4 md:text-lg md:px-10"
                                >
                                    Get started
                                </a>
                                </div>
                                <div className="mt-3 sm:mt-0 sm:ml-3">
                                <a
                                    href="#"
                                    className="transition duration-500 hover:scale-105 md:hover:scale-110 w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-700 bg-purple-100 hover:bg-purple-200 md:py-4 md:text-lg md:px-10"
                                >
                                    Live demo
                                </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegulatoryServices;